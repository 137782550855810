import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Checkbox, Collapse, Grid, IconButton, Select, MenuItem, SelectChangeEvent, useTheme, Box } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import React, { useState } from 'react'
import { ILevel } from 'types/cvsInterfaces'
import { languageLevels, pickSkillColor, skillLevels } from 'utils/utils'
import colors from 'constants/colors'
import NewLevePicker from './NewLevelPicker'

/**
 * Level picker component.
 *
 * @param kind - Type of item.
 * @param value - The current value.
 * @param onChange - Function to call when the value changes.
 * @param text - Show select instead of checkboxes.
 * @param disabled - Is component disabled.
 * @param smallIcons - Use small icons.
 * @returns Level picker component.
 * @notExported
 */
const LevelPicker = <T extends ILevel>({
  kind,
  value,
  onChange,
  text = false,
  disabled = false,
  smallIcons = false,
  newPicker,
}: {
  kind: 'skill' | 'language'
  value: T | null
  onChange: (newValue: T | null) => void
  text?: boolean
  disabled?: boolean
  smallIcons?: boolean
  newPicker?: boolean
}) => {
  const theme = useTheme()
  const levels = kind === 'skill' ? skillLevels() : languageLevels()
  const [collapsed, setCollapsed] = useState(false)

  const handleChange = (
    e: React.ChangeEvent<{
      value: unknown
    }>
  ) => {
    onChange(Number(e.target.value) as T)
  }

  if (text) {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Select
          onChange={(e: SelectChangeEvent) => {
            onChange(Number(e.target.value) as T)
          }}
          value={String(value)}
        >
          {levels.map(level => (
            <MenuItem key={level.level} value={level.level}>
              {level.label}
            </MenuItem>
          ))}
        </Select>
        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: 0 }}>
          <Grid
            sx={{
              color: theme.palette.neutral.main,
              fontSize: '12px',
              fontFamily: 'Helvetica',
              textTransform: 'capitalize',
            }}
          >
            {levels.find(levelValue => levelValue.level === value)?.label ?? null}
          </Grid>
          {kind === 'language' && (
            <IconButton onClick={() => setCollapsed(!collapsed)} sx={{ marginLeft: 5 }} size="large">
              {!collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          )}
        </Grid>
        {kind === 'language' && (
          <Collapse
            sx={{ whiteSpace: 'pre-wrap', textAlign: 'justify', maxWidth: '400px', marginTop: 0 }}
            in={collapsed}
          >
            {levels.find(levelValue => levelValue.level === value)?.description ?? null}
          </Collapse>
        )}
      </Grid>
    )
  } else {
    if (newPicker) {
      return (
        <>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <NewLevePicker
              value={value}
              onChange={(newValue: string) => onChange(parseInt(newValue) as T)}
              disabled={disabled}
            />
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: 0 }}>
            <Grid
              sx={{
                color: theme.palette.neutral.main,
                fontSize: '12px',
                fontFamily: 'Helvetica',
                textTransform: 'capitalize',
              }}
            >
              {levels.find(levelValue => levelValue.level === value)?.label ?? null}
            </Grid>
            {kind === 'language' && (
              <IconButton onClick={() => setCollapsed(!collapsed)} sx={{ marginLeft: 5 }} size="large">
                {!collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            )}
          </Grid>
          {kind === 'language' && (
            <Collapse
              sx={{ whiteSpace: 'pre-wrap', textAlign: 'justify', maxWidth: '400px', marginTop: 0 }}
              in={collapsed}
            >
              {levels.find(levelValue => levelValue.level === value)?.description ?? null}
            </Collapse>
          )}
        </>
      )
    } else {
      return (
        <>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            {levels.map((tempValue, index) => (
              <Checkbox
                key={'levelStar' + index}
                disabled={disabled}
                onChange={handleChange}
                checked={(value ?? 0) > index}
                value={tempValue.level}
                icon={<CircleOutlinedIcon fontSize={smallIcons ? 'small' : 'large'} color="disabled" />}
                checkedIcon={
                  <>
                    <CircleIcon
                      fontSize={smallIcons ? 'small' : 'large'}
                      sx={{ color: pickSkillColor(tempValue.level) }}
                    />
                    <Box
                      sx={
                        smallIcons
                          ? {
                              color: colors.background,
                              fontSize: '0.7rem',
                              position: 'absolute',
                              top: '25%',
                              left: '40%',
                            }
                          : {
                              color: colors.background,
                              fontSize: '1rem',
                              position: 'absolute',
                              top: '27%',
                              left: '41%',
                            }
                      }
                    >
                      {tempValue.level}
                    </Box>
                  </>
                }
                name={'levelStar' + index}
                sx={smallIcons ? { padding: 1 } : {}}
                size={smallIcons ? 'small' : 'medium'}
                color="warning"
              />
            ))}
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: 0 }}>
            <Grid
              sx={{
                color: theme.palette.neutral.main,
                fontSize: '12px',
                fontFamily: 'Helvetica',
                textTransform: 'capitalize',
              }}
            >
              {levels.find(levelValue => levelValue.level === value)?.label ?? null}
            </Grid>
            {kind === 'language' && (
              <IconButton onClick={() => setCollapsed(!collapsed)} sx={{ marginLeft: 5 }} size="large">
                {!collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            )}
          </Grid>
          {kind === 'language' && (
            <Collapse sx={{ marginLeft: 5, marginTop: 0 }} in={collapsed}>
              {levels.find(levelValue => levelValue.level === value)?.description ?? null}
            </Collapse>
          )}
        </>
      )
    }
  }
}

export default LevelPicker
