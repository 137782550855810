import { Grid } from '@mui/material'
import React from 'react'
import CardContent from './CardContent'
import CardContentText from './CardContentText'

/** @notExported */
interface ExtendedCardContentProps {
  /** Card content description. */
  description
  /** Card content skills. */
  skills?
  /** Card content children. */
  children: React.ReactNode
}

/**
 * Extended card content component.
 *
 * @returns Extended card content element.
 * @notExported
 */
const ExtendedCardContent: React.FC<ExtendedCardContentProps> = ({ description, skills, children }) => {
  return (
    <Grid container justifyContent="flex-start" direction="row" alignItems="flex-start" spacing={2}>
      <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
        <CardContent>
          <CardContent wrapper>{children}</CardContent>
        </CardContent>
      </Grid>
      <Grid item xs={12} sm={6} md={7} lg={8} xl={9}>
        <CardContentText type={'description'}>{description}</CardContentText>
      </Grid>
      {skills && (
        <Grid item xs={12}>
          <CardContent>
            <CardContent wrapper>{skills}</CardContent>
          </CardContent>
        </Grid>
      )}
    </Grid>
  )
}

export default ExtendedCardContent
