import { ListItemIcon, Menu, MenuItem } from '@mui/material'
import PublicIcon from '@mui/icons-material/Public'
import React, { useEffect, useState } from 'react'
import Flag from 'react-world-flags'
import { ALL_LANGUAGES, COUNTRY_CODES } from 'constants/caleo-constants'
import CaleoIconButton from 'Components/reusable/IconButtons/CaleoIconButton'
import { useTranslation } from 'react-i18next'

/** @notExported */
interface ILanguageSelectorProps {
  /** Initiallly selected language. */
  initialLanguage: string
  /** If true, language selector will be global. */
  global?: boolean
  /** Id of the language selector items. */
  id?: string | number
  /** If true, language selector will be open. */
  open?: boolean
  /** Anchor element of the language menu. */
  languageMenuAnchorEl?: Element | null
  /** Function to set global/element language. */
  setLanguage(lng: string): void
}

/**
 * Application language selector controls.
 *
 * @returns Language selector component.
 * @notExported
 */
const LanguageSelector: React.FC<ILanguageSelectorProps> = ({
  initialLanguage,
  global,
  id,
  open,
  languageMenuAnchorEl = null,
  setLanguage,
}) => {
  const { t, i18n } = useTranslation()
  const [lang, setLang] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(open ?? false)
  const [anchorEl, setAnchorEl] = useState<Element | null>(languageMenuAnchorEl)

  let toolTip = ''
  if (global) toolTip = t('controls.selectgloballanguage')
  else toolTip = t('controls.selectdialoglanguage')

  useEffect(() => {
    if (initialLanguage) {
      setLang(initialLanguage)
    }
  }, [initialLanguage])

  useEffect(() => {
    if (lang) {
      setLanguage(lang)
    }
  }, [lang])

  const handleLanguage = (value: string) => {
    setLang(value)
    setAnchorEl(null)
    setIsOpen(false)
  }

  const handleOpenLanguageMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
    setIsOpen(true)
  }

  const handleCloseLanguageMenu = () => {
    setAnchorEl(null)
    setIsOpen(false)
  }

  return (
    <div>
      <CaleoIconButton tooltip={toolTip} clickAction={handleOpenLanguageMenu} icon={<PublicIcon />} size="medium" />
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen || false}
        onClose={handleCloseLanguageMenu}
      >
        {ALL_LANGUAGES.map(language => {
          const countryCode = COUNTRY_CODES[language]
          return (
            <MenuItem
              key={language}
              id={id ? `${id}-${language}` : language}
              onClick={() => handleLanguage(language)}
              style={{ width: '150px' }}
            >
              <>
                <ListItemIcon>
                  <Flag style={{ height: '16px', width: '24px' }} code={countryCode} fallback={<span>??</span>} />
                </ListItemIcon>
                {i18n.t(`languages.${language}`)}
              </>
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

export default LanguageSelector
