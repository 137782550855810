import React from 'react'
import { Grid } from '@mui/material'

/** @notExported  */
interface CardContentProps {
  /** Whether the card content is wrapped in a flexbox. */
  wrapper?
  /** Card content. */
  children: React.ReactNode
}

/**
 * Card content.
 *
 * @returns Card content component.
 * @notExported
 */
const CardContent: React.FC<CardContentProps> = ({ wrapper, children }) => {
  return (
    <Grid
      sx={
        wrapper
          ? {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'start',
            }
          : {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%',
            }
      }
    >
      {children}
    </Grid>
  )
}

export default CardContent
