import DeleteIcon from '@mui/icons-material/DeleteSweep'
import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

/** @notExported  */
interface DeleteButtonProps {
  /** Action to be performed when the button is clicked. */
  clickAction: () => void
  /** Custom label for the button. */
  customLabel?: string
}

/**
 * Delete button.
 *
 * @returns Delete button component.
 * @notExported
 * @category Buttons
 */
const DeleteButton: React.FC<DeleteButtonProps> = ({ clickAction, customLabel }) => {
  const { t } = useTranslation()
  return (
    <Button
      startIcon={<DeleteIcon />}
      color="primary"
      aria-label={t('delete')}
      sx={{
        color: theme => theme.palette.text.primary,
        verticalAlign: 'center',
        '&:hover': {
          backgroundColor: theme => theme.palette.error.light,
          color: theme => theme.palette.error.dark,
        },
      }}
      onClick={() => clickAction()}
    >
      {customLabel ?? t('delete')}
    </Button>
  )
}

export default DeleteButton
