import { DialogContent, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import { cvCertificatesAPI, cvCoursesAPI, cvEducationsAPI } from 'api/CvAPI'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ICertificate, ICertificateTranslation, ICourse, ICourseTranslation, IEducation } from 'types/cvsInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import DataContext from 'Components/reusable/DataContext'
import {
  getCertificatesInitialData,
  getCourseInitialData,
  getEducationInitialData,
} from 'Components/reusable/DataContext/InitialData'
import { certificateSchema, courseSchema, educationSchema } from 'Components/reusable/DataContext/ValidationSchema'
import newWithEditModal from 'Components/reusable/HOC/newWithEditModal'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported */
interface EducationModalProps {
  /** Education item. */
  item?: IEducation | ICourse | ICertificate | null
  /** CV id. */
  cvId: number
  /** Set data function. */
  setData: (dataObject) => void
  /** Set locale base function. */
  setLocaleBase: (localeBase) => void
  /** Set api function. */
  setApi: (api) => void
  /** Set schema function. */
  setSchema: (schema) => void
  /** Education item. */
  education?: IEducation
  /** Course item. */
  course?: ICourse
  /** Certificate item. */
  certificate?: ICertificate
  /** Data object. */
  data
}

/**
 * Education modal.
 *
 * @returns Education modal component.
 * @notExported
 */
const EducationModal: React.FC<EducationModalProps> = ({
  item,
  cvId,
  setData,
  setLocaleBase,
  setApi,
  setSchema,
  education,
  course,
  certificate,
  data,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const { t, i18n } = useTranslation()
  const [educationType, setEducationType] = useState<string | null>(null)
  const courseTranslation: ICourseTranslation = chooseDBTranslation(i18n, item as ICourse)
  const certTranslation: ICertificateTranslation = chooseDBTranslation(i18n, item as ICertificate)

  useEffect(() => {
    if (courseTranslation?.course?.length) setEducationType('courses')
    else if (certTranslation?.certificate?.length) setEducationType('certificates')
    else setEducationType('degrees')
  }, [])

  useEffect(() => {
    ;(async () => {
      if (education) {
        setEducationType('degrees')
      } else if (course) {
        setEducationType('courses')
      } else if (certificate) {
        setEducationType('certificates')
      }

      let dataItem
      let schema
      let api
      let localeBase
      if (educationType === 'degrees') {
        if (education) {
          dataItem = await getEducationInitialData(cvId, education)
        } else {
          dataItem = await getEducationInitialData(cvId)
        }
        schema = educationSchema()
        api = cvEducationsAPI
        localeBase = 'profile.education.modal'
      } else if (educationType === 'certificates') {
        if (certificate) {
          dataItem = await getCertificatesInitialData(cvId, certificate)
        } else {
          dataItem = await getCertificatesInitialData(cvId)
        }
        schema = certificateSchema()
        api = cvCertificatesAPI
        localeBase = 'profile.certificate.modal'
      } else {
        if (course) {
          dataItem = await getCourseInitialData(cvId, course)
        } else {
          dataItem = await getCourseInitialData(cvId)
        }
        schema = courseSchema()
        api = cvCoursesAPI
        localeBase = 'profile.course.modal'
      }
      if (isComponentMounted.current) {
        if (!item) {
          setData(dataItem)
        }
        setSchema(schema)
        setApi(api)
        setLocaleBase(localeBase)
      }
    })()
  }, [educationType])

  // item-specific fields
  let fields: JSX.Element
  let dateFields: JSX.Element
  if (educationType === 'certificates') {
    fields = (
      <>
        <DataContext.TextField<ICertificate> field="certificate" required fullWidth insetLabel />
        <DataContext.TextField<ICertificate> field="certifier" required fullWidth insetLabel />
        <DataContext.TextField<ICertificate> field="licenceNumber" fullWidth insetLabel />
        <DataContext.TextField<ICertificate> field="url" fullWidth insetLabel />
      </>
    )
    dateFields = (
      <>
        <Grid item xs={6}>
          <DataContext.DateResolutionField<ICertificate> field="dateResolution" required />
        </Grid>
        <Grid item xs={6}>
          <DataContext.DateField<ICertificate> field="issueDate" required insetLabel />
          <DataContext.DateField<ICertificate>
            field="endDate"
            isClearable
            end={true}
            minDate={data.issueDate || null}
            insetLabel
          />
        </Grid>
      </>
    )
  } else if (educationType === 'courses') {
    fields = (
      <>
        <DataContext.TextField<ICourse> field="course" required fullWidth insetLabel />
        <DataContext.TextField<ICourse> field="organizer" required fullWidth insetLabel />
      </>
    )
    dateFields = (
      <>
        <Grid item xs={6}>
          <DataContext.DateResolutionField<ICourse> field="dateResolution" required />
        </Grid>
        <Grid item xs={6}>
          <DataContext.DateField<ICourse> field="startDate" required start={true} insetLabel />
          <DataContext.DateField<ICourse>
            field="endDate"
            isClearable
            end={true}
            minDate={data.startDate || null}
            insetLabel
          />
        </Grid>
      </>
    )
  } else {
    fields = (
      <>
        <DataContext.TextField<IEducation> field="degree" required fullWidth insetLabel />
        <DataContext.TextField<IEducation> field="school" required fullWidth insetLabel />
      </>
    )
    dateFields = (
      <>
        <Grid item xs={6}>
          <DataContext.DateResolutionField<IEducation> field="dateResolution" required />
        </Grid>
        <Grid item xs={6}>
          <DataContext.DateField<IEducation> field="startDate" required start={true} insetLabel />
          <DataContext.DateField<IEducation>
            field="endDate"
            isClearable
            end={true}
            minDate={data.startDate || null}
            insetLabel
          />
        </Grid>
      </>
    )
  }

  return (
    <DialogContent>
      <Grid container spacing={2} direction="row">
        <Grid item xs={6}>
          <Grid container spacing={2} direction="column">
            {item ? null : (
              <Grid item>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="educationType"
                    value={educationType}
                    onChange={e => setEducationType(e.target.value)}
                  >
                    <FormControlLabel value="degrees" control={<Radio />} label={t('profile.education.modal.degree')} />
                    <FormControlLabel value="courses" control={<Radio />} label={t('profile.course.modal.course')} />
                    <FormControlLabel
                      value="certificates"
                      control={<Radio />}
                      label={t('profile.certificate.modal.certificate')}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
            <Grid item>
              <DataContext.TranslationImport<ICertificate | ICourse | IEducation> />
              {fields}
              <Grid container alignItems="center" direction="row">
                {dateFields}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <DataContext.TextField<IEducation | ICourse | ICertificate>
            field="description"
            maxHintLength={1000}
            multiline
            grow
            rows={21}
            fullWidth
            insetLabel
          />
        </Grid>
      </Grid>
    </DialogContent>
  )
}

export default newWithEditModal(EducationModal)
