import { INetwork } from 'types/networkInterfaces'
import { IAccount } from 'types/userInterfaces'
import { SubAPI } from './API'

/**
 * User API.
 * @notExported
 */
class UserAPI extends SubAPI {
  /**
   * Get user data by id.
   *
   * @param userId - Account ID.
   * @returns Account data.
   */
  public getUserData(userId: number, controller?: AbortController): Promise<IAccount> {
    return this.api.get(`users/${userId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Set user language.
   *
   * @param language - Language code.
   * @returns Promise of language change.
   */
  public setLanguage(language: string, controller?: AbortController): Promise<void> {
    return this.api.post(`users/language`, { language }, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get sales networks.
   *
   * @returns Sales networks.
   */
  public getSalesNetworks(controller?: AbortController): Promise<INetwork[]> {
    return this.api.get('/users/networks', controller ? { signal: controller.signal } : undefined)
  }
}

export const userAPI = new UserAPI()
