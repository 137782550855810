import { IconButton, SxProps, Tooltip, useTheme } from '@mui/material'
import React, { MouseEventHandler } from 'react'

/** @notExported */
interface CaleoIconButtonProps {
  /** Action to perform when button is clicked. */
  clickAction?: MouseEventHandler<unknown>
  /** Tooltip for button. */
  tooltip?: string | React.ReactNode
  /** Whether or not the button is valid. */
  valid?: boolean
  /** Size of the button. */
  size?: 'medium' | 'small' | undefined | 'xsmall' | 'large'
  /** Icon to display. */
  icon
  /** Whether or not the button should have a colored background. */
  coloredBackground?: boolean
  /** Additional styles to apply to the button. */
  sx?: SxProps
}

/**
 * Caleo icon button component.
 *
 * @returns Caleo icon button component.
 * @notExported
 * @category Buttons
 */
const CaleoIconButton: React.FC<CaleoIconButtonProps> = ({
  clickAction,
  tooltip,
  valid = true,
  size = 'small',
  icon,
  coloredBackground,
  sx,
}) => {
  const theme = useTheme()

  const getButton = () => {
    return (
      <IconButton
        sx={Object.assign(
          coloredBackground
            ? {
                float: 'right',
                border: `1px solid ${theme.palette.secondary.main}`,
                background: theme.palette.secondary.light,
              }
            : {
                float: 'right',
              },
          sx ?? {}
        )}
        onClick={clickAction}
        disabled={!valid}
        size={size === 'xsmall' ? 'medium' : size}
      >
        {icon}
      </IconButton>
    )
  }
  return <>{tooltip ? <Tooltip title={tooltip}>{!valid ? <>{getButton()}</> : getButton()}</Tooltip> : getButton()}</>
}

export default CaleoIconButton
