import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import React from 'react'
import { IOrganizationType, ISearchFields } from 'types/searchInterfaces'
import { fuseFiltering, organizationSearchTypes } from 'utils/utils'

/** @notExported  */
interface IOrganizationTypePickerProps {
  /** The current value. */
  value: IOrganizationType | null
  /** Action to do when the value changes. */
  onChange: (newValue: ISearchFields | null) => void
  /** The label text. */
  label?: string
  /** Whether the field is required. */
  required?: boolean
  /** Whether the field is disabled. */
  disabled: boolean
}

/**
 * Organization type picker component.
 *
 * @returns The organization type picker component.
 * @notExported
 */
const OrganizationTypePicker: React.FC<IOrganizationTypePickerProps> = ({
  value,
  onChange,
  label = 'State',
  required = false,
  disabled,
}) => {
  const typeOptions = organizationSearchTypes()

  const getSelectedValue = () => {
    if (value) {
      const selectedValueItem = typeOptions.find(item => item.value === value)
      return selectedValueItem
    } else {
      return typeOptions[0]
    }
  }

  return (
    <>
      <CaleoInputLabel label={label} required={required} />
      <Autocomplete
        filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['label'])}
        disabled={disabled}
        value={getSelectedValue()}
        options={typeOptions}
        fullWidth
        selectOnFocus
        disableClearable
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(selectedValue, option) => option.value === selectedValue.value}
        style={{ marginTop: '0px' }}
        onChange={(_event, newValue) => {
          if (newValue !== null) {
            onChange(newValue.value as unknown as ISearchFields)
          }
        }}
        renderInput={params => (
          <TextField {...params} name="organizationType" margin="dense" size="small" fullWidth variant="outlined" />
        )}
      />
    </>
  )
}

export default OrganizationTypePicker
