import { useMediaQuery } from '@mui/material'
import React, { useState, useContext, useEffect } from 'react'

interface MenuState {
  showMenu: boolean
  setMenu: (open: boolean) => void
  drawerWidth: string
}

const menuContext = React.createContext<MenuState>({} as MenuState)

const { Provider } = menuContext

/**
 * Provider for handling the navigation menu type and visibility.
 *
 * @param children
 * @returns Navigation menu provider.
 * @notExported
 */
export const MenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [showMenu, setMenu] = useState<boolean>(true)
  const smallScreen = useMediaQuery('(min-width:1400px)', { noSsr: true })
  const drawerWidth = showMenu ? '240px' : '72px'

  useEffect(() => {
    setMenu(smallScreen)
  }, [])

  return <Provider value={{ showMenu, setMenu, drawerWidth }}>{children}</Provider>
}

/**
 * Hook for getting navigation menu state.
 *
 * @returns Navigation menu context variables and functions.
 * @notExported
 */
export function useMenu(): MenuState {
  return useContext(menuContext)
}
