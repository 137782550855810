import { DialogContent } from '@mui/material'
import newWithEditModal from 'Components/reusable/HOC/newWithEditModal'
import DataContext from 'Components/reusable/DataContext'
import React from 'react'
import { IUrl } from 'types/cvsInterfaces'

/**
 * Modal for adding new URL to profile.
 *
 * @returns URL modal component.
 * @notExported
 */
const LinkModal: React.FC = () => {
  return (
    <DialogContent>
      <DataContext.TextField<IUrl> field="url" required fullWidth insetLabel />
      <DataContext.TextField<IUrl> field="description" required multiline grow rows={12} fullWidth insetLabel />
    </DialogContent>
  )
}

export default newWithEditModal(LinkModal)
