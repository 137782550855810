import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ISearchCertificate, ISearchDegree, ISearchCourse } from 'types/searchInterfaces'
import * as Yup from 'yup'
import DataContext from 'Components/reusable/DataContext'
import LoadingIndicator from 'Components/reusable/LoadingIndicator'
import AddButton from 'Components/reusable/IconButtons/AddButton'
import { useTranslation } from 'react-i18next'
import { useSearchData } from 'Components/General/SearchProvider/SearchProvider'

/** @notExported */
interface IEditEducationFieldsProps {
  /** The function to add a new item. */
  onAdd: (newItem: ISearchCertificate | ISearchDegree | ISearchCourse) => void
  /** The type of education. */
  type: 'certificate' | 'degree' | 'course'
  /** Whether to show the add button. */
  noButton?: boolean
  /** Whether to show inset label. */
  insetLabel?: boolean
}

/**
 * The EducationSearchRequirement component.
 *
 * @param onAdd - The function to add a new item.
 * @param type - The type of education.
 * @returns The EducationSearchRequirement component.
 * @notExported
 */
const EducationSearchRequirement: React.FC<IEditEducationFieldsProps> = ({ onAdd, type, noButton, insetLabel }) => {
  const [data, setData] = useState<ISearchCertificate | ISearchDegree | ISearchCourse | null>(null)
  let schema
  const { t } = useTranslation()
  const { resetFields, setResetFields } = useSearchData()

  useEffect(() => {
    switch (type) {
      case 'certificate':
        setData({
          certificate: '',
        } as ISearchCertificate)

        schema = Yup.object({
          certificate: Yup.string().nullable(),
        })

        break

      case 'degree':
        setData({
          degree: '',
        } as ISearchDegree)

        schema = Yup.object({
          degree: Yup.string().nullable(),
        })

        break

      case 'course':
        setData({
          course: '',
        } as ISearchCourse)

        schema = Yup.object({
          course: Yup.string().nullable(),
        })

        break
    }
  }, [type])

  useEffect(() => {
    if (resetFields) {
      switch (type) {
        case 'certificate':
          setData({ certificate: '' } as ISearchCertificate)
          break
        case 'degree':
          setData({ degree: '' } as ISearchDegree)
          break
        case 'course':
          setData({ course: '' } as ISearchCourse)
          break
      }
      setResetFields(false)
    }
  }, [resetFields, setResetFields])

  if (!data) {
    return <LoadingIndicator />
  }

  const add = async () => {
    if (data[type]?.length) {
      switch (type) {
        case 'certificate':
          setData({ certificate: '' } as ISearchCertificate)
          break
        case 'degree':
          setData({ degree: '' } as ISearchDegree)
          break
        case 'course':
          setData({ course: '' } as ISearchCourse)
          break
      }
      onAdd(data)
    }
  }

  return (
    <DataContext data={data} onSubmit={add} localeBase="resources.searchEmployees" schema={schema} onChange={setData}>
      <Grid item container alignItems="flex-end" spacing={2}>
        <Grid item xs={noButton ? 12 : 10}>
          {type === 'certificate' && (
            <DataContext.TextField<ISearchCertificate>
              field="certificate"
              fullWidth
              insetLabel={insetLabel}
              InputLabelProps={{ shrink: true }}
            />
          )}
          {type === 'course' && (
            <DataContext.TextField<ISearchCourse>
              field="course"
              fullWidth
              insetLabel={insetLabel}
              InputLabelProps={{ shrink: true }}
            />
          )}
          {type === 'degree' && (
            <DataContext.TextField<ISearchDegree>
              field="degree"
              fullWidth
              insetLabel={insetLabel}
              InputLabelProps={{ shrink: true }}
            />
          )}
        </Grid>
        {!noButton && (
          <Grid item xs={2}>
            <AddButton valid={!!data[type] || data[type].length > 1} clickAction={add} tooltip={t('add')} />
          </Grid>
        )}
      </Grid>
    </DataContext>
  )
}

export default EducationSearchRequirement
