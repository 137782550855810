import { ISkillCharts, ISkillTotals, ISkillDemand } from 'types/reportsInterfaces'
import {
  ISkill,
  IIndustry,
  IProject,
  ISkillOrIndustryOrRole,
  IRole,
  IEmployer,
  ISkillSuggestions,
} from 'types/cvsInterfaces'
import { SubAPI } from './API'
import { updateDate } from '../utils/utils'

/**
 * Skill API.
 * @notExported
 */
class SkillAPI extends SubAPI {
  /**
   * Get all types of all skills.
   *
   * @returns Array of skills.
   */
  public getAll(controller?: AbortController): Promise<ISkillOrIndustryOrRole[]> {
    return this.api.get(`skills`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get all types of all unpromoted skills.
   *
   * @returns Array of skills.
   */
  public getAllUnpromoted(controller?: AbortController): Promise<ISkillOrIndustryOrRole[]> {
    return this.api.get('skills/unpromoted', controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get all skills.
   *
   * @returns Array of skills.
   */
  public getAllSkills(controller?: AbortController): Promise<ISkill[]> {
    return this.api.get(`skills/skills`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get all unpromoted skills.
   *
   * @returns Array of skills.
   */
  public getAllUnpromotedSkills(controller?: AbortController): Promise<ISkill[]> {
    return this.api.get(`skills/unpromoted/skills`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get all industries.
   *
   * @returns Array of industries.
   */
  public getAllIndustries(controller?: AbortController): Promise<IIndustry[]> {
    return this.api.get(`skills/industries`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get all unpromoted industries.
   *
   * @returns Array of industries.
   */
  public getAllUnpromotedIndustries(controller?: AbortController): Promise<IIndustry[]> {
    return this.api.get(`skills/unpromoted/industries`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get all roles.
   *
   * @returns Array of roles.
   */
  public getAllRoles(controller?: AbortController): Promise<IRole[]> {
    return this.api.get(`skills/roles`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get all unpromoted roles.
   *
   * @returns Array of roles.
   */
  public getAllUnpromotedRoles(controller?: AbortController): Promise<IRole[]> {
    return this.api.get(`skills/unpromoted/roles`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get skill suggestions for a role.
   *
   * @param roleId - Role ID.
   * @returns Array of skills.
   */
  public getSkillSuggestions(roleId: number, controller?: AbortController): Promise<ISkillSuggestions> {
    return this.api.get(`skills/suggestions/${roleId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get all skill totals.
   *
   * @param body - Totals body.
   * @returns Array of skill totals data.
   */
  public getAllSkillTotals(
    body: { type: string; isPromoted: boolean },
    controller?: AbortController
  ): Promise<ISkillTotals[]> {
    return this.api.post(`reports/skills/totals`, body, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get skill totals for a team.
   *
   * @param teamId - Team ID.
   * @param body - Totals body.
   * @returns Array of skill totals data for the team.
   */
  public getTeamSkillTotals(
    teamId: number,
    body: { type: string; isPromoted: boolean },
    controller?: AbortController
  ): Promise<ISkillTotals[]> {
    return this.api.post(
      `reports/skills/totals/teams/${teamId}`,
      body,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get all skill charts data.
   *
   * @param body - Chart body.
   * @returns Array of skill charts data.
   */
  public getAllSkillCharts(
    body: { type: string; isPromoted: boolean },
    controller?: AbortController
  ): Promise<ISkillCharts[]> {
    return this.api.post(`reports/skills/charts`, body, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get skill charts data for a team.
   *
   * @param teamId - Team ID.
   * @param body - Chart body.
   * @returns Array of skill charts data for the team.
   */
  public getTeamSkillCharts(
    teamId: number,
    body: { type: string; isPromoted: boolean },
    controller?: AbortController
  ): Promise<ISkillCharts[]> {
    return this.api.post(
      `reports/skills/charts/teams/${teamId}`,
      body,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Update skill.
   *
   * @param data - Skill data.
   * @returns Updated skill.
   */
  public putSkill(data, controller?: AbortController): Promise<ISkill> {
    return this.api.put(`skills/${data.skill.id}`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Create skill.
   *
   * @param body - Skill data.
   * @returns Created skill.
   */
  public createSkill(
    body: { name: string; language: string; kind?: string },
    controller?: AbortController
  ): Promise<ISkillOrIndustryOrRole> {
    return this.api.post(`skills`, body, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Delete skill.
   *
   * @param id - Skill ID.
   * @returns Promise of skill deletion.
   */
  public delete(id: number, controller?: AbortController): Promise<void> {
    return this.api.delete(`skills/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Merge skills.
   *
   * @param newSkillId - New skill ID.
   * @param oldSkills - Old skill IDs.
   * @returns Promise of skill merge.
   */
  public merge(newSkillId: number, oldSkills: number[], controller?: AbortController): Promise<void> {
    return this.api.put(
      `skills/merge`,
      { newSkillId, oldSkills },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Translate skill.
   *
   * @param data - Skill translations data.
   * @returns Updated skill.
   */
  public translate(data, controller?: AbortController): Promise<ISkillOrIndustryOrRole> {
    return this.api.put(`skills/translate`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Split skills.
   *
   * @param oldSkillId - Old skill ID.
   * @param newSkillIds - New skill IDs.
   * @returns Promise of skill split.
   */
  public split(oldSkillId: number, newSkillIds: number[], controller?: AbortController): Promise<void> {
    return this.api.put(
      'skills/split',
      { oldSkillId, newSkillIds },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Promote skill.
   *
   * @param id - Skill ID.
   * @returns Promise of skill promotion.
   */
  public promote(id: number, controller?: AbortController): Promise<ISkillOrIndustryOrRole> {
    return this.api.put(`skills/${id}/promote`, undefined, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get projects by SkillId matching personSkill.
   *
   * @param id - Skill ID.
   */
  public async getSkillProjects(id: number, controller?: AbortController): Promise<IProject[]> {
    const data: IProject[] = await this.api.get(
      `cvs/personSkills/projects/${id}`,
      controller ? { signal: controller.signal } : undefined
    )
    data.forEach(updateDate)
    return data
  }

  /**
   * Get projects by SkillId matching industry.
   *
   * @param id - Skill ID.
   */
  public async getIndustryProjects(id: number, controller?: AbortController): Promise<IProject[]> {
    const data: IProject[] = await this.api.get(
      `cvs/industry/projects/${id}`,
      controller ? { signal: controller.signal } : undefined
    )
    data.forEach(updateDate)
    return data
  }

  /**
   * Get projects by SkillId matching role.
   *
   * @param id - Skill ID.
   */
  public async getRoleProjects(id: number, controller?: AbortController): Promise<IProject[]> {
    const data: IProject[] = await this.api.get(
      `cvs/role/projects/${id}`,
      controller ? { signal: controller.signal } : undefined
    )
    data.forEach(updateDate)
    return data
  }

  /**
   * Get employers by SkillId matching role.
   *
   * @param id - Skill ID.
   */
  public async getRoleEmployers(id: number, controller?: AbortController): Promise<IEmployer[]> {
    const data: IEmployer[] = await this.api.get(
      `cvs/role/employers/${id}`,
      controller ? { signal: controller.signal } : undefined
    )
    data.forEach(updateDate)
    return data
  }

  /**
   * Import skill trends.
   *
   * @param data - Trends data file.
   * @returns Promise of skill trends import.
   */
  public importTrends(data: FormData, controller?: AbortController): Promise<void> {
    return this.api.post(`skills/trend/import`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Download skill trends export.
   *
   * @returns Excel file of skill trends.
   */
  public downloadExport(controller?: AbortController): Promise<Blob> {
    return this.api.get('skills/trend/export', controller ? { signal: controller.signal } : undefined, undefined, {
      responseType: 'blob',
    })
  }

  /**
   * Get demand statistics.
   *
   * @param selectedTeam - Selected team ID.
   * @param type - Skill type.
   * @param isPromoted - Include promoted?
   * @returns Skill demand statistics.
   */
  public getDemandStatistics(
    selectedTeam: number,
    type: string,
    isPromoted: boolean,
    controller?: AbortController
  ): Promise<ISkillDemand[]> {
    return this.api.post(
      'reports/skills/charts/demand',
      { selectedTeam, type, isPromoted },
      controller ? { signal: controller.signal } : undefined
    )
  }
}

export const skillAPI = new SkillAPI()
