import { SubAPI } from './API'
import { IUrl } from '../types/cvsInterfaces'

/**
 * URL API
 * @notExported
 */
class UrlAPI extends SubAPI {
  /**
   * Get URLs for CV.
   *
   * @param cvId - CV ID.
   * @returns Array of URLs.
   */
  public getUrls(cvId: number, controller?: AbortController): Promise<IUrl[]> {
    return this.api.get(`urls/${cvId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Create URL for CV.
   *
   * @param cvId - CV ID.
   * @param data - URL data.
   * @returns Promise of URL creation.
   */
  public create(cvId: number, data: unknown): Promise<void> {
    return this.api.post(`urls/${cvId}`, data)
  }

  /**
   * Update URL for CV.
   *
   * @param item - URL item.
   * @param data - Updated data.
   * @returns Promise of URL deletion.
   */
  public save(item: IUrl, data: unknown): Promise<void> {
    return this.api.put(`urls/${item.cvId}`, data)
  }

  /**
   * Delete URL from CV.
   *
   * @param id - URL ID.
   * @param cvId - CV ID.
   * @returns Promise of URL deletion.
   */
  public delete(id: number, cvId: number): Promise<void> {
    return this.api.delete(`urls/${cvId}?id=${id}`)
  }
}

export const urlAPI = new UrlAPI()
