import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import React, { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import { InputField } from '../InputField'

interface OwnProps {
  className?: string
  error?: string | false
  fullWidth?: boolean
  grow?: boolean
  helperText?: string | null
  label?: string
  required?: boolean
  style?: React.CSSProperties
  inputType?: string
  setInputType?: (type) => void
  expandedInputOptions?: boolean
  insetLabel?: boolean
}

/**
 * Experience time picker component.
 *
 * @param className - {string} Class name for styling the component.
 * @param error - {string | false} Error message.
 * @param fullWidth - {boolean} Should component be full width.
 * @param grow - {boolean} Should component grow.
 * @param helperText - {string | null} Helper text.
 * @param label - {string} Label text.
 * @param required - {boolean} Is the component required.
 * @param style - {React.CSSProperties} Style parameter of the component.
 * @param inputType - {string} Input type.
 * @param setInputType - Function to set input type.
 * @param expandedInputOptions - {boolean} Should the expanded options be displayed.
 * @returns the experience time picker component.
 */
export const ExperienceTimePicker: React.FC<OwnProps & Omit<ComponentProps<typeof TextField>, keyof OwnProps>> = ({
  className,
  error,
  fullWidth,
  grow,
  helperText,
  label,
  required,
  style: styleProp = {},
  inputType,
  setInputType,
  expandedInputOptions = false,
  insetLabel,
  ...rest
}) => {
  const { t } = useTranslation()

  const style = {
    ...styleProp,
    width: fullWidth ? '100%' : undefined,
    flexGrow: grow ? 1 : undefined,
  }

  return (
    <Grid style={style}>
      {!insetLabel && <CaleoInputLabel required={required} label={label} />}
      {inputType && setInputType && (
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="experienceInputType"
            value={inputType}
            onChange={e => setInputType(e.target.value)}
          >
            <Grid container direction="row">
              <FormControlLabel value="years" control={<Radio />} label={t('profile.skill.modal.experience.years')} />
              <FormControlLabel value="months" control={<Radio />} label={t('profile.skill.modal.experience.months')} />
              {expandedInputOptions && (
                <>
                  <FormControlLabel value="days" control={<Radio />} label={t('profile.skill.modal.experience.days')} />
                  <FormControlLabel
                    value="manDays"
                    control={<Radio />}
                    label={t('profile.skill.modal.experience.manDays')}
                  />
                  <FormControlLabel
                    value="manMonths"
                    control={<Radio />}
                    label={t('profile.skill.modal.experience.manMonths')}
                  />
                </>
              )}
            </Grid>
          </RadioGroup>
        </FormControl>
      )}
      <InputField
        margin="dense"
        FormHelperTextProps={{ style: { textAlign: 'right' } }}
        error={error}
        className={`${className}`}
        label={insetLabel ? label : undefined}
        variant="outlined"
        helperText={error ? error : helperText}
        insetLabel={insetLabel}
        {...{ ...rest, fullWidth }}
      />
    </Grid>
  )
}
