import { Grid } from '@mui/material'
import { useSearchData } from 'Components/General/SearchProvider/SearchProvider'
import DataContext from 'Components/reusable/DataContext'
import React, { useMemo } from 'react'
import {
  ISearchAvailability,
  ISearchCertificate,
  ISearchCourse,
  ISearchDegree,
  ISearchFields,
  ISearchLanguage,
  ISearchSkill,
} from 'types/searchInterfaces'
import { useNotificationPopup } from 'Components/reusable/Notification'
import { IIndustry, IRole, ISkill } from 'types/cvsInterfaces'
import LanguageSearchRequirement from 'Components/General/LanguageSearchRequirement/LanguageSearchRequirement'
import SkillSearchRequirement from 'Components/General/SkillSearchRequirement/SkillSearchRequirement'
import { useTranslation } from 'react-i18next'
import EducationSearchRequirement from '../SearchComponents/EducationSearchRequirement'

/** @notExported */
interface ISearchFieldsProps {
  /** Skill options */
  skillOptions: ISkill[]
  /** Role options */
  roleOptions: IRole[]
  /** Industry options */
  industryOptions: IIndustry[]
}

/**
 * SearchFields component for handling search fields.
 *
 * @param {Object} skillOptions - Options for skills
 * @param {Object} roleOptions - Options for roles
 * @param {Object} industryOptions - Options for industries
 */
const SearchFields: React.FC<ISearchFieldsProps> = ({ skillOptions, roleOptions, industryOptions }) => {
  const { t } = useTranslation()
  const { searchData, setSearchData } = useSearchData()
  const { setNotificationPopup } = useNotificationPopup()

  const anonymous = useMemo(() => {
    const networks = searchData.terms.networks
    return !!networks.find(network => network.anonymouslySearchable === true)
  }, [searchData.terms.networks])

  /**
   * Adds a skill, role, or industry to the searchData based on the provided type.
   *
   * @param {ISearchSkill | undefined} newItem - The item to be added
   * @param {'skill' | 'role' | 'industry'} type - The type of the item to be added
   * @return {void}
   */
  const addSkill = (newItem: ISearchSkill | undefined, type: 'skill' | 'role' | 'industry'): void => {
    if (!newItem) {
      return
    }
    if (type === 'skill') {
      if (searchData.terms.skills.find(skill => skill.skillId === newItem.skillId)) {
        setNotificationPopup({
          message: t('search.skillAlreadyExists'),
          type: 'warning',
          duration: 'short',
        })
        return
      }

      if (newItem.name && newItem.name.length > 0) {
        setSearchData({
          ...searchData,
          terms: {
            ...searchData.terms,
            skills: [...searchData.terms.skills, newItem],
          },
        })
      }
    } else if (type === 'industry') {
      if (searchData.terms.industries.find(industry => industry.skillId === newItem.skillId)) {
        setNotificationPopup({
          message: t('search.industryAlreadyExists'),
          type: 'warning',
          duration: 'short',
        })
        return
      }

      if (newItem.name.length > 0) {
        setSearchData({
          ...searchData,
          terms: {
            ...searchData.terms,
            industries: [...searchData.terms.industries, newItem],
          },
        })
      }
    } else if (type === 'role') {
      if (searchData.terms.roles.find(role => role.skillId === newItem.skillId)) {
        setNotificationPopup({
          message: t('search.roleAlreadyExists'),
          type: 'warning',
          duration: 'short',
        })
        return
      }

      if (newItem.name.length > 0) {
        setSearchData({
          ...searchData,
          terms: {
            ...searchData.terms,
            roles: [...searchData.terms.roles, newItem],
          },
        })
      }
    }
  }

  /**
   * Adds a new language to the search data if it does not already exist,
   * and sets a notification if the language already exists.
   *
   * @param {ISearchLanguage} newLanguage - the new language to be added
   */
  const addLanguage = (newLanguage?: ISearchLanguage) => {
    if (!newLanguage) return

    const languageExists = searchData.terms.languages.find(
      ({ languageCode }) => languageCode === newLanguage.languageCode
    )
    if (languageExists) {
      setNotificationPopup({
        message: t('search.languageAlreadyExists'),
        type: 'warning',
        duration: 'short',
      })
      return
    }

    const updatedLanguages = [
      ...searchData.terms.languages,
      { languageCode: newLanguage.languageCode, level: newLanguage.level, name: newLanguage.name },
    ]
    setSearchData({
      ...searchData,
      terms: {
        ...searchData.terms,
        languages: updatedLanguages,
      },
    })
  }

  /**
   * Adds education item to the searchData state if it doesn't already exist.
   *
   * @param {string} type - the type of education item
   * @param {ISearchCertificate | ISearchDegree | ISearchCourse} newItem - the new education item to be added
   * @return {void}
   */
  const addEducation = (type: string, newItem: ISearchCertificate | ISearchDegree | ISearchCourse): void => {
    if (!newItem) {
      return
    }

    const itemValue = newItem[type]
    const existingItem = searchData.terms[type + 's'].find(item => item[type] === itemValue)

    if (existingItem) {
      setNotificationPopup({
        message: t(`search.${type}AlreadyExists`),
        type: 'warning',
        duration: 'short',
      })
      return
    }

    setSearchData({
      ...searchData,
      terms: {
        ...searchData.terms,
        [type + 's']: [...searchData.terms[type + 's'], { [type]: itemValue }],
      },
    })
  }

  return (
    <>
      <Grid item xs={12} container spacing={1} alignItems="center">
        <Grid item xs="auto" sx={{ fontWeight: 'bold' }}>
          {t('search.availability')}:
        </Grid>
        <Grid item xs="auto">
          <DataContext.DatePickerField<ISearchAvailability>
            field="startDate"
            dateResolution="month"
            isClearable
            start={true}
          />
        </Grid>
        <Grid item>-</Grid>
        <Grid item xs={4}>
          <DataContext.DatePickerField<ISearchAvailability>
            field="endDate"
            isClearable
            dateResolution="month"
            end={true}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        {!anonymous && (
          <Grid item xs={12} sm={4}>
            <DataContext.TextField<ISearchFields> label={t('search.name')} field="name" insetLabel fullWidth />
          </Grid>
        )}
        <Grid item xs={12} sm={4}>
          <DataContext.TextField<ISearchFields> label={t('search.location')} field="location" insetLabel fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LanguageSearchRequirement onAdd={newItem => addLanguage(newItem)} insetLabel />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12} sm={4}>
          <SkillSearchRequirement
            onAdd={newItem => addSkill(newItem, 'skill')}
            type="skill"
            showUnpromoted={true}
            options={skillOptions}
            insetLabel
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SkillSearchRequirement
            onAdd={newItem => addSkill(newItem, 'role')}
            type="role"
            showUnpromoted={true}
            options={roleOptions}
            insetLabel
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SkillSearchRequirement
            onAdd={newItem => addSkill(newItem, 'industry')}
            type="industry"
            showUnpromoted={true}
            options={industryOptions}
            insetLabel
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12} sm={4}>
          <EducationSearchRequirement
            onAdd={newItem => addEducation('certificate', newItem)}
            type="certificate"
            noButton
            insetLabel
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <EducationSearchRequirement
            onAdd={newItem => addEducation('degree', newItem)}
            type="degree"
            noButton
            insetLabel
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <EducationSearchRequirement
            onAdd={newItem => addEducation('course', newItem)}
            type="course"
            noButton
            insetLabel
          />
        </Grid>
      </Grid>
    </>
  )
}

export default SearchFields
