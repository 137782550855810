import { i18n as i18nType } from 'i18next'
import { useTranslation } from 'react-i18next'
import { ILanguage } from '../types/cvsInterfaces'
import { languageAPI } from '../api/LanguageAPI'

export type Translateable = { languageId: number; Language?: ILanguage }
export type TranslateableObject<T extends Translateable> = { translations: T[] }
export type TranslationType<T, K = T extends TranslateableObject<infer P> ? P : never> = K

/**
 * Checks if the given object is a translateable object.
 *
 * @param {Record<string, unknown>} obj - The object to check.
 * @return {boolean} - Returns true if the object is a translateable object, otherwise returns false.
 */
export function isTranslateableObject(obj: Record<string, unknown>): obj is TranslateableObject<Translateable> {
  return 'translations' in obj && obj['translations'] !== undefined
}

export function useDBTranslation(objectOrChoices: null): null
export function useDBTranslation(objectOrChoices: undefined): undefined
export function useDBTranslation<T extends Translateable>(choices: T[]): T
/**
 * Retrieves the first translation of a given object from the database.
 *
 * @param {K} object - The object to retrieve the translation from.
 * @return {K['translations'][0]} The first translation of the object.
 */
export function useDBTranslation<T extends Translateable, K extends TranslateableObject<T>>(
  object: K
): K['translations'][0]
export function useDBTranslation<T extends Translateable>(choices: T[] | null | undefined): T | null | undefined
/**
 * Retrieves the translation object for a given translateable object.
 *
 * @param {K | null | undefined} object - The translateable object to retrieve the translation for.
 * @return {K['translations'][0] | null | undefined} The translation object for the given translateable object.
 */
export function useDBTranslation<T extends Translateable, K extends TranslateableObject<T>>(
  object: K | null | undefined
): K['translations'][0] | null | undefined
/**
 * Retrieves the translation of a given object or choices from the database.
 *
 * @param {K | T[] | null | undefined} objectOrChoices - The object or choices to retrieve the translation for.
 * @return {K['translations'][0] | T | null | undefined} The translation of the given object or choices.
 */
export function useDBTranslation<T extends Translateable, K extends TranslateableObject<T>>(
  objectOrChoices: K | T[] | null | undefined
): K['translations'][0] | T | null | undefined
/**
 * Generates a translated object or a list of translated objects from a database.
 *
 * @param {K | T[] | null | undefined} objectOrChoices - The object or list of objects to be translated.
 * @return {T | null | undefined} - The translated object or list of objects.
 */
export function useDBTranslation<T extends Translateable, K extends TranslateableObject<T>>(
  objectOrChoices: K | T[] | null | undefined
): T | null | undefined {
  const { i18n } = useTranslation()

  return chooseDBTranslation(i18n, objectOrChoices)
}

export function chooseDBTranslation(i18n: i18nType, objectOrChoices: null): null
export function chooseDBTranslation(i18n: i18nType, objectOrChoices: undefined): undefined
export function chooseDBTranslation<T extends Translateable>(i18n: i18nType, choices: T[]): T
/**
 * Retrieves the first translation of an object from the database based on the given language.
 *
 * @param {i18nType} i18n - The i18n object used for language localization.
 * @param {K} object - The object for which the translation is retrieved.
 * @return {K['translations'][0]} The first translation of the object in the specified language.
 */
export function chooseDBTranslation<T extends Translateable, K extends TranslateableObject<T>>(
  i18n: i18nType,
  object: K
): K['translations'][0]
/**
 * Generates a function comment for the given function body.
 *
 * @param {i18nType} i18n - the i18n object used for translation
 * @param {T[] | null | undefined} choices - an array of translateable objects, or null/undefined
 * @return {T | null | undefined} the chosen translateable object, or null/undefined
 */
export function chooseDBTranslation<T extends Translateable>(
  i18n: i18nType,
  choices: T[] | null | undefined
): T | null | undefined
/**
 * Retrieves the translation for a given object from the specified i18n database.
 *
 * @param {i18nType} i18n - The i18n database to retrieve the translation from.
 * @param {K | null | undefined} object - The object for which to retrieve the translation.
 * @return {K['translations'][0] | null | undefined} The translation of the object, or null/undefined if the object is null/undefined.
 */
export function chooseDBTranslation<T extends Translateable, K extends TranslateableObject<T>>(
  i18n: i18nType,
  object: K | null | undefined
): K['translations'][0] | null | undefined
/**
 * Chooses the appropriate translation from a database for the given object or choices.
 *
 * @param {i18nType} i18n - The i18n object used for translation.
 * @param {K | T[] | null | undefined} objectOrChoices - The object or choices to be translated.
 * @return {K['translations'][0] | T | null | undefined} The chosen translation or object, or null or undefined if not found.
 */
export function chooseDBTranslation<T extends Translateable, K extends TranslateableObject<T>>(
  i18n: i18nType,
  objectOrChoices: K | T[] | null | undefined
): K['translations'][0] | T | null | undefined
/**
 * Returns the translation for a given language from an array of translateable objects.
 *
 * @param {i18nType} i18n - The i18n object containing the desired language.
 * @param {K | T[] | null | undefined} objectOrChoices - The array of translateable objects or a single translateable object.
 * @return {T | null | undefined} The translation for the desired language, or null if no translation is found.
 */
export function chooseDBTranslation<T extends Translateable, K extends TranslateableObject<T>>(
  i18n: i18nType,
  objectOrChoices: K | T[] | null | undefined
): T | null | undefined {
  const choices =
    objectOrChoices && ('translations' in objectOrChoices ? objectOrChoices.translations : objectOrChoices)

  if (!choices) {
    return choices
  }

  for (const choice of choices) {
    if (choice.Language?.name === i18n.language) {
      return choice
    }
  }

  if (choices[0]) {
    return choices[0]
  }

  console.warn('No translation found!')
  return {} as T
}

/**
 * This function chooses the index of a translation in the database that matches a given language.
 *
 * @param {i18nType} i18n - The i18n object representing the desired language.
 * @param {T[]} choices - An array of Translateable objects representing the available translations.
 * @return {number} - The index of the chosen translation in the array, or -1 if no match is found.
 */
export function chooseDBTranslationIndex<T extends Translateable>(i18n: i18nType, choices: T[]): number {
  for (let i = 0; i < choices.length; ++i) {
    if (choices[i].Language?.name === i18n.language) {
      return i
    }
  }

  return -1
}

/**
 * Creates a translation for a given language.
 *
 * @param {string} language - The language to create the translation for.
 * @param {Omit<T, 'id' | 'languageId' | 'Language' | 'createdAt' | 'updatedAt' | K>} body - The data for the translation, excluding certain fields.
 * @return {T} The created translation.
 */
export async function createTranslation<T extends Translateable, K extends keyof T>(
  language: string,
  body: Omit<T, 'id' | 'languageId' | 'Language' | 'createdAt' | 'updatedAt' | K>
) {
  /// K is the extra fields to omit, such as parent id (`assignmentId`)
  const languageDataMap = await getLanguageDataMap()

  return {
    ...body,
    languageId: languageDataMap[language].id,
    Language: languageDataMap[language],
  } as unknown as T
}

export type LanguageIdMap = { [key: string]: number }
export type LanguageIdReverseMap = { [key: number]: string }

let languageDataPromise: Promise<ILanguage[]> | null = null

/**
 * Retrieves the language data by making a request to the language API.
 *
 * @return {Promise<ILanguage[]>} A promise that resolves to an array of language objects.
 */
export async function getLanguageData(): Promise<ILanguage[]> {
  if (!languageDataPromise) {
    languageDataPromise = languageAPI.getAllLanguages()
  }
  return languageDataPromise
}

/**
 * Retrieves the language data map.
 *
 * @return {Promise<{ [name: string]: ILanguage }>} A promise that resolves to an object containing language data mapped by name.
 */
export async function getLanguageDataMap(): Promise<{ [name: string]: ILanguage }> {
  const languages = await getLanguageData()
  return Object.assign({}, ...languages.map(lang => ({ [lang.name]: lang })))
}

/**
 * Retrieves the language ID map.
 *
 * @return {Promise<LanguageIdMap>} A promise that resolves to the language ID map.
 */
export async function getLanguageIdMap(): Promise<LanguageIdMap> {
  const languages = await getLanguageData()
  return Object.assign({}, ...languages.map(lang => ({ [lang.name]: lang.id })))
}

/**
 * Retrieves a reverse map of language IDs to language names.
 *
 * @return {Promise<LanguageIdReverseMap>} A promise that resolves to the reverse map of language IDs to language names.
 */
export async function getLanguageIdReverseMap(): Promise<LanguageIdReverseMap> {
  const languages = await getLanguageData()
  return Object.assign({}, ...languages.map(lang => ({ [lang.id]: lang.name })))
}
