import { DialogContent, Grid } from '@mui/material'
import DataContext, { NewOrExisting } from 'Components/reusable/DataContext'
import React from 'react'
import { IEmployer, IPersonRole, IRole } from 'types/cvsInterfaces'
import newWithEditModal from 'Components/reusable/HOC/newWithEditModal'
import RoleCreate from '../ProjectsCard/RoleCreate'
import SkillsItem from '../SkillsCard/SkillsItem'

/** @notExported   */
interface IEmployerModalProps {
  /** Employer data. */
  data: IEmployer
  /** Employer data setter. */
  setData: (dataObject) => void
}

/**
 * Employer modal.
 *
 * @returns Employer modal component.
 * @notExported
 */
const EmployerModal: React.FC<IEmployerModalProps> = ({ data, setData }) => {
  const getDisabledRoleOptions = () => {
    const disabledOptions: IRole[] = []
    data.EmployerRoles?.map(role => disabledOptions.push(role.Skill))
    return disabledOptions
  }

  const replaceOrAddRole = ({ newItem }: { newItem?: NewOrExisting<IPersonRole> }) => {
    setData({ ...data, EmployerRoles: [...(data.EmployerRoles ?? []), newItem as IPersonRole] })
  }

  const deleteItem = (id: number) => {
    const newItems: NewOrExisting<IPersonRole>[] = (data.EmployerRoles ?? []).filter(item => item.skillId !== id)
    setData({ ...data, EmployerRoles: newItems })
  }

  const checkRoles = () => {
    const filtered = data.EmployerRoles?.filter(role => role.id !== undefined)
    const items = data.EmployerRoles

    return (
      <>
        <RoleCreate
          cvId={data.cvId}
          onAdd={newItem => {
            replaceOrAddRole(newItem)
          }}
          label="profile.employer.modal.role"
          usedRoles={getDisabledRoleOptions()}
          insetLabel
        />
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginTop: 10 }}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            {filtered?.map(skill => (
              <SkillsItem
                key={skill.id}
                skill={skill}
                editable
                onDeleteClick={() => {
                  deleteItem(skill.skillId)
                }}
                type="role"
              />
            ))}
          </Grid>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            {items?.map(skill => {
              if (skill.id) return

              return (
                <SkillsItem
                  key={skill.skillId}
                  skill={skill}
                  editable
                  newChip={true}
                  onDeleteClick={() => {
                    deleteItem(skill.skillId)
                  }}
                  type="role"
                />
              )
            })}
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item md={4} sm={6} xs={12}>
          <DataContext.TranslationImport<IEmployer> />
          <DataContext.TextField<IEmployer> field="name" required fullWidth insetLabel />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DataContext.DateResolutionField<IEmployer> field="dateResolution" required />
            </Grid>
            <Grid item xs={6}>
              <DataContext.DateField<IEmployer> field="startDate" required start={true} insetLabel />
              <DataContext.DateField<IEmployer>
                field="endDate"
                isClearable
                end={true}
                minDate={data.startDate}
                insetLabel
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <DataContext.TextField<IEmployer>
            field="description"
            maxHintLength={1500}
            required
            multiline
            grow
            rows={11}
            fullWidth
            insetLabel
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <DataContext.TextField<IEmployer> field="primaryRole" fullWidth insetLabel />
          {checkRoles()}
        </Grid>
      </Grid>
    </DialogContent>
  )
}

export default newWithEditModal(EmployerModal)
