import React, { useEffect } from 'react'
import { Box, Chip, Tooltip } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

/**
 * Renders a skill chip component.
 *
 * @param {boolean} hidden - A flag indicating whether the chip is hidden.
 * @param {string} name - The name of the chip.
 * @param {string} itemId - The ID of the item.
 * @param {function} onClick - The click event handler.
 * @param {string} type - The type of the chip.
 * @return {JSX.Element} The rendered skill chip component.
 * @notExported
 */
const SkillChip = ({ hidden, name, itemId, onClick, type }) => {
  const { t } = useTranslation()
  const [hiddenSkill, setHiddenSkill] = useState<boolean>(false)
  const title = hidden ? t('print.visibilityOff') : t('print.visibilityOn')

  useEffect(() => {
    if (hidden !== hiddenSkill) {
      setHiddenSkill(hidden)
    }
  }, [hidden])

  const getName = chipLabel => {
    return (
      <div style={{ maxWidth: '225px' }}>
        <Box component="div" textOverflow="ellipsis" overflow="hidden" my={2}>
          {chipLabel}
        </Box>
      </div>
    )
  }

  return (
    <Tooltip title={title}>
      <Chip
        icon={hiddenSkill ? <VisibilityOffIcon /> : <VisibilityIcon />}
        label={getName(name)}
        color={hiddenSkill ? 'default' : 'primary'}
        onClick={() => {
          setHiddenSkill(!hiddenSkill)
          onClick(!hiddenSkill, itemId, type)
        }}
        style={{ marginRight: 9, marginBottom: 9 }}
      />
    </Tooltip>
  )
}

export default SkillChip
