import { Button, Dialog, DialogActions } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'constants/colors'

/**
 * Confirmation modal higher order component.
 *
 * @param ModalComponent - Modal component to wrap.
 * @returns Confirmation modal component.
 * @notExported
 */
const withConfirmationModal = ModalComponent => {
  const ConfirmationModal = props => {
    const { t } = useTranslation()
    const [disableDelete, setDisableDelete] = useState<boolean>(false)

    return (
      <>
        {props.open && (
          <Dialog
            open
            onClose={() => props.onClose({})}
            maxWidth={props.maxWidth}
            fullWidth={props.fullWidth}
            sx={{
              '.MuiDialog-paper': {
                backgroundColor: colors.secondaryBackground,
                borderRadius: 1,
              },
            }}
          >
            <ModalComponent {...props} disableDelete={value => setDisableDelete(value)} />
            <DialogActions
              sx={{
                background: colors.secondaryBackground,
                paddingRight: '22px',
                paddingBottom: '22px',
              }}
            >
              <Button onClick={props.onClose} variant="contained" color="primary">
                {props.noLabel ? t(props.noLabel) : !disableDelete ? t('confirmation.no') : t('confirmation.ok')}
              </Button>
              {!disableDelete && (
                <Button onClick={props.confirm} variant="contained" color="primary">
                  {props.yesLabel ? t(props.yesLabel) : t('confirmation.yes')}
                </Button>
              )}
            </DialogActions>
          </Dialog>
        )}
      </>
    )
  }
  return ConfirmationModal
}

export default withConfirmationModal
