import { Autocomplete, Box, Checkbox, Chip, FormControlLabel, Grid, Typography } from '@mui/material'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import AddButton from 'Components/reusable/IconButtons/AddButton'
import { InputField } from 'Components/reusable/InputFields/InputField'
import { skillAPI } from 'api/SkillAPI'
import { useUser } from 'hooks'
import { useIsComponentMounted } from 'hooks/util'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IIndustry, IRole, ISkill } from 'types/cvsInterfaces'
import { IError } from 'types/error'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'

/** @notExported */
interface IFilterFieldsProps {
  /** A function to set the skill filter. */
  setSkillFilter: (values: ISkill[]) => void
  /** A function to set the role filter. */
  setRoleFilter: (values: IRole[]) => void
  /** A function to set the industry filter. */
  setIndustryFilter: (values: IIndustry[]) => void
  /** A function to set the certificate filter. */
  setCertificateFilter: (values: string[]) => void
  /** Should all items be shown  */
  allItems: boolean
  /** A function to set all items. */
  setAllItems: (values: boolean) => void
}

/**
 * FilterFields component.
 *
 * @param setSkillFilter - A function to set the skill filter.
 * @param setRoleFilter - A function to set the role filter.
 * @param setIndustryFilter - A function to set the industry filter.
 * @param setCertificateFilter - A function to set the certificate filter.
 * @returns The FilterFields component.
 * @notExported
 */
const FilterFields: React.FC<IFilterFieldsProps> = ({
  setSkillFilter,
  setRoleFilter,
  setIndustryFilter,
  setCertificateFilter,
  allItems,
  setAllItems,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const { t, i18n } = useTranslation()
  const { groups } = useUser()

  const [backendError, setBackendError] = useState<IError>()
  const [skillOptions, setSkillOptions] = useState<ISkill[]>([])
  const [roleOptions, setRoleOptions] = useState<IRole[]>([])
  const [industryOptions, setIndustryOptions] = useState<IIndustry[]>([])
  const [certificateInput, setCertificateInput] = useState<string>()
  const [certificates, setCertificates] = useState<string[]>([])
  const [certError, setCertError] = useState<string>('')

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        const skills = skillOptions.length > 0 ? skillOptions : await skillAPI.getAllSkills(controller)
        const roles = roleOptions.length > 0 ? roleOptions : await skillAPI.getAllRoles(controller)
        const industries = industryOptions.length > 0 ? industryOptions : await skillAPI.getAllIndustries(controller)
        if (isComponentMounted.current) {
          setSkillOptions(skills)
          setRoleOptions(roles)
          setIndustryOptions(industries)
        }
      } catch (error) {
        setBackendError(error as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (certificates) {
      setCertificateFilter(certificates)
    }
  }, [certificates])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const certificateSubmit = () => {
    if (certificateInput && certificateInput.length && !certificates.includes(certificateInput)) {
      setCertError('')
      setCertificates(previous => [...previous, certificateInput])
      setCertificateInput('')
    } else {
      setCertError('certError')
    }
  }

  const getFilterButton = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAllItems(event.target.checked)
            }}
            checked={allItems}
          />
        }
        label={t(`team.modal.showAll`)}
      />
    )
  }

  return (
    <Grid item container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight="bold">
          {t('team.filters')}
        </Typography>
      </Grid>
      <Grid item xs={6} md={4} lg={2.4}>
        <Autocomplete
          onChange={(_event, newValue) => {
            if (newValue) {
              setSkillFilter(newValue)
            }
          }}
          filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['translations.name'])}
          clearOnBlur
          handleHomeEndKeys
          options={skillOptions}
          getOptionLabel={option => chooseDBTranslation(i18n, option).name}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderOption={(props, option) => {
            return (
              <Box component="li" {...props} key={option.id}>
                {chooseDBTranslation(i18n, option).name}
              </Box>
            )
          }}
          renderInput={params => (
            <InputField
              {...params}
              fullWidth
              sx={{ '& .MuiOutlinedInput-root': { padding: '3px' } }}
              variant="outlined"
              label={t('team.skillPicker')}
              insetLabel
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="small"
            />
          )}
          fullWidth
          multiple
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2.4}>
        <Autocomplete
          onChange={(_event, newValue) => {
            if (newValue) {
              setRoleFilter(newValue)
            }
          }}
          filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['translations.name'])}
          clearOnBlur
          handleHomeEndKeys
          options={roleOptions}
          getOptionLabel={option => chooseDBTranslation(i18n, option).name}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderOption={(props, option) => {
            return (
              <Box component="li" {...props} key={option.id}>
                {chooseDBTranslation(i18n, option).name}
              </Box>
            )
          }}
          renderInput={params => (
            <InputField
              {...params}
              fullWidth
              sx={{ '& .MuiOutlinedInput-root': { padding: '3px' } }}
              variant="outlined"
              label={t('team.rolePicker')}
              insetLabel
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="small"
            />
          )}
          fullWidth
          multiple
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2.4}>
        <Autocomplete
          onChange={(_event, newValue) => {
            if (newValue) {
              setIndustryFilter(newValue)
            }
          }}
          filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['translations.name'])}
          clearOnBlur
          handleHomeEndKeys
          options={industryOptions}
          getOptionLabel={option => chooseDBTranslation(i18n, option).name}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderOption={(props, option) => {
            return (
              <Box component="li" {...props} key={option.id}>
                {chooseDBTranslation(i18n, option).name}
              </Box>
            )
          }}
          renderInput={params => (
            <InputField
              {...params}
              fullWidth
              sx={{ '& .MuiOutlinedInput-root': { padding: '3px' } }}
              variant="outlined"
              label={t('team.industryPicker')}
              insetLabel
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="small"
            />
          )}
          fullWidth
          multiple
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2.5}>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={0}>
          <Grid item style={{ margin: 0 }}>
            <InputField
              label={t('team.certificateField')}
              onChange={e => {
                setCertificateInput(e.target.value)
              }}
              value={certificateInput}
              error={certError}
              style={{ margin: 0 }}
              size="small"
              margin="dense"
              insetLabel
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <AddButton
              valid={certificateInput ? certificateInput.length > 0 : false}
              clickAction={certificateSubmit}
              tooltip={t('add')}
            />
          </Grid>
        </Grid>
        {certificates.length > 0 &&
          certificates.map((cert, i) => (
            <Chip
              key={i}
              onDelete={() => {
                setCertificates(certificates.filter(item => item !== cert))
              }}
              label={cert}
              color="info"
            />
          ))}
      </Grid>
      {groups.includes('admin') && (
        <Grid item xs="auto">
          {getFilterButton()}
        </Grid>
      )}
    </Grid>
  )
}

export default FilterFields
