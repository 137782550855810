/**
 * Custom date column sort function.
 *
 * @param rowA - The first row to compare.
 * @param rowB - The second row to compare.
 * @param columnId - The column ID to sort by.
 * @returns -1, 0, or 1 depending on whether the first row is less than, equal to, or greater than the second row.
 */
export const sortByDateColumn = (rowA: any, rowB: any, columnId: string) => {
  if (!rowA.original[columnId]) return -1
  if (!rowB.original[columnId]) return 1
  const a = new Date(rowA.original[columnId])
  const b = new Date(rowB.original[columnId])

  return a > b ? 1 : a < b ? -1 : 0
}
