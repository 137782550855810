import { Grid, useMediaQuery, useTheme } from '@mui/material'
import Footer from 'Components/General/Footer'
import NavigationMenu from 'Components/General/NavigationMenu'
import { useMenu } from 'Components/General/NavigationMenu/menu'
import Toolbar, { ToolbarProvider } from 'Components/General/Toolbar'
import en from 'date-fns/locale/en-GB'
import fi from 'date-fns/locale/fi'
import sv from 'date-fns/locale/sv'
import { useLogin, useNotifications, useUser } from 'hooks'
import React, { useContext, useEffect, Suspense } from 'react'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { ChatContext } from 'Components/General/ChatContext'
import LoadingIndicator from 'Components/reusable/LoadingIndicator'
import { removeNonCaleoStorage } from 'utils/utils'
import reactLazyWithRetry from '@fatso83/retry-dynamic-import/react-lazy'
import ActivateAccount from 'pages/AccountManagement/ActivateAccount'
import ForgotPassword from 'pages/AccountManagement/ForgotPassword'
import Login from 'pages/AccountManagement/Login'
import TermsAndPolicy from 'pages/AccountManagement/TermsAndPolicy/TermsAndPolicy'
import Logout from 'pages/AccountManagement/Logout'
import ResetPassword from 'pages/AccountManagement/ResetPassword'
import VerifyAccount from 'pages/AccountManagement/VerifyAccount'
import HttpErrorPage from 'Components/General/HttpErrorPage'
import Unsubscribe from 'pages/AccountManagement/Unsubscribe'
import CVPrint from 'pages/CVPrint'
import Notifications from 'pages/Notifications'
import Profile from 'pages/Profile'
import Search from 'pages/Search/index'
import ReleaseNotes from 'pages/ReleaseNotes'
import Team from 'pages/Team'
import backgroundImage from 'assets/images/background.png'
import Allocation from 'pages/Allocation'

const Admin = reactLazyWithRetry(() => import('pages/Admin'))
const CompanyAdmin = reactLazyWithRetry(() => import('pages/CompanyAdmin'))
const Assignments = reactLazyWithRetry(() => import('pages/Assignments'))
const AssignmentAddEdit = reactLazyWithRetry(
  () => import('pages/Assignments/AssignmentsList/AddEdit/AssignmentAddEdit')
)
const AssignmentProposed = reactLazyWithRetry(() => import('pages/Assignments/AssignmentProposals/RoleCandidates/'))
const ProposeToRole = reactLazyWithRetry(() => import('pages/Assignments/AssignmentProposals/AddEdit/ProposeToRole'))
const AssignmentsView = reactLazyWithRetry(() => import('pages/Assignments/AssignmentsView'))
const Networks = reactLazyWithRetry(() => import('pages/Networks'))
const NetworkEdit = reactLazyWithRetry(() => import('pages/Networks/NetworkAddEdit/NetworkAddEdit'))
const NetworkManage = reactLazyWithRetry(() => import('pages/Networks/NetworkManage/NetworkManage'))
const NetworkBenefits = reactLazyWithRetry(() => import('pages/Networks/NetworkBenefits/NetworkBenefits'))
const SalesPoolView = reactLazyWithRetry(() => import('pages/SalesPool/SalesPoolView'))
const SalesPoolPreview = reactLazyWithRetry(() => import('pages/SalesPool/SalesPoolPreview/SalesPoolPreview'))
const Scout = reactLazyWithRetry(() => import('pages/Scout'))
const Statistics = reactLazyWithRetry(() => import('pages/Statistics'))
const UserManual = reactLazyWithRetry(() => import('pages/UserManual'))
const SalesPool = reactLazyWithRetry(() => import('pages/SalesPool'))
const Chat = reactLazyWithRetry(() => import('pages/Chat'))
const Room = reactLazyWithRetry(() => import('pages/Chat/Room'))
const WorkOrders = reactLazyWithRetry(() => import('pages/WorkOrders'))
const WorkOrderAddEdit = reactLazyWithRetry(() => import('pages/WorkOrders/WorkOrdersList/AddEdit/WorkOrderAddEdit'))
const WorkOrderProposed = reactLazyWithRetry(() => import('pages/WorkOrders/WorkOrderProposals/RoleCandidates/'))
const WorkOrderProposeToRole = reactLazyWithRetry(
  () => import('pages/WorkOrders/WorkOrderProposals/AddEdit/ProposeToRole')
)
const WorkOrdersView = reactLazyWithRetry(() => import('pages/WorkOrders/WorkOrdersView'))

const localeMap = {
  en: en,
  fi: fi,
  sv: sv,
}

/**
 * Checks if every item in the userItems array is included in the allowedItems array.
 *
 * @param {string[]} userItems - The array of items provided by the user.
 * @param {string[]} allowedItems - The array of items that are allowed.
 * @return {boolean} Returns true if every item in the userItems array is included in the allowedItems array, otherwise returns false.
 * @notExported
 */
const checkArrayEvery = (userItems: string[], allowedItems: string[]) => {
  return allowedItems.every(item => userItems.includes(item))
}

/**
 * Checks if any of the items in the userItems array are present in the allowedItems array.
 *
 * @param {string[]} userItems - The array of items provided by the user.
 * @param {string[]} allowedItems - The array of allowed items.
 * @return {boolean} Returns true if any of the userItems are present in the allowedItems, otherwise returns false.
 * @notExported
 */
const checkArrayOneOf = (userItems: string[], allowedItems: string[]) => {
  return allowedItems.some(item => userItems.includes(item))
}

/**
 * Renders a private route based on the user's logged-in status and the provided props.
 *
 * @param {Object} props - The props for the private route component.
 * @param {React.Component} props.component - The component to render for the private route.
 * @param {boolean} props.loggedIn - Indicates whether the user is logged in or not.
 * @param {...any} props.rest - Additional props to be passed to the Route component.
 * @return {React.Element} The rendered private route component.
 * @notExported
 */
const PrivateRoute = ({
  children,
  path,
  loggedIn,
  groups,
  networkAccess,
  features,
  salesGroupSearch,
}: {
  children
  path: string
  loggedIn: boolean
  groups: string[]
  networkAccess: boolean
  features: string[]
  salesGroupSearch?: boolean
}) => {
  if (loggedIn) {
    if (groups && path.includes('/admin') && checkArrayOneOf(groups, ['admin', 'inpuDataManager'])) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (
      path.includes('/company-admin') &&
      groups &&
      features &&
      checkArrayEvery(features, ['companyAdmin']) &&
      checkArrayOneOf(groups, ['companyAdmin'])
    ) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (
      path.includes('/employees') &&
      groups &&
      features &&
      checkArrayEvery(features, ['search']) &&
      checkArrayOneOf(groups, ['sales', 'admin'])
    ) {
      return children
    }

    if (
      path.includes('/assignments') &&
      groups &&
      features &&
      checkArrayEvery(features, ['assignments']) &&
      checkArrayOneOf(groups, ['sales', 'freelancer'])
    ) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (
      path.includes('/workorders') &&
      groups &&
      features &&
      checkArrayEvery(features, ['assignments']) &&
      checkArrayOneOf(groups, ['sales', 'freelancer'])
    ) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (
      path === '/networks/add' &&
      networkAccess &&
      groups &&
      features &&
      checkArrayEvery(features, ['networks']) &&
      checkArrayOneOf(groups, ['admin'])
    ) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (
      /\/networks\/\d*/.test(path) &&
      networkAccess &&
      groups &&
      features &&
      checkArrayEvery(features, ['networks']) &&
      checkArrayOneOf(groups, ['admin'])
    ) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (path.includes('/networks/manage') && networkAccess && features && checkArrayEvery(features, ['networks'])) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (path === '/networks/benefits') {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (
      path === '/networks' &&
      !path.includes('/benefits') &&
      networkAccess &&
      features &&
      checkArrayEvery(features, ['networks'])
    ) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (path.includes('/help') && groups && checkArrayOneOf(groups, ['admin'])) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (
      path.includes('/statistics') &&
      groups &&
      features &&
      checkArrayEvery(features, ['reports']) &&
      checkArrayOneOf(groups, ['companyAdmin', 'admin', 'teamLeader'])
    ) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (
      path.includes('/profile') &&
      features &&
      (checkArrayEvery(features, ['profile']) || checkArrayEvery(features, ['search']))
    ) {
      return children
    }

    if (
      path.includes('/print') &&
      features &&
      (checkArrayEvery(features, ['profile']) || checkArrayEvery(features, ['search']))
    ) {
      return children
    }

    if (
      path.includes('/allocation') &&
      features &&
      (checkArrayEvery(features, ['allocations']) || checkArrayEvery(features, ['search']))
    ) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (path.includes('/teams') && features && checkArrayEvery(features, ['teams'])) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (path.includes('/scout') && features && checkArrayEvery(features, ['scout'])) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (
      path.includes('/salespool') &&
      groups &&
      features &&
      checkArrayEvery(features, ['salespool']) &&
      checkArrayOneOf(groups, ['sales'])
    ) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (path.includes('/unsubscribe')) {
      return children
    }

    if (path.includes('/notifications')) {
      return children
    }

    if (path.includes('/chat') && features && checkArrayEvery(features, ['chat'])) {
      return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    }

    if (path.includes('/releases')) {
      return children
    }

    if (salesGroupSearch) {
      return <Navigate to="/employees" />
    } else {
      return <Navigate to="/profile" />
    }
  }

  return <Navigate to={'/login'} state={{ url: window.location.pathname }} />
}

/**
 * Renders the Routes component.
 *
 * @return {ReactNode} The rendered component.
 * @notExported
 */
const CaleoRouter: React.FC = () => {
  const { loggedIn, setLoginLoading, setLoggedIn, setLoggingIn, error } = useLogin()
  const { groups, ready, networkAccess, features, user } = useUser()
  const { drawerWidth } = useMenu()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const { setRefresh, unreadCount, notifications } = useNotifications()
  const { roomData, isConnected, setRoomData, fullyRendered } = useContext(ChatContext)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (features && features.includes('chat') && isConnected && roomData && fullyRendered) {
      setRoomData(undefined)
    }

    setRefresh(new Date())

    if (
      location.pathname.includes('/login') ||
      location.pathname.includes('/activate') ||
      location.pathname.includes('/confirm') ||
      location.pathname.includes('/reset-password') ||
      location.pathname.includes('/change-password') ||
      location.pathname.includes('/forgot-password') ||
      location.pathname.includes('/terms') ||
      location.pathname.includes('/privacy-policy') ||
      location.pathname.includes('/logout')
    ) {
      document.body.style.background = `url(${backgroundImage})`
    } else {
      document.body.style.background = 'none'
    }

    return () => {
      document.body.style.background = 'none'
    }
  }, [location.pathname])

  const { i18n } = useTranslation()
  registerLocale('en', en)
  registerLocale('fi', fi)
  registerLocale('sv', sv)
  setDefaultLocale(localeMap[i18n.language])

  useEffect(() => {
    if (error && error.includes('azureLogin')) {
      removeNonCaleoStorage()
      setLoginLoading(false)
      setLoggedIn(false)
      setLoggingIn(false)
      navigate('/login')
    }
  }, [error])

  useEffect(() => {
    ;(async () => {
      const locales = i18n.languages
      if (user && user.language && user.language !== i18n.language && locales && locales.includes(user.language)) {
        await i18n.changeLanguage(user.language)
      }
    })()
  }, [user])

  if (!ready && loggedIn && location.pathname !== '/activate') {
    return (
      <Grid>
        <LoadingIndicator />
      </Grid>
    )
  }

  let hideToolbarMenu = false

  if (
    location &&
    location.pathname &&
    (location.pathname.includes('/assignments/public') ||
      (location.pathname.includes('/salespool') && !loggedIn) ||
      location.pathname.includes('/terms') ||
      location.pathname.includes('/privacy-policy') ||
      location.pathname.includes('/reset-password') ||
      location.pathname.includes('/forgot-password') ||
      location.pathname.includes('/change-password') ||
      location.pathname.includes('/login') ||
      location.pathname.includes('/register') ||
      location.pathname.includes('/confirm') ||
      location.pathname.includes('/activate'))
  ) {
    hideToolbarMenu = true
  }

  const toolbarPagestyles: { margin: number | string; marginLeft?: number | string; width?: string } = { margin: 0 }
  if (hideToolbarMenu) {
    toolbarPagestyles.margin = 0
    toolbarPagestyles.width = '100%'
  } else {
    if (mobileView) {
      toolbarPagestyles.margin = 0
      toolbarPagestyles.width = '100%'
    } else {
      toolbarPagestyles.marginLeft = drawerWidth
      toolbarPagestyles.width = `calc(100% - ${drawerWidth}px)`
    }
  }

  return (
    <ToolbarProvider>
      <NavigationMenu />
      <Grid sx={toolbarPagestyles}>
        <Toolbar unreadCount={unreadCount} />
        <Grid
          sx={{
            marginBottom: '5px',
            marginTop: '15px',
            minHeight: 'calc(100vh - 69px - 74px)',
          }}
        >
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="terms" element={<TermsAndPolicy />} />
            <Route path="privacy-policy" element={<TermsAndPolicy />} />
            <Route
              path="assignments/public/:publicId"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <AssignmentsView />
                </Suspense>
              }
            />
            <Route
              path="workorders/public/:publicId"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <WorkOrdersView />
                </Suspense>
              }
            />
            <Route
              path="salespool"
              element={
                <PrivateRoute
                  loggedIn={loggedIn}
                  path="/salespool"
                  features={features}
                  groups={groups}
                  networkAccess={networkAccess}
                >
                  <SalesPool />
                </PrivateRoute>
              }
            />
            <Route
              path="salespool/:publicId"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <SalesPoolView />
                </Suspense>
              }
            />
            <Route
              path="salespool/item/:publicId"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <SalesPoolPreview />
                </Suspense>
              }
            />
            <Route
              path="profile/:profileId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/profile/:profileId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="profile"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/profile"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="notifications"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/notifications"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Notifications setRefresh={setRefresh} notifications={notifications} />
                </PrivateRoute>
              }
            />
            <Route
              path="employees"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/employees"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Search />
                </PrivateRoute>
              }
            />
            <Route
              path="teams"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/teams"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Team />
                </PrivateRoute>
              }
            />
            <Route
              path="assignments/proposals"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/assignments/proposals"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Assignments />
                </PrivateRoute>
              }
            />
            <Route
              path="workorders/proposals"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/workorders/proposals"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <WorkOrders />
                </PrivateRoute>
              }
            />
            <Route
              path="assignments/proposals/:proposalId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/assignments/proposals/:proposalId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Assignments />
                </PrivateRoute>
              }
            />
            <Route
              path="workorders/proposals/:proposalId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/workorders/proposals/:proposalId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <WorkOrders />
                </PrivateRoute>
              }
            />
            <Route
              path="assignments/proposals"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/assignments/proposals"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Assignments />
                </PrivateRoute>
              }
            />
            <Route
              path="workorders/proposals"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/workorders/proposals"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <WorkOrders />
                </PrivateRoute>
              }
            />
            <Route
              path="assignments/add"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/assignments/add"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <AssignmentAddEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="workorders/add"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/workorders/add"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <WorkOrderAddEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="assignments/:assignmentId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/assignments/:assignmentId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Assignments />
                </PrivateRoute>
              }
            />
            <Route
              path="workorders/:workorderId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/workorders/:workorderId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <WorkOrders />
                </PrivateRoute>
              }
            />
            <Route
              path="assignments"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/assignments"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Assignments />
                </PrivateRoute>
              }
            />
            <Route
              path="workorders"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/workorders"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <WorkOrders />
                </PrivateRoute>
              }
            />
            <Route
              path="assignments/edit/:id"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/assignments/edit/:id"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <AssignmentAddEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="workorders/edit/:id"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/workorders/edit/:id"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <WorkOrderAddEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="assignments/edit"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/assignments/edit"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <AssignmentAddEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="workorders/edit"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/workorders/edit"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <WorkOrderAddEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="assignments/propose/:roleId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/assignments/propose/:roleId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <ProposeToRole />
                </PrivateRoute>
              }
            />
            <Route
              path="workorders/propose/:roleId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/workorders/propose/:roleId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <WorkOrderProposeToRole />
                </PrivateRoute>
              }
            />
            <Route
              path="assignments/proposal/edit/:proposalId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/assignments/proposal/edit/:proposalId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <ProposeToRole />
                </PrivateRoute>
              }
            />
            <Route
              path="workorders/proposal/edit/:proposalId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/workorders/proposal/edit/:proposalId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <WorkOrderProposeToRole />
                </PrivateRoute>
              }
            />
            <Route
              path="assignments/copy/:id"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/assignments/copy/:id"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <AssignmentAddEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="workorders/copy/:id"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/workorders/copy/:id"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <WorkOrderAddEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="assignments/copy/share/:id"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/assignments/copy/share/:id"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <AssignmentAddEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="workorders/copy/share/:id"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/workorders/copy/share/:id"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <WorkOrderAddEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="assignments/proposed/:id"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/assignments/proposed/:id"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <AssignmentProposed />
                </PrivateRoute>
              }
            />
            <Route
              path="workorders/proposed/:id"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/workorders/proposed/:id"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <WorkOrderProposed />
                </PrivateRoute>
              }
            />
            <Route
              path="admin"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/admin"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="admin/employees/:employeeId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/admin/employees/:employeeId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="admin/employees"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/admin/employees"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="admin/organizations/:organizationId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/admin/organizations/:organizationId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="admin/organizations"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/admin/organizations"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="admin/permissions"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/admin/permissions"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="admin/permissions/groups/:groupId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/admin/permissions/groups/:groupId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="admin/permissions/groups"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/admin/permissions/groups"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="admin/permissions/users/:userId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/admin/permissions/users/:userId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="admin/permissions/users"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/admin/permissions/users"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="admin/features"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/admin/features"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="company-admin"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/company-admin"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <CompanyAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="company-admin/employees/:employeeId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/company-admin/employees/:employeeId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <CompanyAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="company-admin/employees"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/company-admin/employees"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <CompanyAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="company-admin/organizations/:organizationId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/company-admin/organizations/:organizationId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <CompanyAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="company-admin/organizations"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/company-admin/organizations"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <CompanyAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="company-admin/permissions"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/company-admin/permissions"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <CompanyAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="company-admin/permissions/groups/:groupId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/company-admin/permissions/groups/:groupId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <CompanyAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="company-admin/permissions/groups"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/company-admin/permissions/groups"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <CompanyAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="company-admin/permissions/users/:userId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/company-admin/permissions/users/:userId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <CompanyAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="company-admin/permissions/users"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/company-admin/permissions/users"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <CompanyAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="allocation/:profileId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/allocation/:profileId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Allocation />
                </PrivateRoute>
              }
            />
            <Route
              path="allocation"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/allocation"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Allocation />
                </PrivateRoute>
              }
            />
            <Route
              path="print/:cvId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/print/:cvId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <CVPrint />
                </PrivateRoute>
              }
            />
            <Route
              path="print"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/print"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <CVPrint />
                </PrivateRoute>
              }
            />
            <Route
              path="scout"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/scout"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Scout />
                </PrivateRoute>
              }
            />
            <Route
              path="networks/manage/:id/notifications"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/networks/manage/:id/notifications"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <NetworkManage />
                </PrivateRoute>
              }
            />
            <Route
              path="networks/manage/:id/proposalinfo"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/networks/manage/:id/organizations"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <NetworkManage />
                </PrivateRoute>
              }
            />
            <Route
              path="networks/manage/:id/infopage"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/networks/manage/:id/organizations"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <NetworkManage />
                </PrivateRoute>
              }
            />
            <Route
              path="networks/manage/:id/parse"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/networks/manage/:id/organizations"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <NetworkManage />
                </PrivateRoute>
              }
            />
            <Route
              path="networks/manage/:id/organizations/:organizationId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/networks/manage/:id/organizations/:organizationId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <NetworkManage />
                </PrivateRoute>
              }
            />
            <Route
              path="networks/manage/:id/organizations"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/networks/manage/:id/organizations"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <NetworkManage />
                </PrivateRoute>
              }
            />
            <Route
              path="networks/manage/:id/employees/:employeeId"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/networks/manage/:id/employees/:employeeId"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <NetworkManage />
                </PrivateRoute>
              }
            />
            <Route
              path="networks/manage/:id/employees"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/networks/manage/:id/employees"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <NetworkManage />
                </PrivateRoute>
              }
            />
            <Route
              path="networks/manage/:id"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/networks/manage/:id"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <NetworkManage />
                </PrivateRoute>
              }
            />
            <Route
              path="networks/benefits"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/networks/benefits"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <NetworkBenefits />
                </PrivateRoute>
              }
            />
            <Route
              path="networks/add"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/networks/add"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <NetworkEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="networks/:id"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/networks/:id"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <NetworkEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="networks"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/networks"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Networks />
                </PrivateRoute>
              }
            />
            <Route
              path="help"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/help"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <UserManual />
                </PrivateRoute>
              }
            />
            <Route
              path="help/:url"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="help/:url"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <UserManual />
                </PrivateRoute>
              }
            />
            <Route
              path="statistics"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/statistics"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Statistics />
                </PrivateRoute>
              }
            />
            <Route
              path="unsubscribe"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="unsubscribe"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Unsubscribe />
                </PrivateRoute>
              }
            />
            <Route
              path="chat"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/chat"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Chat />
                </PrivateRoute>
              }
            />
            <Route
              path="chat/:name"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/chat/:name"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <Room />
                </PrivateRoute>
              }
            />
            <Route
              path="releases"
              element={
                <PrivateRoute
                  groups={groups}
                  loggedIn={loggedIn}
                  path="/releases"
                  features={features}
                  networkAccess={networkAccess}
                >
                  <ReleaseNotes />
                </PrivateRoute>
              }
            />
            <Route path="activate" element={<ActivateAccount />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="change-password/:token" element={<ResetPassword />} />
            <Route path="logout" element={<Logout />} />
            <Route path="404" element={<HttpErrorPage type={'404'} />} />
            <Route path="403" element={<HttpErrorPage type={'403'} />} />
            <Route path="confirm/:token" element={<VerifyAccount />} />
            <Route
              path="*"
              element={
                <PrivateRoute
                  path={location.pathname}
                  loggedIn={loggedIn}
                  groups={groups}
                  networkAccess={networkAccess}
                  features={features}
                  salesGroupSearch={groups && features && groups.includes('sales') && features.includes('search')}
                >
                  <Profile />
                </PrivateRoute>
              }
            />
          </Routes>
        </Grid>
        <Footer />
      </Grid>
    </ToolbarProvider>
  )
}

export default CaleoRouter
