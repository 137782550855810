/**
 * Authentication token key
 */
export const STORAGE_TOKEN_KEY_NAME = 'token'

/**
 * Refresh token key
 */
export const STORAGE_REFRESH_TOKEN_KEY_NAME = 'refreshToken'

/**
 * Azure authentication token key
 */
export const STORAGE_AZURE_TOKEN_KEY_NAME = 'accessToken'

/**
 * Azure home account ID
 */
export const STORAGE_AZURE_ACCOUNT_ID = 'accountId'
