import { Box } from '@mui/material'
import { Autocomplete } from '@mui/material'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IAllocationScopeItem } from 'types/allocationInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { InputField } from '../InputField'

/** @notExported  */
interface AllocationScopePickerProps {
  /** The current value. */
  value?: { value: number; label: string; type: string }
  /** The list of scopes. */
  items: IAllocationScopeItem[]
  /** The function to call when the value changes. */
  onChange: (item: { value: number; label: string; type: string }) => void
  /** The label of the picker. */
  label?: string
  /** Whether the picker is disabled. */
  disabled?: boolean
  /** Whether the picker is required. */
  required?: boolean
  /** Whether to inset label. */
  insetLabel?: boolean
}

/**
 * Allocation scope picker component.
 *
 * @param value - the current value.
 * @param items - the list of scopes.
 * @param onChange - the function to call when the value changes.
 * @param label - the label of the picker.
 * @param disabled - whether the picker is disabled.
 * @param required - whether the picker is required.
 * @returns the allocation scope picker component.
 * @notExported
 */
const AllocationScopePicker: React.FC<AllocationScopePickerProps> = ({
  value,
  items,
  onChange,
  label,
  disabled = false,
  required = false,
  insetLabel,
}) => {
  const { t, i18n } = useTranslation()

  const getName = (item: IAllocationScopeItem) => {
    const name = chooseDBTranslation(i18n, item).name
    if (name && name.length) {
      return name
    }

    return t('team.noName')
  }

  const options =
    items?.map(val => ({
      value: val,
      label: getName(val),
      type: val.type,
    })) ?? []

  const loading = !items

  let selected
  if (value) {
    selected = {
      value: value.value,
      label: value.label,
      type: value.type,
    }
  }

  return (
    <>
      {!insetLabel && label && <CaleoInputLabel label={label} required={required} />}
      <Autocomplete
        loading={loading}
        disabled={disabled}
        style={{ marginTop: label ? '10px' : 0, minWidth: '280px' }}
        value={selected}
        onChange={(_event, newValue) => {
          if (newValue) {
            onChange({ value: newValue.value.id, label: newValue.label, type: newValue.type })
          }
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={options}
        renderOption={(props, option) => {
          if (typeof option.value === 'string') {
            return (
              <Box component="li" {...props}>
                {option.label}
              </Box>
            )
          }
          return (
            <Box component="li" {...props} key={option.value.id}>
              {option.label}
            </Box>
          )
        }}
        renderInput={params => (
          <InputField
            {...params}
            placeholder={label && !insetLabel ? '' : t('allocation.selectScope')}
            insetLabel={insetLabel}
            label={insetLabel ? label : undefined}
            InputLabelProps={{ shrink: insetLabel ? true : undefined }}
            margin="dense"
            size="small"
            required={required}
          />
        )}
        fullWidth
      />
    </>
  )
}

export default AllocationScopePicker
