import React, { useContext } from 'react'
import { Portal } from '@mui/material'

import { toolbarContext } from '.'

/**
 * Portal component to display extra controls in the toolbar.
 *
 * @param children - Extra elements to be displayed.
 * @returns Component for displaying extra controls in the toolbar.
 * @notExported
 */
export const ToolbarItems: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { itemRef } = useContext(toolbarContext)

  if (itemRef.current) {
    return <Portal container={itemRef.current}>{children}</Portal>
  } else {
    return null
  }
}
