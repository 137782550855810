import { Button } from '@mui/material'
import React from 'react'

/** @notExported  */
interface ToolbarButtonProps {
  /** Action to be performed when the button is clicked. */
  clickAction: () => void
  /** Button content. */
  children: React.ReactNode
}

/**
 * Toolbar button.
 *
 * @returns Toolbar button component.
 * @notExported
 * @category Buttons
 */
const ToolbarButton: React.FC<ToolbarButtonProps> = ({ clickAction, children }) => {
  return (
    <Button
      id="search"
      type="button"
      onClick={() => clickAction()}
      sx={{
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: theme => theme.palette.secondary.light,
        },
        width: '100%',
        height: '100%',
      }}
    >
      {children}
    </Button>
  )
}

export default ToolbarButton
