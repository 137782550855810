import { STORAGE_TOKEN_KEY_NAME, STORAGE_REFRESH_TOKEN_KEY_NAME, STORAGE_AZURE_TOKEN_KEY_NAME } from '../constants'

/**
 * Retrieves the token from local storage.
 *
 * @return {Promise<string | null>} The token value if it exists, otherwise null.
 */
export const getToken = async (): Promise<string | null> => {
  return localStorage.getItem(STORAGE_TOKEN_KEY_NAME)
}

/**
 * Remove a token from local storage.
 *
 * @return {Promise<void>} Promise that resolves when the token is removed.
 */
export const removeToken = async (): Promise<void> => {
  localStorage.removeItem(STORAGE_TOKEN_KEY_NAME)
}

/**
 * Sets the token in the local storage.
 *
 * @param {string} token - The token to be set.
 * @return {Promise<void>} - A promise that resolves when the token is set.
 */
export const setToken = async (token: string): Promise<void> => {
  localStorage.setItem(STORAGE_TOKEN_KEY_NAME, token)
}

/**
 * Sets the refresh token in the local storage.
 *
 * @param {string} refreshToken - The refresh token to be set.
 * @return {Promise<void>} - A Promise that resolves when the refresh token is set.
 */
export const setRefreshToken = async (refreshToken: string): Promise<void> => {
  localStorage.setItem(STORAGE_REFRESH_TOKEN_KEY_NAME, refreshToken)
}

/**
 * Retrieves the refresh token from local storage.
 *
 * @return {Promise<string | null>} The refresh token, or null if it is not found.
 */
export const getRefreshToken = async (): Promise<string | null> => {
  return localStorage.getItem(STORAGE_REFRESH_TOKEN_KEY_NAME)
}

/**
 * Removes the refresh token from local storage.
 *
 * @return {Promise<void>} - A promise that resolves when the refresh token is successfully removed.
 */
export const removeRefreshToken = async (): Promise<void> => {
  localStorage.removeItem(STORAGE_REFRESH_TOKEN_KEY_NAME)
}

/**
 * Retrieves the Azure token from local storage.
 *
 * @return {Promise<string | null>} The Azure token or null if not found.
 */
export const getAzureToken = async (): Promise<string | null> => {
  return localStorage.getItem(STORAGE_AZURE_TOKEN_KEY_NAME)
}
