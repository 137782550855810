import React from 'react'
import GlobalFilter from './GlobalFilter'
import { Toolbar, Grid } from '@mui/material'
import VisibilityControls from './VisibilityControls'

/**
 * Table toolbar component.
 *
 * @param search - Should search field be shown.
 * @param preGlobalFilteredRowsCount - Number of rows before filtering.
 * @param setGlobalFilter - Function to set global filter.
 * @param globalFilter - Global filter value.
 * @param toolbarControls - Toolbar controls.
 * @param table - Table instance.
 * @param newStyle - New style.
 * @returns Table toolbar.
 * @notExported
 */
const TableToolbar = ({
  search,
  preGlobalFilteredRowsCount,
  setGlobalFilter,
  globalFilter,
  toolbarControls,
  table,
  newStyle,
}) => {
  return (
    <Toolbar disableGutters={newStyle ? true : false}>
      <Grid container alignItems="center">
        {search && (
          <Grid item xs={12} md={7}>
            <GlobalFilter
              rowCount={preGlobalFilteredRowsCount}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              newStyle={newStyle}
            />
          </Grid>
        )}
        <Grid item xs container justifyContent="flex-end" alignContent="center">
          {toolbarControls}
        </Grid>
        <Grid item xs container justifyContent="flex-end" alignContent="center">
          <VisibilityControls table={table} />
        </Grid>
      </Grid>
    </Toolbar>
  )
}

export default TableToolbar
