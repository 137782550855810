import React, { useState, useEffect, useContext } from 'react'
import jwtDecode from 'jwt-decode'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { authAPI } from 'api/AuthAPI'
import {
  STORAGE_TOKEN_KEY_NAME,
  STORAGE_REFRESH_TOKEN_KEY_NAME,
  STORAGE_AZURE_TOKEN_KEY_NAME,
  STORAGE_AZURE_ACCOUNT_ID,
} from '../constants'
import axios from 'axios'
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { IError } from 'types/error'
import { scopes } from 'api/authConfig'
import { removeNonCaleoStorage } from 'utils/utils'
import { getAuthorization, socket } from 'api/WebSocket'
import { getStorageValue } from './data'

interface LoginState {
  token: string | null
  refreshToken: string | null
  azureToken: string | null
  userId: number | null
  loggingIn: boolean
  setLoggingIn: (value: boolean) => void
  loggedIn: boolean
  setLoggedIn: (value: boolean) => void
  doLogin: (body: unknown) => Promise<void>
  logout: () => Promise<void>
  azureLogin: () => void
  getAzureUser: () => Promise<void>
  error: string | undefined
  loginLoading: boolean
  setResetPassword: (value: boolean) => void
  setLoginLoading: (value: boolean) => void
}

export class LoginError extends Error {}

// The default value's type is a lie here because the default value is
// never actually used! We always give the unexported Provider a valid
// value as a prop
const loginContext = React.createContext<LoginState>({} as LoginState)

const { Provider } = loginContext

/**
 * Function for extracting ID from access token.
 *
 * @param token - Access token.
 * @returns User ID.
 * @notExported
 */
const extractUserIdFromToken = (token: string | null): number | null => {
  if (!token) {
    return null
  }

  try {
    const data = jwtDecode<null | { id?: number }>(token)
    if (data && 'id' in data && typeof data.id === 'number') {
      return data.id
    }
  } catch (err) {
    window.localStorage.removeItem(STORAGE_TOKEN_KEY_NAME)
    window.localStorage.removeItem(STORAGE_REFRESH_TOKEN_KEY_NAME)
  }

  return null
}

/**
 * Provider for handling authentication operations in the application.
 *
 * @param children
 * @returns Provider for handling authentication operations.
 */
export const LoginProvider: React.FC<Record<string, unknown>> = ({ children }) => {
  const { t } = useTranslation()
  const [token, _setToken] = useState<string | null>(localStorage.getItem(STORAGE_TOKEN_KEY_NAME))
  const [refreshToken, _setRefreshToken] = useState<string | null>(localStorage.getItem(STORAGE_REFRESH_TOKEN_KEY_NAME))
  const [azureToken, _setAzureToken] = useState<string | null>(localStorage.getItem(STORAGE_AZURE_TOKEN_KEY_NAME))
  const [userId, setUserId] = useState<number | null>(extractUserIdFromToken(token))
  const [loggingIn, setLoggingIn] = useState<boolean>(false)
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [loginLoading, setLoginLoading] = useState<boolean>(false)
  const [resetPassword, setResetPassword] = useState<boolean>(false)
  const [doAzureLogin, setDoAzureLogin] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { instance, inProgress } = useMsal()

  const setToken = (token: string | null, refreshToken: string | null) => {
    if (token && refreshToken) {
      window.localStorage.setItem(STORAGE_TOKEN_KEY_NAME, token)
      window.localStorage.setItem(STORAGE_REFRESH_TOKEN_KEY_NAME, refreshToken)
    } else {
      window.localStorage.removeItem(STORAGE_TOKEN_KEY_NAME)
      window.localStorage.removeItem(STORAGE_REFRESH_TOKEN_KEY_NAME)
      window.localStorage.removeItem(STORAGE_AZURE_TOKEN_KEY_NAME)
    }

    _setRefreshToken(refreshToken)
    _setToken(token)
    if (token && refreshToken) {
      setUserId(extractUserIdFromToken(token))
      setLoggedIn(true)
    }
  }

  useEffect(() => {
    const account = instance.getActiveAccount()

    const callbackId = instance.addEventCallback(async (event: EventMessage) => {
      if (!resetPassword) {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          setLoginLoading(true)
          const payload = event.payload as AuthenticationResult
          instance.setActiveAccount(payload.account)
          localStorage.setItem(STORAGE_AZURE_TOKEN_KEY_NAME, payload.accessToken)
          localStorage.setItem(STORAGE_AZURE_ACCOUNT_ID, payload.account.homeAccountId)
          _setAzureToken(payload.accessToken)
          await getAzureUser()
          setLoggingIn(false)
          if (!error) setLoggedIn(true)
          setLoginLoading(false)
          return
        }

        if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload) {
          setLoginLoading(true)
          const payload = event.payload as AuthenticationResult
          instance.setActiveAccount(payload.account)
          localStorage.setItem(STORAGE_AZURE_TOKEN_KEY_NAME, payload.accessToken)
          localStorage.setItem(STORAGE_AZURE_ACCOUNT_ID, payload.account.homeAccountId)
          _setAzureToken(payload.accessToken)
          await getAzureUser()
          setLoggingIn(false)
          if (!error) setLoggedIn(true)
          setLoginLoading(false)
          return
        }

        if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE && event.interactionType === InteractionType.Silent) {
          removeNonCaleoStorage()
          setLoginLoading(false)
          setLoggedIn(false)
          setLoggingIn(false)
          if (event.error?.name === 'InteractionRequiredAuthError') {
            setLoginLoading(true)
            removeNonCaleoStorage()
            await instance.loginRedirect({ scopes: scopes, redirectUri: '/login' })
            setLoginLoading(false)
          }
          return
        }

        if (event.eventType === EventType.SSO_SILENT_SUCCESS && event.payload) {
          setLoginLoading(true)
          const payload = event.payload as AuthenticationResult
          instance.setActiveAccount(payload.account)
          localStorage.setItem(STORAGE_AZURE_TOKEN_KEY_NAME, payload.accessToken)
          localStorage.setItem(STORAGE_AZURE_ACCOUNT_ID, payload.account.homeAccountId)
          _setAzureToken(payload.accessToken)
          await getAzureUser()
          setLoggingIn(false)
          if (!error) setLoggedIn(true)
          setLoginLoading(false)
          return
        }

        if (event.eventType === EventType.SSO_SILENT_FAILURE) {
          _setToken(null)
          _setAzureToken(null)
          _setRefreshToken(null)
          setLoggedIn(false)
          setLoggingIn(false)
          setUserId(null)
          setLoginLoading(false)
          removeNonCaleoStorage()
          navigate('/login')
          return
        }

        if (
          (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE && event.interactionType !== InteractionType.Silent) ||
          event.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE
        ) {
          _setToken(null)
          _setAzureToken(null)
          _setRefreshToken(null)
          setLoggedIn(false)
          setLoggingIn(false)
          setUserId(null)
          setLoginLoading(false)
          removeNonCaleoStorage()
          navigate('/login')
          return
        }

        // for Azure AD debugging
        if (
          getStorageValue('debug', false) === true &&
          event.eventType /*!== EventType.LOGIN_SUCCESS &&
          event.eventType !== EventType.ACQUIRE_TOKEN_SUCCESS &&
          event.eventType !== EventType.ACQUIRE_TOKEN_FAILURE &&
          event.eventType !== EventType.SSO_SILENT_SUCCESS*/
        ) {
          console.info('Azure AD SSO event debug:')
          console.info('event type', event.eventType)
          console.info('event error', event.error)
          console.info('event interactionType', event.interactionType)
          console.info('event payload', event.payload)
        }
      }
    })

    ;(async () => {
      if (!resetPassword) {
        if (
          !localStorage.getItem(STORAGE_AZURE_TOKEN_KEY_NAME) &&
          !localStorage.getItem(STORAGE_AZURE_ACCOUNT_ID) &&
          !localStorage.getItem(STORAGE_TOKEN_KEY_NAME) &&
          !localStorage.getItem(STORAGE_REFRESH_TOKEN_KEY_NAME)
        ) {
          if (account && !error) {
            if (location.pathname) {
              localStorage.setItem('azureRedirect', location.pathname + location.search)
            }
            try {
              await instance.ssoSilent({
                scopes: scopes,
                redirectUri: '/healt',
                loginHint: account.username,
              })
            } catch (error) {
              localStorage.clear()
              setLoggedIn(false)
              setLoggingIn(false)
              setLoginLoading(false)
              navigate('/login')
            }
          }
        }

        if (localStorage.getItem(STORAGE_AZURE_TOKEN_KEY_NAME) && account && !error) {
          setLoginLoading(true)
          if (location.pathname) {
            localStorage.setItem('azureRedirect', location.pathname + location.search)
          }
          try {
            await instance.acquireTokenSilent({
              account,
              scopes: scopes,
              redirectUri: '/healt',
            })
          } catch (error) {
            localStorage.clear()
            setLoggedIn(false)
            setLoggingIn(false)
            setLoginLoading(false)
            navigate('/login')
          }
        }

        if (
          localStorage.getItem(STORAGE_TOKEN_KEY_NAME) &&
          localStorage.getItem(STORAGE_REFRESH_TOKEN_KEY_NAME) &&
          !localStorage.getItem(STORAGE_AZURE_TOKEN_KEY_NAME) &&
          !localStorage.getItem(STORAGE_AZURE_ACCOUNT_ID)
        ) {
          setLoggedIn(true)
          setLoggingIn(false)
          setLoginLoading(false)
        }
      }
    })()

    const onStorageEvent = (event: StorageEvent) => {
      if (event.key === null) {
        _setToken(null)
        _setRefreshToken(null)
        setUserId(null)
      } else if (event.key === STORAGE_TOKEN_KEY_NAME) {
        _setToken(event.newValue)
        setUserId(extractUserIdFromToken(event.newValue))
      } else if (event.key === STORAGE_REFRESH_TOKEN_KEY_NAME) {
        _setRefreshToken(event.newValue)
      }
    }

    window.addEventListener('storage', onStorageEvent)

    return () => {
      window.removeEventListener('storage', onStorageEvent)
      if (callbackId) {
        instance.removeEventCallback(callbackId)
      }
    }
  }, [])

  useEffect(() => {
    if (doAzureLogin && !error && inProgress === InteractionStatus.None) {
      if (location.pathname && !location.pathname.includes('login') && !localStorage.getItem('azureRedirect')) {
        localStorage.setItem('azureRedirect', location.pathname + location.search)
      }
      instance.loginRedirect({ scopes: scopes, redirectUri: '/login' })
      setDoAzureLogin(false)
    }
  }, [doAzureLogin, inProgress])

  const doLogin = async (body: unknown): Promise<void> => {
    if (loggingIn) {
      return
    }

    setLoggedIn(false)
    setLoggingIn(true)
    try {
      const response = await authAPI.login(body)
      if (typeof response === 'string') {
        if (response === 'Wrong email or password!') {
          throw new LoginError(t('login.wrongPassword'))
        }
        if (response === 'Account not verified!') {
          throw new LoginError(t('login.notActivated'))
        }
        throw new LoginError(t('login.failed'))
      } else if (response.message === 'Terms and policies not accepted') {
        navigate('/activate', {
          state: {
            termsNotAccepted: true,
            loginDetails: body,
            userData: response,
          },
        })
      } else {
        setToken(response.token, response.refreshToken)
        setLoggedIn(true)
      }
    } catch (err) {
      if (err instanceof LoginError) {
        throw err
      }

      console.error('Unexpected Error while logging in: ', err)
    } finally {
      setLoggingIn(false)
    }
  }

  const logout = async (): Promise<void> => {
    if (loggingIn) {
      return
    }

    if (azureToken && !localStorage.getItem(STORAGE_TOKEN_KEY_NAME)) {
      const currentAccount = instance.getActiveAccount()
      _setToken(null)
      _setAzureToken(null)
      _setRefreshToken(null)
      setLoggedIn(false)
      if (currentAccount) {
        await instance.logoutRedirect({ account: currentAccount })
      } else {
        await instance.logoutRedirect()
      }
      removeNonCaleoStorage()
      socket.disconnect()
      setToken(null, null)
      navigate('/login')
      return
    }

    try {
      await authAPI.logout()
      _setToken(null)
      _setAzureToken(null)
      _setRefreshToken(null)
      setLoggedIn(false)
    } catch (err) {
      removeNonCaleoStorage()
      // Ignore errors caused by the client not being logged in in the
      // first place
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        throw err
      }
    }

    // Disconnect websocket connection
    socket.disconnect()

    removeNonCaleoStorage()
    setToken(null, null)
  }

  const azureLogin = () => {
    if (loggingIn) {
      return
    }

    setLoginLoading(true)
    setLoggingIn(true)
    setError(undefined)

    if (location.state && location.state.url && !location.state.url.includes('login')) {
      localStorage.setItem('azureRedirect', location.state.url)
    }

    setDoAzureLogin(true)
  }

  const getAzureUser = async () => {
    try {
      const data = await authAPI.getUserId()

      if (data.activated === false) {
        setLoginLoading(false)
        navigate('/activate', {
          state: {
            termsNotAccepted: true,
            loginDetails: { azure: true },
            userData: {
              userId: data.userId,
            },
          },
        })
      } else {
        setUserId(data.userId)
        socket.disconnect()

        socket.io.opts.extraHeaders = {
          Authorization: `Bearer ${getAuthorization()}`,
        }

        socket.connect()
        socket.emit('user', data.userId, (_status: string, _secret: string) => {
          return
        })
      }
    } catch (error) {
      const err = error as IError
      localStorage.clear()
      setError(err.key)
      setLoggingIn(false)
      setLoginLoading(false)
      setLoggedIn(false)
    } finally {
      setLoginLoading(false)
    }
  }

  return React.createElement(
    Provider,
    {
      value: {
        token,
        refreshToken,
        azureToken,
        userId,
        loggingIn,
        setLoggingIn,
        loggedIn,
        setLoggedIn,
        doLogin,
        logout,
        azureLogin,
        getAzureUser,
        error,
        loginLoading,
        setResetPassword,
        setLoginLoading,
      },
    },
    children as React.ReactNode
  )
}

/**
 * Hook for using LoginProvider
 *
 * @returns Hook variables and functions.
 */
export function useLogin(): LoginState {
  return useContext(loginContext)
}
