import { chooseDBTranslation } from 'utils/translations'
import { Paragraph, TextRun } from 'docx'
import { CVData } from './index'

/**
 * Generates a paragraph for the 'about' section.
 *
 * @param {object} i18n - The internationalization object.
 * @param {CVData} data - The CV data object.
 * @return {Paragraph} The generated paragraph for the 'about' section.
 * @notExported
 */
const about = (i18n, data: CVData): Paragraph => {
  // return about section

  return new Paragraph({
    keepLines: true,
    children: [
      new TextRun({
        text: data ? chooseDBTranslation(i18n, data.about).about : '',
        size: 22,
        font: {
          name: 'Raleway',
        },
      }),
    ],
  })
}

export default about
