import { Avatar, Grid } from '@mui/material'
import { personAPI } from 'api/PersonAPI'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import { useIsComponentMounted } from 'hooks/util'
import React, { useEffect, useState } from 'react'
import { IPerson } from 'types/userInterfaces'

/** @notExported */
interface ISalesContactProps {
  /** Sales contact person */
  salesContact: IPerson
}

/**
 * Sales contact component.
 *
 * @returns Sales contact component.
 * @notExported
 */
const SalesContact: React.FC<ISalesContactProps> = ({ salesContact }) => {
  const isComponentMounted = useIsComponentMounted()
  const [image, setImage] = useState<string>('')

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      const profileImage = await personAPI.getProfileImage(salesContact.id, controller)
      if (!isComponentMounted.current) return
      setImage(profileImage)
    })()

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <Grid item>
      <Grid container alignItems="center" direction="row" justifyContent="center" spacing={2}>
        <Grid item>
          <Avatar src={image} sx={{ width: 80, height: 80 }} alt="ProfilePic" />
        </Grid>
        <Grid item>
          <Grid container alignItems="flex-start" direction="column" justifyContent="center">
            <Grid item>
              <CardContentText type="header">{`${salesContact.firstName} ${salesContact.lastName}`}</CardContentText>
            </Grid>
            <Grid item>
              <CardContentText>{salesContact.Account?.email}</CardContentText>
            </Grid>
            <Grid item>
              <CardContentText>{salesContact.telephone}</CardContentText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(SalesContact)
