import { SubAPI } from './API'

export type CountriesData = Record<string, string>
export type LanguagesData = Record<string, string>

/**
 * Utils API.
 * @notExported
 */
class UtilsAPI extends SubAPI {
  /**
   * Get countries data.
   *
   * @param language - Data language.
   * @returns Countries data.
   */
  public getCountriesData(language: string, controller?: AbortController): Promise<CountriesData> {
    return this.api.get(`utils/countries/${language}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get languages data.
   *
   * @param language - Data language.
   * @returns Languages data.
   */
  public getLanguagesData(language: string, controller?: AbortController): Promise<LanguagesData> {
    return this.api.get(`utils/languages/${language}`, controller ? { signal: controller.signal } : undefined)
  }
}

export const utilsAPI = new UtilsAPI()
