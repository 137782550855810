import { adminAPI } from 'api/AdminAPI'
import { assignmentAPI } from 'api/AssignmentAPI'
import { organizationAPI } from 'api/OrganizationAPI'
import { skillAPI } from 'api/SkillAPI'
import { teamAPI } from 'api/TeamAPI'
import { CountriesData, LanguagesData, utilsAPI } from 'api/UtilsAPI'
import { useLoading } from 'Components/reusable/LoadingProvider'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IEmployee } from 'types/adminInterfaces'
import { IAssignment } from 'types/assignmentInterfaces'
import { IEmployer, IProject, ISkill } from 'types/cvsInterfaces'
import { IError } from 'types/error'
import { SiteId } from 'types/ids'
import { ITeam } from 'types/teamInterfaces'
import { IPerson } from 'types/userInterfaces'
import { useIsComponentMounted } from './util'

/**
 * Hook for getting countries data list.
 *
 * @returns Hook variables and functions.
 */
export const useCountryData = () => {
  const isComponentMounted = useIsComponentMounted()
  const [countries, setCountries] = useState<CountriesData>({})
  const [error, setError] = useState<IError>()

  const { i18n } = useTranslation()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        const results = await utilsAPI.getCountriesData(i18n.language, controller)
        if (isComponentMounted.current) setCountries(results)
      } catch (error) {
        setError(error as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [i18n.language])

  return {
    countries,
    error,
  }
}

/**
 * Hook for getting languages data list.
 *
 * @returns Hook variables and functions.
 */
export const useLanguageData = () => {
  const isComponentMounted = useIsComponentMounted()
  const [languages, setLanguages] = useState<LanguagesData>()
  const [error, setError] = useState<IError>()

  const { i18n } = useTranslation()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        const results = await utilsAPI.getLanguagesData(i18n.language, controller)
        if (isComponentMounted.current) setLanguages(results)
      } catch (error) {
        setError(error as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [i18n.language])

  return {
    languages,
    error,
  }
}

/**
 * Hook for getting skills data.
 *
 * @returns Hook variables and functions.
 */
export const useSkillsData = () => {
  const isComponentMounted = useIsComponentMounted()
  const [skills, setSkills] = useState<ISkill[]>()
  const { startLoading, endLoading } = useLoading()
  const [error, setError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        startLoading('skillsDataUsage')
        const results = await skillAPI.getAllSkills(controller)
        if (isComponentMounted.current) setSkills(results)
      } catch (err) {
        setError(err as IError)
      } finally {
        endLoading('skillsDataUsage')
      }
    })()

    return () => {
      controller.abort()
    }
  }, [])

  return {
    skills,
    error,
  }
}

/**
 * Hook for getting projects or employers based on skill.
 *
 * @param id - Skill ID
 * @param type - Type of skill
 * @return Hook variables and functions.
 */
export const useConnectedSkillData = (id: number, type: string) => {
  const isComponentMounted = useIsComponentMounted()
  const [projects, setProjects] = useState<IProject[]>([])
  const [employers, setEmployers] = useState<IEmployer[]>([])
  const { startLoading, endLoading } = useLoading()
  const [error, setError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        startLoading('connectedSkillData')
        if (type === 'skill') {
          const results = await skillAPI.getSkillProjects(id, controller)
          if (isComponentMounted.current) setProjects(results)
        } else if (type === 'industry') {
          const results = await skillAPI.getIndustryProjects(id, controller)
          if (isComponentMounted.current) setProjects(results)
        } else if (type === 'role') {
          const results = await skillAPI.getRoleProjects(id, controller)
          const employerResults = await skillAPI.getRoleEmployers(id, controller)
          if (isComponentMounted.current) {
            setProjects(results)
            setEmployers(employerResults)
          }
        }
      } catch (err) {
        setError(err as IError)
      } finally {
        endLoading('connectedSkillData')
      }
    })()

    return () => {
      controller.abort()
    }
  }, [])

  return {
    projects,
    employers,
    error,
  }
}

/**
 * Hook for getting people based on site ID.
 *
 * @param id - Site ID.
 * @returns Hook variables and functions.
 */
export const useConnectedSiteData = (id: SiteId) => {
  const isComponentMounted = useIsComponentMounted()
  const [people, setPeople] = useState<IPerson[]>([])
  const { startLoading, endLoading } = useLoading()
  const [error, setError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        startLoading('connectedSiteData')
        if (id !== 0) {
          const results = await organizationAPI.getSitePeople(id, controller)
          if (isComponentMounted.current) setPeople(results)
        }
      } catch (err) {
        setError(err as IError)
      } finally {
        endLoading('connectedSiteData')
      }
    })()

    return () => {
      controller.abort()
    }
  }, [])

  return {
    people,
    error,
  }
}

/**
 * Hook for getting employees, assignments and teams based on organization ID.
 *
 * @param organizationId - Organization ID
 * @param networkManage - Is user network manager
 * @returns Hook variables and functions.
 */
export const useConnectedOrganizationData = (organizationId: number, networkManage?: boolean) => {
  const isComponentMounted = useIsComponentMounted()
  const [employees, setEmployees] = useState<IEmployee[]>([])
  const [assignments, setAssignments] = useState<IAssignment[]>([])
  const [teams, setTeams] = useState<ITeam[]>([])
  const { startLoading, endLoading } = useLoading()
  const [error, setError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        startLoading('connectedOrganizationData')
        if (!networkManage) {
          const employees = await adminAPI.getOrgEmployees(organizationId, controller)
          const assignments = await assignmentAPI.getOrganizationAssignments(organizationId, controller)
          const teams = await teamAPI.getOrganizationTeams(organizationId, controller)
          if (isComponentMounted.current) {
            setEmployees(employees)
            setAssignments(assignments)
            setTeams(teams)
          }
        }
      } catch (err) {
        setError(err as IError)
      } finally {
        endLoading('connectedOrganizationData')
      }
    })()

    return () => {
      controller.abort()
    }
  }, [])

  return {
    employees,
    assignments,
    teams,
    error,
  }
}

/**
 * Function for getting value from local storage.
 *
 * @param key - Key of the local storage item.
 * @param defaultValue - Default value of the local storage item.
 * @returns Value of the local storage item.
 */
export function getStorageValue(key: string, defaultValue) {
  const saved = localStorage.getItem(key)
  let initial = defaultValue
  if (saved) {
    initial = JSON.parse(saved)
  }
  return initial || defaultValue
}

/**
 * Hook for getting and setting value of local storage item.
 *
 * @param key - Key of the local storage item.
 * @param defaultValue - Default value of the local storage item.
 * @returns Value of and function for setting local storage value.
 */
export const useLocalStorage = (key: string, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue)
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
