import React, { useState } from 'react'
import { useStyles } from 'pages/Allocation/styles'
import { useTranslation } from 'react-i18next'
import { Button, Tooltip, Typography, Box, Grid, useTheme } from '@mui/material'
import { IAllocation } from 'types/allocationInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import './calendar.css'

interface IComponentProps {
  /** The allocation items. */
  items: IAllocation[]
}

/**
 * Renders an allocation calendar component.
 *
 * @param {IAllocation[]} items - The allocation items.
 * @return {ReactElement} The rendered allocation calendar.
 * @notExported
 */
const AllocationCalendar: React.FC<IComponentProps> = ({ items }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const currentYear = new Date().getFullYear()
  const classes = useStyles()

  const [year, setYear] = useState<number>(currentYear)
  const months = Array.from(Array(12))

  const yearAllocations = items.filter(item => {
    const startDate = new Date(item.startDate)
    const endDate = item.endDate ? new Date(item.endDate) : null

    if (startDate.getFullYear() <= year && (!endDate || endDate.getFullYear() >= year)) {
      return true
    }
    return false
  })

  const getName = allocation => {
    if (allocation.Assignment) {
      return chooseDBTranslation(i18n, allocation.Assignment).name
    } else if (allocation.type === 'other') {
      return t('allocation.other')
    } else {
      return t('allocation.own')
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} container alignContent="center">
        <Grid item xs={6} container alignItems="center" justifyContent="flex-start">
          <Button
            sx={
              year === currentYear - 1
                ? { fontSize: '20px', fontWeight: 'bold' }
                : { fontSize: '18px', fontWeight: 'bold', opacity: '25%' }
            }
            onClick={() => setYear(currentYear - 1)}
          >
            {currentYear - 1}
          </Button>
          <Button
            sx={
              year === currentYear
                ? { fontSize: '20px', fontWeight: 'bold' }
                : { fontSize: '18px', fontWeight: 'bold', opacity: '25%' }
            }
            onClick={() => setYear(currentYear)}
          >
            {currentYear}
          </Button>
          <Button
            sx={
              year === currentYear + 1
                ? { fontSize: '20px', fontWeight: 'bold' }
                : { fontSize: '18px', fontWeight: 'bold', opacity: '25%' }
            }
            onClick={() => setYear(currentYear + 1)}
          >
            {currentYear + 1}
          </Button>
        </Grid>
        <Grid item xs={6} container alignItems="center" justifyContent="flex-end">
          <Grid
            sx={{
              width: '15px',
              height: '15px',
              borderRadius: '30%',
              display: 'inline-block',
              backgroundColor: theme.palette.secondary.main,
            }}
          />
          <Grid sx={{ marginRight: '25px', marginLeft: '5px' }}>{t('allocation.own')}</Grid>
          <Grid
            sx={{
              width: '15px',
              height: '15px',
              borderRadius: '30%',
              display: 'inline-block',
              backgroundColor: theme.palette.warning.main,
            }}
          />
          <Grid sx={{ marginRight: '25px', marginLeft: '5px' }}>{'<100%'}</Grid>
          <Grid
            sx={{
              width: '15px',
              height: '15px',
              borderRadius: '30%',
              display: 'inline-block',
              backgroundColor: theme.palette.error.main,
            }}
          />
          <Grid sx={{ marginRight: '25px', marginLeft: '5px' }}>{'100%'}</Grid>
          <Box
            border={1}
            sx={{
              width: '15px',
              height: '15px',
              borderRadius: '30%',
              color: theme.palette.neutral.main,
              display: 'inline-block',
              backgroundColor: theme.palette.background.default,
            }}
          />
          <Grid sx={{ marginRight: '25px', marginLeft: '5px' }}>{'0%'}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="center" alignContent="center" spacing={2}>
        {months.map((m, i) => {
          const currentMonth = new Date().getMonth()
          let monthNameClass = {
            color: theme.palette.primary.main,
            opacity: '0.8',
            marginBottom: '5px',
            fontWeight: 'bold',
          }
          if (year > currentYear) {
            monthNameClass = {
              color: theme.palette.primary.main,
              opacity: '1',
              marginBottom: '5px',
              fontWeight: 'bold',
            }
          } else if (year < currentYear) {
            monthNameClass = {
              color: theme.palette.primary.main,
              opacity: '0.8',
              marginBottom: '5px',
              fontWeight: 'bold',
            }
          } else if (i >= currentMonth) {
            monthNameClass = {
              color: theme.palette.primary.main,
              opacity: '1',
              marginBottom: '5px',
              fontWeight: 'bold',
            }
          }
          return (
            <Grid key={i} item xs={12} sm={6} md={4} lg={3} container alignContent="flex-start">
              <Grid item xs={12} sx={{ textAlign: 'center', ...monthNameClass }} className="calendar-month-name">
                {t(`months.${i}`)}
              </Grid>
              <Grid item xs={12}>
                <Calendar
                  activeStartDate={new Date(year, i, 1)}
                  minDetail="month"
                  view="month"
                  nextLabel={null}
                  next2Label={null}
                  prevLabel={null}
                  prev2Label={null}
                  showNeighboringMonth={false}
                  showNavigation={false}
                  value={new Date()}
                  formatShortWeekday={(_locale: unknown, date: Date) => t(`weekdaysShort.${date.getDay()}`)}
                  tileContent={({ date }) => {
                    let percent = 0
                    const allocationPercents: {
                      name: string
                      percent: number
                    }[] = []
                    const dayAllocations: IAllocation[] = []
                    yearAllocations.forEach(a => {
                      if (date >= new Date(a.startDate) && (!a.endDate || date <= new Date(a.endDate))) {
                        dayAllocations.push(a)
                      }
                    })
                    dayAllocations.forEach(a => {
                      percent += a.percent
                      allocationPercents.push({
                        name: getName(a),
                        percent: a.percent,
                      })
                    })
                    return (
                      <Tooltip
                        placement="top"
                        title={
                          <React.Fragment>
                            <Typography fontWeight="bold">
                              {t('allocation.totalAllocation')}: {percent}%
                            </Typography>
                            {allocationPercents.map((ap, i) => (
                              <Typography key={i}>
                                {ap.name}: {ap.percent} %
                              </Typography>
                            ))}
                          </React.Fragment>
                        }
                      >
                        <Grid
                          sx={{
                            zIndex: 10,
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            textAlign: 'center',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                        />
                      </Tooltip>
                    )
                  }}
                  tileClassName={({ date }) => {
                    let percent = 0
                    let hasPersonal = false
                    const dayAllocations: IAllocation[] = []
                    yearAllocations.forEach(a => {
                      if (date >= new Date(a.startDate) && (!a.endDate || date <= new Date(a.endDate))) {
                        dayAllocations.push(a)
                      }
                    })
                    dayAllocations.forEach(a => {
                      percent += a.percent
                      if (a.type === 'personal') hasPersonal = true
                    })

                    if (hasPersonal) {
                      return classes.calendarOwn
                    } else if (percent >= 100) {
                      return classes.calendarFull
                    } else if (percent < 100 && percent > 0) {
                      return classes.calendarNotFull
                    } else {
                      return null
                    }
                  }}
                />
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default AllocationCalendar
