import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useTranslation } from 'react-i18next'

/** @notExported */
interface BackButtonProps {
  /** Action to perform when button is clicked. */
  clickAction?: () => void
}

/**
 * Back button component.
 *
 * @returns Back button component.
 * @notExported
 * @category Buttons
 */
const BackButton: React.FC<BackButtonProps> = ({ clickAction }) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={t('back') as string}>
      <IconButton onClick={() => clickAction && clickAction()} size="large">
        <ArrowBack />
      </IconButton>
    </Tooltip>
  )
}

export default BackButton
