import _ from 'lodash'
import React, { useContext, useState, useMemo } from 'react'
import {
  IPersonSkill,
  IPersonIndustry,
  IPersonRole,
  IEmployer,
  IProject,
  IEducation,
  ICourse,
  ICertificate,
  IReference,
  ILanguageSkill,
  IUrl,
} from 'types/cvsInterfaces'
import { ICVData, IItemVisibility, ILayout, IVisibilities } from 'types/layoutInterfaces'

interface PrintPreviewState {
  loading: boolean
  visibleLayout?: ILayout
  setVisibleLayout: (newValue) => void
  visibleData?: ICVData
  data?: ICVData
  setData: (newValue) => void
}

const printContext = React.createContext<PrintPreviewState>({} as PrintPreviewState)

const { Provider } = printContext

/**
 * Generates the visible data for the PrintPreviewProvider component based on the provided layout and data.
 *
 * @param {ICVData} data - The input data for the PrintPreviewProvider component.
 * @param {ILayout} visibleLayout - The layout containing the item visibilities.
 * @return {ICVData | undefined} The visible data for the PrintPreviewProvider component.
 * @notExported
 */
export const PrintPreviewProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [visibleLayout, setVisibleLayout] = useState<ILayout>()
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<ICVData>()

  const calculateVisibilities = (ItemVisibilities: IItemVisibility[]): IVisibilities => {
    return {
      skill: calculateTypeVisibilities(ItemVisibilities, 'skills'),
      industry: calculateTypeVisibilities(ItemVisibilities, 'industries'),
      role: calculateTypeVisibilities(ItemVisibilities, 'roles'),
      employment: calculateTypeVisibilities(ItemVisibilities, 'employments'),
      project: calculateTypeVisibilities(ItemVisibilities, 'projects'),
      degree: calculateTypeVisibilities(ItemVisibilities, 'degrees'),
      course: calculateTypeVisibilities(ItemVisibilities, 'courses'),
      certificate: calculateTypeVisibilities(ItemVisibilities, 'certificates'),
      reference: calculateTypeVisibilities(ItemVisibilities, 'references'),
      language: calculateTypeVisibilities(ItemVisibilities, 'languages'),
      url: calculateTypeVisibilities(ItemVisibilities, 'urls'),
    }
  }

  const calculateTypeVisibilities = (ItemVisibilities: IItemVisibility[], type: string) => {
    let visibilities: IItemVisibility[] = []
    if (ItemVisibilities && ItemVisibilities.length > 0) {
      visibilities = ItemVisibilities.filter((visibility: IItemVisibility) => visibility.type === type)
    }

    return visibilities
  }

  const calculateVisibleData = (data: ICVData, visibilities: IVisibilities | undefined): ICVData => {
    let visibilitiesLength = 0
    for (const key in visibilities) {
      visibilitiesLength += visibilities[key].length
    }

    if (!visibilitiesLength || !visibilities) {
      return data
    }

    const visibleData: ICVData = data

    if (data.skills.length && visibilities.skill.length) {
      visibleData.skills = getVisibleItems(visibilities.skill, data.skills) as IPersonSkill[]
    }
    if (data.industries.length && visibilities.industry.length) {
      visibleData.industries = getVisibleItems(visibilities.industry, data.industries) as IPersonIndustry[]
    }
    if (data.roles.length && visibilities.role.length) {
      visibleData.roles = getVisibleItems(visibilities.role, data.roles) as IPersonRole[]
    }
    if (data.employments.length && visibilities.employment.length) {
      visibleData.employments = getVisibleItems(visibilities.employment, data.employments) as IEmployer[]
    }
    if (data.projects.length && visibilities.project.length) {
      visibleData.projects = getVisibleItems(visibilities.project, data.projects) as IProject[]
    }
    if (data.degrees.length && visibilities.degree.length) {
      visibleData.degrees = getVisibleItems(visibilities.degree, data.degrees) as IEducation[]
    }
    if (data.courses.length && visibilities.course.length) {
      visibleData.courses = getVisibleItems(visibilities.course, data.courses) as ICourse[]
    }
    if (data.certificates.length && visibilities.certificate.length) {
      visibleData.certificates = getVisibleItems(visibilities.certificate, data.certificates) as ICertificate[]
    }
    if (data.references.length && visibilities.reference.length) {
      visibleData.references = getVisibleItems(visibilities.reference, data.references) as IReference[]
    }
    if (data.languages.length && visibilities.language.length) {
      visibleData.languages = getVisibleItems(visibilities.language, data.languages) as ILanguageSkill[]
    }
    if (data.urls.length && visibilities.url.length) {
      visibleData.urls = getVisibleItems(visibilities.url, data.urls) as IUrl[]
    }

    return visibleData
  }

  const getVisibleItems = (visibilities: IItemVisibility[], items) => {
    const visibleItems: unknown[] = []
    for (const item of items) {
      const visibility = visibilities.find(x => x.itemId === item.id)
      if (visibility && !visibility.hidden) {
        visibleItems.push(item)
      }
      if (!visibility) {
        visibleItems.push(item)
      }
    }

    return visibleItems
  }

  const visibilities = useMemo(() => {
    if (visibleLayout?.ItemVisibilities) {
      setLoading(true)
      return calculateVisibilities(visibleLayout.ItemVisibilities)
    }
  }, [visibleLayout])

  const visibleData: ICVData | undefined = useMemo(() => {
    if (visibilities && data) {
      setLoading(false)
      return calculateVisibleData(_.cloneDeep(data), visibilities)
    }
  }, [visibilities, data, visibleLayout])

  return (
    <Provider
      value={{
        loading,
        visibleLayout,
        setVisibleLayout,
        visibleData,
        data,
        setData,
      }}
    >
      {children}
    </Provider>
  )
}

export function usePreviewData(): PrintPreviewState {
  return useContext(printContext)
}
