import { AES, enc } from 'crypto-js'

/**
 * Encrypts the given text using the provided secret key.
 *
 * @param {string} text - The text to be encrypted.
 * @param {string} secret - The secret key used for encryption.
 * @return {string} The encrypted text.
 */
export const Encrypt = (text: string, secret: string) => {
  if (secret.length && typeof text === 'string' && text.length) {
    const cipher = AES.encrypt(text, secret).toString()

    return cipher
  }
}

/**
 * Decrypts a cipher using a secret key.
 *
 * @param {string} cipher - The cipher to be decrypted.
 * @param {string} secret - The secret key used for decryption.
 * @return {string} The decrypted plaintext.
 */
export const Decrypt = (cipher: string, secret: string, t) => {
  if (secret.length && typeof cipher === 'string' && cipher.length) {
    if (cipher.startsWith('User ') && (cipher.endsWith(' left') || cipher.endsWith(' joined'))) {
      let translated = ''
      const words = cipher.split(' ')

      for (const word of words) {
        if (word.includes('User')) {
          translated += t('chat.user')
        } else if (word.includes('left')) {
          translated += t('chat.left')
        } else if (word.includes('joined')) {
          translated += t('chat.joined')
        } else {
          translated += word + ' '
        }
      }

      return translated.length ? translated : cipher
    }

    try {
      const bytes = AES.decrypt(cipher, secret)
      const decrypted = bytes.toString(enc.Utf8)

      return decrypted
    } catch (err) {
      console.error('UNABLE TO DECIPHER', err)
    }
  }
}
