import { IReleaseNote } from 'types/releaseNoteInterfaces'
import { SubAPI } from './API'

/**
 * Release Note API
 * @notExported
 */
class ReleaseAPI extends SubAPI {
  /**
   * Get release notes.
   *
   * @returns Array of release notes.
   */
  public getNotes(controller?: AbortController): Promise<IReleaseNote[]> {
    return this.api.get(`release/`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Create release note.
   *
   * @param data - Release note data.
   * @returns Created release note.
   */
  public createNote(data, controller?: AbortController): Promise<IReleaseNote> {
    return this.api.post(`release/`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Update release note.
   *
   * @param data - Release note data.
   * @param id - Release note ID.
   * @returns Updated release note.
   */
  public saveNote(data, id: number, controller?: AbortController): Promise<IReleaseNote> {
    return this.api.put(`release/${id}`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Delete release note.
   *
   * @param id - Release note ID.
   * @returns Promise of release note deletion.
   */
  public deleteNote(id: number): Promise<void> {
    return this.api.delete(`release/${id}`)
  }
}

export const releaseAPI = new ReleaseAPI()
