import React, { useEffect, useMemo, useState } from 'react'
import { alpha, useTheme } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import { Grid, InputAdornment, InputBase } from '@mui/material'
import { debounce } from 'lodash'
import { InputField } from 'Components/reusable/InputFields/InputField'
import CaleoIconButton from 'Components/reusable/IconButtons/CaleoIconButton'
import CloseIcon from '@mui/icons-material/Close'

/**
 * Global filter component for custom table.
 *
 * @param rowCount - Row count.
 * @param globalFilter - Global filter value.
 * @param setGlobalFilter - Global filter value setter.
 * @param newStyle - New style.
 * @returns Global filter component.
 * @notExported
 */
const GlobalFilter = ({ rowCount, setGlobalFilter, globalFilter, newStyle }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState<string>(globalFilter)

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })

  useEffect(() => {
    debouncedResults(searchValue)
  }, [searchValue])

  const handleChange = (value: string) => {
    if (value.length > 1 || value === '') setGlobalFilter(value || undefined)
  }

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300)
  }, [])

  return (
    <Grid
      sx={{
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 'auto',
        },
      }}
    >
      {newStyle ? (
        <InputField
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={`${rowCount} ${t('custom-table.records')}`}
          inputProps={{ 'aria-label': 'search' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
                {searchValue && searchValue.length > 0 && (
                  <CaleoIconButton
                    icon={<CloseIcon />}
                    clickAction={() => {
                      setGlobalFilter('')
                      setSearchValue('')
                    }}
                    tooltip={t('custom-table.clear')}
                  />
                )}
              </InputAdornment>
            ),
          }}
          fullWidth
          variant="outlined"
        />
      ) : (
        <>
          <Grid
            sx={{
              width: theme.spacing(7),
              height: '100%',
              position: 'absolute',
              pointerEvents: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SearchIcon />
          </Grid>
          <InputBase
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            placeholder={`${rowCount} ${t('custom-table.records')}`}
            sx={{
              root: { color: 'inherit' },
              input: {
                padding: theme.spacing(1, 1, 1, 7),
                transition: theme.transitions.create('width'),
                width: '100%',
                [theme.breakpoints.up('md')]: {
                  width: 200,
                },
              },
            }}
            inputProps={{ 'aria-label': 'search' }}
            fullWidth
          />
        </>
      )}
    </Grid>
  )
}

export default GlobalFilter
