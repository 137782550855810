import { Grid, InputLabel } from '@mui/material'
import React from 'react'

/** @notExported   */
interface CaleoInputLabelProps {
  /** HTML for attribute. */
  htmlFor?: string
  /** label text. */
  label?: React.ReactNode
  /** whether the input is required. */
  required?: boolean
  /** icon. */
  icon?: JSX.Element
}

/**
 * Caleo input label.
 *
 * @returns Caleo input label component.
 * @notExported
 */
const CaleoInputLabel: React.FC<CaleoInputLabelProps> = ({ htmlFor, label, required = false, icon }) => {
  return (
    <Grid container alignItems="center">
      <Grid item sx={{ color: theme => theme.palette.primary.main, marginRight: '5px' }}>
        {icon}
      </Grid>
      <Grid item>
        <InputLabel
          sx={{
            fontWeight: 'bold',
            letterSpacing: '0px',
            color: theme => theme.palette.primary.main,
            fontSize: '15px',
            fontFamily: 'Helvetica',
            '&::first-letter': {
              textTransform: 'capitalize',
            },
          }}
          required={required}
          htmlFor={htmlFor}
        >
          {label}
        </InputLabel>
      </Grid>
    </Grid>
  )
}

export default CaleoInputLabel
