import { SubAPI } from './API'
import { ISearch, ISearchResult } from 'types/searchInterfaces'

type SearchEmployeesSubObject = {
  id: number
}

interface SearchEmployeesSubAPIOptions<T> {
  dateFields?: (keyof T)[]
}

interface SearchOptions {
  partial: boolean
  networkIds: number[]
  tokens: unknown[]
}

/**
 * Search employee sub API
 * @notExported
 */
class SearchEmployeesSubAPI<T extends SearchEmployeesSubObject> extends SubAPI {
  options?: SearchEmployeesSubAPIOptions<T>

  constructor(options?: SearchEmployeesSubAPIOptions<T>) {
    super()

    this.options = options
  }

  /**
   * Search employees.
   *
   * @param options - Search options.
   * @returns Array of search results.
   */
  public async search(data: SearchOptions, controller?: AbortController): Promise<ISearchResult[]> {
    return await this.api.post(`persons/search`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Propose an assignment to person.
   *
   * @param anonymousId - Anonymous person ID.
   * @param options - Assignment options.
   * @returns Nothing.
   */
  public async propose(anonymousId: string, data: unknown, controller?: AbortController): Promise<void> {
    return await this.api.post(
      `persons/search/propose/${anonymousId}`,
      data,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Request a shortlist of employees.
   *
   * @param options - Request options.
   * @returns Nothing.
   */
  public async request(data: unknown, controller?: AbortController): Promise<void> {
    return await this.api.post(`persons/search/request`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Search employees.
   *
   * @param data - Search options.
   * @returns Array of search results.
   */
  public async postAllSearchFields(data: unknown, controller?: AbortController): Promise<T[]> {
    return this.customConvert(
      (await this.api.post(`persons/search`, data, controller ? { signal: controller.signal } : undefined)) as T[]
    )
  }

  public async postSearchResults(
    data: ISearchResult[],
    tokens: number[],
    controller?: AbortController
  ): Promise<ISearchResult[]> {
    return await this.api.post(
      `persons/search/results`,
      { data, tokens },
      controller ? { signal: controller.signal } : undefined
    )
  }

  dateFields = ['startDate', 'endDate', 'createdAt', 'updatedAt']

  /**
   * Convert date fields to Date objects.
   *
   * @param rawData - Raw data.
   * @returns Converted data.
   */
  customConvert(rawData) {
    if (rawData === null) {
      return null
    }
    if (rawData instanceof Array) {
      rawData.forEach(item => this.customConvert(item))
    }
    Object.keys(rawData).forEach(key => {
      const currentItem = rawData[key]
      if (!(currentItem instanceof Date) && this.dateFields.includes(key)) {
        rawData[key] = new Date(currentItem)
      } else if (currentItem instanceof Array) {
        this.customConvert(currentItem)
      }
    })
    return rawData
  }
}

export const searchEmployeesApi = new SearchEmployeesSubAPI<ISearch>()
