import Autocomplete from '@mui/material/Autocomplete'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import React from 'react'
import { IAllocation } from 'types/allocationInterfaces'
import { StateCode } from 'types/ids'
import { fuseFiltering } from 'utils/utils'
import { InputField } from '../InputField'

/** @notExported */
interface IStatePickerProps {
  /** The current value. */
  value: StateCode | null
  /** Action to do when the value changes. */
  onChange: (newValue: IAllocation | null) => void
  /** The label text. */
  label?: string
  /** Whether the field is required. */
  required?: boolean
  /** Whether the field is disabled. */
  disabled: boolean
  /** The available options. */
  stateOptions
  /** Is the label inset. */
  insetLabel?: boolean
}

/**
 * State picker component.
 *
 * @returns The state picker component.
 * @notExported
 */
const StatePicker: React.FC<IStatePickerProps> = ({
  value,
  onChange,
  label = 'State',
  required = false,
  disabled,
  stateOptions,
  insetLabel,
}) => {
  const getSelectedValue = () => {
    if (value) {
      const selectedValueItem = stateOptions.find(item => item.state === value)
      return selectedValueItem
    } else {
      return stateOptions[0]
    }
  }

  return (
    <>
      {!insetLabel && <CaleoInputLabel label={label} required={required} />}
      <Autocomplete
        filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['label'])}
        disabled={disabled}
        value={getSelectedValue()}
        options={stateOptions}
        fullWidth
        selectOnFocus
        disableClearable
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(selectedValue, option) => option.state === selectedValue.state}
        style={{ marginTop: '0px' }}
        onChange={(_event, newValue) => {
          if (newValue !== null) {
            onChange(newValue.state as unknown as IAllocation)
          }
        }}
        renderInput={params => (
          <InputField
            {...params}
            insetLabel={insetLabel}
            label={insetLabel ? label : undefined}
            name="state"
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
            required={required}
          />
        )}
      />
    </>
  )
}

export default StatePicker
