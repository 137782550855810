import { IItemVisibility, ILayout } from 'types/layoutInterfaces'
import { SubAPI } from './API'
import { IItemOrder } from '../types/layoutInterfaces'

/**
 * Layout API
 * @notExported
 */
class LayoutAPI extends SubAPI {
  /**
   * Get all layouts for a cv
   *
   * @param cvId - CV ID.
   * @returns Array of layouts.
   */
  public getAll(cvId: number, controller?: AbortController): Promise<ILayout[]> {
    return this.api.get(`pdf/cv/layouts/${cvId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get a layout for CV by name.
   *
   * @param cvId - CV ID.
   * @param name - Layout name.
   * @returns Layout data.
   */
  public get(cvId: number, name: string, controller?: AbortController): Promise<ILayout> {
    return this.api.get(`pdf/layouts/${cvId}/${name}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Hide/show section
   *
   * @param layoutId - Layout ID.
   * @param items - Items to hide/show.
   * @param itemType - Item type.
   * @param hidden - Status.
   * @returns Promise of section change.
   */
  public hideSection(
    layoutId: number,
    items: number[],
    itemType: string,
    hidden: boolean,
    controller?: AbortController
  ): Promise<void> {
    return this.api.post(
      `pdf/sectionvisibilitys`,
      { layoutId, items, itemType, hidden },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Hide/show item
   *
   * @param layoutId - Layout ID.
   * @param itemId - Item ID.
   * @param itemType - Item type.
   * @param hidden - Status.
   * @returns Item visibility.
   */
  public hideItem(
    layoutId: number,
    itemId: number,
    itemType: string,
    hidden: boolean,
    controller?: AbortController
  ): Promise<IItemVisibility> {
    return this.api.post(
      `pdf/visibilitys`,
      { layoutId, itemId, itemType, hidden },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Toggle contact info
   *
   * @param layoutId - Layout ID.
   * @param showContactInfo - Status of contact info.
   * @returns Item visibility.
   */
  public toggleContactInfo(
    layoutId: number,
    showContactInfo = false,
    controller?: AbortController
  ): Promise<IItemVisibility> {
    return this.api.post(
      `pdf/showContactInfo`,
      { layoutId, showContactInfo },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Create a layout
   *
   * @param cvId - CV ID.
   * @param orders - Orders of all items.
   * @param visibilities - Visibilities of all items.
   * @param name - Name of the layout.
   * @returns Created layout.
   */
  public createLayout(
    cvId: number,
    orders: IItemOrder[],
    visibilities: IItemVisibility[],
    name: string,
    controller?: AbortController
  ): Promise<ILayout> {
    return this.api.post(
      `pdf/layouts`,
      { cvId, orders, visibilities, name },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Update layout items order.
   *
   * @param order - New order of items.
   * @param itemType - Item type.
   * @param layoutId - Layout ID.
   * @returns Promise of order update.
   */
  public saveOrder(order: unknown[], itemType: string, layoutId: number, controller?: AbortController): Promise<void> {
    return this.api.post(
      `pdf/orders`,
      { order, itemType, layoutId },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Delete layout.
   *
   * @param id - Layout ID.
   * @returns Promise of layout deletion.
   */
  public deleteLayout(id: number, controller?: AbortController): Promise<void> {
    return this.api.delete(`pdf/layouts/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Change profile image visibility status.
   *
   * @param layoutId - Layout ID.
   * @param status - Status.
   * @returns Promise of profile image visibility change.
   */
  public changeProfileImage(layoutId: number, status: boolean, controller?: AbortController): Promise<void> {
    return this.api.put(
      `pdf/profileimage/${layoutId}`,
      { status },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Change name hiding status.
   *
   * @param layoutId - Layout ID.
   * @param status - Status.
   * @returns Promise of name hiding change.
   */
  public changeNameHiding(layoutId: number, status: boolean, controller?: AbortController): Promise<void> {
    return this.api.put(
      `pdf/namehiding/${layoutId}`,
      { status },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Change last used hiding status.
   *
   * @param layoutId - Layout ID.
   * @param status - Status.
   * @returns Promise of last used hiding change.
   */
  public changeLastUsedHiding(layoutId: number, status: boolean, controller?: AbortController): Promise<void> {
    return this.api.put(
      `pdf/lastusedhide/${layoutId}`,
      { status },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Change certificate expiry status.
   *
   * @param layoutId - Layout ID.
   * @param status - Status.
   * @returns Promise of certificate expiry change.
   */
  public changeCertificateExpiry(layoutId: number, status: boolean, controller?: AbortController): Promise<void> {
    return this.api.put(
      `pdf/certificateexpiry/${layoutId}`,
      { status },
      controller ? { signal: controller.signal } : undefined
    )
  }
}

export const layoutAPI = new LayoutAPI()
