import { Autocomplete, Box, TextField } from '@mui/material'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import { useSearchableNetworks } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { INetwork } from 'types/networkInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'

interface NetworkPickerProps {
  values: INetwork[]
  onChange: (values: INetwork[]) => void
  label: string
  disabled?: boolean
  required?: boolean
  icon?: JSX.Element
  items?: INetwork[]
}

/**
 * Network picker component.
 *
 * @param value - the current value.
 * @param onChange - the function to call when the value changes.
 * @param label - the label to display.
 * @param disabled - whether the component is disabled.
 * @param required - whether the component is required.
 * @param icon - the icon to display.
 * @param items - the options to pick from.
 * @returns The network picker component.
 */
export const NetworkPicker: React.FC<NetworkPickerProps> = ({
  values,
  onChange,
  label,
  disabled = false,
  required = false,
  icon,
  items,
}) => {
  const { t, i18n } = useTranslation()
  const { networks } = useSearchableNetworks()

  const getName = (network: INetwork) => {
    return chooseDBTranslation(i18n, network).name ?? network.id
  }

  const options = items
    ? items.map(network => ({
        value: network,
        label: getName(network),
      }))
    : networks?.map(network => ({
        value: network,
        label: getName(network),
      })) ?? []

  const selected = values.map(network => ({
    value: network,
    label: getName(network),
  }))

  const selectAllLabel = selected.length === options.length ? t('picker.deselectAll') : t('picker.selectAll')

  const loading = !networks

  const toggleSelectAll = () => {
    const opt = selected.length === options.length ? [] : options
    onChange(opt.filter(item => item.value.id !== 0).map(v => v.value))
  }

  return (
    <>
      <CaleoInputLabel icon={icon} label={label} required={required} />
      <Autocomplete
        loading={loading}
        disabled={disabled}
        value={selected}
        onChange={(_event, newValue) => {
          if (typeof newValue !== 'string') {
            if (newValue && newValue.find(option => option.value.id === 0)) {
              toggleSelectAll()
            } else {
              onChange(newValue.filter(item => item.value.id !== 0).map(v => v.value))
            }
          }
        }}
        filterOptions={(options, { inputValue }) => {
          const filtered = fuseFiltering(options, inputValue, ['label', 'value.translations.name'])
          return [{ label: selectAllLabel, value: { id: 0 } }, ...filtered]
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={options}
        isOptionEqualToValue={(option, value) => {
          return option.value.id === value.value.id
        }}
        renderOption={(props, option) => {
          return (
            <Box component="li" {...props} key={option.value.id}>
              {option.value.id !== 0 ? getName(option.value) : option.label}
            </Box>
          )
        }}
        renderInput={params => <TextField {...params} fullWidth margin="none" variant="outlined" />}
        fullWidth
        multiple
      />
    </>
  )
}
