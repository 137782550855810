import { DialogContent, Typography } from '@mui/material'
import withSimpleModal from 'Components/reusable/HOC/withSimpleModal'
import i18n from 'i18next'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { ITermsAndPolicy } from 'types/termsAndPolicyInterfaces'
import { chooseDBTranslation } from 'utils/translations'

/**
 * Terms and policy modal.
 *
 * @param item - Terms and policy item.
 * @returns Terms and policy modal.
 * @notExported
 */
const TermsAndPolicyModal: React.FC<{ item: ITermsAndPolicy }> = ({ item }) => {
  return (
    <DialogContent>
      <Typography>
        <ReactMarkdown>{chooseDBTranslation(i18n, item).content}</ReactMarkdown>
      </Typography>
    </DialogContent>
  )
}

export default withSimpleModal(TermsAndPolicyModal)
