import Autocomplete from '@mui/material/Autocomplete'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import React from 'react'
import { IPerson } from 'types/userInterfaces'
import { fuseFiltering } from 'utils/utils'
import { InputField } from '../InputField'

/**
 * Country item type
 * @notExported
 */
interface CountryType {
  code: string
  label: string
}

/** @notExported */
interface CountryPickerProps {
  /**  The current value. */
  value: string | null
  /** The change handler. */
  onChange: (newValue: IPerson | null) => void
  /** The label. */
  label?: string
  /** Whether the field is required. */
  required?: boolean
  /** Whether the field is disabled. */
  disabled: boolean
  /** The list of countries. */
  countriesOptions: CountryType[]
  /** The inset label. */
  insetLabel?: boolean
}

/**
 * Country picker component.
 *
 * @returns The country picker component.
 * @notExported
 */
const CountryPicker: React.FC<CountryPickerProps> = ({
  value,
  onChange,
  label = 'Country',
  required = false,
  disabled,
  countriesOptions,
  insetLabel,
  ...rest
}) => {
  const getSelectedValue = (): CountryType => {
    let selected: CountryType | undefined
    if (value) {
      selected = countriesOptions.find(item => item.code.toLowerCase() === value)
    }

    const result = selected ?? {
      code: '',
      label: '',
    }

    return result
  }

  return (
    <>
      {!insetLabel && <CaleoInputLabel label={label} required={required} />}
      <Autocomplete
        filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['label'])}
        disabled={disabled}
        value={getSelectedValue()}
        options={countriesOptions}
        fullWidth
        selectOnFocus
        disableClearable
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(selectedValue, option) => option.code === selectedValue.code}
        style={{ marginTop: '0px' }}
        onChange={(_event, newValue) => {
          if (newValue !== null) {
            onChange(newValue.code.toLowerCase() as unknown as IPerson)
          }
        }}
        renderInput={params => (
          <InputField
            {...params}
            insetLabel={insetLabel}
            name="country"
            margin="dense"
            size="small"
            fullWidth
            variant="outlined"
            label={insetLabel ? label : undefined}
            InputLabelProps={{ shrink: insetLabel ? true : undefined }}
            required={required}
          />
        )}
        {...rest}
      />
    </>
  )
}

export default CountryPicker
