import { Grid } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ILevel, ISkillInterestLevel } from 'types/cvsInterfaces'
import NewLevePicker from 'Components/reusable/InputFields/LevelPicker/NewLevelPicker'
import { InputField } from 'Components/reusable/InputFields/InputField'

/**
 * Wizard default values.
 */
export interface IWizardDefaultValues {
  level?: ILevel | null
  interest?: ISkillInterestLevel | null
  experienceMonths?: number
}

/** @notExported */
interface IDefaultValuesProps {
  /** Wizard default values. */
  data?: IWizardDefaultValues
  /** Set wizard default values. */
  setData: (newData: IWizardDefaultValues) => void
}

/**
 * Wizard default values component.
 *
 * @returns Wizard default values component.
 * @notExported
 */
const DefaultValues: React.FC<IDefaultValuesProps> = ({ data, setData }) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={1}>
      <Grid item>
        <InputField
          value={data?.experienceMonths?.toString() ?? ''}
          type="number"
          onChange={e => {
            const numericValue = parseInt(e.target.value)
            setData({ ...data, experienceMonths: numericValue })
          }}
          label={t('profile.fillingWizard.defaultExperience')}
          insetLabel
          required
        />
      </Grid>
      <Grid item>
        <NewLevePicker
          label={t('profile.fillingWizard.defaultLevel')}
          onChange={newValue => {
            setData({ ...data, level: parseInt(newValue, 10) as ILevel })
          }}
          value={data?.level ?? null}
          required
        />
      </Grid>
      <Grid item>
        <NewLevePicker
          label={t('profile.fillingWizard.defaultInterest')}
          onChange={newValue => {
            setData({ ...data, interest: parseInt(newValue, 10) as ISkillInterestLevel })
          }}
          value={data?.interest ?? null}
          required
        />
      </Grid>
    </Grid>
  )
}

export default DefaultValues
