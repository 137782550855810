import { DialogContent, Grid, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import newWithSimpleModal from 'Components/reusable/HOC/newWithSimpleModal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ICertificate, ICourse, ICv, IEducation, IEmployer, IProject } from 'types/cvsInterfaces'
import { COUNTRY_CODES } from 'constants/caleo-constants'
import Flag from 'react-world-flags'
import BorderedSection from 'Components/reusable/CaleoCustomComponents/BorderedSection'
import colors from 'constants/colors'

/** @notExported */
interface IProfileProgressModalProps {
  /** The CV object. */
  cv: ICv | null
}

/**
 * The ProfileProgressModal component.
 *
 * @param cv - The CV object.
 * @returns The ProfileProgressModal component.
 * @notExported
 */
const ProfileProgressModal: React.FC<IProfileProgressModalProps> = ({ cv }) => {
  const { t } = useTranslation()

  const data = (translation: string, type: string) => {
    const flags: string[] = []
    let check = false

    const getCheck = (data, type?: string) => {
      switch (type) {
        case 'employers':
          check =
            data.length > 0 &&
            data.every(
              (item: IEmployer) =>
                item.translations.length >= 2 &&
                item.translations.every(tr => {
                  if (tr.Language.name === 'sv') return true
                  return tr.name.length > 0
                })
            )
          break

        case 'projects':
          check =
            data.length > 0 &&
            data.every(
              (item: IProject) =>
                item.translations.length >= 2 &&
                item.translations.every(tr => {
                  if (tr.Language.name === 'sv') return true
                  return tr.name.length > 0
                })
            )
          break

        case 'education':
          check =
            data.length > 0 &&
            data.every((item: IEducation) =>
              item.translations.every(tr => {
                if (tr.Language.name === 'sv') return true
                return item.translations.length >= 2 && tr.degree.length > 0
              })
            )
          break

        case 'courses':
          check =
            data.length > 0 &&
            data.every((item: ICourse) =>
              item.translations.every(tr => {
                if (tr.Language.name === 'sv') return true
                return item.translations.length >= 2 && tr.course.length > 0
              })
            )
          break

        case 'certificates':
          check =
            data.length > 0 &&
            data.every((item: ICertificate) =>
              item.translations.every(tr => {
                if (tr.Language.name === 'sv') return true
                return item.translations.length >= 2 && tr.certificate.length > 0
              })
            )
          break

        default:
          break
      }
    }

    const getFlags = (data, type?: string) => {
      if (type === 'about') {
        if (cv?.translations) {
          for (const choice of cv.translations) {
            if (choice.about.length > 0 && !flags.includes(COUNTRY_CODES[choice.Language.name])) {
              flags.push(COUNTRY_CODES[choice.Language.name])
            }
          }
        }
      } else if (type === 'role') {
        if (cv?.translations) {
          for (const choice of cv.translations) {
            if (choice.primaryRole.length > 0 && !flags.includes(COUNTRY_CODES[choice.Language.name])) {
              flags.push(COUNTRY_CODES[choice.Language.name])
            }
          }
        }
      } else {
        const enTranslation = data.every(item => item.translations.find(tr => tr.Language.name === 'en'))
        const fiTranslation = data.every(item => item.translations.find(tr => tr.Language.name === 'fi'))
        const svTranslation = data.every(item => item.translations.find(tr => tr.Language.name === 'sv'))

        if (enTranslation) flags.push(COUNTRY_CODES['en'])
        if (fiTranslation) flags.push(COUNTRY_CODES['fi'])
        if (svTranslation) flags.push(COUNTRY_CODES['sv'])
      }
    }

    if (cv) {
      switch (type) {
        case 'role':
          check = cv.translations.every(tr => {
            if (tr.Language.name === 'sv') return true
            return tr.primaryRole.length > 0
          })
          getFlags(cv, 'role')
          break
        case 'about':
          check = cv.translations.every(tr => {
            if (tr.Language.name === 'sv') return true
            return tr.about.length > 0
          })
          getFlags(cv, 'about')
          break
        case 'skills':
          check = cv.PersonSkills.length > 0
          break
        case 'employers':
          getCheck(cv.Employers, type)
          getFlags(cv.Employers)
          break
        case 'projects':
          getCheck(cv.Projects, type)
          getFlags(cv.Projects)
          break
        case 'education':
          getCheck(cv.Education, type)
          getFlags(cv.Education)
          break
        case 'courses':
          getCheck(cv.Courses, type)
          getFlags(cv.Courses)
          break
        case 'certificates':
          getCheck(cv.Certificates, type)
          getFlags(cv.Certificates)
          break
        case 'languages':
          check = cv.LanguageSkills.length > 0
          break
      }
    }

    return (
      <Grid container spacing={1} alignItems="center" direction="row" justifyContent="flex-start">
        <Grid item xs={1}>
          {check ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
        </Grid>
        <Grid item xs="auto">
          {t(translation)}
        </Grid>
        {flags.map(flag => (
          <Grid key={flag} item xs={1}>
            <Flag
              key={flag}
              sx={{ height: '20px', width: '32px', marginLeft: '10px' }}
              code={flag}
              fallback={<span>{flag}</span>}
            />
          </Grid>
        ))}
      </Grid>
    )
  }

  const educationIcon =
    (cv &&
      cv.Education.length > 0 &&
      cv.Education.every(
        item => item.translations.length >= 2 && item.translations.every(tr => tr.degree.length > 0)
      )) ||
    (cv &&
      cv.Courses.length > 0 &&
      cv.Courses.every(item => item.translations.length >= 2 && item.translations.every(tr => tr.course.length > 0))) ||
    (cv &&
      cv.Certificates.length > 0 &&
      cv.Certificates.every(
        item => item.translations.length >= 2 && item.translations.every(tr => tr.certificate.length > 0)
      )) ? (
      <CheckIcon color="success" />
    ) : (
      <CloseIcon color="error" />
    )

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography gutterBottom fontSize="0.9rem">
            {t('profile.progress.info')}
          </Typography>
          <Typography gutterBottom fontSize="0.9rem">
            {t('profile.progress.info2')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {data('profile.modal.role', 'role')}
          {data('profile.modal.about', 'about')}
          {data('skills.title', 'skills')}
          {data('employer.title', 'employers')}
          {data('project.title', 'projects')}
          <BorderedSection
            borderBefore="1%"
            color={colors.borderColor}
            customIcon={educationIcon}
            title={t('education.title')}
          >
            {data('degrees.title', 'education')}
            {data('courses.title', 'courses')}
            {data('certificates.title', 'certificates')}
          </BorderedSection>
          {data('language.title', 'languages')}
        </Grid>
      </Grid>
    </DialogContent>
  )
}

export default newWithSimpleModal(ProfileProgressModal)
