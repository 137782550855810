import React, { useContext, useEffect, useState } from 'react'
import { IError } from 'types/error'
import { ISalesPool, IEditedSalesPool } from 'types/salesPoolInterfaces'
import { salesPoolAPI } from 'api/SalesPoolAPI'
import { useIsComponentMounted } from 'hooks/util'

interface SalesPoolState {
  salesPools: ISalesPool[]
  setSalesPools: (salesPools: ISalesPool[]) => void
  updateSalesPools: boolean
  setUpdateSalesPools: (value: boolean) => void
  editedSalesPool: IEditedSalesPool | undefined
  setEditedSalesPool: (data: IEditedSalesPool | undefined) => void
  loadingSalesPools: boolean
  error: IError | undefined
}

const salesPoolContext = React.createContext<SalesPoolState>({} as SalesPoolState)

const { Provider } = salesPoolContext

/**
 * Provider for managing sales pool data.
 *
 * @param children
 * @returns SalesPool context provider.
 * @notExported
 */
export const SalesPoolProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isComponentMounted = useIsComponentMounted()
  const [salesPools, setSalesPools] = useState<ISalesPool[]>([])
  const [updateSalesPools, setUpdateSalesPools] = useState<boolean>(false)
  const [editedSalesPool, setEditedSalesPool] = useState<IEditedSalesPool>()
  const [loadingSalesPools, setLoadingSalesPools] = useState<boolean>(false)
  const [error, setError] = useState<IError>()

  useEffect(() => {
    ;(async () => {
      const controller = new AbortController()

      if (updateSalesPools) {
        try {
          setLoadingSalesPools(true)
          const result = await salesPoolAPI.getSalesPools(controller)
          if (isComponentMounted.current) setSalesPools(result)
        } catch (error) {
          setError(error as IError)
        } finally {
          if (isComponentMounted.current) {
            setLoadingSalesPools(false)
            setUpdateSalesPools(false)
          }
        }
      }

      return () => {
        controller.abort()
      }
    })()
  }, [updateSalesPools])

  return (
    <Provider
      value={{
        salesPools,
        setSalesPools,
        updateSalesPools,
        setUpdateSalesPools,
        editedSalesPool,
        setEditedSalesPool,
        loadingSalesPools,
        error,
      }}
    >
      {children}
    </Provider>
  )
}

/**
 * Hook for managing sales pools.
 *
 * @returns SalesPool context variables and functions.
 * @notExported
 */
export function useSalesPool(): SalesPoolState {
  return useContext(salesPoolContext)
}
