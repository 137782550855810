import { SubAPI } from './API'
import { ISalesPoolOptions, ISalesPoolResult, ISalesPoolData, ISalesPool } from 'types/salesPoolInterfaces'

/**
 * SalesPoolAPI
 * @notExported
 */
class SalesPoolAPI extends SubAPI {
  /**
   * Creates a sales pool.
   *
   * @param options - Sales pool options.
   * @returns Created sales pool.
   */
  public async createSalesPool(options: ISalesPoolOptions, controller?: AbortController): Promise<ISalesPool> {
    return await this.api.post(`salespool/`, options, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Gets the sales pool results for a public ID.
   *
   * @param publicId - Public ID.
   * @returns Sales pool results.
   */
  public getResults(publicId: string, controller?: AbortController): Promise<ISalesPoolData> {
    return this.api.get(`salespool/public/${publicId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Gets the sales pool result for a public ID.
   *
   * @param publicId - Public ID.
   * @returns Sales pool result.
   */
  public getResult(publicId: string, controller?: AbortController): Promise<ISalesPoolResult> {
    return this.api.get(`salespool/result/${publicId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Gets all sales pools.
   *
   * @returns Array of sales pools.
   */
  public getSalesPools(controller?: AbortController): Promise<ISalesPool[]> {
    return this.api.get('salespool', controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Copies a sales pool.
   *
   * @param id - Sales pool ID.
   * @returns Copied sales pool.
   */
  public copySalesPool(id: number, controller?: AbortController): Promise<ISalesPool> {
    return this.api.post(`salespool/${id}`, {}, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Updates a sales pool.
   *
   * @param id - Sales pool ID.
   * @param options - Sales pool options.
   * @returns Updated sales pool.
   */
  public updateSalesPool(id: number, options: ISalesPoolOptions, controller?: AbortController): Promise<ISalesPool> {
    return this.api.put(`salespool/${id}`, options, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Deletes a sales pool.
   *
   * @param id - Sales pool ID.
   * @returns Promise of sales pool deletion.
   */
  public deleteSalesPool(id: number): Promise<void> {
    return this.api.delete(`salespool/${id}`)
  }
}

export const salesPoolAPI = new SalesPoolAPI()
