import { Button } from '@mui/material'
import React from 'react'

/** @notExported */
interface CaleoYellowButtonProps {
  /** Click action. */
  clickAction: () => void
  /** Whether the button is valid. */
  valid: boolean
  /** Button label. */
  label
}

/**
 * Caleo yellow button.
 *
 * @returns Caleo yellow button component.
 * @category Buttons
 */
const CaleoYellowButton: React.FC<CaleoYellowButtonProps> = ({ clickAction, valid, label }) => {
  return (
    <Button
      id="search"
      type="button"
      variant="contained"
      disabled={!valid}
      onClick={() => clickAction()}
      color="warning"
    >
      {label}
    </Button>
  )
}

export default CaleoYellowButton
