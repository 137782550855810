import { Box, styled } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import caleo404 from 'assets/images/Caleo-404-page.png'

const DivContainer = styled('div')(() => ({
  position: 'relative',
}))

const DivH1 = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '2.5rem',
  paddingTop: '2vw',
}))

const DivH2 = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '1.25rem',
}))

const DivNumbers = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  fontSize: '8.5vw',
  position: 'absolute',
  top: '65%',
  left: '43%',
  transform: 'translate(-50%, -50%)',
}))

const ImgPicture = styled('img')(() => ({
  maxWidth: '45vw',
}))

/**
 * Error type.
 * @notExported
 */
type DeniedType = '404' | '403'

/** @notExported  */
interface HttpErrorPageProps {
  /** Error type. */
  type: DeniedType
}

/**
 * HttpErrorPage component.
 *
 * @returns HttpErrorPage component.
 * @notExported
 */
const HttpErrorPage: React.FC<HttpErrorPageProps> = ({ type }) => {
  const { t } = useTranslation()
  const error = type
  let h2Translation = ''
  switch (type) {
    case '404':
      h2Translation = 'accessDeniedBody'
      break
    case '403':
      h2Translation = 'noRights'
      break
  }

  return (
    <Box textAlign="center">
      <DivH1>{t('accessDenied')}</DivH1>
      <DivH2>{t(h2Translation)}</DivH2>
      <DivContainer>
        <ImgPicture src={caleo404} alt="accessDenied" />
        <DivNumbers>{error}</DivNumbers>
      </DivContainer>
    </Box>
  )
}

export default HttpErrorPage
