import { Button } from '@mui/material'
import React from 'react'

/** @notExported  */
interface CaleoSecondaryButtonProps {
  /** Action to perform on button click. */
  clickAction: (e: React.MouseEvent<HTMLButtonElement>) => void
  /** Whether the button is valid. */
  valid
  /** Label of the button. */
  label
}

/**
 * Caleo secondary button.
 *
 * @returns Caleo secondary button component.
 * @notExported
 * @category Buttons
 */
const CaleoSecondaryButton: React.FC<CaleoSecondaryButtonProps> = ({ clickAction, valid, label }) => {
  return (
    <Button
      id="search"
      type="button"
      color="secondary"
      variant="contained"
      disabled={!valid}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => clickAction(e)}
    >
      {label}
    </Button>
  )
}

export default CaleoSecondaryButton
