import React from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { useLogin } from 'hooks'
import { removeNonCaleoStorage } from 'utils/utils'

/**
 * Logs out the user if they are logged in, and redirects them to the login page.
 *
 * @return {null} Returns null.
 * @notExported
 */
const Logout: React.FC = () => {
  const { loggedIn, logout } = useLogin()
  const location = useLocation()
  const navigate = useNavigate()
  const nextUrl = location.state?.url

  if (loggedIn) {
    try {
      logout()
      if (nextUrl) {
        navigate(nextUrl)
      }
    } catch (err) {
      console.error('Could not log out: ', err)
    }
  } else {
    removeNonCaleoStorage()
    return <Navigate to="/login" />
  }

  return null
}

export default Logout
