import React, { useEffect, useState } from 'react'
import { IAllocationData, IBlock, IAllocation } from 'types/allocationInterfaces'
import { IPerson } from 'types/userInterfaces'
import { lightenDarkenColor } from 'utils/utils'
import { chooseDBTranslation } from 'utils/translations'
import { useTranslation } from 'react-i18next'
import { Button, CircularProgress, DialogContent, Grid, Typography, useTheme } from '@mui/material'
import newWithSimpleModal from 'Components/reusable/HOC/newWithSimpleModal'
import { allocationAPI } from 'api/AllocationAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { IError } from 'types/error'
import ResizableBlock from './ResizableBlock'
import colors from 'constants/colors'
import { addYears } from 'date-fns'
import Timeline from 'Components/reusable/CaleoCustomComponents/Timeline'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import AllocationTable from 'Components/General/AllocationTable/AllocationTable'
import { useCompanyPermissions } from 'hooks'
import { useTeamLeaderPermissions } from 'hooks/permissions'
import { useIsComponentMounted } from 'hooks/util'
import { getAllocationInitialData } from 'Components/reusable/DataContext/InitialData'
import AddIcon from '@mui/icons-material/Add'

/** @notExported  */
interface IDragEditModalProps {
  /** Person object */
  person: IPerson
  /** Function to update data   */
  updateData: () => void
  /** Function to delete an item   */
  deleteItem: () => void
  /** Function to set edited item  */
  setEditedItem: (value: IAllocation | null | undefined) => void
  /** Should the allocations be updated */
  updateFromEditing: Date | undefined
}

/**
 * Allocation drag edit modal.
 *
 * @returns Allocation drag edit modal compnent.
 * @notExported
 */
const DragEditModal: React.FC<IDragEditModalProps> = ({
  person,
  updateData,
  deleteItem,
  setEditedItem,
  updateFromEditing,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const { salesAccess } = useCompanyPermissions(person.organizationId)
  const { teamLeaderAccess } = useTeamLeaderPermissions()

  const [allocationData, setAllocationData] = useState<IAllocationData[]>()
  const [allocations, setAllocations] = useState<IAllocation[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [backendError, setBackendError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        setLoading(true)
        if (person.id && !(allocationData && allocationData.length && allocationData[0].personId === person.id)) {
          const results = await allocationAPI.getPersonDraggableAllocationBlocks(person.id, controller)
          if (!isComponentMounted.current) return
          setAllocationData(results)
        }
        if (updateFromEditing && person.id && allocationData && allocationData[0].personId === person.id) {
          const results = await allocationAPI.getPersonDraggableAllocationBlocks(person.id, controller)
          if (!isComponentMounted.current) return
          setAllocationData(results)
        }
        const data = await allocationAPI.getAllocations(person.id, controller)
        if (!isComponentMounted.current) return
        setAllocations(data)
      } catch (error) {
        setBackendError(error as IError)
      } finally {
        if (isComponentMounted.current) setLoading(false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [person, updateFromEditing])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const updateAllocationData = (data: IAllocationData) => {
    if (allocationData) {
      const newAllocationData = [...allocationData]

      const index = newAllocationData.findIndex(allocationData => allocationData.id === data.id)
      newAllocationData[index] = data

      setAllocationData(newAllocationData)
    }
  }

  const startDate = new Date(),
    endDate = addYears(new Date(), 1),
    daySize = 3,
    stripeSize = daySize * 1

  return (
    <DialogContent sx={{ backgroundColor: 'white' }}>
      <Grid>
        <Grid container spacing={1} mb={0.5}>
          {!loading ? (
            <>
              <Grid item xs={12} mb={1}>
                <CardContentText type="darkSubHeader">{t('allocation.dragSubHeader')}</CardContentText>
              </Grid>
              <Grid item xs={12}>
                {allocationData && allocationData.length > 0 && t('allocation.dragHelp')}
                <Timeline startDate={startDate} endDate={endDate} daySize={daySize} minWidth={1095} />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 0, marginBottom: 16 }}>
                {allocationData && allocationData.length > 0 ? (
                  allocationData.map((data: IAllocationData, i: number) => {
                    const assignment = data.Assignment ? `, ${chooseDBTranslation(i18n, data.Assignment).name}` : ''
                    const role = data.AssignmentRole ? `, ${chooseDBTranslation(i18n, data.AssignmentRole).name}` : ''
                    const startAfterDays = data.blocks[0].percent === 0 ? data.blocks[0].length : 0
                    const resizeLeft = data.startDate < new Date() ? false : true
                    const resizeRight = addYears(new Date(), 1) < data.endDate ? false : true

                    const dashedBorders: {
                      borderRight: string
                      borderLeft: string
                      borderTop?: string
                      borderBottom?: string
                    } = {
                      borderRight: `1px dashed ${theme.palette.secondary.main}`,
                      borderLeft: `1px dashed ${theme.palette.secondary.main}`,
                    }
                    if (i === 0) {
                      dashedBorders.borderTop = `1px dashed ${theme.palette.secondary.main}`
                    }
                    if (i + 1 === allocationData.length) {
                      dashedBorders.borderBottom = `1px dashed ${theme.palette.secondary.main}`
                    }

                    return (
                      <div
                        key={i}
                        style={{
                          width: 1095,
                          height: 40,
                          ...dashedBorders,
                          background: `repeating-linear-gradient( 90deg, ${colors.background}, ${
                            colors.background
                          } ${stripeSize}px, ${lightenDarkenColor('#D3D3D3', 25)} ${stripeSize}px, ${lightenDarkenColor(
                            '#D3D3D3',
                            25
                          )} ${stripeSize * 2}px)`,
                        }}
                      >
                        {data.blocks.map((block: IBlock, i: number) => {
                          if (block.percent > 0) {
                            return (
                              <ResizableBlock
                                key={i}
                                data={data}
                                maxWidth={1095}
                                block={block}
                                daySize={daySize}
                                startAfterDays={startAfterDays}
                                type={data.type}
                                assignment={assignment}
                                role={role}
                                resizeLeft={resizeLeft}
                                resizeRight={resizeRight}
                                updateData={(data: IAllocationData) => {
                                  updateAllocationData(data)
                                  updateData()
                                }}
                                setBackendError={setBackendError}
                              />
                            )
                          }
                        })}
                      </div>
                    )
                  })
                ) : (
                  <Typography mt={2}>{t('allocation.dragNoAllocations')}</Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <CardContentText type="darkSubHeader">{t('allocation.dragTableHeader')}</CardContentText>
                  </Grid>
                  {salesAccess && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          setEditedItem({
                            ...(await getAllocationInitialData(null)),
                            type: 'project',
                            personId: person.id,
                            Person: person,
                          } as unknown as IAllocation)
                        }}
                        startIcon={<AddIcon />}
                      >
                        <span style={{ fontWeight: 'bold' }}> {t('allocation.addTitle')}</span>
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <AllocationTable
                  items={allocations}
                  openItem={(item: IAllocation) => {
                    setEditedItem(item)
                  }}
                  deleteItem={deleteItem}
                  personId={person.id}
                  salesAccess={salesAccess || teamLeaderAccess}
                  rows={5}
                />
              </Grid>
            </>
          ) : (
            <Grid item alignContent="center" justifyContent="center">
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      </Grid>
    </DialogContent>
  )
}

export default newWithSimpleModal(DragEditModal)
