import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISearchSkill } from 'types/searchInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import * as Yup from 'yup'
import DataContext from 'Components/reusable/DataContext'
import LoadingIndicator from 'Components/reusable/LoadingIndicator'
import { ISkillOrIndustryOrRole } from 'types/cvsInterfaces'

/** @notExported */
interface ISkillSearchRequirementProps {
  /** Function to add requirement. */
  onAdd: (newItem?: ISearchSkill) => void
  /** Type of skill. */
  type: 'skill' | 'industry' | 'role' | 'all'
  /** Whether to show label. */
  noLabel?: boolean
  /** Whether to show unpromoted skills. */
  showUnpromoted?: boolean
  /** The skills that can be selected. */
  options?: ISkillOrIndustryOrRole[]
  /** Whether to show inset label. */
  insetLabel?: boolean
}

/**
 * Search requirement field for skills or roles or industries.
 *
 * @returns Skill search requirement component.
 * @notExported
 */
const SkillSearchRequirement: React.FC<ISkillSearchRequirementProps> = ({
  onAdd,
  type,
  noLabel,
  showUnpromoted = false,
  options,
  insetLabel,
}) => {
  const [data, setData] = useState<ISearchSkill | null>(null)
  const { i18n, t } = useTranslation()

  const skillSchema =
    type === 'all'
      ? Yup.object({
          skillId: Yup.number().required(i18n.t('fieldRequired')),
        })
      : Yup.object({
          skillId: Yup.number().required(i18n.t('fieldRequired')),
          experienceMonths: Yup.number().typeError(i18n.t('numberTypeError')).required(i18n.t('fieldRequired')),
        })

  useEffect(() => {
    setData({
      name: '',
      experienceMonths: 0,
      level: 0,
      required: false,
      skillId: null,
      Skill: null,
    } as unknown as ISearchSkill)
  }, [onAdd])

  if (!data) {
    return <LoadingIndicator />
  }

  const saveData = (newData: ISearchSkill) => {
    setData(newData)
    if (newData.skillId) {
      const skillTranslations = newData.Skill && chooseDBTranslation(i18n, newData.Skill)
      onAdd({ ...newData, name: skillTranslations?.name ?? '' })
    }
  }

  return (
    <DataContext data={data} localeBase={`resources.searchEmployees.${type}`} schema={skillSchema} onChange={saveData}>
      <DataContext.SkillField<ISearchSkill>
        field="skillId"
        dataField="Skill"
        noPlaceholders={false}
        placeholder={noLabel ? t('search.allSkills') : undefined}
        hideLabel={noLabel}
        fullWidth
        submitOnEnter={true}
        type={type}
        search
        showUnpromoted={showUnpromoted}
        options={options}
        insetLabel={insetLabel}
        resetOnSelect
      />
    </DataContext>
  )
}

export default SkillSearchRequirement
