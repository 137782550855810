import { Button, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add'

/** @notExported */
interface GreenAddButtonProps {
  /** Action to be performed when the button is clicked. */
  clickAction: () => void
  /** Whether the button should be disabled. */
  valid: boolean
  /** Label of the button. */
  label
}

/**
 * Green add button.
 *
 * @returns Green add button component.
 * @notExported
 * @category Buttons
 */
const GreenAddButton: React.FC<GreenAddButtonProps> = ({ clickAction, valid, label }) => {
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Button
      id="search"
      type="button"
      variant="contained"
      disabled={!valid}
      onClick={() => clickAction()}
      color="success"
      sx={{ margin: mobileView ? 1 : 0 }}
      startIcon={mobileView ? undefined : <AddIcon />}
    >
      {mobileView ? <AddIcon /> : label}
    </Button>
  )
}

export default GreenAddButton
