import { Autocomplete, Box, Grid, TextField } from '@mui/material'
import { useSearchData } from 'Components/General/SearchProvider/SearchProvider'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ISkillOrIndustryOrRole } from 'types/cvsInterfaces'
import { ISearchSkill } from 'types/searchInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'
import SearchIcon from '@mui/icons-material/Search'
import TermsDisplay from '../TermsDisplay'

/** @notExported */
interface ISkillSearchRequirementProps {
  /** Function to add requirement. */
  onAdd: (newItem: ISearchSkill) => void
  /** Function to add text parameter. */
  addTextParameter: (text: string) => void
  /** The skills that can be selected. */
  skills: ISkillOrIndustryOrRole[]
  /** Whether anonymous network is selected. */
  anonymous?: boolean
}

/**
 * Search requirement field for skills, roles, industries and text.
 *
 * @returns Skill search requirement component.
 * @notExported
 */
const SkillOrTextRequirement: React.FC<ISkillSearchRequirementProps> = ({
  onAdd,
  addTextParameter,
  skills,
  anonymous,
}) => {
  const { i18n, t } = useTranslation()
  const { searchData } = useSearchData()

  const selectable = useMemo(() => {
    return skills.filter(option => {
      if (
        searchData.terms.skills.find(item => item.skillId === option.id) ||
        searchData.terms.industries.find(item => item.skillId === option.id) ||
        searchData.terms.roles.find(item => item.skillId === option.id)
      ) {
        return false
      } else {
        return true
      }
    })
  }, [skills, searchData.terms.skills, searchData.terms.industries, searchData.terms.roles])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Autocomplete
          value={[]}
          noOptionsText={t('controls.noOptionsText')}
          options={selectable}
          multiple
          freeSolo={anonymous ? false : true}
          getOptionLabel={option => {
            if (typeof option === 'string') {
              return option
            }
            return chooseDBTranslation(i18n, option).name
          }}
          isOptionEqualToValue={(option, value) => {
            if (value && option.id === value.id) {
              return true
            }
            return false
          }}
          onChange={(_e, values) => {
            for (const value of values) {
              if (typeof value === 'string') {
                addTextParameter(value)
              } else {
                onAdd({
                  name: chooseDBTranslation(i18n, value).name,
                  skillId: value.id,
                  Skill: value,
                })
              }
            }
          }}
          filterOptions={(options, { inputValue }) => {
            if (inputValue === '') {
              return options
            }
            return fuseFiltering(options, inputValue, ['translations.name'])
          }}
          renderInput={params => (
            <TextField
              {...params}
              margin="dense"
              variant="outlined"
              size="small"
              name="SkillOrTextRequirement"
              InputProps={{ ...params.InputProps, startAdornment: <SearchIcon /> }}
            />
          )}
          renderOption={(props, option) => (
            <Box {...props} key={option.id} component="li">
              <span
                style={{
                  fontWeight: 'normal',
                  marginRight: 5,
                }}
              >
                {t(`search.${option.kind}.name`)}
              </span>
              <span style={{ fontWeight: 'bold' }}>{chooseDBTranslation(i18n, option).name}</span>
            </Box>
          )}
        />
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <TermsDisplay />
      </Grid>
    </Grid>
  )
}

export default SkillOrTextRequirement
