import React from 'react'
import { useTranslation } from 'react-i18next'
import { IEmployer } from 'types/cvsInterfaces'
import { useDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'
import withProfileCardItem from 'Components/reusable/HOC/withProfileCardItem'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import ExtendedCardContent from 'Components/reusable/CaleoCustomComponents/ExtendedCardContent'
import SkillsItem from '../SkillsCard/SkillsItem'
import { Grid } from '@mui/material'

/** @notExported  */
interface IEmployerItemCardProps {
  /** Employer item. */
  employer: IEmployer
  /** Is item anonymous. */
  anonymous: boolean
}

/**
 * Employer item card.
 *
 * @returns Employer item card component.
 * @notExported
 */
const EmployerItemCard: React.FC<IEmployerItemCardProps> = ({ employer, anonymous }) => {
  const { t } = useTranslation()
  const employerTranslation = useDBTranslation(employer)

  const description = !anonymous ? employerTranslation.description : ''

  const getContent = () => {
    return (
      <>
        <CardContentText type={'header'}>{!anonymous ? employerTranslation.name : ''}</CardContentText>
        <CardContentText type={'date'}>
          {convertToDisplayDate(employer.startDate, employer.dateResolution)} -{' '}
          {employer.endDate ? convertToDisplayDate(employer.endDate, employer.dateResolution) : '...'}
        </CardContentText>
        {employerTranslation.primaryRole.length > 0 && (
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item>
              <CardContentText fontWeight={'bold'}>
                {t('profile.employer.role')}
                {':'}
              </CardContentText>
            </Grid>
            <Grid item>
              <CardContentText>{employerTranslation.primaryRole}</CardContentText>
            </Grid>
          </Grid>
        )}
        {employer?.EmployerRoles && employer?.EmployerRoles?.length > 0 && (
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <CardContentText fontWeight={'bold'}>
              {t('profile.employer.roles')}
              {':  '}
            </CardContentText>
            {employer.EmployerRoles?.map(role => (
              <SkillsItem key={role.id} skill={role} editable type="role" />
            ))}
          </Grid>
        )}
      </>
    )
  }

  return <ExtendedCardContent description={description}>{getContent()}</ExtendedCardContent>
}

export default withProfileCardItem(EmployerItemCard)
