import { TextField, Grid, Typography } from '@mui/material'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import React, { ComponentPropsWithRef } from 'react'
import { MULTILINE_TEXT_FIELD_MAX_LENGTH } from 'constants/caleo-constants'
import { useTranslation } from 'react-i18next'

interface OwnProps {
  className?: string
  error?: string | false
  fullWidth?: boolean
  grow?: boolean
  helperText?: string | null
  label?: React.ReactNode
  required?: boolean
  style?: React.CSSProperties
  backendError?: string
  icon?: JSX.Element
  id?: string
  type?: string
  multiline?: boolean
  autosize?: boolean
  insetLabel?: boolean
}

/**
 * Input field component.
 *
 * @param className - {string} Class name for styling the component.
 * @param error - {string | false} Error message.
 * @param fullWidth - {boolean} Should component be full width.
 * @param grow - {boolean} Should component grow.
 * @param helperText - {string | null} Helper text.
 * @param label - {React.ReactNode} Label text.
 * @param required - {boolean} Is the component required.
 * @param style - {React.CSSProperties} Style parameter of the component.
 * @param backendError - {string} Backend request error message.
 * @param icon - {JSX.Element} Icon element.
 * @param id - {string} Element ID.
 * @param type - {string} Type.
 * @param multiline - {boolean} Is field multiline.
 * @param autosize - {boolean} Should the component resize automatically.
 * @param insetLabel - {boolean} Should the label be inset.
 * @returns {JSX.Element} - Input field component.
 */
export const InputField: React.FC<OwnProps & Omit<ComponentPropsWithRef<typeof TextField>, keyof OwnProps>> = ({
  className,
  error,
  fullWidth,
  grow,
  helperText,
  label,
  required,
  style: styleProp = {},
  backendError,
  icon,
  id,
  type,
  multiline,
  autosize,
  insetLabel,
  ...rest
}) => {
  const { t } = useTranslation()

  const style = {
    ...styleProp,
    width: fullWidth ? '100%' : undefined,
    flexGrow: grow ? 1 : undefined,
  }

  if (backendError) {
    error = backendError
  }

  return (
    <Grid style={style}>
      {!insetLabel && <CaleoInputLabel htmlFor={id} icon={icon} required={required} label={label} />}
      <TextField
        id={id}
        margin="dense"
        FormHelperTextProps={{ style: { textAlign: 'right' } }}
        error={!!error}
        className={`${className}`}
        label={
          insetLabel ? (
            <Grid pr={label && typeof label === 'string' && label.length > 15 ? 1.5 : 0.4}>
              {label}
              {required && (
                <Typography color="#BE3C3C" fontSize="0.85em" fontWeight="bold" component="span" pl={0.5} pr={0.5}>
                  {required && `${t('required')}`}
                </Typography>
              )}
            </Grid>
          ) : undefined
        }
        variant="outlined"
        helperText={error ? error : helperText}
        type={type}
        multiline={multiline}
        size="small"
        InputLabelProps={insetLabel ? { shrink: true } : {}}
        inputProps={
          multiline
            ? { maxLength: MULTILINE_TEXT_FIELD_MAX_LENGTH, style: autosize ? { resize: 'vertical' } : undefined }
            : undefined
        }
        {...{ ...rest, fullWidth }}
      />
    </Grid>
  )
}
