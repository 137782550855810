import { Grid, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import EditButton from '../IconButtons/EditButton'

/**
 * Profile card item higher order component.
 *
 * @param Component - The component to wrap.
 * @returns The wrapped component.
 * @notExported
 */
const withProfileCardItem = Component => {
  const ProfileCardItem = props => {
    const theme = useTheme()
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    return (
      <>
        <Grid
          sx={
            props.showDivider !== undefined
              ? {
                  width: '100%',
                  background: theme.palette.background.default,
                  padding: '0px',
                  margin: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  borderRadius: '0px',
                  boxShadow: 'none',
                }
              : {
                  width: '100%',
                  background: theme.palette.background.default,
                  borderRadius: '5px',
                  padding: '0px',
                  margin: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  marginTop: '18px',
                  [theme.breakpoints.down('sm')]: {
                    marginTop: '10px',
                  },
                }
          }
        >
          <Grid
            sx={{
              width: '95%',
              [theme.breakpoints.down('sm')]: {
                margin: '8px 8px',
              },
              margin: '18px 18px',
              alignItems: 'start',
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={11}>
                <Component {...props} />
              </Grid>
              <Grid item xs={1}>
                {props.editable && (
                  <EditButton clickAction={() => props.onOpen()} size={mobileView ? 'small' : 'medium'} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {props.showDivider && (
          <Grid
            sx={{
              width: '95%',
              [theme.breakpoints.down('sm')]: {
                margin: '8px 8px',
              },
              margin: '18px 18px',
              height: '1px',
              background: theme.palette.neutral.main,
            }}
          />
        )}
      </>
    )
  }
  return ProfileCardItem
}

export default withProfileCardItem
