import { useLanguageData } from 'hooks'
import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ISearchLanguage } from 'types/searchInterfaces'
import * as Yup from 'yup'
import DataContext from 'Components/reusable/DataContext'
import LoadingIndicator from 'Components/reusable/LoadingIndicator'
import LanguageIcon from '@mui/icons-material/Language'
import { LanguageType } from 'types/cvsInterfaces'

/** @notExported  */
interface ILanguageSearchRequirementProps {
  /** Function to add language requirement. */
  onAdd: (newItem?: ISearchLanguage) => void
  /** Whether to show inset label. */
  insetLabel?: boolean
}

/**
 * Search requirement field for language.
 *
 * @returns Language search requirement component.
 * @notExported
 */
const LanguageSearchRequirement: React.FC<ILanguageSearchRequirementProps> = ({ onAdd, insetLabel }) => {
  const [data, setData] = useState<ISearchLanguage | null>(null)
  const { i18n } = useTranslation()
  const { languages } = useLanguageData()

  const languageSchema = Yup.object({
    languageCode: Yup.string().required(i18n.t('fieldRequired')),
    level: Yup.number().typeError(i18n.t('numberTypeError')).required(i18n.t('fieldRequired')),
  })

  useEffect(() => {
    setData({
      languageCode: null,
      level: 0,
    } as unknown as ISearchLanguage)
  }, [onAdd])

  const languageList = useMemo(() => {
    const languagesList: LanguageType[] = []
    if (languages) {
      for (const property in languages) {
        languagesList.push({ languageCode: property, label: languages?.[property] ?? null })
      }
    }
    return languagesList
  }, [languages])

  if (!data || !languages) {
    return <LoadingIndicator />
  }

  const saveData = newData => {
    setData(newData)
    if (newData.languageCode) {
      const languageTranslation = languages?.[newData.languageCode]
      onAdd({ ...newData, name: languageTranslation ?? '' })
    }
  }

  return (
    <DataContext
      data={data}
      localeBase={`resources.searchEmployees.language`}
      schema={languageSchema}
      onChange={saveData}
    >
      <DataContext.LanguageField<ISearchLanguage>
        field="languageCode"
        fullWidth
        languageOptions={languageList}
        submitOnEnter={true}
        icon={<LanguageIcon />}
        insetLabel={insetLabel}
      />
    </DataContext>
  )
}

export default LanguageSearchRequirement
