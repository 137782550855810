import { Button } from '@mui/material'
import React from 'react'

/** @notExported */
interface YellowOutlinedButtonProps {
  /** Action to be performed when the button is clicked. */
  clickAction: () => void
  /** Whether the button is valid. */
  valid: boolean
  /** Label of the button. */
  label
  /** Icon of the button. */
  icon?: JSX.Element
  /** Whether to remove the margin. */
  noMargin?: boolean
  /** Whether to set the button's width to 100%. */
  noFullWidth?: boolean
}

/**
 * Yeallow outined button.
 *
 * @returns Yellow outline button component.
 * @notExported
 * @category Buttons
 */
const YellowOutlinedButton: React.FC<YellowOutlinedButtonProps> = ({
  clickAction,
  valid,
  label,
  icon,
  noMargin,
  noFullWidth,
}) => {
  return (
    <Button
      variant="outlined"
      startIcon={icon}
      disabled={!valid}
      onClick={() => clickAction()}
      style={{ width: noFullWidth ? 'inherit' : '100%', marginTop: noMargin ? 0 : '10px' }}
      color="warning"
    >
      {label}
    </Button>
  )
}

export default YellowOutlinedButton
