import { Chip, styled } from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import { IAssignmentRoleLanguageRequirement } from 'types/assignmentInterfaces'
import StarIcon from '@mui/icons-material/Star'
import { LanguagesData } from 'api/UtilsAPI'
import { ILanguageSkill } from 'types/cvsInterfaces'
import { ISearchLanguage } from 'types/searchInterfaces'

/** @notExported  */
interface ILanguageChipProps {
  /** Language data. */
  language: IAssignmentRoleLanguageRequirement | ILanguageSkill | ISearchLanguage
  /** Language options. */
  languages: LanguagesData | undefined
  /** Hide level. */
  hideLevel?: boolean
  /** Chip click handler. */
  onClick?: () => void
  /** Chip delete handler. */
  onDelete?: () => void
  /** Chip size. */
  size?: 'small'
}

/**
 * Language chip.
 *
 * @returns Language chip component.
 * @notExported
 */
const LanguageChip: React.FC<ILanguageChipProps> = ({ language, languages, hideLevel, size, onClick, onDelete }) => {
  const SpanChipTextStyle = styled('span')(({ theme: Theme }) => ({
    color: Theme.palette.background.default,
  }))

  const ChipSkillsListItem = styled(Chip)(() => ({
    borderRadius: '15px',
    height: '27px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '4px',
    float: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
  }))

  const ChipSkillsListItemLanguageChip = styled(ChipSkillsListItem)(() => ({
    borderRadius: '4px',
    background: '#167b94',
  }))

  return (
    <ChipSkillsListItemLanguageChip
      label={
        <SpanChipTextStyle>
          <span>{language.languageCode ? languages?.[language.languageCode] ?? '' : ''}</span>{' '}
          {!hideLevel && (
            <>
              <span>
                <StarIcon sx={{ fontSize: 16 }} />
              </span>
              <span>{language.level}</span>
            </>
          )}
        </SpanChipTextStyle>
      }
      size={size ?? undefined}
      color="secondary"
      className={classNames()}
      onClick={onClick ?? undefined}
      onDelete={onDelete ?? undefined}
    />
  )
}

export default LanguageChip
