import { io } from 'socket.io-client'
import { BASE_URL } from 'constants/env'
import { STORAGE_AZURE_TOKEN_KEY_NAME, STORAGE_TOKEN_KEY_NAME } from 'constants/index'

/**
 * Function for getting authentication token.
 *
 * @returns Authentication token.
 * @notExported
 */
export function getAuthorization(): string {
  let token = ''
  if (localStorage.getItem(STORAGE_TOKEN_KEY_NAME)) token = localStorage.getItem(STORAGE_TOKEN_KEY_NAME) as string

  if (localStorage.getItem(STORAGE_AZURE_TOKEN_KEY_NAME))
    token = localStorage.getItem(STORAGE_AZURE_TOKEN_KEY_NAME) as string

  return token
}

/**
 * Socket IO instance initialization.
 */
export const socket = io(BASE_URL.replace('/api', ''), {
  withCredentials: true,
  path: BASE_URL.includes('localhost') ? '/socket.io/' : '/api/socket.io/',
  reconnection: false,
  autoConnect: false,
  extraHeaders: {
    Authorization: `Bearer ${getAuthorization()}`,
  },
})
