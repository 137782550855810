import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import LanguageIcon from '@mui/icons-material/Language'
import SchoolIcon from '@mui/icons-material/School'
import StarsIcon from '@mui/icons-material/Stars'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import WorkIcon from '@mui/icons-material/Work'
import { Grid } from '@mui/material'
import { LanguagesData } from 'api/UtilsAPI'
import LanguageChip from 'Components/reusable/CaleoCustomComponents/LanguageChip/LanguageChip'
import { differenceInCalendarDays } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ICvData } from './index'

/** @notExported */
interface IOverviewItem {
  /** CV data. */
  data: ICvData | undefined
  /** Language options. */
  languages: LanguagesData
  /** Profile public ID. */
  publicId?: string
}

/**
 * Component for displaying overview of the profile qualifications.
 *
 * @returns Overview component.
 * @notExported
 */
const ProfileOverview: React.FC<IOverviewItem> = ({ data, languages, publicId }) => {
  const { t } = useTranslation()

  let employerDays = 0
  let projectDays = 0
  let degreesCount = 0
  let coursesCount = 0
  let certificatesCount = 0
  const languageChips: JSX.Element[] = []
  if (data) {
    for (const employer of data.Employers) {
      const endDate = employer.endDate ? new Date(employer.endDate) : new Date()
      employerDays += differenceInCalendarDays(endDate, new Date(employer.startDate))
    }
    for (const project of data.Projects) {
      const endDate = project.endDate ? new Date(project.endDate) : new Date()
      projectDays += differenceInCalendarDays(endDate, new Date(project.startDate))
    }
    degreesCount = data.Education.length
    coursesCount = data.Courses.length
    certificatesCount = data.Certificates.length
    for (const language of data.LanguageSkills) {
      languageChips.push(
        <LanguageChip key={language.id} size="small" language={language} languages={languages} hideLevel />
      )
    }
  }
  let employerYears = Math.floor(employerDays / 365)
  let employerMonths = Math.round((employerDays % 365) / 30)
  if (employerMonths > 11) {
    employerMonths--
    employerYears++
  }
  let projectYears = Math.floor(projectDays / 365)
  let projectMonths = Math.round((projectDays % 365) / 30)
  if (projectMonths > 11) {
    projectMonths = 0
    projectYears++
  }

  const overviewItem = (icon: JSX.Element, title: string, data, suffix?: string, extra?, extraSuffix?: string) => {
    if ((publicId && (data > 0 || data.length > 0)) || !publicId) {
      return (
        <Grid container justifyContent="space-between">
          <Grid item xs={5} md={4} lg={5} sx={{ whiteSpace: 'nowrap', justifyContent: 'flex-start', display: 'flex' }}>
            {icon} {t(title)}
          </Grid>
          <Grid item xs={7} md={8} lg={7}>
            <Grid container direction="row-reverse" justifyContent="flex-start" spacing={1}>
              {extra && (
                <>
                  {extraSuffix && (
                    <Grid item>
                      <span style={{ fontWeight: 'normal' }}>{t(extraSuffix)}</span>
                    </Grid>
                  )}
                  <Grid item>{extra}</Grid>
                </>
              )}
              {suffix && (
                <Grid item>
                  <span style={{ fontWeight: 'normal' }}>{t(suffix)}</span>
                </Grid>
              )}
              <Grid item>{data}</Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Grid item md={5}>
      <Grid container justifyContent="flex-end">
        <Grid sx={{ marginBottom: '10px', width: '100%', fontSize: 20, fontWeight: 'bold' }}>
          {overviewItem(
            <WorkIcon sx={{ mr: '5px' }} />,
            'employer.title',
            employerYears,
            'profile.skill.years.abbr',
            employerMonths > 0 ? employerMonths : undefined,
            employerMonths > 0 ? 'profile.skill.months.abbr' : undefined
          )}
          {overviewItem(
            <TimelapseIcon sx={{ mr: '5px' }} />,
            'project.title',
            projectYears,
            'profile.skill.years.abbr',
            projectMonths > 0 ? projectMonths : undefined,
            projectMonths > 0 ? 'profile.skill.months.abbr' : undefined
          )}
          {overviewItem(<SchoolIcon sx={{ mr: '5px' }} />, 'education.title', degreesCount, 'degrees')}
          {overviewItem(<StarsIcon sx={{ mr: '5px' }} />, 'courses.title', coursesCount, 'courses')}
          {overviewItem(
            <EmojiEventsIcon sx={{ mr: '5px' }} />,
            'certificates.title',
            certificatesCount,
            'certificates'
          )}
          {overviewItem(<LanguageIcon sx={{ mr: '5px' }} />, 'language.title', languageChips)}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(ProfileOverview)
