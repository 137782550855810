import React from 'react'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { useTheme } from '@mui/material/styles'
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions'
import { Grid } from '@mui/material'

/**
 * Table pagination component.
 *
 * @param count - Page count.
 * @param page - Current page.
 * @param rowsPerPage - Page size.
 * @param onPageChange - Function to handle page change.
 * @returns Pagination component.
 * @notExported
 */
const TablePaginationActions: React.FC<TablePaginationActionsProps> = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme()

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item>
        <IconButton
          sx={{ order: 2, padding: 0.5 }}
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          size="small"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          sx={{ order: 3, padding: 0.5 }}
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          size="small"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          size="small"
          sx={{ order: 5, padding: 0.5 }}
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
          size="small"
          sx={{ order: 6, padding: 0.5 }}
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default TablePaginationActions
