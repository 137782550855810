import { Box, Button, Grid, Link, Popover, Slider, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ISearchSkill } from 'types/searchInterfaces'
import { skillLevels } from 'utils/utils'
import colors from 'constants/colors'

/** @notExported */
interface IEditPopoverProps {
  /** The item to edit. */
  item: ISearchSkill
  /** The anchor element. */
  anchorEl: HTMLElement | undefined
  /** The updated item. */
  updatedItem: ISearchSkill
  /** Function to set the updated item. */
  setUpdatedItem: (updatedItem: ISearchSkill) => void
  /** Function to save the updated item. */
  saveItem: (updatedItem: ISearchSkill) => void
  /** Function to close the popover. */
  onClose: () => void
}

/**
 * EditPopover component for editing items in a popover.
 *
 * @param {IEditPopoverProps} item - The item to be edited
 * @param {HTMLElement | undefined} anchorEl - The anchor element for the popover
 * @param {ISearchSkill} updatedItem - The updated item
 * @param {React.Dispatch<React.SetStateAction<ISearchSkill>>} setUpdatedItem - Function to set the updated item
 * @param {Function} saveItem - Function to save the item
 * @param {Function} onClose - Function to close the popover
 */
const EditPopover: React.FC<IEditPopoverProps> = ({
  item,
  anchorEl,
  updatedItem,
  setUpdatedItem,
  saveItem,
  onClose,
}) => {
  const { t } = useTranslation()
  const levels = skillLevels()

  return (
    <Popover
      sx={{
        maxWidth: '1000px',
        '.MuiPopover-paper': {
          borderRadius: 1,
        },
      }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box p={2}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {t('search.criteria')}
            </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            {(item.experienceMonths !== updatedItem.experienceMonths ||
              item.level !== updatedItem.level ||
              item.requiredProjects !== updatedItem.requiredProjects) && (
              <Link
                underline="hover"
                sx={{
                  color: colors.newPrimary,
                  cursor: 'pointer',
                  fontWeight: 'bold',
                }}
                onClick={() => setUpdatedItem(item)}
              >
                {t('search.reset')}
              </Link>
            )}
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Grid container item xs={12} justifyContent="space-between">
                <Typography variant="body2">{t('search.experience')}</Typography>
                <Typography variant="body2">{`${
                  updatedItem.experienceMonths ? updatedItem.experienceMonths / 12 : 0
                } ${t('search.years')}`}</Typography>
              </Grid>
              <Slider
                defaultValue={updatedItem.experienceMonths ?? 0}
                value={updatedItem.experienceMonths ? updatedItem.experienceMonths / 12 : 0}
                step={1}
                marks
                min={0}
                max={10}
                onChange={(_e, value) => {
                  if (typeof value === 'number') {
                    setUpdatedItem({ ...updatedItem, experienceMonths: value * 12 })
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={12} justifyContent="space-between">
                <Typography variant="body2">{t('search.level')}</Typography>
                <Typography variant="body2">{`${updatedItem.level ?? 0}: ${
                  updatedItem.level
                    ? levels.find(levelValue => levelValue.level === updatedItem.level)?.label
                    : t('search.unspecified')
                }`}</Typography>
              </Grid>
              <Slider
                defaultValue={updatedItem.level ?? 0}
                value={updatedItem.level ?? 0}
                step={1}
                marks
                min={0}
                max={5}
                onChange={(_e, value) => {
                  if (typeof value === 'number') {
                    setUpdatedItem({ ...updatedItem, level: value })
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={12} justifyContent="space-between">
                <Typography variant="body2">{t('search.requiredProjects')}</Typography>
                <Typography variant="body2">{`${updatedItem.requiredProjects ?? 0}: ${t(
                  'search.projects'
                )}`}</Typography>
              </Grid>
              <Slider
                defaultValue={updatedItem.requiredProjects ?? 0}
                value={updatedItem.requiredProjects ?? 0}
                step={1}
                marks
                min={0}
                max={10}
                onChange={(_e, value) => {
                  if (typeof value === 'number') {
                    setUpdatedItem({ ...updatedItem, requiredProjects: value })
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button color="newPrimary" variant="contained" onClick={() => saveItem(updatedItem)} sx={{ mr: 1 }}>
              {t('search.save')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  )
}

export default EditPopover
