import { chooseDBTranslation } from 'utils/translations'
import { Paragraph, TextRun } from 'docx'
import { CVData } from './index'

/**
 * Generates an array of paragraphs containing URLs and their descriptions.
 *
 * @param {object} i18n - The internationalization object.
 * @param {CVData} data - The CV data object.
 * @returns {Paragraph[]} An array of paragraphs containing URLs and their descriptions.
 * @notExported
 */
const url = (i18n, data: CVData): Paragraph[] => {
  const urls: Paragraph[] = []

  for (let i = 0; i < data.urls.length; i++) {
    const url = data.urls[i]

    const line = new Paragraph({
      style: 'CaleoNormal',
      text: '',
    })

    urls.push(
      new Paragraph({
        keepLines: true,
        style: 'CaleoNormal',
        children: [
          new TextRun({
            text: url && url.url,
            break: 1,
          }),
          new TextRun({
            text: url ? chooseDBTranslation(i18n, url).description : '',
            break: 1,
          }),
        ],
      }),
      line
    )
  }

  return urls
}

export default url
