import { chooseDBTranslation } from 'utils/translations'
import { BorderStyle, Paragraph, TextRun } from 'docx'
import { CVData } from './index'
import { convertToDisplayDate } from 'utils/utils'
import colors from 'constants/colors'

/**
 * Generates the project section of a CV.
 *
 * @param {Object} i18n - the internationalization object
 * @param {Object} data - the CV data
 * @param {Object} t - the translation object
 * @return {Array} an array of Paragraph objects representing the project section
 * @notExported
 */
const project = (i18n, data: CVData, t): Paragraph[] => {
  // return project section
  const projects: Paragraph[] = []

  for (let i = 0; i < data.projects.length; i++) {
    const project = data.projects[i]
    const curIndex = i
    let date = ''
    if (project.startDate) {
      date += `${convertToDisplayDate(project.startDate, project.dateResolution)} - `
    }
    if (project.endDate) {
      date += `${convertToDisplayDate(project.endDate, project.dateResolution)}`
    }

    let skills = ''
    if (project.PersonSkills) {
      project.PersonSkills.forEach(skill => {
        skills += chooseDBTranslation(i18n, skill.Skill).name + '   '
      })
    }

    let industries = ''
    if (project.Industries) {
      project.Industries.forEach(industry => {
        industries += chooseDBTranslation(i18n, industry.Skill).name + '   '
      })
    }

    let line = new Paragraph({
      style: 'CaleoNormal',
      text: '',
    })
    if (curIndex + 1 < data.certificates.length) {
      line = new Paragraph({
        style: 'CaleoNormal',
        text: '',
        border: {
          bottom: {
            color: colors.borders,
            size: 6,
            space: 1,
            style: BorderStyle.SINGLE,
          },
        },
      })
    }

    const primaryRole = chooseDBTranslation(i18n, project).primaryRole
      ? chooseDBTranslation(i18n, project).primaryRole
      : ''

    const children = [
      new TextRun({
        text: project ? chooseDBTranslation(i18n, project).name : '',
        bold: true,
        break: 1,
      }),
      new TextRun({
        text: project ? date : '',
        color: colors.gray,
        break: 1,
      }),
      new TextRun({
        text: `${t('print.project.role')}: `,
        bold: true,
        break: 1,
      }),
      new TextRun({
        text: primaryRole ? primaryRole : '',
      }),
      new TextRun({
        text: `${t('print.project.customer')}: `,
        bold: true,
        break: 1,
      }),
      new TextRun({
        text: project ? chooseDBTranslation(i18n, project).customerName : '',
      }),
      new TextRun({
        text: `${t('print.project.employer')}: `,
        bold: true,
        break: 1,
      }),
      new TextRun({
        text: project ? chooseDBTranslation(i18n, project).employerName : '',
      }),
      new TextRun({
        text: project ? chooseDBTranslation(i18n, project).description : '',
        break: 1,
      }),
    ]

    if (skills.length) {
      children.push(
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: `${t('print.project.skills')}: `,
          bold: true,
          break: 1,
        }),
        new TextRun({
          text: skills,
        })
      )
    }

    if (industries.length) {
      children.push(
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: `${t('print.project.industries')}: `,
          bold: true,
          break: 1,
        }),
        new TextRun({
          text: industries,
        })
      )
    }

    projects.push(
      new Paragraph({
        keepLines: true,
        style: 'CaleoNormal',
        children: children,
      }),
      line
    )
  }

  return projects
}

export default project
