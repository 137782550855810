import { ILanguage } from 'types/cvsInterfaces'
import { SubAPI } from './API'

/**
 * Language API
 * @notExported
 */
class LanguageAPI extends SubAPI {
  /**
   * Get all languages
   *
   * @returns Array of languages data
   */
  public getAllLanguages(): Promise<ILanguage[]> {
    return this.api.get(`languages/all`)
  }
}

export const languageAPI = new LanguageAPI()
