import { chooseDBTranslation } from 'utils/translations'
import { BorderStyle, Paragraph, TextRun } from 'docx'
import { CVData } from './index'
import colors from 'constants/colors'

/**
 * Generates the reference section of a CV document.
 *
 * @param {type} i18n - the internationalization object
 * @param {CVData} data - the data object containing CV information
 * @param {type} t - the translation function
 * @return {Paragraph[]} an array of paragraphs representing the reference section
 * @notExported
 */
const reference = (i18n, data: CVData, t): Paragraph[] => {
  // return reference section
  const references: Paragraph[] = []

  for (let i = 0; i < data.references.length; i++) {
    const reference = data.references[i]
    const curIndex = i

    let line = new Paragraph({
      style: 'CaleoNormal',
      text: '',
    })
    if (curIndex + 1 < data.certificates.length) {
      line = new Paragraph({
        style: 'CaleoNormal',
        text: '',
        border: {
          bottom: {
            color: colors.borders,
            size: 6,
            space: 1,
            style: BorderStyle.SINGLE,
          },
        },
      })
    }

    references.push(
      new Paragraph({
        keepLines: true,
        style: 'CaleoNormal',
        children: [
          new TextRun({
            text: `${t('print.reference.referee')}: `,
            bold: true,
            break: 1,
          }),
          new TextRun({
            text: reference ? `${reference.firstName} ${reference.lastName}` : '',
          }),
          new TextRun({
            text: `${t('print.reference.company')}: `,
            bold: true,
            break: 1,
          }),
          new TextRun({
            text: reference ? reference.company : '',
          }),
          new TextRun({
            text: reference ? chooseDBTranslation(i18n, reference).letter : '',
            break: 1,
          }),
          new TextRun({
            text: '',
            break: 1,
          }),
        ],
      }),
      line
    )
  }

  return references
}

export default reference
