import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import { Box, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IBorderedSectionProps {
  color?: string
  icon?: React.ElementType
  customIcon?: React.ReactNode
  title?: string
  fontSize?: string
  fontWeight?: string | number
  sx?: SxProps
  borderBefore?: string
  noMargin?: boolean
  background?: string
  required?: boolean
  children: React.ReactNode
}

const BorderedSection: React.FC<IBorderedSectionProps> = ({
  color,
  icon,
  customIcon,
  title,
  fontSize,
  fontWeight,
  sx,
  borderBefore,
  noMargin,
  background,
  required,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        borderLeft: `1px solid ${color ?? '#000'}`,
        borderBottom: `1px solid ${color ?? '#000'}`,
        borderRight: `1px solid ${color ?? '#000'}`,
        borderRadius: '5px',
        marginTop: noMargin ? '0' : '1em',
        marginBottom: noMargin ? '0' : '1em',
        background: background ?? 'inherit',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100% !important',
          paddingLeft: '2px',
          paddingRight: '2px',
        }}
      >
        <Box
          sx={{
            borderTop: `1px solid ${color ?? '#000'}`,
            width: borderBefore ?? '42.8%',
            borderTopLeftRadius: '5px',
          }}
        ></Box>
        {(icon || title) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'center',
              gap: '0.25em',
              width: 'fit-content',
              height: '2em',
              margin: '-1em 0.2em -0.9em 0.2em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: fontSize ?? '0.8rem',
              fontWeight: fontWeight ?? 'bold',
            }}
          >
            {icon && <SvgIcon fontSize="inherit" sx={{ color: color ?? '#000' }} component={icon} />}
            {customIcon && customIcon}
            {title && (
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  gap: '0.25em',
                  width: 'fit-content',
                  height: '2em',
                  margin: '-1em 0.2em -0.9em 0.2em',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: fontSize ?? '0.8rem',
                  fontWeight: fontWeight ?? 'bold',
                }}
              >
                {title}
                {required && <span style={{ color: 'rgb(190, 60, 60)', fontSize: '0.85em' }}>{t('required')}</span>}
              </span>
            )}
          </Box>
        )}
        <Box
          sx={{
            borderTop: `1px solid ${color ?? '#000'}`,
            width: '1em',
            flexGrow: '2',
          }}
        ></Box>
      </Box>
      <Box sx={{ padding: '1em' }}>{children}</Box>
    </Box>
  )
}

export default BorderedSection
