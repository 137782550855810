import { useSearchableNetworks, useUser } from 'hooks'
import React, { useContext, useEffect, useState } from 'react'
import { INetwork } from 'types/networkInterfaces'
import { IProposalSearchFields, SearchState, ISearchData } from 'types/proposalSearchInterfaces'

const searchContext = React.createContext<SearchState>({} as SearchState)

const { Provider } = searchContext

/**
 * Function for reseting proposal search fields.
 *
 * @param networks - Possible network options.
 * @returns Empty proposal search fields data.
 * @notExported
 */
function constructEmptyProposalSearchFields({ networks = [] }: { networks?: INetwork[] }): IProposalSearchFields {
  return {
    startDate: null,
    endDate: null,
    roleName: '',
    skills: [],
    languages: [],
    networks: networks,
  }
}

/**
 * Function for reseting search data.
 *
 * @param networks - Possible network options.
 * @returns Empty search data.
 * @notExported
 */
function constructEmptySearchData({ networks = [] }: { networks?: INetwork[] }): ISearchData {
  return {
    terms: constructEmptyProposalSearchFields({ networks }),
    lastFetched: new Date(),
  }
}

/**
 * Proposal search provider.
 *
 * @param children
 * @returns Proposal search provider.
 * @notExported
 */
export const ProposalSearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, ready } = useUser()
  const { networks } = useSearchableNetworks()
  const [searchData, setSearchData] = useState<ISearchData>(constructEmptySearchData({ networks }))
  const [initialNetworksSet, setInitialNetworksSet] = useState(false)

  const searchReady = initialNetworksSet

  const resetSearchFields = () => {
    setSearchData(searchData => ({
      ...searchData,
      terms: constructEmptyProposalSearchFields({ networks }),
    }))
  }

  const resetSearch = () => {
    setSearchData(constructEmptySearchData({ networks }))
  }

  useEffect(() => {
    if (!initialNetworksSet && ready && user && networks && networks.length > 0) {
      setSearchData(searchData => ({
        ...searchData,
        terms: {
          ...searchData.terms,
          networks: networks,
        },
      }))
      setInitialNetworksSet(true)
    }
  }, [initialNetworksSet, ready, user, networks])

  useEffect(() => {
    resetSearch()
  }, [user, networks])

  return (
    <Provider
      value={{
        searchData,
        setSearchData,
        resetSearchFields,
        resetSearch,
        ready: searchReady,
        searchableNetworks: networks,
      }}
    >
      {children}
    </Provider>
  )
}

/**
 * Hook for doing proposal searches.
 *
 * @returns Proposal search context variables and functions.
 * @notExported
 */
export function useProposalSearchData(): SearchState {
  return useContext(searchContext)
}
