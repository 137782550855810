import { chooseDBTranslation } from 'utils/translations'
import { Paragraph, TextRun, Table, TableRow, TableCell, BorderStyle, AlignmentType, WidthType } from 'docx'
import { CVData } from './index'
import { IPersonSkillOrIndustryOrRole } from 'types/cvsInterfaces'
import { roundToDecimal, skillLevels } from 'utils/utils'

/**
 * Generates a table of skills for a CV.
 *
 * @param {i18n} i18n - the internationalization object
 * @param {CVData} data - the data for the CV
 * @param {type} type - the type of skills to generate (e.g., 'skills', 'industry', 'role')
 * @param {t} t - the translation function
 * @param {boolean} hideLastUsed - whether to hide the last used date
 * @return {Table} the generated table of skills
 * @notExported
 */
const skill = (i18n, data: CVData, type, t, hideLastUsed: boolean): Table => {
  const items: IPersonSkillOrIndustryOrRole[] = data[type]

  const borders = {
    top: {
      style: BorderStyle.NONE,
      size: 0,
      color: '#FF0000',
    },
    bottom: {
      style: BorderStyle.NONE,
      size: 0,
      color: '#FF0000',
    },
    left: {
      style: BorderStyle.NONE,
      size: 0,
      color: '#FF0000',
    },
    right: {
      style: BorderStyle.NONE,
      size: 0,
      color: '#FF0000',
    },
  }
  const spacing = {
    after: 60,
    before: 60,
  }

  interface Item {
    name: Paragraph
    level: Paragraph
  }

  const itemCells: Item[] = []
  for (const item of items) {
    const level = item.level ? t(skillLevels()[(item.level ?? 1) - 1].label) : ''
    itemCells.push({
      name: new Paragraph({
        keepLines: true,
        style: 'CaleoNormal',
        children: [
          new TextRun({
            text: item ? chooseDBTranslation(i18n, item.Skill).name : '',
            font: {
              name: 'Raleway',
            },
          }),
        ],
        spacing,
      }),
      level: new Paragraph({
        keepLines: true,
        style: 'CaleoNormal',
        children: [
          new TextRun({
            text: level,
            font: {
              name: 'Raleway',
            },
          }),
          new TextRun({
            text: `${t('profile.skill.experience')}: ${
              item.experienceMonths
                ? `${roundToDecimal(item.experienceMonths / 12, 1)} ${t('profile.skill.years.abbr')}`
                : '-'
            }`,
            font: {
              name: 'Raleway',
            },
            break: 1,
          }),
          ...(!hideLastUsed
            ? [
                new TextRun({
                  text: `${t('profile.skill.lastUsed')}: ${item.lastUsed ? item.lastUsed : ''}`,
                  font: {
                    name: 'Raleway',
                  },
                  break: 1,
                }),
              ]
            : []),
        ],
        alignment: AlignmentType.LEFT,
        spacing,
      }),
    })
  }

  const firstColCells = itemCells
    .map((cell, i) => {
      if (i + 1 <= (itemCells.length + 1) / 2) return cell
    })
    .filter(cell => cell !== undefined) as Item[]

  const secondColCells = itemCells
    .map((cell, i) => {
      if (i + 1 > (itemCells.length + 1) / 2) return cell
    })
    .filter(cell => cell !== undefined) as Item[]

  const itemRows: TableRow[] = firstColCells.map((cell, i) => {
    return new TableRow({
      children: [
        new TableCell({
          children: [cell.name],
          width: {
            size: 2150,
            type: WidthType.DXA,
          },
          borders,
        }),
        new TableCell({
          children: [cell.level],
          width: {
            size: 2150,
            type: WidthType.DXA,
          },
          borders,
        }),
        new TableCell({
          children: [],
          width: {
            size: 400,
            type: WidthType.DXA,
          },
          borders,
        }),
        new TableCell({
          children: secondColCells[i] ? [secondColCells[i].name] : [],
          width: {
            size: 2150,
            type: WidthType.DXA,
          },
          borders,
        }),
        new TableCell({
          children: secondColCells[i] ? [secondColCells[i].level] : [],
          width: {
            size: 2150,
            type: WidthType.DXA,
          },
          borders,
        }),
      ],
    })
  })

  itemRows.push(
    new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              keepLines: true,
              children: [
                new TextRun({
                  text: `  ${t('print.skills.expert')}  `,
                }),
                new TextRun({
                  text: '|',
                }),
                new TextRun({
                  text: `  ${t('print.skills.advanced')}  `,
                }),
                new TextRun({
                  text: '|',
                }),
                new TextRun({
                  text: `  ${t('print.skills.intermediate')}  `,
                }),
                new TextRun({
                  text: '|',
                }),
                new TextRun({
                  text: `  ${t('print.skills.novice')}  `,
                }),
                new TextRun({
                  text: '|',
                }),
                new TextRun({
                  text: `  ${t('print.skills.fundamentals')}  `,
                }),
              ],
              style: 'GrayText',
            }),
          ],
          borders,
          columnSpan: 5,
        }),
      ],
    })
  )

  return new Table({
    rows: itemRows && itemRows.length ? itemRows : [],
  })
}

export default skill
