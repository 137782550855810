import { Paragraph, TextRun, Table, TableRow, TableCell, TableLayoutType, BorderStyle, WidthType } from 'docx'
import { CVData } from './index'
import { languageLevels } from 'utils/utils'

/**
 * Generates a language section table for the given CV data.
 *
 * @param {CVData} data - The CV data object.
 * @param {object} languages - The language object.
 * @param {function} t - The translation function.
 * @return {Table} - The generated language section table.
 * @notExported
 */
const language = (data: CVData, languages, t): Table => {
  // return language section

  const borders = {
    top: {
      style: BorderStyle.NONE,
      size: 0,
      color: '#FF0000',
    },
    bottom: {
      style: BorderStyle.NONE,
      size: 0,
      color: '#FF0000',
    },
    left: {
      style: BorderStyle.NONE,
      size: 0,
      color: '#FF0000',
    },
    right: {
      style: BorderStyle.NONE,
      size: 0,
      color: '#FF0000',
    },
  }

  interface LanguageItem {
    item: TextRun[]
  }

  const languageRows: TableRow[] = []
  const languageCells: LanguageItem[] = []
  for (const language of data.languages) {
    const level = language.level ? t(languageLevels()[(language.level ?? 1) - 1].label) : ''

    languageCells.push({
      item: [
        new TextRun({
          text: language ? languages[language.languageCode] : '',
          font: {
            name: 'Raleway',
          },
          break: 1,
        }),
        new TextRun({
          text: '',
          break: 1,
        }),
        new TextRun({
          text: level,
          font: {
            name: 'Raleway',
          },
        }),
        new TextRun({
          text: '',
        }),
      ],
    })
  }

  for (let i = 0; i < languageCells.length; i += 2) {
    if (i + 1 === languageCells.length && languageCells.length % 2) {
      languageRows.push(
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  keepLines: true,
                  style: 'CaleoNormal',
                  children: [...languageCells[i].item],
                }),
              ],
              width: {
                size: 4300,
                type: WidthType.DXA,
              },
              borders,
            }),
            new TableCell({
              children: [],
              width: {
                size: 400,
                type: WidthType.DXA,
              },
              borders,
            }),
            new TableCell({
              children: [],
              width: {
                size: 4300,
                type: WidthType.DXA,
              },
              borders,
            }),
          ],
        })
      )
    } else {
      languageRows.push(
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  keepLines: true,
                  style: 'CaleoNormal',
                  children: [...languageCells[i].item],
                }),
              ],
              width: {
                size: 4300,
                type: WidthType.DXA,
              },
              borders,
            }),
            new TableCell({
              children: [],
              width: {
                size: 400,
                type: WidthType.DXA,
              },
              borders,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  keepLines: true,
                  style: 'CaleoNormal',
                  children: [...languageCells[i + 1].item],
                }),
              ],
              width: {
                size: 4300,
                type: WidthType.DXA,
              },
              borders,
            }),
          ],
        })
      )
    }
  }

  languageRows.push(
    new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              keepLines: true,
              children: [
                new TextRun({
                  text: `  ${t('language.native')}  `,
                }),
                new TextRun({
                  text: '|',
                }),
                new TextRun({
                  text: `  ${t('language.excellent')}  `,
                }),
                new TextRun({
                  text: '|',
                }),
                new TextRun({
                  text: `  ${t('language.moderate')}  `,
                }),
                new TextRun({
                  text: '|',
                }),
                new TextRun({
                  text: `  ${t('language.good')}  `,
                }),
                new TextRun({
                  text: '|',
                }),
                new TextRun({
                  text: `  ${t('language.basic')}  `,
                }),
              ],
              style: 'GrayText',
            }),
          ],
          borders,
          columnSpan: 5,
        }),
      ],
    })
  )

  return new Table({
    rows: languageRows,
    layout: TableLayoutType.FIXED,
    columnWidths: [4300, 400, 4300],
  })
}

export default language
