import { DialogContent, Grid } from '@mui/material'
import DataContext from 'Components/reusable/DataContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ILanguageSkill, LanguageType } from 'types/cvsInterfaces'
import AddToArrayButton from 'Components/reusable/Buttons/AddToArrayButton'
import newWithEditModal from 'Components/reusable/HOC/newWithEditModal'
import NewLanguageItem from './NewLanguageItem'

/** @notExported */
interface LanguageModalProps {
  /** Language skill item. */
  item: ILanguageSkill | null
  /** Language options. */
  languages
  /** Already added language skills. */
  items: ILanguageSkill[]
  /** Multiple added language skills. */
  multipleItems: ILanguageSkill[]
  /** Function for adding multiple language skills. */
  addMultiple: () => void
  /** Function for removing language skills from array. */
  removeFromArray: (items) => void
}

/**
 * Language skill modal.
 *
 * @returns Language skill modal component.
 * @notExported
 */
const LanguageModal: React.FC<LanguageModalProps> = ({
  item,
  items,
  languages,
  multipleItems,
  addMultiple,
  removeFromArray,
}) => {
  const { t } = useTranslation()

  const languageOptions = Object.assign({}, languages)

  const arrayLanguagesList: LanguageType[] = []
  for (const property in languageOptions) {
    arrayLanguagesList.push({ languageCode: property, label: languageOptions[property] })
  }

  const languagesList: LanguageType[] = []
  for (const i of items) {
    if (item) {
      if (i.languageCode !== item.languageCode) {
        delete languageOptions[i.languageCode]
      }
    } else {
      delete languageOptions[i.languageCode]
    }
  }

  for (const i of multipleItems) {
    delete languageOptions[i.languageCode]
  }
  for (const property in languageOptions) {
    languagesList.push({ languageCode: property, label: languageOptions[property] })
  }

  const handleLanguageSkillDelete = languageCode => {
    const newSkillsData = multipleItems.filter(item => item.languageCode !== languageCode)
    removeFromArray(newSkillsData)
  }

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <DataContext.LanguageField<ILanguageSkill>
              field="languageCode"
              languageOptions={languagesList}
              required
              insetLabel
            />
          </Grid>
          <Grid item xs={12}>
            <DataContext.LanguageLevelField<ILanguageSkill> field="level" required fullWidth insetLabel />
          </Grid>
          {!item && (
            <DataContext.Validation>
              {({ valid }) => (
                <AddToArrayButton valid={valid} clickAction={() => addMultiple()} label={t('language.addLanguage')} />
              )}
            </DataContext.Validation>
          )}
        </Grid>
      </DialogContent>
      {multipleItems.length > 0 ? (
        <Grid sx={{ minWidth: '150px', paddingTop: '20px', paddingRight: '10px' }}>
          {multipleItems.map((skill, i) =>
            !item ? (
              <NewLanguageItem
                key={skill.id || `new-${i}`}
                skill={skill as ILanguageSkill}
                onDeleteClick={() => handleLanguageSkillDelete(skill.languageCode)}
                languages={arrayLanguagesList}
              />
            ) : null
          )}
        </Grid>
      ) : null}
    </Grid>
  )
}

export default newWithEditModal(LanguageModal)
