import Tab, { tabClasses } from '@mui/material/Tab'
import { styled } from '@mui/material/styles'
import colors from 'constants/colors'

export const TabItem = styled(Tab)(({ theme }) => ({
  opacity: 1,
  overflow: 'initial',
  zIndex: 2,
  textTransform: 'initial',
  color: colors.newPrimary,
  fontWeight: 'bold',
  fontSize: 18,
  backgroundColor: theme.palette.background.paper,
  transition: '0.2s',
  [theme.breakpoints.up('sm')]: {
    minWidth: 120,
  },
  '&:before': {
    transition: '0.2s',
  },
  '&:not(:first-of-type)': {
    '&:before': {
      content: '" "',
      position: 'absolute',
      left: 0,
      display: 'block',
      height: 20,
      width: 1,
      zIndex: 1,
      backgroundColor: theme.palette.grey[300],
    },
  },
  [`& + .${tabClasses.selected}::before`]: {
    opacity: 0,
  },
  '&:hover': {
    [`&:not(.${tabClasses.selected})`]: {
      backgroundColor: 'rgba(0 0 0 / 0.1)',
    },
    '&::before': {
      opacity: 0,
    },
    [`& + .${tabClasses.root}::before`]: {
      opacity: 0,
    },
  },
  [`&.${tabClasses.selected}`]: {
    backgroundColor: colors.borderColor,
    color: colors.black,
  },
  [`&.${tabClasses.selected} + .${tabClasses.root}`]: {
    zIndex: 1,
  },
  [`&.${tabClasses.selected} + .${tabClasses.root}::before`]: {
    opacity: 0,
  },
}))
