import { DialogActions, DialogContent, Grid, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IError } from 'types/error'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { useTranslation } from 'react-i18next'
import DataContext, { NewOrExisting } from 'Components/reusable/DataContext'
import { IChangePassword } from 'types/accountInterfaces'
import { getChangePasswordInitialData } from 'Components/reusable/DataContext/InitialData'
import { changePasswordSchema } from 'Components/reusable/DataContext/ValidationSchema'
import withSimpleModal from 'Components/reusable/HOC/withSimpleModal'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import SaveButton from 'Components/reusable/Buttons/SaveButton'
import { authAPI } from 'api/AuthAPI'

/** @notExported */
interface IPasswordModalProps {
  /** Submit indicator. */
  submitIndicator: boolean
  /** Function for submitting modal. */
  setSubmitLocally: (value: boolean) => void
  /** Function for resetting modal. */
  resetSubmit: () => void
  /** Function for closing modal. */
  onClose: () => void
}

/**
 * Password change modal.
 *
 * @returns Modal for changing password.
 * @notExported
 */
const PasswordModal: React.FC<IPasswordModalProps> = ({ submitIndicator, setSubmitLocally, resetSubmit, onClose }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [backendError, setBackendError] = useState<IError>()
  const [data, setData] = useState<NewOrExisting<IChangePassword>>(getChangePasswordInitialData())
  const [passwordError, setPasswordError] = useState<string>()

  const localeBase = 'changePassword'
  const schema = changePasswordSchema()

  useEffect(() => {
    ;(async () => {
      const controller = new AbortController()

      if (submitIndicator) {
        try {
          await authAPI.changePassword(data, controller)
          onClose()
        } catch (error) {
          const err = error as IError
          if (
            err.response &&
            err.response.status === 400 &&
            (err.response.data === 'same-password-error' || err.response.data === 'wrong-password-error')
          ) {
            setPasswordError(err.response.data)
          } else {
            setBackendError(err)
          }
          resetSubmit()
        }
      }

      return () => {
        controller.abort()
      }
    })()
  }, [submitIndicator])

  useEffect(() => {
    if (passwordError) {
      setPasswordError(undefined)
    }
  }, [data])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <DataContext
      data={data}
      onChange={setData}
      onSubmit={() => setSubmitLocally(true)}
      schema={schema}
      localeBase={localeBase}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <DataContext.PasswordField<IChangePassword> field="currentPassword" required fullWidth type="password" />
          </Grid>
          <Grid item xs={12}>
            <DataContext.PasswordField<IChangePassword> field="newPassword" required fullWidth type="password" />
          </Grid>
          <Grid item xs={12}>
            <DataContext.PasswordField<IChangePassword> field="confirmPassword" required fullWidth type="password" />
          </Grid>
          {passwordError && (
            <Grid item xs={12} mb={2}>
              <CardContentText type="error">{t(passwordError)}</CardContentText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ background: theme.palette.background.paper, paddingRight: '22px', paddingBottom: '22px' }}>
        <DataContext.Validation>
          {({ valid }) => (
            <SaveButton
              label={t('save')}
              valid={valid && !passwordError}
              clickAction={() => {
                setSubmitLocally(true)
              }}
            />
          )}
        </DataContext.Validation>
      </DialogActions>
    </DataContext>
  )
}

export default withSimpleModal(PasswordModal)
