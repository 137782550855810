import { allocationAPI } from 'api/AllocationAPI'
import { useEffect, useState } from 'react'
import { IError } from 'types/error'
import { IAllocation } from 'types/allocationInterfaces'

/**
 * Hook for getting allocations for a person.
 *
 * @param personId - ID of the person to get allocations for.
 * @returns Hook variables and functions.
 */
export const useAllocations = (personId: number) => {
  const [allocations, setAllocations] = useState<IAllocation[]>([])
  const [error, setError] = useState<IError>()
  const [update, setUpdate] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        setLoading(true)
        const allocations = await allocationAPI.getAllocations(personId, controller)
        setAllocations(allocations)
      } catch (error) {
        setError(error as IError)
      } finally {
        setUpdate(false)
        setLoading(false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [personId, update])

  return {
    allocations,
    error,
    setUpdate,
    loading,
  }
}
