import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Checkbox,
  Collapse,
  DialogContent,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import { useConnectedOrganizationData } from 'hooks/data'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IEmployee } from 'types/adminInterfaces'
import { IAssignment } from 'types/assignmentInterfaces'
import { IError } from 'types/error'
import { ITeam } from 'types/teamInterfaces'
import { IOrganization } from 'types/userInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'
import newWithConfirmationModal from 'Components/reusable/HOC/newWithConfirmationModal'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported  */
interface IDeleteOrganizationConfirmProps {
  /** Organization being deleted */
  item: IOrganization
  /** Is user in network management */
  networkManage?: boolean
}

/**
 * Component for displaying delete organization confirmation
 *
 * @returns Component for displaying delete organization confirmation
 * @notExported
 */
const DeleteOrganizationConfirm: React.FC<IDeleteOrganizationConfirmProps> = ({ item, networkManage }) => {
  const isComponentMounted = useIsComponentMounted()
  const { t, i18n } = useTranslation()
  const [peopleDetailsOpen, setPeopleDetailsOpen] = useState<boolean>(false)
  const [assignmentDetailsOpen, setAssignmentDetailsOpen] = useState<boolean>(false)
  const [teamDetailsOpen, setTeamDetailsOpen] = useState<boolean>(false)
  const { employees, assignments, teams, error } = useConnectedOrganizationData(item.id, networkManage)
  const [backendError, setBackendError] = useState<IError>()

  useEffect(() => {
    if (isComponentMounted.current) setBackendError(error as IError)
  }, [error])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <DialogContent>
      {!networkManage && (employees.length || assignments.length || teams.length) ? (
        <>
          <Typography color="primary" fontWeight="bold" ml={3} variant="h2">
            {t(`confirmation.organization.data`)}
          </Typography>
          <p style={{ marginLeft: '15px' }}>{t(`confirmation.organization.text`)}</p>
          {employees.length > 0 && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setPeopleDetailsOpen(!peopleDetailsOpen)}
                    icon={<ExpandMoreIcon />}
                    checkedIcon={<ExpandLessIcon />}
                    name="collapseCh"
                  />
                }
                label={t('employees.title')}
                labelPlacement="start"
                style={{ fontWeight: 'bold' }}
              />
              <Collapse in={peopleDetailsOpen}>
                <CardContentText type="error" fontWeight="bold">
                  {t('confirmation.person.data')}
                </CardContentText>
                <Table aria-label="allocation table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>{t('admin.employee.name')}</b>
                      </TableCell>
                      <TableCell>
                        <b>{t('admin.employee.email')}</b>
                      </TableCell>
                      <TableCell>
                        <b>{t('admin.employee.lastLogin')}</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employees.map((employee: IEmployee) => (
                      <TableRow key={employee.id}>
                        <TableCell variant="head" scope="row">
                          {employee.Person.firstName} {employee.Person.lastName}
                        </TableCell>
                        <TableCell variant="head" scope="row">
                          {employee.email}
                        </TableCell>
                        <TableCell variant="head" scope="row">
                          {employee?.lastLogin && convertToDisplayDate(employee.lastLogin)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </>
          )}
          {assignments.length > 0 && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setAssignmentDetailsOpen(!assignmentDetailsOpen)}
                    icon={<ExpandMoreIcon />}
                    checkedIcon={<ExpandLessIcon />}
                    name="collapseCh"
                  />
                }
                label={t('assignments.title')}
                labelPlacement="start"
              />
              <Collapse in={assignmentDetailsOpen}>
                <CardContentText type="error" fontWeight="bold">
                  {t('confirmation.assignments.data')}
                </CardContentText>
                <Table aria-label="allocation table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>{t('assignment.modal.name')}</b>
                      </TableCell>
                      <TableCell>
                        <b>{t('assignment.modal.startDate')}</b>
                      </TableCell>
                      <TableCell>
                        <b>{t('assignment.modal.endDate')}</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {assignments.map((assignment: IAssignment) => (
                      <TableRow key={assignment.id}>
                        <TableCell variant="head" scope="row">
                          {chooseDBTranslation(i18n, assignment).name}
                        </TableCell>
                        <TableCell>{convertToDisplayDate(assignment.startDate)}</TableCell>
                        <TableCell>{assignment.endDate ? convertToDisplayDate(assignment?.endDate) : null}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </>
          )}
          {teams.length > 0 && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setTeamDetailsOpen(!teamDetailsOpen)}
                    icon={<ExpandMoreIcon />}
                    checkedIcon={<ExpandLessIcon />}
                    name="collapseCh"
                  />
                }
                label={t('team.title')}
                labelPlacement="start"
              />
              <Collapse in={teamDetailsOpen}>
                <CardContentText type="error" fontWeight="bold">
                  {t('confirmation.teams.data')}
                </CardContentText>
                <Table aria-label="allocation table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>{t('team.modal.name')}</b>
                      </TableCell>
                      <TableCell>
                        <b>{t('team.modal.description')}</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teams.map((team: ITeam) => (
                      <TableRow key={team.id}>
                        <TableCell variant="head" scope="row">
                          {chooseDBTranslation(i18n, team).name}
                        </TableCell>
                        <TableCell variant="head" scope="row">
                          {chooseDBTranslation(i18n, team)?.description}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </>
          )}
        </>
      ) : (
        <>
          <Typography color="primary" fontWeight="bold" variant="h2" textTransform="none">
            {t('confirmation.organization.text')}
          </Typography>
        </>
      )}
      <p style={{ marginLeft: '15px' }}>{t('no-undo')}</p>
    </DialogContent>
  )
}

export default newWithConfirmationModal(DeleteOrganizationConfirm)
