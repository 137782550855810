import { Box } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { organizationAPI } from 'api/OrganizationAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import { useIsComponentMounted } from 'hooks/util'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IError } from 'types/error'
import { IOrganization } from 'types/userInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'
import { InputField } from '../InputField'

/** @notExported */
interface IOrganizationPickerProps {
  /** The current value. */
  value: IOrganization | null
  /** Action to do when the value changes. */
  onChange: (newValue: IOrganization | null) => void
  /** The label text. */
  label?: string
  /** Whether the field is required. */
  required?: boolean
  /** Whether the field is disabled. */
  disabled: boolean
  /** The name of the field. */
  name?: string
  /** The available options. */
  options?: IOrganization[]
  /** Whether to show other options. */
  showOthers?: boolean
  /** The usage type of organization. */
  type?: 'admin' | 'proposal' | 'network'
  /** Whether the label should be inset. */
  insetLabel?: boolean
}

/**
 * Organization picker component.
 *
 * @returns The organization picker component.
 * @notExported
 */
const OrganizationPicker: React.FC<IOrganizationPickerProps> = ({
  value,
  onChange,
  label,
  required = false,
  disabled,
  name,
  options,
  showOthers = false,
  type,
  insetLabel,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const [backendError, setBackendError] = useState<IError>()
  const [open, setOpen] = useState<boolean>(false)
  const [orgs, setOrgs] = useState<IOrganization[]>([])
  const [loaded, setLoaded] = useState<boolean>(false)
  const loading = open && !loaded

  const { t, i18n } = useTranslation()

  useEffect(() => {
    const controller = new AbortController()
    let active = true

    if (!loading) {
      return undefined
    }

    ;(async () => {
      if (!options) {
        try {
          if (orgs.length > 0) return
          const organizations = await organizationAPI.getAllowedOrganizations(controller)
          if (!isComponentMounted.current) return
          if (active) {
            setOrgs(organizations)
            setLoaded(true)
          }
        } catch (error) {
          setBackendError(error as IError)
        }
      } else {
        if (showOthers) {
          const freelancerOption = {
            id: 0,
            translations: [{ name: `${t('admin.permission.groupType.freelancer')}` }],
          } as IOrganization
          options.push(freelancerOption)

          if (type === 'admin') {
            const recruitOption = {
              id: -1,
              translations: [{ name: `${t('admin.permission.groupType.recruit')}` }],
            } as IOrganization
            options.push(recruitOption)
          }
          if (type !== 'network' && type !== 'admin' && type !== 'proposal') {
            const showAllOption = { id: -1, translations: [{ name: `${t('admin.skills.showAll')}` }] } as IOrganization
            options.push(showAllOption)
          }
        }
        setOrgs(options)
        setLoaded(true)
      }
    })()

    return () => {
      controller.abort()
      active = false
    }
  }, [loading])

  useEffect(() => {
    if (name) {
      setOpen(true)
    }
  }, [name])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <>
      {label && !insetLabel && <CaleoInputLabel label={label} required={required} />}
      <Autocomplete
        open={open}
        loading={loading}
        disabled={disabled}
        value={value}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        style={{ marginTop: '0px' }}
        onChange={(_event, newValue) => onChange(newValue)}
        filterOptions={(options, { inputValue }) => {
          const result = fuseFiltering(options, inputValue, ['translations.name'])
          return result
        }}
        selectOnFocus
        clearOnBlur
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id
        }}
        handleHomeEndKeys
        options={orgs}
        getOptionLabel={option => chooseDBTranslation(i18n, option).name}
        renderOption={(props, option) => {
          return (
            <Box component="li" {...props} key={option.id}>
              {chooseDBTranslation(i18n, option).name}
            </Box>
          )
        }}
        renderInput={params => (
          <InputField
            {...params}
            insetLabel={insetLabel}
            label={insetLabel ? label : undefined}
            InputLabelProps={{ shrink: insetLabel ? true : undefined }}
            fullWidth
            size="small"
            margin="dense"
            required={required}
          />
        )}
        fullWidth
      />
    </>
  )
}

export default OrganizationPicker
