import React from 'react'
import caleoLogo from 'assets/images/caleoLogo.svg'
import { Grid } from '@mui/material'

/** @notExported */
interface LoginPageCardWrapperProps {
  /** Login page card content. */
  children: React.ReactNode
}

/**
 * Login page card wrapper.
 *
 * @returns Login page card wrapper component.
 * @notExported
 */
const LoginPageCardWrapper: React.FC<LoginPageCardWrapperProps> = ({ children }) => {
  return (
    <Grid id="login" display="flex">
      <img src={caleoLogo} alt="logo" style={{ position: 'absolute', left: '0%', width: '159px', padding: '18px' }} />
      {children}
    </Grid>
  )
}

export default LoginPageCardWrapper
