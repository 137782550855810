import { Checkbox, FormControlLabel, Tooltip } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * Renders a visibility checkbox component.
 *
 * @param {boolean} checked - Indicates whether the checkbox is checked or not.
 * @param {function} onChange - Callback function that is called when the checkbox value changes.
 * @return {JSX.Element} The rendered visibility checkbox component.
 * @notExported
 */
const VisibilityCheckbox = ({ checked, onChange }) => {
  const { t } = useTranslation()
  const title = checked ? t('print.visibilityOff') : t('print.visibilityOn')

  return (
    <FormControlLabel
      onClick={event => event.stopPropagation()}
      onFocus={event => event.stopPropagation()}
      style={{ marginLeft: 5 }}
      label=""
      control={
        <Tooltip title={title}>
          <Checkbox
            color="primary"
            icon={<VisibilityIcon />}
            checkedIcon={<VisibilityOffIcon />}
            inputProps={{
              'aria-label': 'checkbox',
            }}
            checked={checked}
            onChange={event => {
              onChange(event.target.checked)
            }}
          />
        </Tooltip>
      }
    />
  )
}

export default VisibilityCheckbox
