import { Checkbox, DialogContent, FormControlLabel, Grid } from '@mui/material'
import { allocationAPI } from 'api/AllocationAPI'
import { assignmentAPI } from 'api/AssignmentAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { useNotificationPopup } from 'Components/reusable/Notification'
import newWithEditModal from 'Components/reusable/HOC/newWithEditModal'
import DataContext, { NewOrExisting } from 'Components/reusable/DataContext'
import { assignmentAllocationSchema, ownAllocationSchema } from 'Components/reusable/DataContext/ValidationSchema'
import { useUser } from 'hooks/user'
import React, { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { IAllocation, IAllocationScopeItem, IAllocationTranslation } from 'types/allocationInterfaces'
import { IAssignment, IAssignmentRole } from 'types/assignmentInterfaces'
import { IError } from 'types/error'
import { createTranslation } from 'utils/translations'
import { allocationStates } from 'utils/utils'
import PersonPicker from 'Components/reusable/InputFields/PersonPicker'
import { NetworkId, OrganizationId, TeamId } from 'types/ids'
import { IPerson } from 'types/userInterfaces'
import AllocationScopePicker from 'Components/reusable/InputFields/AllocationScopePicker'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported */
interface IComponentProps {
  /** Function to be called when the modal is closed. */
  onClose: ({ newItem }: { newItem?: IAllocation }) => void
  /** The item to be edited. */
  item?: IAllocation | null
  /** The id of the person. */
  personId: number
  /** The data of the item. */
  data: IAllocation
  /** Function to be called when the data is changed. */
  setData: (newData) => void
  /** Submit indicator. */
  submitIndicator: boolean
  /** Function to reset the submit indicator. */
  resetSubmit: () => void
  /** Function to set the schema. */
  setSchema: (schema) => void
  /** Whether the modal is for management. */
  management?: boolean
  /** The scope options. */
  scopeOptions: IAllocationScopeItem[]
  /** Does the user have sales access */
  salesAccess?: boolean
}

/**
 * Allocation modal.
 *
 * @returns Allocation modal component.
 * @notExported
 */
const AllocationModal: React.FC<IComponentProps> = ({
  onClose,
  item,
  personId,
  data,
  setData,
  submitIndicator,
  resetSubmit,
  setSchema,
  management,
  scopeOptions,
  salesAccess,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const { t, i18n } = useTranslation()
  const { setSuccessNotificationPopup } = useNotificationPopup()
  const states = allocationStates()
  const { user, groups } = useUser()

  const [backendError, setBackendError] = useState<IError>()
  const [assignments, setAssignments] = useState<IAssignment[]>([])
  const [assignmentRoles, setAssignmentRoles] = useState<IAssignmentRole[]>([])
  const [showNames, setShowNames] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<IPerson>()
  const [selectedScope, setSelectedScope] = useState<{ value: number; label: string; type: string }>()

  const types: { type: string; label: string; info?: React.ReactElement }[] = []

  if (item && !item.id) {
    personId = item.personId
  }

  if ((user && user.Person && user.Person.id === personId) || salesAccess) {
    types.push({
      type: 'personal',
      label: i18n.t('allocation.own'),
      info: (
        <Trans i18nKey="allocation.own.info">
          Use <strong>own</strong> allocation to mark vacations, absences or dedicated time for own purposes.
        </Trans>
      ),
    })
  }

  if (groups && (groups.includes('admin') || groups.includes('sales')) && assignments.length > 0) {
    types.push({
      type: 'project',
      label: i18n.t('allocation.project'),
    })
  }

  if (groups && (groups.includes('sales') || groups.includes('admin') || groups.includes('teamLeader'))) {
    types.push({
      type: 'other',
      label: i18n.t('allocation.other'),
      info: (
        <Trans i18nKey="allocation.other.info">
          Use <strong>quick assignments</strong> to do lightweight resourcing.
        </Trans>
      ),
    })
  }

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (data.assignmentId) {
        if (!item) {
          setData({
            ...data,
            startDate: assignments?.find(assignment => assignment.id === data?.assignmentId)?.startDate,
            endDate: assignments?.find(assignment => assignment.id === data?.assignmentId)?.endDate,
            translations: [
              await createTranslation<IAllocationTranslation, 'allocationId'>(i18n.language, {
                information:
                  assignments
                    ?.find(assignment => assignment.id === data?.assignmentId)
                    ?.translations.find(language => language.Language.name === i18n.language)?.description ?? '',
              }),
            ],
          } as unknown as NewOrExisting<IAllocation>)
        }
        try {
          if (groups && (groups.includes('sales') || groups.includes('admin'))) {
            const results = await assignmentAPI.getAssignmentRoles(data.assignmentId, controller)
            if (!isComponentMounted.current) return
            setAssignmentRoles(results)
          }
        } catch (error) {
          setBackendError(error as IError)
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [data.assignmentId])

  useEffect(() => {
    ;(async () => {
      if (data?.roleId && !item) {
        setData({
          ...data,
          percent: assignmentRoles.find(role => (role.id as number) === (data?.roleId as number))?.allocationPercentage,
        })
      }
    })()
  }, [data?.roleId])

  useEffect(() => {
    const controller = new AbortController()

    setData({
      ...data,
      assignmentId: null,
      roleId: null,
      Assignment: null,
      AssignmentRole: null,
    } as unknown as NewOrExisting<IAllocation>)

    setShowNames(false)

    if (data.type === 'project') {
      ;(async () => {
        try {
          if (groups && (groups.includes('sales') || groups.includes('admin'))) {
            const results = await assignmentAPI.getAssignmentList(controller)
            if (!isComponentMounted.current) return
            setAssignments(results)
          }
        } catch (error) {
          setBackendError(error as IError)
        }
      })()
    } else if (!item) {
      setData({ ...data, percent: 100 })
    }

    return () => {
      controller.abort()
    }
  }, [data.type])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (submitIndicator === true) {
        try {
          let newItem: IAllocation
          if (item && item.id) {
            if (data.type !== 'project') {
              newItem = await allocationAPI.saveOther(data as IAllocation, controller)
            } else {
              newItem = await allocationAPI.save(data as IAllocation, controller)
            }
          } else {
            if (selectedScope && selectedUser) {
              if (data.type !== 'project') {
                newItem = await allocationAPI.createOther(
                  {
                    ...data,
                    personId: selectedUser.id,
                  },
                  controller
                )
              } else {
                newItem = await allocationAPI.create(
                  {
                    ...data,
                    personId: selectedUser.id,
                  },
                  controller
                )
              }
            } else {
              if (data.type !== 'project') {
                newItem = await allocationAPI.createOther(
                  {
                    ...data,
                    personId: personId,
                  },
                  controller
                )
              } else {
                newItem = await allocationAPI.create(
                  {
                    ...data,
                    personId: personId,
                  },
                  controller
                )
              }
            }
          }
          if (!isComponentMounted.current) return
          onClose({ newItem })
          setSuccessNotificationPopup()
        } catch (err) {
          setBackendError(err as IError)
          resetSubmit()
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [submitIndicator])

  useEffect(() => {
    if (data.type !== 'project') {
      setSchema(ownAllocationSchema())
    } else {
      setSchema(assignmentAllocationSchema())
    }
  }, [data.type])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <DialogContent sx={{ backgroundColor: 'white' }}>
      <DataContext.TranslationImport<IAllocation> />
      {!item && management && (
        <AllocationScopePicker
          value={selectedScope}
          onChange={scope => setSelectedScope(scope)}
          items={scopeOptions || []}
          label={t('allocation.networkScope')}
          required
          insetLabel
        />
      )}
      {!item && management && selectedScope && (
        <PersonPicker
          value={selectedUser ?? null}
          organizationId={
            user && selectedScope && selectedScope.type === 'own-company'
              ? (user.organizationId as OrganizationId)
              : selectedScope && selectedScope.type === 'company'
              ? (selectedScope.value as OrganizationId)
              : undefined
          }
          teamId={selectedScope && selectedScope.type === 'team' ? (selectedScope.value as TeamId) : undefined}
          networkId={selectedScope && selectedScope.type === 'network' ? (selectedScope.value as NetworkId) : undefined}
          onChange={newValue => {
            if (newValue) {
              setSelectedUser(newValue)
            }
          }}
          insetLabel
          required
        />
      )}
      {(!item || (item && !item.id)) && (
        <DataContext.TypeField<IAllocation>
          field="type"
          typeOptions={types}
          required
          disabled={!item && management && (!selectedUser || !selectedScope)}
          insetLabel
        />
      )}
      {data && data.type === 'project' && assignments.length > 0 && (
        <>
          <DataContext.AssignmentField<IAllocation>
            field="assignmentId"
            dataField="Assignment"
            assignmentOptions={assignments}
            required
            disabled={!item && management && (!selectedUser || !selectedScope)}
            insetLabel
          />
          {data.assignmentId && assignmentRoles && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={e => {
                      e.stopPropagation()
                      setShowNames(!showNames)
                    }}
                    checked={showNames}
                  />
                }
                label={t(`allocation.showAllocatedRoles`)}
                disabled={!item && management && (!selectedUser || !selectedScope)}
              />
              <DataContext.RoleField<IAllocation>
                field="roleId"
                dataField="AssignmentRole"
                assignmentRoles={assignmentRoles}
                showNames={showNames}
                required
                disabled={!item && management && (!selectedUser || !selectedScope)}
                insetLabel
              />
            </>
          )}
        </>
      )}
      <DataContext.StateField<IAllocation>
        field="state"
        stateOptions={states}
        required
        disabled={!item && management && (!selectedUser || !selectedScope)}
        insetLabel
      />
      <DataContext.NumberField<IAllocation>
        field="percent"
        required
        fullWidth
        disabled={!item && management && (!selectedUser || !selectedScope)}
        insetLabel
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DataContext.DateField<IAllocation>
            field="startDate"
            required
            dateResolution="day"
            start={true}
            disabled={!item && management && (!selectedUser || !selectedScope)}
            insetLabel
          />
        </Grid>
        <Grid item xs={6}>
          <DataContext.DateField<IAllocation>
            field="endDate"
            required
            dateResolution="day"
            end={true}
            minDate={data.startDate || null}
            disabled={!item && management && (!selectedUser || !selectedScope)}
            insetLabel
          />
        </Grid>
      </Grid>
      <DataContext.TextField<IAllocation>
        field="information"
        multiline
        grow
        rows={6}
        fullWidth
        disabled={!item && management && (!selectedUser || !selectedScope)}
        insetLabel
      />
    </DialogContent>
  )
}

export default newWithEditModal(AllocationModal)
