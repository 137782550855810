import { DialogContent, Grid } from '@mui/material'
import DataContext, { NewOrExisting } from 'Components/reusable/DataContext'
import React from 'react'
import {
  IPersonSkill,
  IProject,
  ISkill,
  IIndustry,
  IPersonIndustry,
  IRole,
  IPersonRole,
  IPersonSkillOrIndustryOrRole,
} from 'types/cvsInterfaces'
import { CvId } from 'types/ids'
import newWithEditModal from 'Components/reusable/HOC/newWithEditModal'
import SkillsItem from '../SkillsCard/SkillsItem'
import SkillCreate from './SkillCreate'
import IndustryCreate from './IndustryCreate'
import RoleCreate from './RoleCreate'

/** @notExported    */
interface IProjectModalProps {
  /** CV ID. */
  cvId: CvId
  /** Project object. */
  item: IProject | null
  /** withEditModal data object. */
  data: IProject
  /** Function to set withEditModal data object. */
  setData: (dataObject) => void
}

/**
 * Modal for adding new project.
 *
 * @param cvId -
 * @param item - Project object.
 * @param data - withEditModal data object.
 * @param setData - Function to set withEditModal data object.
 * @returns Project modal component.
 * @notExported
 */
const ProjectModal: React.FC<IProjectModalProps> = ({ cvId, item, data, setData }) => {
  const getDisabledSkillOptions = () => {
    const disabledOptions: ISkill[] = []
    data.PersonSkills?.map(personSkill => disabledOptions.push(personSkill.Skill))
    return disabledOptions
  }

  const getDisabledIndustryOptions = () => {
    const disabledOptions: IIndustry[] = []
    data.Industries?.map(industry => disabledOptions.push(industry.Skill))
    return disabledOptions
  }

  const getDisabledRoleOptions = () => {
    const disabledOptions: IRole[] = []
    data.ProjectRoles?.map(role => disabledOptions.push(role.Skill))
    return disabledOptions
  }

  const replaceOrAddSkill = ({ newItem }: { newItem?: NewOrExisting<IPersonSkill> }) => {
    setData({ ...data, PersonSkills: [...(data.PersonSkills ?? []), newItem as IPersonSkill] })
  }

  const replaceOrAddIndustry = ({ newItem }: { newItem?: NewOrExisting<IPersonIndustry> }) => {
    setData({ ...data, Industries: [...(data.Industries ?? []), newItem as IPersonIndustry] })
  }

  const replaceOrAddRole = ({ newItem }: { newItem?: NewOrExisting<IPersonRole> }) => {
    setData({ ...data, ProjectRoles: [...(data.ProjectRoles ?? []), newItem as IPersonRole] })
  }

  const deleteItem = (id: ISkill['id'], type: string) => {
    if (type === 'skill') {
      const newItems: NewOrExisting<IPersonSkill>[] = (data.PersonSkills ?? []).filter(item => item.skillId !== id)
      setData({ ...data, PersonSkills: newItems })
    } else if (type === 'industry') {
      const newItems: NewOrExisting<IPersonIndustry>[] = (data.Industries ?? []).filter(item => item.skillId !== id)
      setData({ ...data, Industries: newItems })
    } else if (type === 'role') {
      const newItems: NewOrExisting<IPersonRole>[] = (data.ProjectRoles ?? []).filter(item => item.skillId !== id)
      setData({ ...data, ProjectRoles: newItems })
    }
  }

  const checkArrays = (type: string) => {
    let filtered: IPersonSkillOrIndustryOrRole[] | undefined
    let items: IPersonSkillOrIndustryOrRole[] | undefined

    if (type === 'skill') {
      filtered = data.PersonSkills?.filter(personSkill => personSkill.id !== undefined)
      items = data.PersonSkills
    } else if (type === 'industry') {
      filtered = data.Industries?.filter(industry => industry.id !== undefined)
      items = data.Industries
    } else if (type === 'role') {
      filtered = data.ProjectRoles?.filter(role => role.id !== undefined)
      items = data.ProjectRoles
    }

    if (item) {
      return (
        <>
          {type === 'skill' && (
            <SkillCreate
              cvId={cvId}
              onAdd={newItem => {
                replaceOrAddSkill(newItem)
              }}
              usedSkills={getDisabledSkillOptions()}
              insetLabel
            />
          )}
          {type === 'industry' && (
            <IndustryCreate
              cvId={cvId}
              onAdd={newItem => {
                replaceOrAddIndustry(newItem)
              }}
              usedIndustries={getDisabledIndustryOptions()}
              insetLabel
            />
          )}
          {type === 'role' && (
            <RoleCreate
              cvId={cvId}
              onAdd={newItem => {
                replaceOrAddRole(newItem)
              }}
              label="profile.employer.modal.role"
              usedRoles={getDisabledRoleOptions()}
              insetLabel
            />
          )}
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ my: 2 }}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              {filtered?.map(skill => (
                <SkillsItem
                  key={skill.id}
                  skill={skill}
                  editable
                  onDeleteClick={() => {
                    deleteItem(skill.skillId, type)
                  }}
                  type={type}
                />
              ))}
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              {items?.map(skill => {
                if (skill.id) return

                return (
                  <SkillsItem
                    key={skill.skillId}
                    skill={skill}
                    editable
                    newChip={true}
                    onDeleteClick={() => {
                      deleteItem(skill.skillId, type)
                    }}
                    type={type}
                  />
                )
              })}
            </Grid>
          </Grid>
        </>
      )
    } else {
      return (
        <>
          {type === 'skill' && (
            <SkillCreate
              cvId={cvId}
              onAdd={newItem => {
                replaceOrAddSkill(newItem)
              }}
              usedSkills={getDisabledSkillOptions()}
              insetLabel
            />
          )}
          {type === 'industry' && (
            <IndustryCreate
              cvId={cvId}
              onAdd={newItem => {
                replaceOrAddIndustry(newItem)
              }}
              usedIndustries={getDisabledIndustryOptions()}
              insetLabel
            />
          )}
          {type === 'role' && (
            <RoleCreate
              cvId={cvId}
              onAdd={newItem => {
                replaceOrAddRole(newItem)
              }}
              usedRoles={getDisabledRoleOptions()}
              insetLabel
            />
          )}
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ my: 2 }}>
            {items?.map((skill, i) => (
              <SkillsItem
                key={skill.id || `new-${i}`}
                skill={skill}
                editable
                newChip={true}
                onDeleteClick={() => {
                  deleteItem(skill.skillId, type)
                }}
                type={type}
              />
            ))}
          </Grid>
        </>
      )
    }
  }

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item md={4} sm={6} xs={12}>
          <DataContext.TranslationImport<IProject> />
          <DataContext.TextField<IProject> field="name" required fullWidth insetLabel />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DataContext.DateResolutionField<IProject> field="dateResolution" required insetLabel />
            </Grid>
            <Grid item xs={6}>
              <DataContext.DateField<IProject> field="startDate" required start={true} insetLabel />
              <DataContext.DateField<IProject>
                field="endDate"
                isClearable
                end={true}
                minDate={data.startDate}
                insetLabel
              />
            </Grid>
          </Grid>
          <DataContext.TextField<IProject> insetLabel field="customerName" required fullWidth />
          <DataContext.TextField<IProject> insetLabel field="employerName" required fullWidth />
          <DataContext.TextField<IProject> field="primaryRole" fullWidth insetLabel />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <DataContext.TextField<IProject>
            field="description"
            maxHintLength={1500}
            required
            multiline
            grow
            rows={18}
            fullWidth
            insetLabel
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          {checkArrays('skill')}
          {checkArrays('industry')}
          {checkArrays('role')}
        </Grid>
      </Grid>
    </DialogContent>
  )
}

export default newWithEditModal(ProjectModal)
