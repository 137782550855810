import { Paper, useTheme } from '@mui/material'
import React from 'react'

/** @notExported  */
interface ModalPaperProps {
  /** border color. */
  borderColor?: string
  /** minimum height. */
  minHeight?: string
  /** minimum width. */
  minWidth?: boolean
  /** no outline. */
  noOutline?: boolean
  /** children. */
  children: React.ReactNode
}

/**
 * Modal paper component.
 *
 * @returns Modal paper element.
 * @notExported
 */
const ModalPaper: React.FC<ModalPaperProps> = ({ borderColor, minHeight, minWidth, noOutline, children }) => {
  const theme = useTheme()

  const checkHeight = () => {
    if (minHeight === 'lg') {
      return {
        minHeight: '520px',
      }
    } else if (minHeight === 'md') {
      return {
        minHeight: '360px',
      }
    }
  }

  const checkWidth = () => {
    if (minWidth === true) {
      return {
        minWidth: '360px',
      }
    }
  }

  const checkBorder = () => {
    if (borderColor) {
      switch (borderColor) {
        case 'red':
          return {
            borderColor: theme.palette.error.main,
          }

        default:
          return {}
      }
    }
  }

  return (
    <Paper
      elevation={noOutline ? 0 : 2}
      variant={borderColor ? 'outlined' : 'elevation'}
      sx={Object.assign(
        {
          padding: '18px',
          [theme.breakpoints.down('sm')]: {
            padding: '10px',
          },
          alignItems: 'center',
          background: theme.palette.background.default,
          borderRadius: '10px',
          '&:not(:first-child)': {
            marginTop: '1em',
          },
          '& > *:not(:first-child)': {
            marginTop: '1em',
          },
          [theme.breakpoints.down('sm')]: {
            padding: '5px',
            borderRadius: '2px',
          },
        },
        checkBorder(),
        checkHeight(),
        checkWidth()
      )}
    >
      {children}
    </Paper>
  )
}

export default ModalPaper
