import { Box } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { organizationAPI } from 'api/OrganizationAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import { useIsComponentMounted } from 'hooks/util'
import React, { useEffect, useState } from 'react'
import { IError } from 'types/error'
import { OrganizationId } from 'types/ids'
import { ISite } from 'types/userInterfaces'
import { fuseFiltering } from 'utils/utils'
import { InputField } from '../InputField'

/** @notExported  */
interface ISitePickerProps {
  /** The current value. */
  value: ISite | null
  /** The organization ID. */
  organizationId?: OrganizationId
  /** Action to do when the value changes. */
  onChange: (newValue: ISite | null) => void
  /** The label. */
  label?: string
  /** Whether the field is required. */
  required?: boolean
  /** Whether the field is disabled. */
  disabled?: boolean
  /** Whether the label should be inset. */
  insetLabel?: boolean
}

/**
 * Site picker component.
 *
 * @returns The site picker component.
 * @notExported
 */
const SitePicker: React.FC<ISitePickerProps> = ({
  value,
  organizationId,
  onChange,
  label = 'Site',
  required = false,
  disabled = false,
  insetLabel,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const [items, setItems] = useState<ISite[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [backendError, setBackendError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        setLoading(true)
        if (organizationId) {
          const results = await organizationAPI.getOrganizationSites(organizationId, controller)
          if (!isComponentMounted.current) return
          setItems(results)
        }
        setLoading(false)
      } catch (error) {
        setBackendError(error as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [organizationId])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <>
      {!insetLabel && label && <CaleoInputLabel label={label} required={required} />}
      <Autocomplete
        loading={loading}
        disabled={disabled}
        value={value}
        style={{ marginTop: '0px' }}
        onChange={(_event, newValue) => {
          if (typeof newValue !== 'string') {
            onChange(newValue)
          }
        }}
        filterOptions={(options, { inputValue }) =>
          fuseFiltering(options, inputValue, ['city', 'streetAddress', 'postalCode'])
        }
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={items}
        getOptionLabel={option => `${option.streetAddress}, ${option.postalCode} ${option.city}`}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id
        }}
        renderOption={(props, option) => {
          return (
            <Box component="li" {...props} key={option.id}>
              {`${option.streetAddress}, ${option.postalCode} ${option.city}`}
            </Box>
          )
        }}
        renderInput={params => (
          <InputField
            {...params}
            insetLabel={insetLabel}
            label={insetLabel ? label : undefined}
            InputLabelProps={{ shrink: insetLabel ? true : undefined }}
            fullWidth
            size="small"
            margin="dense"
            variant="outlined"
            required={required}
          />
        )}
        fullWidth
      />
    </>
  )
}

export default SitePicker
