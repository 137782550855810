import { Button } from '@mui/material'
import React from 'react'

/** @notExported */
interface CaleoGreenButtonProps {
  /** Action to perform when the button is clicked. */
  clickAction: (e: React.MouseEvent<HTMLButtonElement>) => void
  /** Whether the button is valid. */
  valid
  /** Label for the button. */
  label
}

/**
 * Caleo green button.
 *
 * @returns Caleo green button component.
 * @notExported
 * @category Buttons
 */
const CaleoGreenButton: React.FC<CaleoGreenButtonProps> = ({ clickAction, valid, label }) => {
  return (
    <Button
      id="search"
      type="button"
      variant="contained"
      disabled={!valid}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => clickAction(e)}
      color="success"
    >
      {label}
    </Button>
  )
}

export default CaleoGreenButton
