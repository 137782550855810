import React from 'react'
import { Accept, useDropzone, DropEvent, FileRejection } from 'react-dropzone'
import UserIcon from '@mui/icons-material/Person'
import { Grid, useTheme } from '@mui/material'

/** @notExported */
interface ImageDropzoneProps {
  /** Function to be called when a file is dropped. */
  onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
  /** File type(s) to accept. */
  accept: Accept
  /** Whether to allow multiple files to be dropped. */
  multiple?: boolean | undefined
  /** Old image name. */
  oldImage?: string
  /** Drag hint text. */
  dragHint: string
  /** Drop hint text. */
  dropHint: string
  /** Whether to disable the dropzone. */
  disabled?: boolean
}

/**
 * Image dropzone component.
 *
 * @returns The image dropzone component.
 */
export const ImageDropzone: React.FC<ImageDropzoneProps> = ({
  onDrop,
  accept,
  multiple,
  oldImage,
  dragHint,
  dropHint,
  disabled = false,
}) => {
  const theme = useTheme()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  })

  return (
    <Grid
      sx={{
        cursor: 'pointer',
        fontFamily: 'Helvetica',
        height: '218px',
        borderRadius: '5px 5px 0 0',
        border: '1px solid #D8D8D8',
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} disabled={disabled} />
      <Grid
        sx={{
          display: 'flex',
          height: 'calc(100% - 30px)',
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          justifyContent: 'center',
          backgroundImage: oldImage ? `url(${oldImage})` : undefined,
        }}
      >
        {!oldImage && (
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <UserIcon sx={{ fontSize: 110 }} />
          </Grid>
        )}
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          width: '100%',
          height: '30px',
          backgroundColor: theme.palette.warning.main,
          borderRadius: '0 0 5px 5px',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '25px',
        }}
      >
        {isDragActive ? (
          <p style={{ fontSize: '14px', fontWeight: 'bold', color: theme.palette.text.primary }}>{dropHint}</p>
        ) : (
          <p style={{ fontSize: '14px', fontWeight: 'bold', color: theme.palette.text.primary }}>{dragHint}</p>
        )}
      </Grid>
    </Grid>
  )
}
