import { Button, CircularProgress, SxProps, Theme } from '@mui/material'
import React from 'react'

/** @notExported */
interface CaleoPrimaryButtonProps {
  /** the action to perform when the button is clicked. */
  clickAction: (e: React.MouseEvent<HTMLButtonElement>) => void
  /** if the button should be disabled or not. */
  valid: boolean
  /** the label to display on the button. */
  label
  /** id of the button. */
  id?
  /** if the button is loading. */
  loading?: boolean
  /** the class name for the button. */
  className?: string
  /** the sx prop for the button. */
  sx?: SxProps<Theme> | undefined
  /** the size of the button. */
  size?: 'small' | 'medium'
}

/**
 * Caleo primary button.
 *
 * @returns Caleo primary button component.
 * @notExported
 * @category Buttons
 */
const CaleoPrimaryButton: React.FC<CaleoPrimaryButtonProps> = ({
  clickAction,
  valid,
  label,
  id,
  loading,
  sx,
  size,
}) => {
  return (
    <Button
      id={id}
      type="button"
      variant="contained"
      disabled={!valid}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => clickAction(e)}
      color="primary"
      sx={sx}
      size={size}
    >
      {loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : label}
    </Button>
  )
}

export default CaleoPrimaryButton
