import { Button } from '@mui/material'
import { CountriesData, LanguagesData } from 'api/UtilsAPI'
import colors from 'constants/colors'
import { AlignmentType, BorderStyle, Document, Footer, Packer, PageNumber, Paragraph, Table, TextRun } from 'docx'
import { saveAs } from 'file-saver'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ICertificate,
  ICourse,
  ICvTranslation,
  IEducation,
  IEmployer,
  ILanguageSkill,
  IPersonRole,
  IPersonIndustry,
  IPersonSkill,
  IProject,
  IReference,
  IUrl,
} from 'types/cvsInterfaces'
import { IItemVisibility, ILayout, IOrganizationColors } from 'types/layoutInterfaces'
import { IOrganization } from 'types/userInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import About from './About'
import Education from './Education'
import Employment from './Employment'
import Language from './Language'
import Project from './Project'
import Reference from './Reference'
import Skill from './Skill'
import Top from './Top'
import Url from './Url'
import defaultImg from '../PDFrender/default.jpg'

export interface CVData {
  about: {
    cvId: number
    firstName: string
    lastName: string
    telephone: string
    email: string
    streetAddress: string
    postalCode: string
    city: string
    country: string
    Organization: IOrganization
    translations: ICvTranslation[]
    hidden?: boolean
  }
  degrees: IEducation[]
  courses: ICourse[]
  certificates: ICertificate[]
  skills: IPersonSkill[]
  industries: IPersonIndustry[]
  roles: IPersonRole[]
  employments: IEmployer[]
  projects: IProject[]
  references: IReference[]
  languages: ILanguageSkill[]
  urls: IUrl[]
}

/** @notExported */
interface IDocxRenderProps {
  /** CV data */
  data: CVData
  /** Layout */
  layout: ILayout
  /** Countries data */
  countries: CountriesData
  /** Profile image */
  profileImage: string
  /** Organization logo */
  orgLogo: string
  /** Organization colors */
  orgColors: IOrganizationColors
  /** Languages data */
  languages: LanguagesData
}

/**
 * DOCX render component.
 *
 * @returns DOCX render component.
 * @notExported
 */
const DOCXrender: React.FC<IDocxRenderProps> = ({
  data,
  layout,
  countries,
  profileImage,
  orgLogo,
  orgColors,
  languages,
}) => {
  const { t, i18n } = useTranslation()

  const createHeader = (title: string) => {
    return new Paragraph({
      text: t(title),
      style: 'CaleoHeadings1',
      border: {
        bottom: {
          style: BorderStyle.SINGLE,
          color: '#000000',
          space: 1,
          size: 15,
        },
      },
    })
  }

  const handleLayout = async () => {
    // filter visibilities according to item type
    let degreeVisibilities: IItemVisibility[] = []
    let courseVisibilities: IItemVisibility[] = []
    let certificateVisibilities: IItemVisibility[] = []
    let skillVisibilities: IItemVisibility[] = []
    let industryVisibilities: IItemVisibility[] = []
    let roleVisibilities: IItemVisibility[] = []
    let employmentVisibilities: IItemVisibility[] = []
    let projectVisibilities: IItemVisibility[] = []
    let referenceVisibilities: IItemVisibility[] = []
    let languageVisibilities: IItemVisibility[] = []
    let urlVisibilities: IItemVisibility[] = []

    const aboutVisibility: IItemVisibility | undefined = layout.ItemVisibilities.find(
      (visibility: IItemVisibility) => visibility.type === 'about'
    )
    degreeVisibilities = layout.ItemVisibilities.filter((visibility: IItemVisibility) => visibility.type === 'degrees')
    courseVisibilities = layout.ItemVisibilities.filter((visibility: IItemVisibility) => visibility.type === 'courses')
    certificateVisibilities = layout.ItemVisibilities.filter(
      (visibility: IItemVisibility) => visibility.type === 'certificates'
    )
    skillVisibilities = layout.ItemVisibilities.filter((visibility: IItemVisibility) => visibility.type === 'skills')
    industryVisibilities = layout.ItemVisibilities.filter(
      (visibility: IItemVisibility) => visibility.type === 'industries'
    )
    roleVisibilities = layout.ItemVisibilities.filter((visibility: IItemVisibility) => visibility.type === 'roles')
    employmentVisibilities = layout.ItemVisibilities.filter(
      (visibility: IItemVisibility) => visibility.type === 'employments'
    )
    projectVisibilities = layout.ItemVisibilities.filter(
      (visibility: IItemVisibility) => visibility.type === 'projects'
    )
    referenceVisibilities = layout.ItemVisibilities.filter(
      (visibility: IItemVisibility) => visibility.type === 'references'
    )
    languageVisibilities = layout.ItemVisibilities.filter(
      (visibility: IItemVisibility) => visibility.type === 'languages'
    )
    urlVisibilities = layout.ItemVisibilities.filter((visibility: IItemVisibility) => visibility.type === 'urls')

    // filter hidden data according to layout
    let about = {
      ...data.about,
      hidden: true,
    }
    if (!aboutVisibility || (aboutVisibility && aboutVisibility.hidden === false)) {
      about = { ...data.about, hidden: false }
    }

    let degrees: IEducation[] = []
    degrees = data.degrees.filter(degree => {
      const visibility = degreeVisibilities.find(
        visibility => visibility.itemId === degree.id && visibility.hidden === true
      )
      if (!visibility) {
        return degree
      }
    })

    let courses: ICourse[] = []
    courses = data.courses.filter(course => {
      const visibility = courseVisibilities.find(
        visibility => visibility.itemId === course.id && visibility.hidden === true
      )
      if (!visibility) {
        return course
      }
    })

    let certificates: ICertificate[] = []
    certificates = data.certificates.filter(certificate => {
      const visibility = certificateVisibilities.find(
        visibility => visibility.itemId === certificate.id && visibility.hidden === true
      )
      if (!visibility) {
        return certificate
      }
    })

    let skills: IPersonSkill[] = []
    skills = data.skills.filter(skill => {
      const visibility = skillVisibilities.find(
        visibility => visibility.itemId === skill.id && visibility.hidden === true
      )
      if (!visibility) {
        return skill
      }
    })

    let industries: IPersonIndustry[] = []
    industries = data.industries.filter(industry => {
      const visibility = industryVisibilities.find(
        visibility => visibility.itemId === industry.id && visibility.hidden === true
      )
      if (!visibility) {
        return industry
      }
    })

    let roles: IPersonRole[] = []
    roles = data.roles.filter(role => {
      const visibility = roleVisibilities.find(
        visibility => visibility.itemId === role.id && visibility.hidden === true
      )
      if (!visibility) {
        return role
      }
    })

    let employments: IEmployer[] = []
    employments = data.employments.filter(employment => {
      const visibility = employmentVisibilities.find(
        visibility => visibility.itemId === employment.id && visibility.hidden === true
      )
      if (!visibility) {
        return employment
      }
    })

    let projects: IProject[] = []
    projects = data.projects.filter(project => {
      const visibility = projectVisibilities.find(
        visibility => visibility.itemId === project.id && visibility.hidden === true
      )
      if (!visibility) {
        return project
      }
    })

    let references: IReference[] = []
    references = data.references.filter(reference => {
      const visibility = referenceVisibilities.find(
        visibility => visibility.itemId === reference.id && visibility.hidden === true
      )
      if (!visibility) {
        return reference
      }
    })

    let languages: ILanguageSkill[] = []
    languages = data.languages.filter(language => {
      const visibility = languageVisibilities.find(
        visibility => visibility.itemId === language.id && visibility.hidden === true
      )
      if (!visibility) {
        return language
      }
    })

    let urls: IUrl[] = []
    urls = data.urls.filter(url => {
      const visibility = urlVisibilities.find(visibility => visibility.itemId === url.id && visibility.hidden === true)
      if (!visibility) {
        return url
      }
    })

    return {
      about,
      degrees,
      courses,
      certificates,
      skills,
      industries,
      roles,
      employments,
      projects,
      references,
      languages,
      urls,
    }
  }

  const generate = async () => {
    const cvData = await handleLayout()
    const cvParts: (Table | Paragraph)[] = []

    let image, logo

    if (profileImage) {
      image = await fetch(profileImage)
        .then(res => res.blob())
        .catch(console.error)
    }
    if (orgLogo) {
      logo = await fetch(orgLogo)
        .then(res => res.blob())
        .catch(console.error)
    }

    const tempImg = await fetch(defaultImg)
      .then(res => res.blob())
      .catch(console.error)

    cvParts.push(Top(i18n, data, countries, image, logo, orgColors, layout, tempImg))
    if (chooseDBTranslation(i18n, cvData.about).about && cvData.about.hidden !== true) {
      cvParts.push(createHeader('print.about.title'), About(i18n, cvData))
    }
    if (cvData.skills.length) {
      cvParts.push(createHeader('print.skills.title'), Skill(i18n, cvData, 'skills', t, layout.hideLastUsed))
    }
    if (cvData.industries.length) {
      cvParts.push(createHeader('print.industries.title'), Skill(i18n, cvData, 'industries', t, layout.hideLastUsed))
    }
    if (cvData.roles.length) {
      cvParts.push(createHeader('print.roles.title'), Skill(i18n, cvData, 'roles', t, layout.hideLastUsed))
    }
    if (cvData.employments.length) {
      cvParts.push(createHeader('print.employments.title'), ...Employment(i18n, cvData, t))
    }
    if (cvData.projects.length) {
      cvParts.push(createHeader('print.projects.title'), ...Project(i18n, cvData, t))
    }
    if (cvData.degrees.length) {
      cvParts.push(
        createHeader('print.degrees.title'),
        ...Education(i18n, cvData, 'degrees', layout.showCertificateExpiry, t)
      )
    }
    if (cvData.courses.length) {
      cvParts.push(
        createHeader('print.courses.title'),
        ...Education(i18n, cvData, 'courses', layout.showCertificateExpiry, t)
      )
    }
    if (cvData.certificates.length) {
      cvParts.push(
        createHeader('print.certificates.title'),
        ...Education(i18n, cvData, 'certificates', layout.showCertificateExpiry, t)
      )
    }
    if (cvData.references.length) {
      cvParts.push(createHeader('print.reference.title'), ...Reference(i18n, cvData, t))
    }
    if (cvData.languages.length) {
      cvParts.push(createHeader('print.languages.title'), Language(cvData, languages, t))
    }
    if (cvData.urls.length) {
      cvParts.push(createHeader('print.links.title'), ...Url(i18n, cvData))
    }

    const doc = new Document({
      creator: 'Caleo',
      styles: {
        paragraphStyles: [
          {
            id: 'CaleoHeadings1',
            name: 'Caleo Heading 1',
            basedOn: 'Normal',
            next: 'CaleoNormal',
            quickFormat: true,
            run: {
              size: 32,
              color: orgColors && orgColors.accentColor ? orgColors.accentColor : colors.primary,
              font: {
                name: 'Raleway',
              },
              bold: true,
              allCaps: true,
            },
            paragraph: {
              spacing: {
                after: 100,
                before: 400,
              },
            },
          },
          {
            id: 'GrayText',
            name: 'Gray Text',
            basedOn: 'Normal',
            next: 'CaleoNormal',
            quickFormat: true,
            run: {
              color: colors.borders,
              font: {
                name: 'Raleway',
              },
            },
            paragraph: {
              spacing: {
                before: 200,
                after: 200,
              },
            },
          },
          {
            id: 'CaleoNormal',
            name: 'Caleo Normal',
            basedOn: 'Normal',
            next: 'CaleoNormal',
            quickFormat: true,
            run: {
              font: {
                name: 'Raleway',
              },
            },
          },
        ],
      },
      sections: [
        {
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      children: [PageNumber.CURRENT],
                      font: {
                        name: 'Raleway',
                      },
                    }),
                  ],
                }),
              ],
            }),
          },
          children: cvParts,
        },
      ],
    })

    Packer.toBlob(doc).then(blob => {
      if (data.about.firstName && data.about.lastName) {
        const filename = `${
          chooseDBTranslation(i18n, data?.about.Organization)?.name ?? 'freelancer'
        }_CV_${data?.about.firstName.trim()}_${data?.about.lastName.trim()}_${new Date(
          Date.now()
        ).toLocaleDateString()}.docx`

        saveAs(blob, filename)
      }
    })
  }

  return (
    // button to create docx file
    <Button onClick={generate}>{t('print.downloadDocx')}</Button>
  )
}

export default DOCXrender
