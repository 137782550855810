import React from 'react'
import { useTranslation } from 'react-i18next'
import { ICertificate, ICourse, IEducation } from 'types/cvsInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'
import withProfileCardItem from 'Components/reusable/HOC/withProfileCardItem'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import ExtendedCardContent from 'Components/reusable/CaleoCustomComponents/ExtendedCardContent'
import { Grid, Link } from '@mui/material'

/** @notExported  */
interface IEducationListItemProps {
  /** Item title. */
  title: string
  /** Education item. */
  education?: IEducation
  /** Course item. */
  course?: ICourse
  /** Certificate item. */
  certificate?: ICertificate
  /** Is item anonymous. */
  anonymous: boolean
}

/**
 * Education list item.
 *
 * @returns Education list item component.
 * @notExported
 */
const EducationListItem: React.FC<IEducationListItemProps> = ({ title, education, course, certificate, anonymous }) => {
  const { i18n } = useTranslation()

  let name = ''
  let date = ''
  let issuer = ''
  let description = ''
  let url = ''

  if (title === 'degrees' && education) {
    const translation = chooseDBTranslation(i18n, education)
    issuer = translation.school
    name = translation.degree
    const startDate = convertToDisplayDate(education.startDate, education.dateResolution)
    const endDate = education.endDate ? convertToDisplayDate(education.endDate, education.dateResolution) : null
    date = endDate ? `${startDate} -  ${endDate}` : `${startDate} -`
    description = !anonymous ? translation.description : ''
  } else if (title === 'courses' && course) {
    const translation = chooseDBTranslation(i18n, course)
    name = translation.course
    const startDate = convertToDisplayDate(course.startDate, course.dateResolution)
    const endDate = course.endDate ? convertToDisplayDate(course.endDate, course.dateResolution) : null
    date = endDate ? `${startDate} -  ${endDate}` : `${startDate} -`
    issuer = translation.organizer
    description = !anonymous ? translation.description : ''
  } else if (title === 'certificates' && certificate) {
    const translation = chooseDBTranslation(i18n, certificate)
    issuer = translation.certifier
    const startDate = convertToDisplayDate(certificate.issueDate, certificate.dateResolution)
    const endDate = certificate.endDate ? convertToDisplayDate(certificate.endDate, certificate.dateResolution) : null
    date = endDate ? `${startDate} -  ${endDate}` : `${startDate} -`
    name = translation.certificate
    description = !anonymous ? translation.description : ''
    url = !anonymous ? certificate.url : ''
  }

  const getContent = () => {
    return (
      <>
        <CardContentText type={'header'}>{name}</CardContentText>
        <CardContentText type={'date'}>{date}</CardContentText>
        <Grid>
          <CardContentText>{issuer}</CardContentText>
          <CardContentText>
            <Link target="_blank" rel="noopener" href={url}>
              {url}
            </Link>
          </CardContentText>
        </Grid>
      </>
    )
  }

  return <ExtendedCardContent description={description}>{getContent()}</ExtendedCardContent>
}

export default withProfileCardItem(EducationListItem)
