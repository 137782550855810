import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import StarIcon from '@mui/icons-material/Star'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import { Box, Chip, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IAssignmentRoleSkillRequirement } from 'types/assignmentInterfaces'
import { IPersonSkillOrIndustryOrRole } from 'types/cvsInterfaces'
import { IAnonymousPersonSkill } from 'types/searchInterfaces'
import { useDBTranslation } from 'utils/translations'
import { getSkillExperienceLabel, skillInterestLevels, skillLevels } from 'utils/utils'
import { CustomTooltip } from 'Components/reusable/CaleoCustomComponents/CustomTooltip'
import SkillLevelChip from 'Components/reusable/CaleoCustomComponents/SkillLevelChip'

/** @notExported */
interface ISkillItemProps {
  /** Skill to be displayed. */
  skill: IPersonSkillOrIndustryOrRole | IAssignmentRoleSkillRequirement | IAnonymousPersonSkill
  /** Whether skill is editable. */
  editable: boolean
  /** Function to be called when skill is clicked. */
  onClick?: () => void
  /** Function to be called when delete button is clicked. */
  onDeleteClick?: () => void
  /** Whether experience is shown. */
  showExperience?: boolean
  /** Whether last used is shown. */
  showLastUsed?: boolean
  /** Whether tooltip is shown. */
  showTooltip?: boolean
  /** Whether this is new chip. */
  newChip?: boolean
  /** Whether this is assignment skill. */
  assignmentSkill?: boolean
  /** Type of skill. */
  type: string
  /** Whether shadow is shown. */
  showShadow?: boolean
  /** Whether interest is hidden. */
  hideInterest?: boolean
}

/**
 * Skill item card.
 *
 * @returns Component for displaying skill details.
 * @notExported
 */
const SkillsItem: React.FC<ISkillItemProps> = ({
  skill,
  editable,
  onDeleteClick,
  onClick,
  showExperience,
  showLastUsed,
  showTooltip,
  newChip,
  assignmentSkill,
  type,
  showShadow = false,
  hideInterest = false,
}) => {
  const { t } = useTranslation()

  const personSkill = skill as IPersonSkillOrIndustryOrRole
  const levels = skillLevels()
  const interestLevels = skillInterestLevels()

  const thumbs = Array.from(Array(personSkill?.interestLevel), (x, i) => (
    <ThumbUpIcon
      key={i}
      fontSize="small"
      color="secondary"
      style={{ marginTop: '8px', position: 'relative', top: '3px', marginRight: '4px' }}
    />
  ))

  const getIconsAndValues = () => {
    return (
      <Grid sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
        {assignmentSkill && skill && skill.experienceMonths == 0 ? null : (
          <Grid sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
            <Grid sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
              <CalendarTodayIcon sx={{ marginLeft: '2px', marginRight: '2px', fontSize: '14px' }} />
            </Grid>
            <Grid
              sx={{
                fontSize: '13px',
                fontFamily: 'Helvetica',
                letterSpacing: '0px',
                display: 'inline',
                whiteSpace: 'nowrap',
              }}
            >
              {getSkillExperienceLabel(skill.experienceMonths, true)}
            </Grid>
          </Grid>
        )}
        <Grid sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
          <StarIcon sx={{ marginLeft: '2px', marginRight: '2px', fontSize: '16px' }} />
        </Grid>
        <Grid
          sx={{
            fontSize: '13px',
            fontFamily: 'Helvetica',
            letterSpacing: '0px',
            display: 'inline',
            whiteSpace: 'nowrap',
          }}
        >
          {skill?.level}
        </Grid>
        <Grid sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
          {showLastUsed && (
            <Grid sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
              {personSkill?.interestLevel && !hideInterest && (
                <Grid sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                  <Grid sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <ThumbUpIcon sx={{ marginLeft: '2px', marginRight: '2px', fontSize: '14px' }} />
                  </Grid>
                  <Grid
                    sx={{
                      fontSize: '13px',
                      fontFamily: 'Helvetica',
                      letterSpacing: '0px',
                      display: 'inline',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {personSkill?.interestLevel}
                  </Grid>
                </Grid>
              )}
              <Grid sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                <EventAvailableIcon sx={{ marginLeft: '2px', marginRight: '2px', fontSize: '16px' }} />
              </Grid>
              <Grid
                sx={{
                  fontSize: '13px',
                  fontFamily: 'Helvetica',
                  letterSpacing: '0px',
                  display: 'inline',
                  whiteSpace: 'nowrap',
                }}
              >
                {personSkill?.lastUsed}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }

  const getExperienceYears = () => {
    if (skill.experienceMonths != 0) {
      return <b>{getSkillExperienceLabel(skill.experienceMonths)}</b>
    } else {
      return (
        <Box sx={{ color: theme => theme.palette.error.main, fontStyle: 'italic' }}>
          {t(`profile.${type}.unspecified`)}
        </Box>
      )
    }
  }

  const getLevel = () => {
    return (
      <>
        {t(`profile.${type}.level`)}:
        <SkillLevelChip level={skill?.level ?? 0} type="skill" />
        <Typography style={{ paddingBottom: '4px', fontSize: '14px' }}>
          {levels.find(l => l.level === skill?.level)?.description}
        </Typography>
      </>
    )
  }

  const getIntrest = () => {
    if (personSkill?.interestLevel) {
      return (
        <>
          <Divider />
          <Typography>
            {t(`profile.${type}.interest`)}: {thumbs}
          </Typography>
          <Typography style={{ paddingBottom: '4px', fontSize: '14px' }}>
            {interestLevels.find(l => l.level === personSkill?.interestLevel)?.label}
          </Typography>
        </>
      )
    } else {
      return null
    }
  }

  const getChip = () => {
    return (
      <Chip
        onDelete={editable ? onDeleteClick : undefined}
        onClick={editable ? onClick : undefined}
        icon={
          <Grid
            sx={{
              fontSize: '13px',
              fontFamily: 'Helvetica',
              letterSpacing: '0px',
              paddingLeft: '5px',
              maxWidth: '170px',
            }}
            item
            textOverflow="ellipsis"
            overflow="hidden"
            my={2}
          >
            {translation?.name ?? 'No name'}
          </Grid>
        }
        label={showExperience && getIconsAndValues()}
        color={newChip ? 'warning' : 'secondary'}
        variant={newChip ? 'filled' : 'outlined'}
        sx={{
          borderRadius: '5px',
          height: '27px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '4px',
          float: 'left',
          ...(showShadow && {
            boxShadow: theme =>
              `0 2px 4px 0 ${theme.palette.secondary.light}, 4px 4px 5px 0 ${theme.palette.success.main}`,
          }),
        }}
      />
    )
  }

  const showExperienceMonths = () => {
    return (
      <>
        <Grid container alignItems="center" spacing={1} style={{ marginTop: 1 }}>
          <Grid item>
            <CalendarTodayIcon fontSize="small" />
          </Grid>
          <Grid item>
            <Typography>
              {t(`profile.${type}.experience`)}: {getExperienceYears()}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </>
    )
  }

  const translation = useDBTranslation(skill ? ('Skill' in skill ? skill.Skill : skill) : null)

  const showName = () => {
    if (showTooltip) {
      return (
        <>
          <Typography fontWeight="bold">{translation?.name}</Typography>
          <Divider />
        </>
      )
    } else {
      return <Typography fontSize="14px">{translation?.name}</Typography>
    }
  }

  return (
    <Grid>
      {showTooltip ? (
        <CustomTooltip
          placement="top"
          title={
            <>
              {showName()}
              {showExperienceMonths()}
              {getLevel()}
              {showLastUsed && (
                <>
                  {getIntrest()}
                  <>
                    <Divider />
                    <Grid container alignItems="center" spacing={1} style={{ marginTop: 1 }}>
                      <Grid item>
                        <EventAvailableIcon />
                      </Grid>
                      <Grid item>
                        <Typography>
                          {t(`profile.${type}.lastUsed`)}: <b>{personSkill?.lastUsed ?? null}</b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                </>
              )}
            </>
          }
        >
          {getChip()}
        </CustomTooltip>
      ) : (
        <>
          {translation && translation.name.length > 26 ? (
            <CustomTooltip placement="top" title={<>{showName()}</>}>
              <>{getChip()}</>
            </CustomTooltip>
          ) : (
            getChip()
          )}
        </>
      )}
    </Grid>
  )
}

export default SkillsItem
