import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPersonSkill } from 'types/cvsInterfaces'
import { CvId } from 'types/ids'
import * as Yup from 'yup'
import DataContext, { NewOrExisting } from 'Components/reusable/DataContext'
import LoadingIndicator from 'Components/reusable/LoadingIndicator'

/**
 * YUP schema for skills.
 * @notExported
 */
export const skillSchema = t =>
  Yup.object({
    skillId: Yup.number().required(t('fieldRequired')),
  })

/**@notExported */
interface ISkillCreateProps {
  /** CV ID. */
  cvId: CvId
  /** Function to add skill. */
  onAdd: ({ newItem }: { newItem?: NewOrExisting<IPersonSkill> }) => void
  /** Already added skills. */
  usedSkills?
  /** Whether to inset label. */
  insetLabel?: boolean
}

/**
 * Controls for adding skill to project.
 *
 * @returns Skill create component.
 * @notExported
 */
const SkillCreate: React.FC<ISkillCreateProps> = ({ cvId, onAdd, usedSkills, insetLabel }) => {
  const [data, setData] = useState<NewOrExisting<IPersonSkill> | null>(null)
  const { t } = useTranslation()

  useEffect(() => {
    setData({
      cvId,
      skillId: null as unknown,
    } as NewOrExisting<IPersonSkill>)
  }, [onAdd])

  if (!data) {
    return <LoadingIndicator />
  }

  const add = async () => {
    if (data && data.Skill) onAdd({ newItem: data })
  }

  return (
    <DataContext
      data={data}
      onSubmit={add}
      localeBase="assignment.role.requirement.skill"
      schema={skillSchema(t)}
      onChange={newItem => {
        if (newItem && newItem.Skill) {
          onAdd({ newItem })
        }
      }}
    >
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <DataContext.SkillField<IPersonSkill>
            field="skillId"
            usedSkills={usedSkills}
            dataField="Skill"
            fullWidth
            type="skill"
            placeholder={t('profile.project.modal.addskill.placeholder')}
            submitOnEnter={true}
            insetLabel={insetLabel}
          />
        </Grid>
      </Grid>
    </DataContext>
  )
}

export default SkillCreate
