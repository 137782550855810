import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import React from 'react'

/** @notExported */
interface ILoadingIndicatorProps {
  /** The value of the loading indicator. */
  value: number
  /** The action to perform when the loading indicator is clicked. */
  action?: (open: boolean) => void
  /** The tooltip text. */
  tooltip: string
}

/**
 * The LoadingIndicator component.
 *
 * @param value - The value of the loading indicator.
 * @param action - The action to perform when the loading indicator is clicked.
 * @param tooltip - The tooltip text.
 * @returns The LoadingIndicator component.
 * @notExported
 */
const LoadingIndicator: React.FC<ILoadingIndicatorProps> = ({ value, action, tooltip }) => {
  return (
    <Tooltip title={String(tooltip)}>
      <IconButton
        onClick={() => {
          if (action) action(true)
        }}
        style={{ cursor: 'pointer' }}
        size="large"
      >
        <CircularProgress variant="determinate" value={value} color="secondary" size={24} />
      </IconButton>
    </Tooltip>
  )
}

export default LoadingIndicator
