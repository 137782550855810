import { SubAPI } from './API'
import { IPerson } from 'types/userInterfaces'
import { IContactInformation, ICv } from 'types/cvsInterfaces'
import { CvId, OrganizationId } from 'types/ids'

/**
 * Person API
 * @notExported
 */
class PersonAPI extends SubAPI {
  /**
   * Get person data by id.
   *
   * @param id - Person ID.
   * @returns Person data.
   */
  public getPersonData(id: number, controller?: AbortController): Promise<IPerson> {
    return this.api.get(`persons/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Create placeholder person.
   *
   * @param data - Person data.
   * @returns Created person.
   */
  public async createPlaceholder(data: Partial<IPerson>, controller?: AbortController): Promise<IPerson> {
    return this.api.post(`persons`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get profile image by id.
   *
   * @param id - Person ID.
   * @returns Profile image URL.
   */
  public getProfileImage(id: number, controller?: AbortController): Promise<string> {
    return this.api.get(`files/profile/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Set profile image.
   *
   * @param data - Image data as FormData object.
   * @returns Promise of profile image URL.
   */
  public setProfileImage(data: FormData, controller?: AbortController): Promise<void> {
    return this.api.post(`files/profile`, data, controller ? { signal: controller.signal } : undefined, {
      'Content-Type': 'multipart/form-data',
    })
  }

  /**
   * Delete profile image.
   *
   * @param personId - Person ID.
   * @returns Promise of profile image deletion.
   */
  public deleteProfileImage(personId: number, controller?: AbortController): Promise<void> {
    return this.api.delete(`files/profile/${personId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Create a copy of CV.
   *
   * @param id - CV ID.
   * @param data - CV data.
   * @returns Created CV copy.
   */
  public create(id: number, data: unknown): Promise<ICv> {
    return this.api.post(`persons/copy/${id}`, data)
  }

  /**
   * Update CV copy.
   *
   * @param item - CV copy data.
   * @param data - Updated CV data.
   * @returns Updated CV copy.
   */
  public save(item, data: unknown): Promise<ICv> {
    return this.api.put(`persons/copy/${item.id}`, data)
  }

  /**
   * Delete CV copy.
   *
   * @param id - CV copy ID.
   * @returns Promise of CV copy deletion.
   */
  public delete(id: number): Promise<void> {
    return this.api.delete(`persons/copy/${id}`)
  }

  /**
   * Set default CV.
   *
   * @param id - Person ID.
   * @param cvId - CV ID.
   * @returns Promise of setting default CV.
   */
  public async setDefaultCv(id: number, cvId: CvId): Promise<void> {
    await this.api.put(`persons/default-cv/${id}`, { cvId })
  }

  /**
   * Set CV contact information.
   *
   * @param data - Contact information data.
   * @retruns Promise of setting CV contact information.
   */
  public async setContactInformation(data: IContactInformation, controller?: AbortController): Promise<void> {
    await this.api.put(
      `persons/contact-information/${data.id}`,
      data,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get allocations for organization members.
   *
   * @param id - Organization ID.
   * @returns Array of people with allocations.
   */
  public async getCompanyMembersAllocations(id: number, controller?: AbortController): Promise<IPerson[]> {
    return this.api.get(
      `allocations/organization/${id}/members`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get allocations for team members.
   *
   * @param id - Team ID.
   * @returns Array of people with allocations.
   */
  public async getTeamMembersAllocations(id: number, controller?: AbortController): Promise<IPerson[]> {
    return this.api.get(`allocations/teams/${id}/members`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get network people.
   *
   * @param id - Network ID.
   * @returns Array of people in network.
   */
  public getNetworkPeople(id: number, controller?: AbortController): Promise<IPerson[]> {
    return this.api.get(`persons/networks/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get sales contacts for organization.
   *
   * @param id - Organization ID.
   * @returns Array of sales people.
   */
  public getSalesContacts(id: OrganizationId, controller?: AbortController): Promise<IPerson[]> {
    return this.api.get(`persons/contactSales/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get sales contacts for allowed organizations.
   *
   * @returns Array of sales people.
   */
  public getSalesList(controller?: AbortController): Promise<IPerson[]> {
    return this.api.get(`persons/salesList/`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get allowed people.
   *
   * @returns Array of people.
   */
  public getAllowedPeople(controller?: AbortController): Promise<IPerson[]> {
    return this.api.get(`persons/allowed`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Save allocation info for person.
   *
   * @param id - Person ID.
   * @param allocationInfo - Allocation info text.
   * @returns Updated person.
   */
  public saveAllocationInfo(id: number, allocationInfo: string, controller?: AbortController): Promise<IPerson> {
    return this.api.put(
      `persons/allocationinfo/${id}`,
      { allocationInfo },
      controller ? { signal: controller.signal } : undefined
    )
  }
}

export const personAPI = new PersonAPI()
