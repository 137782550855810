import { ICv } from 'types/cvsInterfaces'
import { minBy } from 'lodash'
import { IPerson } from 'types/userInterfaces'
import { IAnonymousCv, IAnonymousPerson, ISearch, ISearchFields } from 'types/searchInterfaces'

/**
 * Retrieves the CV of a person based on the given parameters.
 *
 * @param {IPerson | ISearch} person - The person object containing the details of the person.
 * @param {ISearchFields | undefined | null} [terms] - The search terms to filter the CV data.
 * @return {ICv | undefined | null} The CV object of the person or undefined if not found.
 */
export function getCV(person: IPerson | ISearch, terms?: ISearchFields | undefined | null): ICv | undefined | null
/**
 * Retrieves the CV of a person based on the given parameters.
 *
 * @param {IPerson | ISearch | IAnonymousPerson} person - The person object containing the details of the person.
 * @param {ISearchFields | undefined | null} [terms] - The search terms to filter the CV data.
 * @return {ICv | IAnonymousCv | undefined | null} The CV object of the person or anonymous CV if not found.
 */
export function getCV(
  person: IPerson | ISearch | IAnonymousPerson,
  terms?: ISearchFields | undefined | null
): ICv | IAnonymousCv | undefined | null
/**
 * Retrieves the appropriate CV based on the given person and search terms.
 *
 * @param {IPerson | ISearch | IAnonymousPerson} person - The person object.
 * @param {ISearchFields | undefined | null} terms - The search terms.
 * @return {ICv | IAnonymousCv | undefined | null} The matching CV or undefined if no match is found.
 */
export function getCV(
  person: IPerson | ISearch | IAnonymousPerson,
  terms: ISearchFields | undefined | null
): ICv | IAnonymousCv | undefined | null {
  if ('id' in person && person && person.CVs && terms) {
    const matchingCVs: ICv[] = []
    // look for CV with terms for all persons CVs
    for (const CV of person.CVs) {
      let skillsMatch = false
      let certsMatch = false
      const cvSkills = CV.PersonSkills
      const cvCerts = CV.Certificates
      if (cvSkills.length > 0 && terms.skills.length > 0) {
        // check if skills match
        skillsMatch = terms.skills.every(item => {
          for (const skill of cvSkills) {
            // get skill translations
            const trs = skill.Skill.translations
            const found = trs.find(tr => tr.name === item.name)
            if (found) {
              return true
            }
          }
        })
      }
      if (cvCerts.length > 0 && terms.certificates.length > 0) {
        // check if certs match
        certsMatch = terms.certificates.every(item => {
          for (const cert of cvCerts) {
            // get cert translations
            const trs = cert.translations
            const found = trs.find(tr => tr.certificate === item.certificate)
            if (found) {
              return true
            }
          }
        })
      }
      // if terms has skills and certs
      if (terms.skills.length > 0 && terms.certificates.length > 0) {
        // if skills and certs match collect CV to matchingCVs
        if (skillsMatch && certsMatch) {
          matchingCVs.push(CV)
        }
      }
      // if terms has only skills
      else if (terms.skills.length > 0) {
        // if skills match collect CV to matchingCVs
        if (skillsMatch) {
          matchingCVs.push(CV)
        }
      }
      // if terms has only certs
      else if (terms.certificates.length > 0) {
        // if certs match collect CV to matchingCVs
        if (certsMatch) {
          matchingCVs.push(CV)
        }
      }
    }

    if (matchingCVs.length) {
      // get smallest CV.id from matchingCVs as used cv
      return minBy(matchingCVs, 'id')
    }
  }

  if ('defaultCvId' in person && typeof person.defaultCvId === 'number') {
    return person?.CVs?.find(item => item.id === person.defaultCvId) ?? null
  }

  if ('DefaultCV' in person) {
    return person?.DefaultCV
  }

  const result = person?.CVs ? person?.CVs.find(item => item.original === true) : null

  if (!result) {
    return person?.CVs?.[0] ?? null
  }

  return result
}
