import { organizationAPI } from 'api/OrganizationAPI'
import { permissionAPI } from 'api/PermissionAPI'
import { useEffect, useState } from 'react'
import { IError } from 'types/error'
import { OrganizationId } from 'types/ids'
import { useIsComponentMounted } from './util'

/**
 * Hook for getting user company permissions.
 *
 * @param organizationId - Organization ID.
 * @returns Hook variables and functions.
 */
export const useCompanyPermissions = (organizationId?: number) => {
  const [salesAccess, setSalesAccess] = useState<boolean>(false)
  const [companyAdminAccess, setCompanyAdminAccess] = useState<boolean>(false)
  const [error, setError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        if (organizationId) {
          const groupsMemberships = await organizationAPI.getUserGroups(organizationId, controller)

          if (groupsMemberships.findIndex(m => m.Group.type === 'sales') > -1) {
            setSalesAccess(true)
          }
          if (groupsMemberships.findIndex(m => m.Group.type === 'companyAdmin') > -1) {
            setCompanyAdminAccess(true)
          }
        }
      } catch (error) {
        setError(error as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [organizationId])

  return {
    salesAccess,
    companyAdminAccess,
    error,
  }
}

/**
 * Hook for getting users caleo owner permission and sales organization IDs.
 *
 * @returns Hook variables and functions.
 */
export const useUserPermissions = () => {
  const isComponentMounted = useIsComponentMounted()
  const [salesOrganizationIds, setSalesOrganizationIds] = useState<OrganizationId[]>([])
  const [caleoOwner, setCaleoOwner] = useState<boolean>(false)
  const [error, setError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        const ids = await permissionAPI.getSalesOrganizationIds(controller)
        const status = await permissionAPI.getCaleoOwnerStatus(controller)
        if (!isComponentMounted.current) return
        setSalesOrganizationIds(ids)
        setCaleoOwner(status)
      } catch (error) {
        setError(error as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [])

  return {
    caleoOwner,
    salesOrganizationIds,
    error,
  }
}

/**
 * Hook for getting team leader permissions.
 *
 * @param teamIds - Team IDs that permissions should be checked for.
 * @returns Hook variables and functions.
 */
export const useTeamLeaderPermissions = (teamIds?: number[]) => {
  const isComponentMounted = useIsComponentMounted()
  const [teamLeaderAccess, setTeamLeaderAccess] = useState<boolean>(false)
  const [teamsAccess, setTeamsAccess] = useState<number[]>([])
  const [error, setError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        if (teamIds?.length) {
          const teams = await permissionAPI.getLeaderAccessToTeams(teamIds, controller)
          if (!isComponentMounted.current) return
          setTeamsAccess(teams)
        } else {
          const results = await permissionAPI.getTeamLeaderAccess(controller)
          if (!isComponentMounted.current) return
          setTeamLeaderAccess(results)
        }
      } catch (error) {
        setError(error as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [])

  return {
    teamLeaderAccess,
    teamsAccess,
    error,
  }
}
