import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Checkbox,
  Collapse,
  DialogContent,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { useConnectedSiteData } from 'hooks/data'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IError } from 'types/error'
import { IPerson, ISite } from 'types/userInterfaces'
import newWithConfirmationModal from 'Components/reusable/HOC/withConfirmationModal'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported  */
interface IDeleteSitesConfirmProps {
  /** Site being deleted */
  item: ISite
  /** Function to disable delete button in parent component */
  disableDelete: (value: boolean) => void
}

/**
 * Component for displaying delete connected site confirmation
 *
 * @returns Component for displaying delete connected site confirmation
 * @notExported
 */
const DeleteConnectedSiteConfirm: React.FC<IDeleteSitesConfirmProps> = ({ item, disableDelete }) => {
  const isComponentMounted = useIsComponentMounted()
  const { t } = useTranslation()
  const [personDetailsOpen, setPersonDetailsOpen] = useState<boolean>(false)
  const { people, error } = useConnectedSiteData(item.id)
  const [backendError, setBackendError] = useState<IError>()

  useEffect(() => {
    if (isComponentMounted.current) setBackendError(error as IError)
  }, [error])

  useEffect(() => {
    if (people.length > 0) disableDelete(true)
  }, [people])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <DialogContent>
      {people.length ? (
        <>
          <Typography color="primary" style={{ fontWeight: 'bold', marginLeft: '15px' }} variant="h2">
            {t(`confirmation.site.data`)}
          </Typography>
          <p style={{ marginLeft: '15px' }}>{t(`confirmation.site.text`)}</p>
          <>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => setPersonDetailsOpen(!personDetailsOpen)}
                  icon={<ExpandMoreIcon />}
                  checkedIcon={<ExpandLessIcon />}
                  name="collapseCh"
                />
              }
              label={t('employees.title')}
              labelPlacement="start"
              style={{ fontWeight: 'bold' }}
            />
            <Collapse in={personDetailsOpen}>
              <Table aria-label="allocation table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('site.person.name')}</TableCell>
                    <TableCell align="right">{t('site.person.email')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {people.map((person: IPerson) => (
                    <TableRow key={person.id}>
                      <TableCell variant="head" scope="row">
                        {`${person.firstName} ${person.lastName}`}
                      </TableCell>
                      <TableCell align="right">{person?.Account?.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </>
        </>
      ) : (
        <>
          <Typography color="primary" style={{ fontWeight: 'bold' }} variant="h2">
            {t('confirmation.text')}
          </Typography>
        </>
      )}
    </DialogContent>
  )
}

export default newWithConfirmationModal(DeleteConnectedSiteConfirm)
