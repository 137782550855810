import { OrganizationId, SiteId } from 'types/ids'
import { IOrganization, IOrganizationQuery, IPerson } from 'types/userInterfaces'
import { SubAPI } from './API'
import { ISite } from '../types/userInterfaces'
import { IGroupMember } from 'types/adminInterfaces'
import { SortingState } from '@tanstack/react-table'
import { INetwork } from 'types/networkInterfaces'

/**
 * Organization API.
 * @notExported
 */
class OrganizationAPI extends SubAPI {
  /**
   * Get all organizations.
   *
   * @param limit - Items per page.
   * @param page - Page number.
   * @param sorting - Sorting state.
   * @param filter - Filter string.
   * @returns Organization query results.
   */
  public getAllOrganizations(
    limit?: number,
    page?: number,
    sorting?: SortingState,
    filter?: string,
    controller?: AbortController
  ): Promise<IOrganizationQuery> {
    return this.api.get(`organizations/all`, controller ? { signal: controller.signal } : undefined, {
      limit,
      page,
      sorting,
      filter: filter ? encodeURI(filter) : undefined,
    })
  }

  /**
   * Get all organizations list.
   *
   * @returns Array of organizations.
   */
  public getAllOrganizationList(controller?: AbortController): Promise<IOrganization[]> {
    return this.api.get(`organizations/list`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get organization logo.
   *
   * @param id - Organization ID.
   * @returns Logo URL.
   */
  public getOrgLogo(id: number, controller?: AbortController): Promise<string> {
    return this.api.get(`files/organization/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Delete organization logo.
   *
   * @param id - Organization ID.
   * @returns Promise of logo deletion.
   */
  public deleteOrgLogo(id: number): Promise<void> {
    return this.api.delete(`files/organization/${id}`)
  }

  /**
   * Get organization sites.
   *
   * @param id - Organization ID.
   * @returns Array of sites.
   */
  public getOrganizationSites(id: OrganizationId, controller?: AbortController): Promise<ISite[]> {
    return this.api.get(`organizations/${id}/site`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get people for site.
   *
   * @param id - Site ID.
   * @returns Array of people.
   */
  public getSitePeople(id: SiteId, controller?: AbortController): Promise<IPerson[]> {
    return this.api.get(`organizations/site/${id}/people`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get people for organization.
   *
   * @param id - Organization ID.
   * @returns Array of people.
   */
  public getOrganizationPeople(id: number): Promise<IPerson[]> {
    return this.api.get(`organizations/${id}/people`)
  }

  /**
   * Get organization by ID.
   *
   * @param id - Organization ID.
   * @returns Organization data.
   */
  public getOrganization(id: number, controller?: AbortController): Promise<IOrganization> {
    return this.api.get(`organizations/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get people for organization.
   *
   * @param id - Organization ID.
   * @returns Organization data.
   */
  public getPersons(id: OrganizationId, controller?: AbortController): Promise<IPerson[]> {
    return this.api.get(`organizations/${id}/people`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get allowed organizations.
   *
   * @returns Array of organizations.
   */
  public getAllowedOrganizations(controller?: AbortController): Promise<IOrganization[]> {
    return this.api.get('organizations/allowed', controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get user groups for organization.
   *
   * @param organizationId - Organization ID.
   * @returns Array of group members.
   */
  public getUserGroups(organizationId: number, controller?: AbortController): Promise<IGroupMember[]> {
    return this.api.get(
      `organizations/${organizationId}/user-permissions`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get company admin access organizations.
   *
   * @param userId - User ID.
   * @param limit - Items per page.
   * @param page - Page number.
   * @param sorting - Sorting state.
   * @param filter - Search filter.
   * @returns Organization query results.
   */
  public getCompanyAdminAccessOrganizations(
    userId: number,
    limit?: number,
    page?: number,
    sorting?: SortingState,
    filter?: string,
    controller?: AbortController
  ): Promise<IOrganizationQuery> {
    return this.api.get(
      `organizations/company-admin/${userId}`,
      controller ? { signal: controller.signal } : undefined,
      {
        limit,
        page,
        sorting,
        filter: filter ? encodeURI(filter) : undefined,
      }
    )
  }

  /**
   * Get company admin access organizations list.
   *
   * @param userId - User ID.
   * @returns Array of organizations.
   */
  public getCompanyAdminAccessOrganizationList(userId: number, controller?: AbortController): Promise<IOrganization[]> {
    return this.api.get(
      `organizations/company-admin/${userId}/list`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get sales access organizations.
   *
   * @returns Array of organizations.
   */
  public getSalesAccessOrganizations(controller?: AbortController): Promise<IOrganization[]> {
    return this.api.get(`organizations/salesaccess`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get organization networks.
   *
   * @param organizationId - Organization ID.
   * @returns Organization with networks.
   */
  public async getOrganizationNetworks(organizationId: number, controller?: AbortController): Promise<IOrganization> {
    return this.api.get(
      `organizations/${organizationId}/networks`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get networks for organization.
   *
   * @param organizationId - Organization ID.
   * @returns Array of networks.
   */
  public async getNetworks(organizationId: number, controller?: AbortController): Promise<INetwork[]> {
    return this.api.get(
      `organizations/networks/${organizationId}`,
      controller ? { signal: controller.signal } : undefined
    )
  }
}

export const organizationAPI = new OrganizationAPI()
