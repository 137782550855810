import { DialogContent } from '@mui/material'
import withSimpleModal from 'Components/reusable/HOC/withSimpleModal'
import React from 'react'
import { useTranslation } from 'react-i18next'

/** @notExported */
interface IInformativeModalProps {
  /** The indicator if the form has been submitted */
  submitIndicator: boolean
  /** The function to call to go back to the previous page */
  goBack: () => void
}

/**
 * Informative Modal component.
 *
 * @returns The informative modal component.
 * @notExported
 */
const InformativeModal: React.FC<IInformativeModalProps> = ({ submitIndicator, goBack }) => {
  const { t } = useTranslation()

  if (submitIndicator) goBack()

  return <DialogContent>{t('resetasked-text')}</DialogContent>
}

export default withSimpleModal(InformativeModal)
