import React, { useEffect, useState, useMemo } from 'react'
import { IAllocation, IAllocationData } from 'types/allocationInterfaces'
import { IPerson } from 'types/userInterfaces'
import { convertToDisplayDate } from 'utils/utils'
import { chooseDBTranslation } from 'utils/translations'
import { useTranslation } from 'react-i18next'
import { Checkbox, Collapse, DialogContent, Divider, FormControlLabel, Grid, Typography } from '@mui/material'
import SkillsItem from 'Components/General/SkillsCard/SkillsItem'
import { IAssignmentRole } from 'types/assignmentInterfaces'
import LanguageChip from 'Components/reusable/CaleoCustomComponents/LanguageChip/LanguageChip'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useLanguageData, useCountryData, useUser, useUserPermissions } from 'hooks'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import CheckIcon from '@mui/icons-material/CheckCircle'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import withSimpleModal from 'Components/reusable/HOC/withSimpleModal'
import _ from 'lodash'
import { personAPI } from 'api/PersonAPI'
import AllocationInputComponent from 'Components/reusable/CaleoCustomComponents/AllocationInputComponent'
import DeleteButton from 'Components/reusable/IconButtons/DeleteButton'
import EditButton from 'Components/reusable/IconButtons/EditButton'
import DeleteConfirm from 'Components/reusable/Confirmations/DeleteConfirm'
import { allocationAPI } from 'api/AllocationAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { IError } from 'types/error'
import { useTeamLeaderPermissions } from 'hooks/permissions'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported */
interface IAllocationDetailsProps {
  /** Allocation data */
  allocation: IAllocationData
  /** Person object */
  person: IPerson
  /** Function to delete an item  */
  onDelete: () => void
  /** Function to save data  */
  saveData: () => void
}

/**
 * Allocation details component.
 *
 * @returns Allocation details component.
 * @notExported
 */
const AllocationDetails: React.FC<IAllocationDetailsProps> = ({ allocation, person, onDelete, saveData }) => {
  const isComponentMounted = useIsComponentMounted()
  const { t, i18n } = useTranslation()
  const { languages } = useLanguageData()
  const { countries } = useCountryData()
  const { user } = useUser()
  const { salesOrganizationIds } = useUserPermissions()
  const { teamLeaderAccess } = useTeamLeaderPermissions()

  const [roleCollapse, setRoleCollapse] = useState<boolean>(false)
  const [assignmentCollapse, setAssignmentCollapse] = useState<boolean>(false)
  const [allowedPeople, setAllowedPeople] = useState<IPerson[]>()
  const [editableAllocation, setEditableAllocation] = useState<IAllocation>()
  const [selectedDelete, setSelectedDelete] = useState<number>()
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [backendError, setBackendError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    setLoading(true)
    ;(async () => {
      const results = await personAPI.getAllowedPeople(controller)
      if (!isComponentMounted.current) return
      setAllowedPeople(results)
    })()
    setLoading(false)

    return () => {
      controller.abort()
    }
  }, [user])

  let shownAllocationData: IAllocation = useMemo(() => {
    return allocation
  }, [allocation])

  const deleteAllocation = async (allocationId: number) => {
    try {
      await allocationAPI.delete(allocationId)
      if (!isComponentMounted.current) return
      onDelete()
      const updatedShownAllocations = Object.assign({}, shownAllocationData)
      updatedShownAllocations.AssignmentRole.Allocations = updatedShownAllocations.AssignmentRole.Allocations.filter(
        item => item.id !== allocationId
      )

      shownAllocationData = updatedShownAllocations

      setDeleteConfirm(false)
    } catch (error) {
      setBackendError(error as IError)
    }
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const skillChips = (role: IAssignmentRole) => {
    return role.AssignmentRoleSkillRequirements.map(req => (
      <SkillsItem key={req.id} skill={req} editable={false} assignmentSkill type="skill" hideInterest />
    ))
  }

  const languageChips = (role: IAssignmentRole) => {
    return role.AssignmentRoleLanguageRequirements.map(req => (
      <LanguageChip key={req.id} language={req} languages={languages} hideLevel />
    ))
  }
  if (!shownAllocationData || loading) return <></>

  const location =
    (countries?.[chooseDBTranslation(i18n, shownAllocationData.Assignment)?.country?.toUpperCase() ?? ''] ?? '') +
    ', ' +
    chooseDBTranslation(i18n, shownAllocationData.Assignment)?.city

  const contactPersons = _.uniqBy(shownAllocationData.Assignment?.AssignmentContactPeople, 'personId')

  const createOrUpdateAllocation = (allocation: IAllocation) => {
    const updatedShownAllocations = Object.assign({}, shownAllocationData)

    if (shownAllocationData?.type === 'project') {
      const index = updatedShownAllocations.AssignmentRole.Allocations.findIndex(item => item.id === allocation.id)
      if (index > -1) {
        updatedShownAllocations.AssignmentRole.Allocations[index] = allocation
      } else {
        updatedShownAllocations.AssignmentRole.Allocations.push(allocation)
      }
      shownAllocationData = updatedShownAllocations
    } else {
      shownAllocationData = allocation
    }
    saveData()
    setEditableAllocation(undefined)
  }

  const allocationRow = (item, allowEdit?: boolean, assignmentName?: string) => {
    return (
      <Grid container key={item.id} direction="row" alignItems="center">
        <Grid item xs={1}>
          {item.state === 1 ? <CheckIcon color="success" /> : <HourglassBottomIcon color="warning" />}
        </Grid>
        <Grid item xs={12} md={9}>
          {`${item.Person.firstName} ${item.Person.lastName}, ${item.percent}% (${convertToDisplayDate(
            new Date(item.startDate),
            'day'
          )}-${item.endDate ? convertToDisplayDate(new Date(item.endDate), 'day') : ''}), ${convertToDisplayDate(
            new Date(item.updatedAt),
            'day'
          )}`}
        </Grid>
        {allowEdit && (
          <>
            <Grid item xs={3} md={1}>
              <DeleteButton
                clickAction={() => {
                  setSelectedDelete(item.id)
                  setDeleteConfirm(true)
                }}
                tooltip={t('allocation.proposal.remove', {
                  assignmentName,
                })}
              />
            </Grid>
            <Grid item xs={3} md={1}>
              <EditButton
                clickAction={() => {
                  setEditableAllocation(item)
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    )
  }

  const shownAllocationDataFromArray = shownAllocationData?.AssignmentRole?.Allocations?.find(
    item => item.id === shownAllocationData.id
  )

  const allowEdit = useMemo(
    () =>
      (shownAllocationData?.Assignment?.AssignmentVisibilities.length < 1 &&
        salesOrganizationIds.includes(shownAllocationData?.Assignment?.ownerId)) ||
      (user?.Person && person.id === user.Person.id) ||
      salesOrganizationIds.includes(person.organizationId) ||
      (teamLeaderAccess && !!allowedPeople?.find(item => item.id === person.id)),
    [shownAllocationData, user, person, salesOrganizationIds, teamLeaderAccess, allowedPeople]
  )

  return (
    <DialogContent sx={{ margin: '10px' }}>
      {shownAllocationData.type === 'project' && (
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <Grid container>
              <Grid item>
                <CardContentText type="darkSubHeader">
                  {chooseDBTranslation(i18n, shownAllocationData?.Assignment)?.name}
                </CardContentText>
              </Grid>
              <Grid item xs={12} fontSize="18px">
                {`${person.firstName} ${person.lastName}`}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} fontSize="25px">
            {t('allocation.startDate')}: <b>{convertToDisplayDate(shownAllocationData.Assignment.startDate)}</b>
          </Grid>
          <Grid item xs={12} lg={4} fontSize="25px">
            {t('allocation.endDate')}:{' '}
            <b>
              {shownAllocationData.Assignment.endDate
                ? convertToDisplayDate(shownAllocationData.Assignment.endDate)
                : '-'}
            </b>
          </Grid>
          <Grid item xs={12} lg={4} fontSize="20px">
            {t('assignment.modal.customer')}:{' '}
            <b>{chooseDBTranslation(i18n, shownAllocationData.Assignment).customer}</b>
          </Grid>
          <Grid item xs={12} lg={4} fontSize="20px">
            {t('assignment.modal.deadline')}:{' '}
            <b>
              {shownAllocationData.Assignment.deadline
                ? convertToDisplayDate(shownAllocationData.Assignment.deadline)
                : '-'}
            </b>
          </Grid>
          <Grid item xs={12} lg={4} fontSize="20px">
            {t('assignment.modal.location')}: <b>{location}</b>
          </Grid>
          <Grid item xs={12} lg={8} fontSize="20px">
            {t('assignment.modal.contactPeople')}:{' '}
            <b>
              {contactPersons.map((contactPerson, index) => {
                return `${contactPerson.Person?.firstName} ${contactPerson?.Person?.lastName}${
                  index + 1 < contactPersons.length ? ', ' : ''
                }`
              })}
            </b>
          </Grid>
          <Grid item xs={12} lg={4} fontSize="20px">
            {t('assignmentView.remoteAllowed')}:{' '}
            <b>{shownAllocationData.Assignment.remoteAllowed ? t('yes') : t('no')}</b>
          </Grid>
        </Grid>
      )}
      {shownAllocationData.type !== 'project' ? (
        <Grid container>
          <Grid item xs={12} lg={6} fontSize={20}>
            {`${person.firstName} ${person.lastName}`}
          </Grid>
          <Grid item xs={12} lg={6} fontSize={40}>
            {`${shownAllocationData.percent}%`}
          </Grid>
        </Grid>
      ) : (
        <Divider />
      )}
      <Grid container mt={2}>
        {shownAllocationData.type !== 'project' ? (
          <>
            <Grid item xs={6}>
              <Typography fontSize={20} fontWeight="bold">{`${t('allocation.startDate')}: ${convertToDisplayDate(
                shownAllocationData.startDate
              )}`}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={20} fontWeight="bold">{`${t('allocation.endDate')}: ${
                shownAllocationData.endDate ? convertToDisplayDate(shownAllocationData.endDate) : ''
              }`}</Typography>
            </Grid>
            <Grid item mt={1}>
              {shownAllocationData.state === 0 ? (
                <HourglassBottomIcon fontSize="small" color="warning" />
              ) : (
                <CheckIcon fontSize="small" color="success" />
              )}
            </Grid>
            <Grid item mt={1}>
              {t(`allocation.state.${shownAllocationData.state}`)}
            </Grid>
          </>
        ) : (
          <>
            <Grid item mt={1} xs={12} lg={4} fontSize="25px">
              {t('profile.role.card.nameColumn')}:{' '}
              <b> {chooseDBTranslation(i18n, shownAllocationData.AssignmentRole).name}</b>
            </Grid>
            <Grid item mt={1} xs={12} lg={4}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item fontSize="20px">
                  {shownAllocationDataFromArray?.state === 0 ? (
                    <HourglassBottomIcon fontSize="small" color="warning" />
                  ) : (
                    <CheckIcon fontSize="small" color="success" />
                  )}
                </Grid>
                <Grid item fontSize="20px">
                  {t(`allocation.state.${shownAllocationDataFromArray?.state}`)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} fontSize="20px">
              {t('allocation.expectedAllocation')}:{' '}
              <b>{`${shownAllocationData.AssignmentRole.allocationPercentage}%`}</b>
            </Grid>
            <Grid item xs={12} fontSize="20px">
              {t('assignment.propose.price')}:{' '}
              <b>
                {shownAllocationData.AssignmentRole.hourlyPrice
                  ? `${shownAllocationData.AssignmentRole.hourlyPrice}€`
                  : t('assignmentView.negotiable')}
              </b>
            </Grid>
            {shownAllocationData.AssignmentRole.AssignmentRoleSkillRequirements && (
              <Grid item xs={12} mt={1}>
                {skillChips(shownAllocationData.AssignmentRole)}
              </Grid>
            )}
            {shownAllocationData.AssignmentRole.AssignmentRoleLanguageRequirements && (
              <Grid item xs={12}>
                {languageChips(shownAllocationData.AssignmentRole)}
              </Grid>
            )}
            {chooseDBTranslation(i18n, shownAllocationData.AssignmentRole).description.length > 0 && (
              <Grid item xs={12} mt={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setRoleCollapse(!roleCollapse)}
                      icon={<ExpandMoreIcon />}
                      checkedIcon={<ExpandLessIcon />}
                      name="collapseRole"
                    />
                  }
                  labelPlacement="start"
                  label={
                    <Typography fontSize={18} fontWeight="bold">
                      {t('assignment.role.description')}
                    </Typography>
                  }
                  style={{ margin: 0 }}
                />
                <Collapse in={roleCollapse}>
                  <CardContentText type="profileDescription">
                    {chooseDBTranslation(i18n, shownAllocationData.AssignmentRole).description}
                  </CardContentText>
                </Collapse>
              </Grid>
            )}
          </>
        )}
        {chooseDBTranslation(i18n, shownAllocationData)?.information.length > 0 && (
          <Grid container spacing={1}>
            <Grid item xs={12} mt={1}>
              <Typography fontSize={18} fontWeight="bold">{`${t('allocation.description')}: `}</Typography>
              <Collapse in={true}>
                <CardContentText type="profileDescription">
                  {shownAllocationData.type === 'project'
                    ? chooseDBTranslation(i18n, shownAllocationDataFromArray)?.information
                    : chooseDBTranslation(i18n, shownAllocationData).information}
                </CardContentText>
              </Collapse>
            </Grid>
          </Grid>
        )}
        {chooseDBTranslation(i18n, shownAllocationData.Assignment)?.description.length > 0 && (
          <Grid item xs={12} mt={1}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => setAssignmentCollapse(!assignmentCollapse)}
                  icon={<ExpandMoreIcon />}
                  checkedIcon={<ExpandLessIcon />}
                  name="collapseAssignment"
                />
              }
              labelPlacement="start"
              label={
                <Typography fontSize={18} fontWeight="bold">
                  {t('assignment.description')}
                </Typography>
              }
              style={{ margin: 0 }}
            />
            <Collapse in={assignmentCollapse}>
              <CardContentText type="profileDescription">
                {chooseDBTranslation(i18n, shownAllocationData.Assignment).description}
              </CardContentText>
            </Collapse>
          </Grid>
        )}
        <Grid item xs={12} mt={1} mb={1}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {shownAllocationData?.AssignmentRole
            ? shownAllocationData.AssignmentRole.Allocations.map(item => {
                return allocationRow(item, allowEdit, chooseDBTranslation(i18n, shownAllocationData.Assignment).name)
              })
            : allocationRow(shownAllocationData, allowEdit)}
        </Grid>
        {allowEdit && shownAllocationData?.Assignment && (
          <>
            <AllocationInputComponent
              roleId={shownAllocationData?.AssignmentRole?.id}
              assignmentId={shownAllocationData?.Assignment?.id}
              start={shownAllocationData?.Assignment?.startDate}
              end={shownAllocationData?.Assignment?.endDate}
              options={allowedPeople}
              allocation={editableAllocation}
              allocatedPerson={person}
              createOrUpdateAllocation={createOrUpdateAllocation}
              showDescription={true}
              type={shownAllocationData.type}
            />
          </>
        )}
        {allowEdit && !shownAllocationData?.Assignment && editableAllocation && (
          <AllocationInputComponent
            start={shownAllocationData?.startDate}
            end={shownAllocationData?.endDate}
            options={allowedPeople}
            allocation={editableAllocation}
            allocatedPerson={person}
            createOrUpdateAllocation={createOrUpdateAllocation}
            showDescription={true}
            type={shownAllocationData.type}
          />
        )}
      </Grid>
      {deleteConfirm && selectedDelete && (
        <DeleteConfirm
          confirmationText={t('assignment.role.allocation.deleteConfirm')}
          open={deleteConfirm}
          confirm={() => deleteAllocation(selectedDelete)}
          onClose={() => {
            setSelectedDelete(undefined)
            setDeleteConfirm(false)
          }}
        />
      )}
    </DialogContent>
  )
}

export default withSimpleModal(AllocationDetails)
