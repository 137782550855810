import React, { useState } from 'react'
import { InputField } from './InputField'

/**
 * Table input field component.
 *
 * @param type - Input field type.
 * @param value - Input field value.
 * @param onChange - Input field change handler.
 * @param helperText - Input field helper text.
 * @param disabled - Input field disabled state.
 * @param error - Input field error state.
 * @returns Table input field component.
 */
export const TableInputField = ({
  type,
  value,
  onChange,
  helperText,
  disabled,
  error,
}: {
  type: string
  value: string | number
  onChange: (newData) => void
  helperText?: string
  disabled?: boolean
  error?: string
}) => {
  const [currentValue, setCurrentValue] = useState<string | number>(value)

  return (
    <InputField
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        setCurrentValue(newValue)
        onChange(newValue)
      }}
      type={type}
      disabled={disabled}
      value={currentValue}
      name={String(value)}
      style={{ maxWidth: type === 'string' ? '150px' : '100px' }}
      helperText={helperText}
      error={error}
    />
  )
}
