import { networkAPI } from 'api/NetworkAPI'
import { userAPI } from 'api/UserAPI'
import { useEffect, useState } from 'react'
import { IError } from 'types/error'
import { ICombinedNetworkMember, INetwork } from 'types/networkInterfaces'
import { useUser } from './user'
import { useIsComponentMounted } from './util'

/**
 * Hook for getting networks.
 *
 * @returns Hook variables and functions.
 */
export const useNetworks = () => {
  const isComponentMounted = useIsComponentMounted()
  const [networks, setNetworks] = useState<INetwork[]>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<IError>()

  const { user } = useUser()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        setLoading(true)
        const results = await networkAPI.getNetworks(controller)
        if (isComponentMounted.current) setNetworks(results)
      } catch (error) {
        setError(error as IError)
      } finally {
        setLoading(false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [user])

  return {
    loading,
    networks,
    error,
  }
}

/**
 * Hook for getting account networks.
 *
 * @param accountId - Account ID.
 * @returns Hook variables and functions.
 */
export const useAccountNetworks = (accountId?: number) => {
  const isComponentMounted = useIsComponentMounted()
  const [networks, setNetworks] = useState<INetwork[]>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (accountId) {
        try {
          setLoading(true)
          const results = await networkAPI.getAccountNetworks(accountId, controller)
          if (isComponentMounted.current) setNetworks(results)
        } catch (error) {
          setError(error as IError)
        } finally {
          setLoading(false)
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [accountId])

  return {
    loading,
    networks,
    error,
  }
}

/**
 * Hook for getting broker accessible networks.
 *
 * @returns Hook variables and functions.
 */
export const useBrokerNetworks = () => {
  const isComponentMounted = useIsComponentMounted()
  const [networks, setNetworks] = useState<INetwork[]>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<IError>()

  const { user } = useUser()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        setLoading(true)
        const results = await networkAPI.getBrokerAccessNetworks(controller)
        if (isComponentMounted.current) setNetworks(results)
      } catch (error) {
        setError(error as IError)
      } finally {
        setLoading(false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [user])

  return {
    loading,
    networks,
    error,
  }
}

/**
 * Hook for getting searchable networks.
 *
 * @returns Hook variables and functions.
 */
export const useSearchableNetworks = () => {
  const isComponentMounted = useIsComponentMounted()
  const [networks, setNetworks] = useState<INetwork[]>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<IError>()

  const { user } = useUser()

  useEffect(() => {
    const controller = new AbortController()
    let stillValid = true
    ;(async () => {
      try {
        setLoading(true)
        const networks = user ? await networkAPI.getSearchableNetworks(controller) : []
        if (stillValid && isComponentMounted.current) {
          setNetworks(networks)
        }
      } catch (error) {
        setError(error as IError)
      } finally {
        setLoading(false)
      }
    })()

    return () => {
      controller.abort()
      stillValid = false
    }
  }, [user])

  return {
    loading,
    networks,
    error,
  }
}

/**
 * Hook for getting network statistics.
 *
 * @param networkId - Network ID.
 * @returns Hook variables and functions.
 */
export const useNetworkStatistics = (networkId: number) => {
  const isComponentMounted = useIsComponentMounted()
  const [combinedNetworkMembers, setCombinedNetworkMembers] = useState<ICombinedNetworkMember[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        setLoading(true)
        const results = await networkAPI.getNetworkStatistics(networkId, controller)
        if (isComponentMounted.current) setCombinedNetworkMembers(results)
      } catch (error) {
        setError(error as IError)
      } finally {
        setLoading(false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [networkId])

  return {
    combinedNetworkMembers,
    loading,
    error,
  }
}

/**
 * Hook for getting sales networks.
 *
 * @returns Hook variables and functions.
 */
export const useSalesNetworks = () => {
  const isComponentMounted = useIsComponentMounted()
  const [networks, setNetworks] = useState<INetwork[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<IError>()

  const { user, groups } = useUser()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        setLoading(true)
        if (groups && !groups.includes('freelancer')) {
          const results = await userAPI.getSalesNetworks(controller)
          if (isComponentMounted.current) setNetworks(results)
        }
      } catch (error) {
        setError(error as IError)
      } finally {
        setLoading(false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [user])

  return {
    loading,
    networks,
    error,
  }
}
