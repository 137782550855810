import React, { ComponentProps } from 'react'
import { Grid, useTheme } from '@mui/material'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import CaleoInputLabel from '../CaleoCustomComponents/CaleoInputLabel'

interface OwnProps {
  value: string
  onChange: (newData: string) => void
  error?: string
  required?: boolean
  label: string
  style?: React.CSSProperties
  disabled?: boolean
}

/**
 * Rich text editor component.
 *
 * @param value - the current value.
 * @param onChange - the change handler.
 * @param error - the error message.
 * @param required - whether the field is required.
 * @param label - the label.
 * @param style - the style.
 * @param disabled - whether the field is disabled.
 * @param rest - the rest of the props.
 * @returns The rich text editor component.
 */
export const RichTextEditor: React.FC<OwnProps & Omit<ComponentProps<typeof ReactQuill>, keyof OwnProps>> = ({
  value,
  onChange,
  error,
  required,
  label,
  style,
  disabled = false,
  ...rest
}) => {
  const theme = useTheme()

  const Parchment = Quill.import('parchment')

  class IndentAttributor extends Parchment.Attributor.Style {
    add(node, value) {
      if (value === 0) {
        this.remove(node)
        return true
      } else {
        return super.add(node, `${value}em`)
      }
    }
  }

  // @ts-expect-error no proper typings
  const IndentStyle = new IndentAttributor('indent', 'text-indent', {
    scope: Parchment.Scope.BLOCK,
    whitelist: ['1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em'],
  })

  const alignClass = Quill.import('attributors/style/align')
  const backgroundClass = Quill.import('attributors/style/background')
  const colorClass = Quill.import('attributors/style/color')

  Quill.register(IndentStyle, true)
  Quill.register(alignClass, true)
  Quill.register(backgroundClass, true)
  Quill.register(colorClass, true)

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }, 'bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['link', 'clean'],
    ],
    history: {
      delay: 1000,
    },
  }

  return (
    <div>
      <CaleoInputLabel required={required} label={label} />
      <ReactQuill
        theme="snow"
        modules={modules}
        value={value}
        onChange={onChange}
        style={{ ...style }}
        readOnly={disabled}
        {...rest}
      />
      {!!error && (
        <Grid item xs={12}>
          <span style={{ color: theme.palette.error.main, fontSize: '0.75rem' }}>{error}</span>
        </Grid>
      )}
    </div>
  )
}
