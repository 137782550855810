import { DialogActions, DialogContent, Grid } from '@mui/material'
import newWithSimpleModal from 'Components/reusable/HOC/newWithSimpleModal'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FileDropzone } from 'Components/reusable/InputFields/Dropzone/FileDropzone'
import { IError } from 'types/error'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { cvParserAPI } from 'api/CvParserAPI'
import CaleoPrimaryButton from 'Components/reusable/Buttons/CaleoPrimaryButton'
import { useLanguageData } from 'hooks'
import { PersonId } from 'types/ids'
import { ICv } from 'types/cvsInterfaces'
import About from 'pages/CVPrint/QuickPreview/About'
import Skill from 'pages/CVPrint/QuickPreview/Skill'
import DataLayout from 'pages/CVPrint/QuickPreview/DataLayout'
import colors from 'constants/colors'
import { chooseDBTranslation } from 'utils/translations'
import Language from 'pages/CVPrint/QuickPreview/Language'

/** @notExported */
interface ICvParseModalProps {
  /** The ID of the person. */
  personId: PersonId
  /** Callback function to close the modal. */
  onClose: (cv?: ICv) => void
}

/**
 * Modal component for parsing CV.
 *
 * @param personId - The ID of the person.
 * @param onClose - Callback function to close the modal.
 * @returns The CV parse modal component.
 * @notExported
 */
const CvParseModal: React.FC<ICvParseModalProps> = ({ personId, onClose }) => {
  const { t, i18n } = useTranslation()
  const { languages } = useLanguageData()

  const [cv, setCv] = useState<File | null>(null)
  const [result, setResult] = useState<ICv>()
  const [loading, setLoading] = useState<boolean>(false)
  const [backendError, setBackendError] = useState<IError>()

  const sendFile = async () => {
    if (cv) {
      try {
        setLoading(true)
        const formData = new FormData()

        formData.append('files', cv)
        formData.append('personId', `${personId}`)

        setResult(await cvParserAPI.parseSingleCV(formData))
      } catch (error) {
        setBackendError(error as IError)
      } finally {
        setLoading(false)
      }
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    setCv(acceptedFiles[0])
  }, [])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <>
      <DialogContent>
        <Grid sx={{ display: !result && !loading ? 'inherit' : 'none' }}>
          <FileDropzone
            onDrop={onDrop}
            accept={{
              'application/pdf': ['.pdf'],
              'application/msword': ['.doc'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
              'text/html': ['.html'],
              'text/plain': ['.txt'],
              'application/rtf': ['.rtf'],
              'image/*': ['.jpg', '.jpeg', '.png', '.bmp'],
            }}
            oldFile={cv?.name}
            dragHint={t('dropbox.dragAttachment')}
            dropHint={t('dropbox.dropAttachment')}
            width="390px"
          />
        </Grid>
        {result && languages && (
          <Grid>
            <Grid item xs={12} style={{ marginTop: 40 }}>
              <About
                description={chooseDBTranslation(i18n, result.translations).about}
                layout
                headingColor={colors.secondary}
              />
            </Grid>
            <Grid item xs={12}>
              <Skill
                items={result.PersonSkills}
                headingColor={colors.secondary}
                type="PersonSkills"
                isHidden={false}
                hideLastUsed={false}
              />
            </Grid>
            <Grid item xs={12}>
              <Skill
                items={result.Industries}
                headingColor={colors.secondary}
                type="Industries"
                isHidden={false}
                hideLastUsed={false}
              />
            </Grid>
            <Grid item xs={12}>
              <Skill
                items={result.Roles}
                headingColor={colors.secondary}
                type="Roles"
                isHidden={false}
                hideLastUsed={false}
              />
            </Grid>
            <Grid item xs={12}>
              <DataLayout data={result} type="Employers" headingColor={colors.secondary} />
            </Grid>
            <Grid item xs={12}>
              <DataLayout data={result} type="Education" headingColor={colors.secondary} />
            </Grid>
            <Grid item xs={12}>
              <DataLayout data={result} type="Courses" headingColor={colors.secondary} />
            </Grid>
            <Grid item xs={12}>
              <DataLayout data={result} type="Certificates" headingColor={colors.secondary} />
            </Grid>
            <Grid item xs={12}>
              <Language items={result.LanguageSkills} headingColor={colors.secondary} languages={languages} />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <CaleoPrimaryButton
          loading={loading}
          clickAction={() => {
            !result ? sendFile() : onClose(result)
          }}
          label={!result ? t('send') : t('close')}
          valid={!result ? !!cv : true}
        />
      </DialogActions>
    </>
  )
}

export default newWithSimpleModal(CvParseModal)
