import { cvProjectsAPI, cvRolesAPI, cvPersonSkillsAPI, cvIndustriesAPI } from 'api/CvAPI'
import React, { useEffect, useMemo, useState } from 'react'
import { ICv, IProject } from 'types/cvsInterfaces'
import withProfileCard from 'Components/reusable/HOC/withProfileCard'
import { getProjectInitialData } from 'Components/reusable/DataContext/InitialData'
import { projectSchema } from 'Components/reusable/DataContext/ValidationSchema'
import ProjectItemCard from './ProjectItemCard'
import ProjectModal from './ProjectModal'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported */
interface IProjectsCardProps {
  /** If true, project card can be edited. */
  editable: boolean
  /** CV object. */
  cv: ICv
  /** Function to set updatedAt. */
  setUpdatedAt: () => void
  /** Function to replace or add item. */
  replaceOrAddItem: (newItem) => IProject[]
  /** Function to open item. */
  openItem: (item) => void
  /** Current edited item. */
  editedItem: IProject | null
  /** Function to close modal. */
  closeItem: () => void
  /** List of already added projects. */
  items: IProject[]
  /** Header text of the card. */
  header: string
  /** Function to set skills. */
  setSkills: (items) => void
  /** Function to set industries. */
  setIndustries: (items) => void
  /** Function to set roles. */
  setRoles: (items) => void
  /** Function to update overview data. */
  updateOverviewData: (type: string, data: unknown | unknown[]) => void
  /** If true, project card is anonymous. */
  anonymous?: boolean
  /** Function to update CV progress. */
  updateProgress: () => void
}

/**
 * Card for displaying CV projects.
 *
 * @returns Project card component.
 * @notExported
 */
const ProjectsCard: React.FC<IProjectsCardProps> = ({
  cv,
  editable,
  setUpdatedAt,
  closeItem,
  replaceOrAddItem,
  openItem,
  editedItem,
  items,
  header,
  setSkills,
  setIndustries,
  setRoles,
  updateOverviewData,
  anonymous = false,
  updateProgress,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const [updateConnected, setUpdateConnected] = useState<number>()

  useEffect(() => {
    const controller = new AbortController()

    if (updateConnected) {
      ;(async () => {
        const skills = await cvPersonSkillsAPI.getList(updateConnected, controller)
        const industries = await cvIndustriesAPI.getList(updateConnected, controller)
        const roles = await cvRolesAPI.getList(updateConnected, controller)
        if (isComponentMounted.current) {
          setSkills(skills)
          setIndustries(industries)
          setRoles(roles)
          setUpdateConnected(undefined)
        }
      })()
    }

    return () => {
      controller.abort()
    }
  }, [updateConnected])

  const itemCards = useMemo(
    () =>
      items.map((item, i) => (
        <ProjectItemCard
          key={item.id}
          editable={editable}
          project={item}
          onOpen={() => openItem(item)}
          header={header}
          anonymous={anonymous}
          showDivider={i < items.length - 1}
        />
      )),
    [items]
  )

  return (
    <>
      {itemCards}
      {editedItem !== undefined && (
        <ProjectModal
          key={editedItem?.id ?? 'new'}
          item={editedItem}
          cvId={cv.id}
          localeBase="profile.project.modal"
          schema={projectSchema()}
          initialData={getProjectInitialData(cv.id)}
          maxWidth="lg"
          fullWidth
          api={cvProjectsAPI}
          onClose={({ newItem }) => {
            if (newItem && (newItem.deleted || newItem.id)) {
              setUpdatedAt()
              updateOverviewData('projects', replaceOrAddItem(newItem))
              setUpdateConnected(newItem.cvId)
              updateProgress()
            }
            closeItem()
          }}
          header={header}
          noEscClose
        />
      )}
    </>
  )
}

export default withProfileCard(ProjectsCard)
