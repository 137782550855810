import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export function setWithExpiry(key: string, value: string, ttl: number) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export function getWithExpiry(key: string) {
  const itemString = window.localStorage.getItem(key)
  if (!itemString) return null

  const item = JSON.parse(itemString)
  const isExpired = new Date().getTime() > item.expiry

  if (isExpired) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

export default function ErrorFallback({ error }) {
  const navigate = useNavigate()

  // Handle failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/
    if (
      error?.message &&
      (chunkFailedMessage.test(error.message) ||
        error.message.includes('Importing a module script failed') ||
        error.message.includes('Failed to load module script:'))
    ) {
      if (!getWithExpiry('chunk_failed')) {
        localStorage.clear()
        setWithExpiry('chunk_failed', 'true', 10000)
        navigate(0)
      }
    }
  }, [error])

  return (
    <div>
      <p>Something went wrong.</p>
      <pre>{error?.message}</pre>
    </div>
  )
}
