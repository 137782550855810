import { SubAPI } from './API'
import { IChatRoom, IChatMember } from 'types/chatInterfaces'
import { IAccount } from 'types/userInterfaces'

/**
 * Chat API
 * @notExported
 */
class ChatAPI extends SubAPI {
  /**
   * Get all rooms.
   *
   * @returns Array of chat rooms.
   */
  public getRooms(): Promise<IChatRoom[]> {
    return this.api.get(`chat/`)
  }

  /**
   * Get room details by room name.
   *
   * @returns Chat room details.
   */
  public getRoom(roomName: string): Promise<IChatRoom> {
    return this.api.get(`chat/room/${roomName}`)
  }

  /**
   * Get all invites.
   *
   * @returns Array of chat invites.
   */
  public getInvites(): Promise<IChatMember[]> {
    return this.api.get(`chat/invites`)
  }

  /**
   * Get allowed accounts.
   *
   * @returns Array of accounts.
   */
  public getAllowed(controller?: AbortController): Promise<IAccount[]> {
    return this.api.get('chat/allowed', controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Create chat room.
   *
   * @param data - Chat room data.
   * @returns Created chat room.
   */
  public create(data: IChatRoom): Promise<IChatRoom> {
    return this.api.post(`chat/`, data)
  }

  /**
   * Update chat room.
   *
   * @param data - Chat room data.
   * @returns Updated chat room.
   */
  public save(data: IChatRoom): Promise<IChatRoom> {
    return this.api.put(`chat/${data.id}`, data)
  }

  /**
   * Delete chat room.
   *
   * @param roomId - Chat room ID.
   * @returns Promise of room deletion.
   */
  public delete(roomId: number): Promise<void> {
    return this.api.delete(`chat/${roomId}`)
  }
}

export const chatAPI = new ChatAPI()
