import { Box, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import React, { useEffect, useState } from 'react'
import { IError } from 'types/error'
import { OrganizationId } from 'types/ids'
import { IPerson } from 'types/userInterfaces'
import { fuseFiltering } from 'utils/utils'
import { organizationAPI } from 'api/OrganizationAPI'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported  */
interface IAssignmentVisibilityPickerProps {
  /** The current value. */
  value: IPerson[]
  /** The organization ID. */
  organizationId?: OrganizationId
  /** Action to do when the value changes.  */
  onChange: (newValue: IPerson[] | null) => void
  /** The label of the field. */
  label?: string
  /** Whether the field is required. */
  required?: boolean
  /** Whether the field is disabled. */
  disabled?: boolean
}

/**
 * Contact people picker component.
 *
 * @returns The contact people picker component.
 * @notExported
 */
const ContactPeoplePicker: React.FC<IAssignmentVisibilityPickerProps> = ({
  value,
  organizationId,
  onChange,
  label = 'ContactPeople',
  required = false,
  disabled = false,
  ...rest
}) => {
  const isComponentMounted = useIsComponentMounted()
  const [open, setOpen] = useState<boolean>(false)
  const [items, setItems] = useState<IPerson[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [backendError, setBackendError] = useState<IError>()

  useEffect(() => {
    ;(async () => {
      const controller = new AbortController()

      try {
        setLoading(true)
        if (!organizationId) {
          setItems([])
        } else {
          if (items.length > 0) {
            setLoading(false)
            return
          }
          let people = await organizationAPI.getPersons(organizationId, controller)
          if (isComponentMounted.current) {
            people = people.filter((person: IPerson) => !person.isPlaceholder)

            const contactPeople: IPerson[] = people.map((person: IPerson) => {
              return {
                ...person,
                personId: person.id,
                fullName: `${person?.firstName} ${person?.lastName}`,
                Person: person,
              }
            })

            setItems(contactPeople)
          }
        }
        setLoading(false)
      } catch (error) {
        setBackendError(error as IError)
      }

      return () => {
        controller.abort()
      }
    })()
  }, [organizationId])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <>
      <CaleoInputLabel label={label} required={required} />
      <Autocomplete
        open={open}
        loading={loading}
        disabled={disabled}
        value={value}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        style={{ marginTop: '0px' }}
        onChange={(_event, newValue) => {
          onChange(newValue)
        }}
        filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['fullName'])}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={items}
        getOptionLabel={option => option.fullName ?? `${option.firstName} ${option.lastName}`}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id
        }}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.id}>
            {option.fullName}
          </Box>
        )}
        renderInput={params => <TextField multiline {...params} fullWidth margin="dense" variant="outlined" />}
        fullWidth
        multiple
        {...rest}
      />
    </>
  )
}

export default ContactPeoplePicker
