import { useSearchableNetworks, useUser } from 'hooks'
import React, { useContext, useEffect, useState } from 'react'
import { INetwork } from 'types/networkInterfaces'
import { ISearchFields, ISearchResult } from 'types/searchInterfaces'
import { IAccount } from 'types/userInterfaces'

export interface ISearchData {
  terms: ISearchFields
  results?: ISearchResult[]
  lastFetched: Date
  ownOrganization: boolean
}

interface SearchState {
  searchData: ISearchData
  setSearchData: (data: ISearchData) => void
  resetSearchFields: () => void
  resetSearch: () => void
  ready: boolean
  detailedView: boolean
  setDetailedView: (newValue: boolean) => void
  searchableNetworks?: INetwork[]
  resetFields: boolean
  setResetFields: (value: boolean) => void
}

const searchContext = React.createContext<SearchState>({} as SearchState)

const { Provider } = searchContext

/**
 * Check for own organization auto generated network.
 *
 * @param network - Checked network.
 * @returns True if network is own organization.
 * @notExported
 */
const isOwnOrganization = (network: INetwork) => network.autoGenerated

/**
 * Function for filtering networks based on features and user.
 *
 * @param features - Feature permissions.
 * @param network - Selected network.
 * @param user - Logged in user.
 * @returns True if network is allowed to be searched.
 * @notExported
 */
const filterNetworks = (features: string[], network: INetwork, user?: IAccount | null) => {
  if (user) {
    if (features.length === 1 && features.includes('search')) {
      return network.anonymouslySearchable
    }

    if (features.length > 1) {
      const isBroker = !!network.NetworkBrokers.find(broker => broker.accountId === user.id)

      if (network.anonymouslySearchable === false) {
        if (isBroker) {
          return true
        } else {
          return isOwnOrganization(network)
        }
      }
    }
  }
  return false
}

/**
 * Function for reseting search fields.
 *
 * @param networks - Possible network options.
 * @returns Empty proposal search fields data.
 * @notExported
 */
function constructEmptySearchFields({
  features,
  networks = [],
  user,
}: {
  features: string[]
  networks?: INetwork[]
  user?: IAccount | null
}): ISearchFields {
  return {
    availability: {},
    name: '',
    location: '',
    organizations: 'SEARCH_USERS_ORGANIZATIONS',
    businessAreas: [],
    roles: [],
    skills: [],
    industries: [],
    languages: [],
    certificates: [],
    degrees: [],
    courses: [],
    networks: networks.filter(network => filterNetworks(features, network, user)),
    projectCountItems: [],
  }
}

/**
 * Function for reseting search data.
 *
 * @param networks - Possible network options.
 * @returns Empty search data.
 * @notExported
 */
function constructEmptySearchData({
  features,
  networks = [],
  user,
}: {
  features: string[]
  networks?: INetwork[]
  user?: IAccount | null
}): ISearchData {
  return {
    terms: constructEmptySearchFields({ features, networks, user }),
    lastFetched: new Date(),
    ownOrganization: false,
  }
}

/**
 * Profile search provider.
 *
 * @param children
 * @returns Search provider.
 * @notExported
 */
export const SearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, features, ready } = useUser()
  const { networks } = useSearchableNetworks()
  const [searchData, setSearchData] = useState<ISearchData>(constructEmptySearchData({ features }))
  const [initialNetworksSet, setInitialNetworksSet] = useState(false)
  const [detailedView, setDetailedView] = useState<boolean>(true)
  const [resetFields, setResetFields] = useState(false)

  const searchReady = initialNetworksSet

  const resetSearchFields = () => {
    setSearchData(searchData => ({
      ...searchData,
      terms: constructEmptySearchFields({ user, features, networks }),
    }))
  }

  const resetSearch = () => {
    setSearchData(constructEmptySearchData({ user, features, networks }))
    setResetFields(true)
  }

  useEffect(() => {
    if (!initialNetworksSet && ready && user && networks && networks.length > 0) {
      setSearchData(searchData => ({
        ...searchData,
        terms: {
          ...searchData.terms,
          networks: networks.filter(network => filterNetworks(features, network, user)),
        },
      }))
      setInitialNetworksSet(true)
    }
  }, [initialNetworksSet, ready, user, networks])

  useEffect(() => {
    setSearchData({
      terms: searchData.terms,
      lastFetched: new Date(),
      ownOrganization: searchData.ownOrganization,
      results: undefined,
    })
  }, [searchData.terms])

  useEffect(() => {
    resetSearch()
  }, [user, networks])

  return (
    <Provider
      value={{
        searchData,
        setSearchData,
        resetSearchFields,
        resetSearch,
        ready: searchReady,
        detailedView,
        setDetailedView,
        searchableNetworks: networks,
        resetFields,
        setResetFields,
      }}
    >
      {children}
    </Provider>
  )
}

/**
 * Hook for doing profile searches.
 *
 * @returns Profile search context variables and functions.
 * @notExported
 */
export function useSearchData(): SearchState {
  return useContext(searchContext)
}
