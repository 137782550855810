import { DialogContent } from '@mui/material'
import newWithEditModal from 'Components/reusable/HOC/newWithEditModal'
import DataContext from 'Components/reusable/DataContext'
import React, { useEffect } from 'react'
import { ICv } from 'types/cvsInterfaces'
import { useTranslation } from 'react-i18next'

/** @notExported */
interface ICopyProfileModalProps {
  /** Cv data */
  data: ICv
  /** Set cv data */
  setData: (value: ICv) => void
}

/**
 * Copy profile modal component.
 *
 * @returns Copy profile modal component.
 * @notExported
 */
const CopyProfileModal: React.FC<ICopyProfileModalProps> = ({ data, setData }) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    if (data.translations.length > 1) {
      const newTranslations = data.translations.filter(tr => {
        if (tr.name || tr.Language.name === i18n.language) {
          return true
        }
      })

      setData({ ...data, translations: newTranslations })
    }
  }, [])

  return (
    <DialogContent>
      <DataContext.TranslationImport<ICv> />
      <DataContext.TextField<ICv> field="name" required fullWidth insetLabel />
      <DataContext.TextField<ICv> field="description" multiline grow rows={5} fullWidth insetLabel />
    </DialogContent>
  )
}

export default newWithEditModal(CopyProfileModal)
