import React from 'react'
import { Switch } from '@mui/material'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import CheckIcon from '@mui/icons-material/Check'

/** @notExported  */
interface AllocationStateSwitchProps {
  /** Whether the switch is checked. */
  checked: boolean
  /** The switch change handler. */
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

/**
 * Allocation state switch.
 *
 * @returns Allocation state switch component.
 * @notExported
 */
const AllocationStateSwitch: React.FC<AllocationStateSwitchProps> = ({ checked, onChange }) => {
  return (
    <Switch
      checked={checked}
      icon={
        <HourglassBottomIcon
          sx={{
            backgroundColor: theme => theme.palette.warning.main,
            borderRadius: '100%',
            padding: '2px',
            color: theme => theme.palette.background.paper,
          }}
          fontSize="small"
        />
      }
      checkedIcon={
        <CheckIcon
          sx={{
            backgroundColor: theme => theme.palette.success.main,
            borderRadius: '100%',
            padding: '2px',
            color: theme => theme.palette.background.paper,
          }}
          fontSize="small"
        />
      }
      onChange={onChange}
    />
  )
}

export default AllocationStateSwitch
