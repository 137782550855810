import { IParsedJSON } from 'types/cvParserInterfaces'
import { IAccount } from 'types/userInterfaces'
import { SubAPI } from './API'
import { ICv } from 'types/cvsInterfaces'

/**
 * API for Cv Parser
 * @notExported
 */
class CvParserAPI extends SubAPI {
  public async parseCV(data: FormData, controller?: AbortController): Promise<IParsedJSON[]> {
    return await this.api.post(`cv-parser/parse`, data, controller ? { signal: controller.signal } : undefined, {
      'Content-Type': 'multipart/form-data',
    })
  }

  /**
   * Saves parsed CV to the database.
   *
   * @param data - Parsed CV data.
   * @param saveDetails - Activation, site, organization and network visibility details.
   * @returns Created account and errors.
   */
  public async save(
    data: IParsedJSON[],
    saveDetails: {
      organizationId: number
      siteId: number
      activation: boolean
      networkVisible: boolean
    },
    controller?: AbortController
  ): Promise<{ account?: IAccount; errors: string[] }[]> {
    return await this.api.post(
      `cv-parser/save`,
      { data, details: saveDetails },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Sends single CV to the parser.
   *
   * @param data - CV file data.
   * @returns Parsed CV data.
   */
  public async parseSingleCV(data: FormData, controller?: AbortController): Promise<ICv> {
    return await this.api.post(`cv-parser/parseSingle`, data, controller ? { signal: controller.signal } : undefined, {
      'Content-Type': 'multipart/form-data',
    })
  }

  /**
   * Gets amount of parse credits left for organization.
   *
   * @param organizationId - Organization ID.
   * @returns Number of parse credits left.
   */
  public async getParseCredits(organizationId: number, controller?: AbortController): Promise<number> {
    return await this.api.get(
      `cv-parser/credits/${organizationId}`,
      controller ? { signal: controller.signal } : undefined
    )
  }
}

export const cvParserAPI = new CvParserAPI()
