import Autocomplete from '@mui/material/Autocomplete'
import React from 'react'
import { TypeCode } from 'types/ids'
import { fuseFiltering } from 'utils/utils'
import { InputField } from '../InputField'

export interface ITypeOption {
  label: string
  value: string
}

/** @notExported */
interface ITypePickerProps {
  /** The current value. */
  value: TypeCode | null
  /** The function to call when the value changes. */
  onChange: (newValue: string | null) => void
  /** The label to display. */
  label?: string
  /** Whether the field is required. */
  required?: boolean
  /** Whether the field is disabled. */
  disabled: boolean
  /** The options to display. */
  typeOptions: ITypeOption[]
}

/**
 * Network type picker component.
 *
 * @returns The network type picker component.
 * @notExported
 */
const TypePicker: React.FC<ITypePickerProps> = ({
  value,
  onChange,
  label = 'Type',
  required = false,
  disabled,
  typeOptions,
}) => {
  const getSelectedValue = () => {
    let selected: ITypeOption | undefined

    if (value) {
      selected = typeOptions.find(item => item.value === value)
    }

    if (!selected) {
      selected = typeOptions[0]
    }

    const result = selected ?? {
      code: '',
      label: '',
    }

    return result
  }

  return (
    <Autocomplete
      filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['label'])}
      disabled={disabled}
      value={getSelectedValue()}
      options={typeOptions}
      fullWidth
      selectOnFocus
      disableClearable
      getOptionLabel={option => option.label}
      isOptionEqualToValue={(selectedValue, option) => option.value === selectedValue.value}
      style={{ marginTop: '0px' }}
      onChange={(_event, newValue) => {
        if (newValue !== null) {
          onChange(newValue.value)
        }
      }}
      renderInput={params => (
        <InputField
          {...params}
          name="type"
          margin="dense"
          size="small"
          fullWidth
          variant="outlined"
          label={label}
          required={required}
          InputLabelProps={{ shrink: true }}
          insetLabel
        />
      )}
    />
  )
}

export default TypePicker
