import { INotification, IAdminNotification, IOptoutSetting } from 'types/notificationInterfaces'
import { SubAPI } from './API'

/**
 * Notification API
 * @notExported
 */
class NotificationAPI extends SubAPI {
  /**
   * Get all users notifications.
   *
   * @returns Array of notifications.
   */
  public async getNotifications(controller?: AbortController): Promise<INotification[]> {
    const notifications: INotification[] = []
    const data: {
      accountId: string
      text_fi: string
      text_en: string
      version: string
      type: string
      key: string
      relatedEntities?: string
      createdAt: Date
    }[] = await this.api.get('notifications', controller ? { signal: controller.signal } : undefined)

    for (const notification of data) {
      notifications.push(this.convertToNotification(notification))
    }
    return notifications
  }

  /**
   * Mark notification as read.
   *
   * @param key - Notification key.
   * @returns Updated notification.
   */
  public async setRead(key: string): Promise<INotification> {
    return this.convertToNotification(await this.api.put('notifications/read', { key }))
  }

  /**
   * Mark all notifications as read.
   *
   * @returns Promise of all notifications marked as read.
   */
  public async setAllRead(): Promise<void> {
    return this.api.get('notifications/read/all')
  }

  /**
   * Send notification to users.
   *
   * @param data - Notification data.
   * @returns Promise of notification sending.
   */
  public async sendNotification(data: IAdminNotification, controller?: AbortController): Promise<void> {
    return this.api.post('notifications', data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Convert notification data from API to INotification.
   *
   * @param data - Notification data from API.
   * @returns Converted notification.
   */
  convertToNotification(data): INotification {
    return {
      accountId: parseInt(data.accountId),
      text_fi: data.text_fi,
      text_en: data.text_en,
      version: data.version,
      type: data.type,
      key: data.key,
      relatedEntities: data.relatedEntities ? JSON.parse(data.relatedEntities) : undefined,
      unread: data.key && data.key.includes('unread') ? true : false,
      createdAt: data.createdAt,
    }
  }

  /**
   * Get notification settings for a user.
   *
   * @param id - User id.
   * @returns Notification settings.
   */
  public async getNotificationSettings(id: number, controller?: AbortController): Promise<IOptoutSetting[]> {
    return this.api.get(`notifications/optout/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Update notification settings for a user.
   *
   * @param id - User id.
   * @param data - Notification settings data.
   * @returns Promise of notification settings update.
   */
  public async putNotificationSettings(
    id: number,
    data: IOptoutSetting[],
    controller?: AbortController
  ): Promise<void> {
    return this.api.put(
      `notifications/optout/${id}`,
      { statuses: data },
      controller ? { signal: controller.signal } : undefined
    )
  }
}

export const notificationAPI = new NotificationAPI()
