import LinkIcon from '@mui/icons-material/Link'
import { Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { chooseDBTranslation } from 'utils/translations'
import { IUrl } from 'types/cvsInterfaces'
import CaleoIconButton from 'Components/reusable/IconButtons/CaleoIconButton'

/** @notExported */
interface IProps {
  /** Selected URL. */
  url: IUrl
  /** Function to edit URL. */
  editUrl: (url: IUrl) => void
}

/**
 * Profile component for displaying URL.
 *
 * @returns Profile component for displaying URL.
 * @notExported
 */
const LinkItem: React.FC<IProps> = ({ url, editUrl }) => {
  const { t, i18n } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  if (url.translations.length < 1) {
    return null
  }

  let languageIndex = url.translations.findIndex(tr => tr.Language.name === i18n.language)
  if (languageIndex < 0) {
    languageIndex = 0
  }

  const translation = chooseDBTranslation(i18n, url)

  return (
    <>
      <CaleoIconButton
        size="xsmall"
        tooltip={
          <>
            <div>{translation.description}</div>
            <div>{url.url}</div>
          </>
        }
        clickAction={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        icon={<LinkIcon fontSize="small" />}
      />
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            editUrl(url)
            setAnchorEl(null)
          }}
        >
          {t('edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(url.url, '_blank', 'noopener,noreferrer')
            setAnchorEl(null)
          }}
        >
          {t('open')}
        </MenuItem>
      </Menu>
    </>
  )
}

export default LinkItem
