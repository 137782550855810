import { Chip, Box, Grid } from '@mui/material'
import colors from 'constants/colors'
import React from 'react'
import { pickSkillColor, skillLevels, languageLevels } from 'utils/utils'

/** @notExported  */
interface SkillLevelChipProps {
  /** Skill or language. */
  type: 'skill' | 'language'
  /** Skill level. */
  level: number
}

/**
 * Skill level chip.
 *
 * @returns Skill level chip.
 * @notExported
 */
const SkillLevelChip: React.FC<SkillLevelChipProps> = ({ type, level }) => {
  const levels = type === 'skill' ? skillLevels() : languageLevels()

  return (
    <Chip
      sx={{
        backgroundColor: pickSkillColor(level, true),
        marginY: 1,
      }}
      avatar={
        <Grid
          style={{
            borderRadius: 16,
            backgroundColor: pickSkillColor(level),
          }}
        >
          <Box
            sx={{
              color: colors.background,
              fontSize: '0.9rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 'inherit',
            }}
          >
            {level}
          </Box>
        </Grid>
      }
      label={
        <>
          <Box sx={{ color: pickSkillColor(level) }}>
            {levels.find(levelValue => levelValue.level === level)?.label ?? null}
          </Box>
        </>
      }
    />
  )
}

export default SkillLevelChip
