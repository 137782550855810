import { chooseDBTranslation } from 'utils/translations'
import { Paragraph, TextRun } from 'docx'
import { CVData } from './index'
import { convertToDisplayDate } from 'utils/utils'
import colors from 'constants/colors'

/**
 * Generates a list of paragraphs based on the provided CV data and type.
 *
 * @param {string} i18n - the internationalization object
 * @param {CVData} data - the CV data object
 * @param {string} type - the type of data to generate paragraphs for
 * @param {function} t - the translation function
 * @return {Paragraph[]} an array of generated paragraphs
 * @notExported
 */
const education = (i18n, data: CVData, type: string, showCertificateExpiry, t): Paragraph[] => {
  const items: Paragraph[] = []

  switch (type) {
    case 'degrees':
      for (let i = 0; i < data.degrees.length; i++) {
        const degree = data.degrees[i]

        let date = ''
        if (degree.startDate) {
          date += `${convertToDisplayDate(degree.startDate, degree.dateResolution)} - `
        }
        if (degree.endDate) {
          date += `${convertToDisplayDate(degree.endDate, degree.dateResolution)}`
        }

        items.push(
          new Paragraph({
            keepLines: true,
            style: 'CaleoNormal',
            children: [
              new TextRun({
                text: degree ? chooseDBTranslation(i18n, degree).school : '',
                bold: true,
                break: 1,
              }),
              new TextRun({
                text: degree ? date : '',
                color: colors.gray,
                break: 1,
              }),
              new TextRun({
                text: `${t('print.degree')}: `,
                bold: true,
                break: 1,
              }),
              new TextRun({
                text: degree ? chooseDBTranslation(i18n, degree).degree : '',
              }),
              new TextRun({
                text: degree ? chooseDBTranslation(i18n, degree).description : '',
                break: 1,
              }),
            ],
          })
        )
      }
      break

    case 'courses':
      for (let i = 0; i < data.courses.length; i++) {
        const course = data.courses[i]
        let date = ''
        if (course.startDate) {
          date += `${convertToDisplayDate(course.startDate, course.dateResolution)} - `
        }
        if (course.endDate) {
          date += `${convertToDisplayDate(course.endDate, course.dateResolution)}`
        }

        items.push(
          new Paragraph({
            keepLines: true,
            style: 'CaleoNormal',
            children: [
              new TextRun({
                text: course ? chooseDBTranslation(i18n, course).course : '',
                bold: true,
                break: 1,
              }),
              new TextRun({
                text: course ? date : '',
                color: colors.gray,
                break: 1,
              }),
              new TextRun({
                text: `${t('print.organizer')}: `,
                bold: true,
                break: 1,
              }),
              new TextRun({
                text: course ? chooseDBTranslation(i18n, course).organizer : '',
              }),
              new TextRun({
                text: course ? chooseDBTranslation(i18n, course).description : '',
                break: 1,
              }),
            ],
          })
        )
      }
      break

    case 'certificates':
      for (let i = 0; i < data.certificates.length; i++) {
        const certificate = data.certificates[i]
        let date = ''
        if (certificate.issueDate) {
          date += `${convertToDisplayDate(certificate.issueDate, certificate.dateResolution)}`
        }
        let endDate = ''
        if (certificate.endDate) {
          endDate += `${convertToDisplayDate(certificate.endDate, certificate.dateResolution)}`
        }

        items.push(
          new Paragraph({
            keepLines: true,
            style: 'CaleoNormal',
            children: [
              new TextRun({
                text: certificate ? chooseDBTranslation(i18n, certificate).certificate : '',
                bold: true,
                break: 1,
              }),
              new TextRun({
                text: certificate ? date : '',
                color: colors.gray,
                break: 1,
              }),
              new TextRun({
                text: `${t('print.certifier')}: `,
                bold: true,
                break: 1,
              }),
              new TextRun({
                text: certificate ? chooseDBTranslation(i18n, certificate).certifier : '',
              }),
              ...(showCertificateExpiry && endDate
                ? [
                    new TextRun({
                      text: `${t('print.certificateExpiry')}: `,
                      bold: true,
                      break: 1,
                    }),
                    new TextRun({
                      text: certificate ? endDate : '',
                    }),
                  ]
                : []),
              ...(certificate.url
                ? [
                    new TextRun({
                      text: `${t('print.url')}: `,
                      bold: true,
                      break: 1,
                    }),
                    new TextRun({
                      text: certificate ? certificate.url : '',
                    }),
                  ]
                : []),
              new TextRun({
                text: certificate ? chooseDBTranslation(i18n, certificate).description : '',
                break: 1,
              }),
            ],
          })
        )
      }
      break
  }

  return items
}

export default education
