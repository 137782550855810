import { CircularProgress, Grid, Typography } from '@mui/material'
import { allocationAPI } from 'api/AllocationAPI'
import colors from 'constants/colors'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IAllocation } from 'types/allocationInterfaces'
import { convertToDisplayDate } from 'utils/utils'
import CardContentText from '../CaleoCustomComponents/CardContentText'
import { CustomTooltip } from '../CaleoCustomComponents/CustomTooltip'
import WarningIcon from '@mui/icons-material/Warning'
import { add, startOfMonth, format } from 'date-fns'
import { enGB } from 'date-fns/locale'
import { fi } from 'date-fns/locale'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported  */
interface IAllocationTimelineProps {
  /** Person ID */
  personId: number | string
  /** Allocations data */
  data?: IAllocation[] | null
  /** Is role view? (default: false) */
  isRoleView?: boolean
}

/**
 * Allocation timeline for person.
 *
 * @returns Allocation timeline component
 * @notExported
 */
const AllocationTimeline: React.FC<IAllocationTimelineProps> = ({ personId, data, isRoleView = false }) => {
  const isComponentMounted = useIsComponentMounted()
  const [allocations, setAllocations] = useState<IAllocation[]>([])
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      const controller = new AbortController()

      setLoading(true)
      if (personId && typeof personId === 'number') {
        if (!data || isRoleView === true) {
          // teams view
          const results = await allocationAPI.getAllocations(personId, controller)
          if (isComponentMounted.current) setAllocations(results)
        } else {
          // search view
          setAllocations(data)
        }
      } else if (personId && typeof personId === 'string') {
        const results = await allocationAPI.getAllocationsForPublicId(personId, controller)
        if (isComponentMounted.current) setAllocations(results)
      } else {
        setAllocations([])
      }
      setLoading(false)

      return () => {
        controller.abort()
      }
    })()
  }, [personId])

  // Make the array of dates from first day of the current month until the first day of the next years current month.
  const getDatesBetween = (startDate: Date, endDate: Date) => {
    const dates: Date[] = []
    const theDate = new Date(startDate)
    while (theDate < endDate) {
      dates.push(new Date(theDate))
      theDate.setDate(theDate.getDate() + 1)
    }
    return dates
  }

  const currentYear = new Date().getFullYear()

  const startOfCurrentMonth = startOfMonth(new Date())
  const plusOneYear = add(startOfCurrentMonth, { years: 1 })

  const datesArray = getDatesBetween(startOfCurrentMonth, plusOneYear)

  const dayBoxWidth = 100 / datesArray.length

  const getBox = (boxColor: string, boxState: 0 | 1, dayCount: number, date: Date, index: number) => {
    const endDate = convertToDisplayDate(date, 'day')
    const startDate = convertToDisplayDate(date.setDate(date.getDate() - dayCount + 1), 'day')
    let redStripes = false
    let showStripes = true
    const pendingText = (
      <Grid container alignItems="center">
        <Grid item>
          <WarningIcon fontSize="small" />
        </Grid>
        <Grid item>
          <CardContentText type="tooltip">{t('allocation.pending')}</CardContentText>
        </Grid>
      </Grid>
    )
    const boxTitle = () => {
      if (boxColor === colors.borders) {
        showStripes = false
        return <CardContentText type="tooltip" fontWeight="bold">{`0%`}</CardContentText>
      } else if (boxColor === colors.orange) {
        return (
          <>
            {boxState < 1 && pendingText}
            <CardContentText type="tooltip">
              {startDate} - {endDate}
            </CardContentText>
            <CardContentText type="tooltip" fontWeight="bold">{`, <100%`}</CardContentText>
          </>
        )
      } else {
        redStripes = true
        return (
          <>
            {boxState < 1 && pendingText}
            <CardContentText type="tooltip">
              {startDate} - {endDate}
            </CardContentText>
            <CardContentText type="tooltip" fontWeight="bold">{`, 100%`}</CardContentText>
          </>
        )
      }
    }

    return (
      <CustomTooltip title={boxTitle()} aria-label="allocation" key={index}>
        <Grid
          style={{
            background: boxState > 0 ? boxColor : 'undefined',
            minWidth: `${dayCount * dayBoxWidth}%`,
          }}
          sx={
            showStripes
              ? {
                  ...(redStripes
                    ? {
                        backgroundImage: theme =>
                          `linear-gradient(135deg,${theme.palette.error.main} 12.50%, #ffffff 12.50%, #ffffff 50%, ${theme.palette.error.main} 50%, ${theme.palette.error.main} 62.50%, #ffffff 62.50%, #ffffff 100%)`,
                        backgroundSize: `5.66px 5.66px`,
                      }
                    : {
                        backgroundImage: theme =>
                          `linear-gradient(135deg,${theme.palette.warning.main} 12.50%, #ffffff 12.50%, #ffffff 50%, ${theme.palette.warning.main} 50%, ${theme.palette.warning.main} 62.50%, #ffffff 62.50%, #ffffff 100%)`,
                        backgroundSize: `5.66px 5.66px`,
                      }),
                }
              : {}
          }
        />
      </CustomTooltip>
    )
  }

  type AllocationUnit = {
    allocationPercent: number
    date: Date
    allocationState: number
  }

  const getPercentArray = (datesArray: Date[], allocations: IAllocation[]): AllocationUnit[] => {
    const allocationPercentArray: AllocationUnit[] = []
    datesArray.forEach(date => {
      const thisDateAllocations = allocations.filter(
        allocation =>
          (allocation.startDate <= date && allocation.endDate >= date) ||
          (allocation.startDate <= date && allocation.endDate === null)
      )
      const allocationPercent = thisDateAllocations.reduce((sum, current) => sum + current.percent, 0)
      const allocationState = thisDateAllocations.reduce((sum, current) => sum + current.state, 0)
      allocationPercentArray.push({ allocationPercent, date, allocationState })
    })
    return allocationPercentArray
  }

  let currentMonth = new Date().getMonth()

  const getMonths = (dates: Date[]) => {
    return dates.map((date: Date, index: number) => {
      const currentDate = new Date(currentYear, currentMonth, 1)
      if (currentDate.getDate() === date.getDate() && currentDate.getMonth() === date.getMonth()) {
        const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
        currentMonth += 1
        return (
          <Grid key={index} borderLeft={1} style={{ width: `${daysInMonth * dayBoxWidth}%` }}>
            <Grid container alignItems="flex-end" style={{ fontSize: 12, marginLeft: 2, height: 15 }}>
              <Typography textTransform="none" fontSize="12px">
                {format(new Date(currentYear, currentMonth - 1, 1), 'LLL', {
                  locale: i18n.language === 'fi' ? fi : enGB,
                })}
              </Typography>
            </Grid>
          </Grid>
        )
      }
    })
  }

  const getColor = (percentValue: number): string => {
    if (percentValue === 0) {
      return colors.background
    } else if (percentValue < 100) {
      return colors.orange
    } else {
      return colors.red
    }
  }

  const allocationsIndicator: JSX.Element[] = []
  if (allocations.length > 0) {
    let prevColor
    let prevState
    let boxCount = 0
    let endDate
    getPercentArray(datesArray, allocations).forEach((item, index: number) => {
      const currentColor = getColor(item.allocationPercent)
      const currentState = item.allocationState > 0 ? 1 : 0
      if (!prevColor || (prevColor === currentColor && prevState === currentState)) {
        boxCount += 1
        endDate = item.date
      } else {
        allocationsIndicator.push(getBox(prevColor, prevState, boxCount, endDate, index))
        endDate = item.date
        boxCount = 1
      }
      prevState = currentState
      prevColor = currentColor
    })
    allocationsIndicator.push(getBox(prevColor, prevState, boxCount, endDate, boxCount))
    endDate.setDate(endDate.getDate() + 1)
  } else {
    allocationsIndicator.push(
      <CustomTooltip
        style={{ width: `${dayBoxWidth * datesArray.length}%` }}
        title={<CardContentText type="tooltip" fontWeight="bold">{`0%`}</CardContentText>}
        aria-label="allocations"
        key={1}
      >
        <Grid
          style={{
            background: colors.borders,
            minWidth: `${dayBoxWidth * datesArray.length}%`,
          }}
        />
      </CustomTooltip>
    )
  }

  if (loading) {
    return (
      <Grid container justifyContent="center" style={{ margin: 10 }}>
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <Grid
      item
      sx={{
        marginTop: '10px',
        marginBottom: '10px',
        opacity: 0.75,
        '&:hover': {
          opacity: 1,
        },
        width: '100%',
      }}
    >
      <Grid container style={{ height: 15, background: colors.background }}>
        {allocationsIndicator}
      </Grid>
      <Grid container>{getMonths(datesArray)}</Grid>
      <Grid container direction="row" justifyContent="space-between" style={{ fontSize: 10 }}>
        <Grid item>{convertToDisplayDate(startOfCurrentMonth)}</Grid>
        <Grid item>{convertToDisplayDate(plusOneYear)}</Grid>
      </Grid>
    </Grid>
  )
}

export default AllocationTimeline
