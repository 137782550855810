import { Grid, Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ICVData, ILayout } from 'types/layoutInterfaces'
import About from './About'
import Education from './Education'
import LanguageSkills from './LanguageSkills'
import Projects from './Projects'
import References from './References'
import Skills from './Skills'
import Roles from './Skills'
import Industries from './Skills'
import Url from './Url'
import WorkHistory from './WorkHistory'

/** @notExported */
interface IControlSectionsProps {
  /** CV data  */
  data: ICVData
  /** CV layout */
  layout: ILayout
  /** Function to hide section */
  hideSection: (value: boolean, type: string) => void
  /** Function to hide item */
  hideItem: (value: boolean, id: number, type: string) => void
}

/**
 * Control sections component.
 *
 * @returns Control sections component.
 * @notExported
 */
const ControlSections: React.FC<IControlSectionsProps> = ({ data, layout, hideSection, hideItem }) => {
  const { t } = useTranslation()
  const [aboutStatus, setAboutStatus] = useState<boolean>(false)
  const [educationStatus, setEducationStatus] = useState<boolean>(false)
  const [workHistoryStatus, setWorkHistoryStatus] = useState<boolean>(false)
  const [projectsStatus, setProjectsStatus] = useState<boolean>(false)
  const [referencesStatus, setReferencesStatus] = useState<boolean>(false)
  const [skillsStatus, setSkillsStatus] = useState<boolean>(false)
  const [industriesStatus, setIndustriesStatus] = useState<boolean>(false)
  const [rolesStatus, setRolesStatus] = useState<boolean>(false)
  const [languageSkillsStatus, setLanguageSkillsStatus] = useState<boolean>(false)
  const [urlStatus, setUrlStatus] = useState<boolean>(false)
  const educations = { degrees: data.degrees, courses: data.courses, certificates: data.certificates }

  const setStatus = (value: boolean, sectionType: string) => {
    if (value === true) {
      if (sectionType === 'about') setAboutStatus(false)
      if (sectionType === 'education') setEducationStatus(false)
      if (sectionType === 'employments') setWorkHistoryStatus(false)
      if (sectionType === 'projects') setProjectsStatus(false)
      if (sectionType === 'references') setReferencesStatus(false)
      if (sectionType === 'skills') setSkillsStatus(false)
      if (sectionType === 'industries') setIndustriesStatus(false)
      if (sectionType === 'roles') setRolesStatus(false)
      if (sectionType === 'languages') setSkillsStatus(false)
      if (sectionType === 'url') setUrlStatus(false)
    }
  }

  const closeAll = () => {
    setAboutStatus(false)
    setEducationStatus(false)
    setWorkHistoryStatus(false)
    setProjectsStatus(false)
    setReferencesStatus(false)
    setSkillsStatus(false)
    setIndustriesStatus(false)
    setRolesStatus(false)
    setLanguageSkillsStatus(false)
    setUrlStatus(false)
  }

  const hideAndCloseSection = (value: boolean, type: string) => {
    hideSection(value, type)
    setStatus(value, type)
  }

  return (
    <Grid container spacing={2}>
      {(aboutStatus ||
        educationStatus ||
        workHistoryStatus ||
        projectsStatus ||
        referencesStatus ||
        skillsStatus ||
        industriesStatus ||
        rolesStatus ||
        languageSkillsStatus ||
        urlStatus) && (
        <Button size="small" onClick={closeAll} style={{ margin: '4px' }}>
          {t('print.layout.closeAll')}
        </Button>
      )}
      <Grid item xs={12}>
        <About
          about={data.about}
          layout={layout}
          hideSection={hideAndCloseSection}
          expanded={aboutStatus}
          setStatus={setAboutStatus}
        />
      </Grid>
      <Grid item xs={12}>
        <Skills
          type="skills"
          items={data.skills}
          layout={layout}
          hideSection={hideAndCloseSection}
          hideItem={hideItem}
          expanded={skillsStatus}
          setStatus={setSkillsStatus}
        />
      </Grid>
      <Grid item xs={12}>
        <Industries
          type="industries"
          items={data.industries}
          layout={layout}
          hideSection={hideAndCloseSection}
          hideItem={hideItem}
          expanded={industriesStatus}
          setStatus={setIndustriesStatus}
        />
      </Grid>
      <Grid item xs={12}>
        <Roles
          type="roles"
          items={data.roles}
          layout={layout}
          hideSection={hideAndCloseSection}
          hideItem={hideItem}
          expanded={rolesStatus}
          setStatus={setRolesStatus}
        />
      </Grid>
      <Grid item xs={12}>
        <WorkHistory
          employments={data.employments}
          layout={layout}
          hideSection={hideAndCloseSection}
          hideItem={hideItem}
          expanded={workHistoryStatus}
          setStatus={setWorkHistoryStatus}
        />
      </Grid>
      <Grid item xs={12}>
        <Projects
          projects={data.projects}
          layout={layout}
          hideSection={hideAndCloseSection}
          hideItem={hideItem}
          expanded={projectsStatus}
          setStatus={setProjectsStatus}
        />
      </Grid>
      <Grid item xs={12}>
        <Education
          educations={educations}
          layout={layout}
          hideSection={hideAndCloseSection}
          hideItem={hideItem}
          expanded={educationStatus}
          setStatus={setEducationStatus}
        />
      </Grid>
      <Grid item xs={12}>
        <References
          references={data.references}
          layout={layout}
          hideSection={hideAndCloseSection}
          hideItem={hideItem}
          expanded={referencesStatus}
          setStatus={setReferencesStatus}
        />
      </Grid>
      <Grid item xs={12}>
        <LanguageSkills
          languages={data.languages}
          layout={layout}
          hideSection={hideAndCloseSection}
          hideItem={hideItem}
          expanded={languageSkillsStatus}
          setStatus={setLanguageSkillsStatus}
        />
      </Grid>
      <Grid item xs={12}>
        <Url
          urls={data.urls}
          layout={layout}
          hideSection={hideAndCloseSection}
          hideItem={hideItem}
          expanded={urlStatus}
          setStatus={setUrlStatus}
        />
      </Grid>
    </Grid>
  )
}

export default React.memo(ControlSections)
