import { Box } from '@mui/material'
import NotificationModal from 'Components/General/Toolbar/NotificationModal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

/**
 * Renders the Unsubscribe component.
 *
 * @return {JSX.Element} The rendered Unsubscribe component.
 * @notExported
 */
const Unsubscribe = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Box p={{ md: 3, sm: 1, xs: 0 }} mt={{ xs: 1 }}>
      <NotificationModal onClose={() => navigate('/')} allowSubmit={t('save')} header={t('notificationSettings')} />
    </Box>
  )
}

export default Unsubscribe
