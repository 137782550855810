import { Button, CircularProgress } from '@mui/material'
import React from 'react'

/** @notExported */
interface SaveButtonProps {
  /** Action to perform on click. */
  clickAction: () => void
  /** Validation state. */
  valid: boolean
  /** Button label. */
  label
  /** Disabled state. */
  disabled?: boolean
  /** Loading state. */
  loading?: boolean
  /** Button color. */
  color?: 'success' | 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' | 'newPrimary' | undefined
}

/**
 * Save button.
 *
 * @returns Save button component.
 * @notExported
 * @category Buttons
 */
const SaveButton: React.FC<SaveButtonProps> = ({ clickAction, valid, label, disabled = false, loading, color }) => {
  return (
    <Button
      variant="contained"
      color={color ? color : 'success'}
      aria-label={label}
      disabled={!valid || disabled}
      onClick={() => clickAction()}
    >
      {loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : label}
    </Button>
  )
}

export default SaveButton
