import React, { useState, useEffect } from 'react'
import { IOverviewData, IBlock, IAllocationData, IAllocationFilters, IAllocation } from 'types/allocationInterfaces'
import { IError } from 'types/error'
import { IPerson } from 'types/userInterfaces'
import { Avatar, Grid, Typography, useTheme } from '@mui/material'
import { allocationAPI } from 'api/AllocationAPI'
import { blockStyle } from 'pages/Allocation/styles'
import { convertToDisplayDate } from 'utils/utils'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { addDays, addWeeks, addMonths, subDays } from 'date-fns'
import { orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import AllocationDetails from './AllocationDetails'
import DragEditModal from './DragEditModal/DragEditModal'
import PersonAllocationBlocks from './PersonAllocationBlocks'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported */
interface IOverviewSummaryProps {
  /** Overview filters */
  filters: IAllocationFilters
  /** Time scope for shown allocations */
  timeScope: number
  /** Allocation scope */
  scope: number[]
  /** Function to delete an item   */
  deleteItem: (value: IAllocation) => void
  /** Function to set edited item */
  setEditedItem: (value: IAllocation | null | undefined) => void
  /** Should the overview be updated */
  updateFromEditing: Date | undefined
}

/**
 * Overview summary component.
 *
 * @returns Overview summary component.
 * @notExported
 */
const OverviewSummary: React.FC<IOverviewSummaryProps> = ({
  filters,
  timeScope,
  scope,
  deleteItem,
  setEditedItem,
  updateFromEditing,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const theme = useTheme()
  const { t } = useTranslation()
  const [detailsOpen, setDetailsOpen] = useState<{ allocation: IAllocationData; person: IPerson }>()
  const [data, setData] = useState<IOverviewData[]>()
  const [open, setOpen] = useState<boolean>(false)
  const [personId, setPersonId] = useState<number>()
  const [allocationData, setAllocationData] = useState<IAllocationData[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [backendError, setBackendError] = useState<IError>()
  const [updateData, setUpdateData] = useState<Date>()
  const [editOpen, setEditOpen] = useState<IPerson>()

  useEffect(() => {
    if (updateFromEditing) setUpdateData(updateFromEditing)
  }, [updateFromEditing])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        const overviewData = orderBy(
          await allocationAPI.getOverviewData({ filters, timeScope, scope }, controller),
          ['person.firstName', 'person.lastName'],
          ['asc', 'asc']
        )

        if (!isComponentMounted.current) return
        setData(overviewData)
      } catch (error) {
        setBackendError(error as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [filters, timeScope, updateData, scope])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        setLoading(true)
        if (personId && open && !(allocationData && allocationData.length && allocationData[0].personId === personId)) {
          const results = await allocationAPI.getPersonAllocationBlocks(personId, timeScope, filters, controller)
          if (!isComponentMounted.current) return
          setAllocationData(results)
        }
        if (personId && timeScope) {
          const results = await allocationAPI.getPersonAllocationBlocks(personId, timeScope, filters, controller)
          if (!isComponentMounted.current) return
          setAllocationData(results)
        }
      } catch (error) {
        setBackendError(error as IError)
      } finally {
        if (isComponentMounted.current) setLoading(false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [filters, personId, open, updateData, timeScope])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const updatePerson = (result: IPerson) => {
    if (data) {
      const newData = [...data]

      const index = newData.findIndex(item => item.person.id === result.id)
      newData[index].person.allocationInfo = result.allocationInfo

      setData(newData)
    }
  }

  const personRow = (item: IOverviewData) => {
    const singleBlock: boolean = item.blocks.filter(block => block.percent > 0).length === 1

    return (
      <>
        <Grid
          container
          spacing={1}
          mb={0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setLoading(true)
            if (personId && personId === item.person.id) {
              setOpen(!open)
            } else {
              setPersonId(item.person.id)
              setOpen(true)
            }
          }}
        >
          <Grid item xs={5} md={4} lg={3} xl={3}>
            <Grid container sx={{ fontWeight: 'bold' }} spacing={0.5} pb={0.5}>
              <Grid item sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Grid container wrap="nowrap" alignItems="center" sx={{ fontSize: '1em', fontWeight: 'normal' }}>
                  <Avatar src={item.profileImage} sx={{ width: 22, height: 22, marginRight: 1 }} variant="circular" />
                  {`${item.person.firstName} ${item.person.lastName}`}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7} md={8} lg={9} xl={9}>
            <Grid container spacing={0} wrap="nowrap">
              {item.blocks.map((block, index) => personBlock(block, index, singleBlock))}
            </Grid>
          </Grid>
        </Grid>
        {open && personId === item.person.id && allocationData && (
          <PersonAllocationBlocks
            allocationData={allocationData}
            person={item.person}
            timeScope={timeScope}
            setDetailsOpen={setDetailsOpen}
            loading={loading}
            setEditOpen={setEditOpen}
            updatePerson={updatePerson}
          />
        )}
      </>
    )
  }

  const personBlock = (block: IBlock, index: number, singleBlock: boolean) => {
    const style = blockStyle(block, timeScope)

    return (
      <Grid key={index} container style={style} alignContent="center" justifyContent="center">
        <Grid item sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {`${block.percent}%${singleBlock ? ` (${block.endDate ? convertToDisplayDate(block.endDate) : '-'})` : ''}`}
        </Grid>
      </Grid>
    )
  }

  const getDateRange = () => {
    const today = new Date(),
      firstEnd = subDays(addWeeks(today, 1), 1),
      secondStart = addDays(firstEnd, 1),
      secondEnd = subDays(addWeeks(secondStart, 1), 1),
      thirdStart = addDays(secondEnd, 1),
      thirdEnd = subDays(addWeeks(thirdStart, 1), 1),
      fourthStart = addDays(thirdEnd, 1),
      fourthEnd = subDays(addWeeks(fourthStart, 1), 1),
      firstMonthEnd = subDays(addMonths(today, 1), 1),
      secondMonthStart = addDays(firstMonthEnd, 1),
      secondMonthEnd = subDays(addMonths(secondMonthStart, 1), 1),
      thirdMonthStart = addDays(secondMonthEnd, 1),
      thirdMonthEnd = subDays(addMonths(thirdMonthStart, 1), 1),
      fourthMonthStart = addDays(thirdMonthEnd, 1),
      fourthMonthEnd = subDays(addMonths(fourthMonthStart, 1), 1)

    switch (timeScope) {
      case 1:
        return (
          <Grid container spacing={0}>
            <Grid
              sx={{ borderLeft: `1px solid ${theme.palette.primary.main}`, fontSize: '1em', fontWeight: 'bold' }}
              container
              justifyContent="center"
              item
              xs={3}
              py={0.5}
            >
              {`${convertToDisplayDate(today)} - ${convertToDisplayDate(firstEnd)}`}
            </Grid>
            <Grid
              sx={{ borderLeft: `1px solid ${theme.palette.primary.main}`, fontSize: '1em', fontWeight: 'bold' }}
              container
              justifyContent="center"
              item
              xs={3}
              py={0.5}
            >
              {`${convertToDisplayDate(secondStart)} - ${convertToDisplayDate(secondEnd)}`}
            </Grid>
            <Grid
              sx={{ borderLeft: `1px solid ${theme.palette.primary.main}`, fontSize: '1em', fontWeight: 'bold' }}
              container
              justifyContent="center"
              item
              xs={3}
              py={0.5}
            >
              {`${convertToDisplayDate(thirdStart)} - ${convertToDisplayDate(thirdEnd)}`}
            </Grid>
            <Grid
              sx={{
                borderLeft: `1px solid ${theme.palette.primary.main}`,
                fontSize: '1em',
                fontWeight: 'bold',
                borderRight: `1px solid ${theme.palette.primary.main}`,
              }}
              container
              justifyContent="center"
              item
              xs={3}
              py={0.5}
            >
              {`${convertToDisplayDate(fourthStart)} - ${convertToDisplayDate(fourthEnd)}`}
            </Grid>
          </Grid>
        )

      case 2:
        return (
          <Grid container spacing={0}>
            <Grid
              sx={{ borderLeft: `1px solid ${theme.palette.primary.main}`, fontSize: '1em', fontWeight: 'bold' }}
              container
              justifyContent="center"
              item
              xs={6}
              py={0.5}
            >
              {`${convertToDisplayDate(today)} - ${convertToDisplayDate(firstMonthEnd)}`}
            </Grid>
            <Grid
              sx={{
                borderRight: `1px solid ${theme.palette.primary.main}`,
                borderLeft: `1px solid ${theme.palette.primary.main}`,
                fontSize: '1em',
                fontWeight: 'bold',
              }}
              container
              justifyContent="center"
              item
              xs={6}
              py={0.5}
            >
              {`${convertToDisplayDate(secondMonthStart)} - ${convertToDisplayDate(secondMonthEnd)}`}
            </Grid>
          </Grid>
        )

      case 3:
        return (
          <Grid container spacing={0}>
            <Grid
              sx={{ borderLeft: `1px solid ${theme.palette.primary.main}`, fontSize: '1em', fontWeight: 'bold' }}
              container
              justifyContent="center"
              item
              xs={4}
              py={0.5}
            >
              {`${convertToDisplayDate(today)} - ${convertToDisplayDate(firstMonthEnd)}`}
            </Grid>
            <Grid
              sx={{ borderLeft: `1px solid ${theme.palette.primary.main}`, fontSize: '1em', fontWeight: 'bold' }}
              container
              justifyContent="center"
              item
              xs={4}
              py={0.5}
            >
              {`${convertToDisplayDate(secondMonthStart)} - ${convertToDisplayDate(secondMonthEnd)}`}
            </Grid>
            <Grid
              sx={{
                borderRight: `1px solid ${theme.palette.primary.main}`,
                borderLeft: `1px solid ${theme.palette.primary.main}`,
                fontSize: '1em',
                fontWeight: 'bold',
              }}
              container
              justifyContent="center"
              item
              xs={4}
              py={0.5}
            >
              {`${convertToDisplayDate(thirdMonthStart)} - ${convertToDisplayDate(thirdMonthEnd)}`}
            </Grid>
          </Grid>
        )

      case 4:
        return (
          <Grid container spacing={0}>
            <Grid
              sx={{ borderLeft: `1px solid ${theme.palette.primary.main}`, fontSize: '1em', fontWeight: 'bold' }}
              container
              justifyContent="center"
              item
              xs={3}
              py={0.5}
            >
              {`${convertToDisplayDate(today)} - ${convertToDisplayDate(firstMonthEnd)}`}
            </Grid>
            <Grid
              sx={{ borderLeft: `1px solid ${theme.palette.primary.main}`, fontSize: '1em', fontWeight: 'bold' }}
              container
              justifyContent="center"
              item
              xs={3}
              py={0.5}
            >
              {`${convertToDisplayDate(secondMonthStart)} - ${convertToDisplayDate(secondMonthEnd)}`}
            </Grid>
            <Grid
              sx={{ borderLeft: `1px solid ${theme.palette.primary.main}`, fontSize: '1em', fontWeight: 'bold' }}
              container
              justifyContent="center"
              item
              xs={3}
              py={0.5}
            >
              {`${convertToDisplayDate(thirdMonthStart)} - ${convertToDisplayDate(thirdMonthEnd)}`}
            </Grid>
            <Grid
              sx={{
                borderRight: `1px solid ${theme.palette.primary.main}`,
                borderLeft: `1px solid ${theme.palette.primary.main}`,
                fontSize: '1em',
                fontWeight: 'bold',
              }}
              container
              justifyContent="center"
              item
              xs={3}
              py={0.5}
            >
              {`${convertToDisplayDate(fourthMonthStart)} - ${convertToDisplayDate(fourthMonthEnd)}`}
            </Grid>
          </Grid>
        )

      default:
        break
    }
  }

  return (
    <Grid container mt={2}>
      {data && data.length > 0 ? (
        <Grid item xs={12} sx={{ fontWeight: 'bold' }}>
          <Grid container spacing={0} sx={{ borderTop: '1px solid #000' }}>
            <Grid item xs={5} md={4} lg={3} xl={3}></Grid>
            <Grid item xs={7} md={8} lg={9} xl={9}>
              {getDateRange()}
            </Grid>
          </Grid>
          {data.map((item, i) => (
            <Grid key={i} container>
              {personRow(item)}
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography align="center" fontWeight="bold" fontSize={32} mt={5}>
            {t('allocation.noResults')}
          </Typography>
          <Typography align="center" mb={5}>
            {t('allocation.noResults.info')}
          </Typography>
        </Grid>
      )}
      {detailsOpen && (
        <AllocationDetails
          allocation={detailsOpen.allocation}
          person={detailsOpen.person}
          onClose={() => setDetailsOpen(undefined)}
          onDelete={() => {
            setUpdateData(new Date())
            setDetailsOpen(undefined)
          }}
          saveData={() => setUpdateData(new Date())}
          header={t(`allocation.${detailsOpen.allocation.type}`)}
          maxWidth={detailsOpen.allocation.type === 'project' ? 'lg' : 'md'}
          fullWidth
        />
      )}
      {editOpen && (
        <DragEditModal
          header={t(`allocation.dragEditHeader`)}
          person={editOpen}
          onClose={() => setEditOpen(undefined)}
          updateData={() => setUpdateData(new Date())}
          maxWidth="lg"
          deleteItem={deleteItem}
          setEditedItem={setEditedItem}
          updateFromEditing={updateFromEditing}
          noEscClose
        />
      )}
    </Grid>
  )
}

export default OverviewSummary
