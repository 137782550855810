import { Grid, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AssignmentProposalModal from 'Components/General/AssignmentProposalModal/AssignmentProposalModal'
import { getAssignmentProposalInitialData } from 'Components/reusable/DataContext/InitialData'
import { assignmentProposalSchema } from 'Components/reusable/DataContext/ValidationSchema'
import colors from 'constants/colors'

/**
 * React functional component for displaying a "Not Found" card and related actions.
 */
const NotFound: React.FC = () => {
  const { t } = useTranslation()

  const [openProposal, setOpenProposal] = useState<boolean>()

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            sx={{
              border: `1px solid ${colors.borderColor}`,
              borderRadius: '5px',
              padding: '1em',
              marginTop: '1em',
              marginBottom: '1em',
            }}
          >
            <Grid item xs={12} sx={{ textAlign: 'center', marginY: '1em' }}>
              <Typography sx={{ fontWeight: 'bold' }}>{t('search.notFound')}</Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center', marginBottom: '1em' }}>
              <Typography>{t('search.notFoundDescription')}</Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center', marginBottom: '1em' }}>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenProposal(true)
                }}
              >
                {t('search.sendRequest')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openProposal && (
        <AssignmentProposalModal
          onClose={() => {
            setOpenProposal(false)
          }}
          initialData={getAssignmentProposalInitialData()}
          schema={assignmentProposalSchema()}
          localeBase="search.request"
          submitOnModal={true}
          hideLanguageHelperText={true}
          maxWidth="md"
          fullWidth={true}
          customButtons={true}
          noEscClose
        />
      )}
    </>
  )
}

export default NotFound
