import { Button, Tooltip } from '@mui/material'
import React from 'react'

/** @notExported */
interface AddToArrayButtonProps {
  /** Action to be performed when the button is clicked. */
  clickAction: () => void
  /** Whether the button should be disabled or not. */
  valid?
  /** Button label. */
  label?
  /** Button icon. */
  icon?: JSX.Element
  /** Tooltip for the button. */
  tooltip?: string | React.ReactFragment
  /** Custom look for the button. */
  customLook?: 'outlined' | 'contained'
  /** Whether to set the button's width to 100%. */
  maxWidth?: boolean
}

/**
 * Add to array button.
 *
 * @returns Add to array button component.
 * @notExported
 * @category Buttons
 */
const AddToArrayButton: React.FC<AddToArrayButtonProps> = ({
  clickAction,
  valid = true,
  label,
  icon,
  tooltip,
  customLook,
  maxWidth,
}) => {
  const getButton = () => {
    return (
      <Button
        disabled={!valid}
        startIcon={icon}
        variant={customLook ?? 'outlined'}
        color="primary"
        sx={{
          width: maxWidth && !label ? '100%' : label && '100%',
          marginTop: '10px',
          paddingRight: !label ? '3px' : 'inherit',
        }}
        onClick={() => clickAction()}
      >
        {label ?? ''}
      </Button>
    )
  }
  return <>{tooltip ? <Tooltip title={tooltip}>{getButton()}</Tooltip> : getButton()}</>
}

export default AddToArrayButton
