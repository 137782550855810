import { chooseDBTranslation } from 'utils/translations'
import { BorderStyle, Paragraph, TextRun } from 'docx'
import { CVData } from './index'
import { convertToDisplayDate } from 'utils/utils'
import colors from 'constants/colors'

/**
 * Generate the employment section of the CV.
 *
 * @param {object} i18n - The internationalization object.
 * @param {CVData} data - The data for the CV.
 * @param {object} t - The translation object.
 * @return {Paragraph[]} An array of Paragraph objects representing the employment section.
 * @notExported
 */
const employment = (i18n, data: CVData, t): Paragraph[] => {
  // return employment section

  const employments: Paragraph[] = []

  for (let i = 0; i < data.employments.length; i++) {
    const employment = data.employments[i]
    const curIndex = i
    let date = ''
    if (employment.startDate) {
      date += `${convertToDisplayDate(employment.startDate, employment.dateResolution)} - `
    }
    if (employment.endDate) {
      date += `${convertToDisplayDate(employment.endDate, employment.dateResolution)}`
    }

    let line = new Paragraph({
      style: 'CaleoNormal',
      text: '',
    })
    if (curIndex + 1 < data.certificates.length) {
      line = new Paragraph({
        style: 'CaleoNormal',
        text: '',
        border: {
          bottom: {
            color: colors.borders,
            size: 6,
            space: 1,
            style: BorderStyle.SINGLE,
          },
        },
      })
    }

    employments.push(
      new Paragraph({
        keepLines: true,
        style: 'CaleoNormal',
        children: [
          new TextRun({
            text: employment ? chooseDBTranslation(i18n, employment).name : '',
            bold: true,
            break: 1,
          }),
          new TextRun({
            text: employment ? date : '',
            color: colors.gray,
            break: 1,
          }),
          new TextRun({
            text: `${t('print.project.role')}: `,
            bold: true,
            break: 1,
          }),
          new TextRun({
            text: employment ? chooseDBTranslation(i18n, employment).primaryRole : '',
          }),
          new TextRun({
            text: employment ? chooseDBTranslation(i18n, employment).description : '',
            break: 1,
          }),
          new TextRun({
            text: '',
            break: 1,
          }),
        ],
      }),
      line
    )
  }

  return employments
}

export default employment
