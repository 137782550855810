import { Box, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { cvEmployersAPI } from 'api/CvAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import { useIsComponentMounted } from 'hooks/util'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IEmployer } from 'types/cvsInterfaces'
import { IError } from 'types/error'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'

/** @notExported  */
interface IEmployerPickerProps {
  /** The ID of the CV. */
  cvId: number
  /** The current value. */
  value: IEmployer | null
  /** Action to do when the value changes. */
  onChange: (newValue: IEmployer | null) => void
  /** The label of the field. */
  label?: string
  /** Whether the field is required. */
  required?: boolean
  /** Whether the field is disabled. */
  disabled: boolean
}

/**
 * Employer picker component.
 *
 * @returns The employer picker component.
 * @notExported
 */
const EmployerPicker: React.FC<IEmployerPickerProps> = ({
  cvId,
  value,
  onChange,
  label = 'Employer',
  required = false,
  disabled,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const [choices, setChoices] = useState<IEmployer[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [backendError, setBackendError] = useState<IError>()

  useEffect(() => {
    ;(async () => {
      const controller = new AbortController()

      try {
        if (choices.length > 0) return
        setLoading(true)
        const results = await cvEmployersAPI.getList(cvId, controller)
        if (isComponentMounted.current) {
          setChoices(results)
          setLoading(false)
        }
      } catch (error) {
        setBackendError(error as IError)
      }

      return () => {
        controller.abort()
      }
    })()
  }, [cvId])

  const { i18n } = useTranslation()

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <>
      <CaleoInputLabel label={label} required={required} />
      <Autocomplete
        loading={loading}
        disabled={disabled}
        value={value}
        style={{ marginTop: '0px' }}
        onChange={(_event, newValue) => {
          if (typeof newValue !== 'string') {
            onChange(newValue)
          }
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={choices}
        getOptionLabel={option => {
          return chooseDBTranslation(i18n, option).name
        }}
        renderOption={(props, option) => {
          return (
            <Box component="li" {...props} key={option.id}>
              {chooseDBTranslation(i18n, option).name}
            </Box>
          )
        }}
        renderInput={params => <TextField {...params} size="small" fullWidth margin="dense" variant="outlined" />}
        fullWidth
        filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['translations.name'])}
      />
    </>
  )
}

export default EmployerPicker
