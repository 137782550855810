import React from 'react'
import { DialogContent, Grid } from '@mui/material'
import { IReference } from 'types/cvsInterfaces'
import DataContext from 'Components/reusable/DataContext'
import newWithEditModal from 'Components/reusable/HOC/newWithEditModal'

const ReferenceModal: React.FC<Record<string, unknown>> = () => {
  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item>
          <DataContext.TranslationImport<IReference> />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DataContext.TextField<IReference> field="firstName" required fullWidth insetLabel />
            </Grid>
            <Grid item xs={6}>
              <DataContext.TextField<IReference> field="lastName" required fullWidth insetLabel />
            </Grid>
          </Grid>
          <DataContext.TextField<IReference> field="company" required fullWidth insetLabel />
          <DataContext.TextField<IReference>
            field="letter"
            maxHintLength={1500}
            required
            multiline
            grow
            rows={12}
            fullWidth
            insetLabel
          />
        </Grid>
      </Grid>
    </DialogContent>
  )
}

export default newWithEditModal(ReferenceModal)
