import { Box } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { networkAPI } from 'api/NetworkAPI'
import { organizationAPI } from 'api/OrganizationAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import { useIsComponentMounted } from 'hooks/util'
import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IError } from 'types/error'
import { IAccount, IOrganization } from 'types/userInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'
import { InputField } from 'Components/reusable/InputFields/InputField'

/** @notExported */
interface IMemberPickerProps {
  /** The current value. */
  value: IOrganization | IAccount | null
  /** Action to do when the value changes. */
  onChange: (newValue: IOrganization | IAccount) => void
  /** The label text. */
  label?: string
  /** The type of member picker. */
  type?: 'teamOrganization' | 'organization' | 'account'
  /** Whether the field is disabled. */
  disabled?: boolean
  /** The team organizations. */
  teamOrganizations?: IOrganization[]
  /** The selected organizations. */
  selectedOrgs?: IOrganization[]
  /** The selected accounts. */
  selectedAccounts?: IAccount[]
  /** The inset label. */
  insetLabel?: boolean
}

/**
 * Member picker component.
 *
 * @returns Member picker component.
 * @notExported
 */
const MemberPicker: React.FC<IMemberPickerProps> = ({
  value,
  onChange,
  label = 'Organization',
  type = 'organization',
  disabled,
  teamOrganizations,
  selectedOrgs,
  selectedAccounts,
  insetLabel,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const [allowedOrgs, setAllowedOrgs] = useState<IOrganization[]>([])
  const [allowedAccounts, setAllowedAccounts] = useState<IAccount[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [backendError, setBackendError] = useState<IError>()
  const [memberInput, setMemberInput] = useState<string | undefined>(undefined)

  const { i18n } = useTranslation()

  let orgs = useMemo(() => {
    if (type === 'organization') {
      let orgs: IOrganization[] = []

      if (selectedOrgs) {
        orgs = allowedOrgs.filter(org => !selectedOrgs.find(item => item.id === org.id))
      } else {
        orgs = allowedOrgs
      }

      return orgs
    }
    return []
  }, [allowedOrgs, selectedOrgs])

  const accounts = useMemo(() => {
    if (type === 'account') {
      let accountsData: IAccount[] = []

      if (selectedAccounts) {
        accountsData = allowedAccounts.filter(account => !selectedAccounts.find(item => item.id === account.id))
      } else {
        accountsData = allowedAccounts
      }

      return accountsData
    }
    return []
  }, [allowedAccounts, selectedAccounts])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        if (!disabled) {
          setLoading(true)
          if (type === 'organization') {
            const results = await organizationAPI.getAllowedOrganizations(controller)
            if (!isComponentMounted.current) return
            setAllowedOrgs(results)
          } else if (type !== 'teamOrganization') {
            setAllowedAccounts(
              (await networkAPI.getPossibleAccounts()).map(account => ({
                ...account,
                fullName: `${account?.Person?.firstName} ${account?.Person?.lastName}`,
              }))
            )
          }
        }
      } catch (error) {
        setBackendError(error as IError)
      } finally {
        if (isComponentMounted.current) setLoading(false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [disabled, type, teamOrganizations])

  if (type === 'teamOrganization' && teamOrganizations) {
    orgs = teamOrganizations
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const getName = (option: IOrganization | IAccount): string => {
    if (type === 'account') {
      const item = option as IAccount
      if (item.Person) {
        return `${item.Person.firstName} ${item.Person.lastName}`
      } else {
        return item.email
      }
    }
    const item = option as IOrganization
    return chooseDBTranslation(i18n, item).name
  }

  return (
    <div style={{ width: '100%' }}>
      {!insetLabel && <CaleoInputLabel label={label} />}
      <Autocomplete
        filterOptions={(options, { inputValue }) =>
          fuseFiltering(options, inputValue, ['translations.name', 'email', 'fullName'])
        }
        loading={loading}
        value={value}
        inputValue={memberInput}
        style={{ marginTop: '0px' }}
        onChange={(_event, newValue) => {
          if (typeof newValue !== 'string') {
            type === 'account' ? onChange(newValue as unknown as IAccount) : onChange(newValue as IOrganization)
          }
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={
          (type === 'organization' || type === 'teamOrganization' ? orgs : accounts) as (IOrganization | IAccount)[]
        }
        getOptionLabel={option => {
          return getName(option)
        }}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id
        }}
        renderOption={(props, option) => {
          return (
            <Box component="li" {...props} key={option.id}>
              {getName(option)}
            </Box>
          )
        }}
        renderInput={params => (
          <InputField
            {...params}
            fullWidth
            size="small"
            margin="dense"
            onChange={event => setMemberInput(event.target.value)}
            variant="outlined"
            insetLabel
            label={insetLabel ? label : undefined}
            InputLabelProps={{ shrink: insetLabel ? true : undefined }}
          />
        )}
        fullWidth
        disabled={disabled}
      />
    </div>
  )
}

export default MemberPicker
