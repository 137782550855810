import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { chooseDBTranslation } from 'utils/translations'
import { ITermsAndPolicy } from 'types/termsAndPolicyInterfaces'
import { termsAndPolicyApi } from 'api/TermsAndPolicyAPI'
import BackButton from 'Components/reusable/IconButtons/BackButton'
import { useTranslation } from 'react-i18next'
import { useLoading } from 'Components/reusable/LoadingProvider'
import { useIsComponentMounted } from 'hooks/util'
import { useNavigate, NavigationType, useNavigationType, useLocation } from 'react-router-dom'

/**
 * Renders the TermsAndPolicyModal component.
 *
 * @return {JSX.Element} The rendered TermsAndPolicyModal component.
 * @notExported
 */
const TermsAndPolicyModal = () => {
  const isComponentMounted = useIsComponentMounted()
  const navigate = useNavigate()
  const navType: NavigationType = useNavigationType()
  const { i18n } = useTranslation()
  const [termsAndPolicies, setTermsAndPolicies] = useState<ITermsAndPolicy[]>()
  const [item, setItem] = useState<ITermsAndPolicy>()
  const { startLoading, endLoading } = useLoading()
  const location = useLocation()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        startLoading('termsAndPolicy')

        const data = await termsAndPolicyApi.getGlobalTermsAndPolicies(controller)
        if (!isComponentMounted.current) return
        setTermsAndPolicies(data)
        if (data.length) {
          if (location.pathname.includes('terms')) {
            setItem(data[1])
          } else {
            setItem(data[0])
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        endLoading('termsAndPolicy')
      }
    })()

    return () => {
      controller.abort()
    }
  }, [])

  if (termsAndPolicies && item) {
    return (
      <Dialog open fullWidth maxWidth="xl">
        <DialogTitle>
          <>{navType !== 'POP' && <BackButton clickAction={() => navigate(-1)} />}</>
        </DialogTitle>
        <DialogContent style={{ minHeight: '80vh' }}>
          <Typography>
            <ReactMarkdown>{chooseDBTranslation(i18n, item).content}</ReactMarkdown>
          </Typography>
        </DialogContent>
      </Dialog>
    )
  } else {
    return <></>
  }
}

export default TermsAndPolicyModal
