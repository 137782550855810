import React, { useContext, useState, useMemo } from 'react'
import LoadingIndicator from '../LoadingIndicator'

interface LoadingApi {
  loading: boolean
  startLoading: (newValue: string) => void
  endLoading: (newValue: string) => void
}

const loadingContext = React.createContext<LoadingApi>({} as LoadingApi)

const { Provider } = loadingContext

/**
 * Loading indicator provider.
 *
 * @param children
 * @returns Provider for showing loading indicator in context.
 */
export const LoadingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loadingIndicators, setLoadingIndicators] = useState<string[]>([])

  const startLoading = (indicatorName: string) => {
    setLoadingIndicators(loadingIndicators => [...loadingIndicators, indicatorName])
  }

  const endLoading = (indicatorName: string) => {
    setLoadingIndicators(loadingIndicators.filter(item => item !== indicatorName))
  }

  const loading = useMemo(() => {
    if (loadingIndicators.length > 0) {
      return true
    }
    return false
  }, [loadingIndicators])

  return (
    <Provider
      value={{
        startLoading,
        endLoading,
        loading,
      }}
    >
      {children}
      {loading && <LoadingIndicator />}
    </Provider>
  )
}

/**
 * Hook for displaying loading indicator.
 *
 * @returns Loading provider context variables and functions.
 */
export const useLoading = () => {
  return useContext(loadingContext)
}
