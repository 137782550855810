import { Checkbox, Chip, Collapse, FormControlLabel } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { chooseDBTranslation } from 'utils/translations'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

/** @notExported */
interface IDataChipsProps {
  /** The data. */
  data
  /** The type. */
  type: 'skill' | 'role' | 'industry' | 'certificate'
}

/**
 * The DataChips component.
 *
 * @param data - The data.
 * @param type - The type.
 * @param color - The color.
 * @returns The DataChips component.
 * @notExported
 */
const DataChips: React.FC<IDataChipsProps> = ({ data, type }) => {
  const { i18n } = useTranslation()

  const [showMore, setShowMore] = useState<boolean>(false)

  const attribute = type === 'certificate' ? 'certificate' : 'name'

  if (data.length > 10) {
    const newData = [...data]
    const split = newData.splice(0, 10)

    return (
      <>
        <FormControlLabel
          labelPlacement="start"
          control={
            <Checkbox
              onChange={() => setShowMore(!showMore)}
              icon={<ExpandMoreIcon />}
              checkedIcon={<ExpandLessIcon />}
              name="showMoreCollapse"
            />
          }
          label={split.map(item => (
            <Chip
              key={item.id}
              label={chooseDBTranslation(i18n, item)[attribute]}
              variant="outlined"
              sx={{ mb: 1, mr: 1, borderRadius: 1 }}
            />
          ))}
          sx={{ m: 0, p: 0 }}
        />
        <Collapse in={showMore}>
          {newData.map(item => (
            <Chip
              key={item.id}
              label={chooseDBTranslation(i18n, item)[attribute]}
              variant="outlined"
              sx={{ mb: 1, mr: 1, borderRadius: 1 }}
            />
          ))}
        </Collapse>
      </>
    )
  } else {
    return data.map(item => (
      <Chip
        key={item.id}
        label={chooseDBTranslation(i18n, item)[attribute]}
        variant="outlined"
        sx={{ mb: 1, mr: 1, borderRadius: 1 }}
      />
    ))
  }
}

export default DataChips
