export const RESOURCE = 'resource'
export const ROLES = 'roles'
export const ACCESS_TOKEN = 'accessToken'
export const ADD = 'add'
export const EDIT = 'edit'
export const TMP_IDENTIFIER = 'T'
export const PASSWORD_MIN_LENGTH = 8
export const PASSWORD_MAX_LENGTH = 255
export const ALLOCATION_TYPE_PROJECT = 'project'
export const ALLOCATION_TYPE_OTHER = 'other'
export const ALLOCATION_TYPE_PERSONAL = 'personal'

export const FI = 'fi'
export const EN = 'en'
export const SV = 'sv'
export const DEFAULT_LANGUAGE = FI
export const ALL_LANGUAGES = [FI, EN]
// export const ALL_LANGUAGES = [FI, EN, SV]
export const COUNTRY_CODES = {
  [FI]: 'fi',
  [SV]: 'se',
  [EN]: 'gb',
}

export const LANGUAGE_IDS = {
  fi: 1,
  en: 2,
  sv: 3,
}

export const TEXT_FIELD_MAX_LENGTH = 255
export const MULTILINE_TEXT_FIELD_MAX_LENGTH = 5000
