import { Grid, Popover, Tooltip } from '@mui/material'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import ModalPaper from 'Components/reusable/CaleoCustomComponents/ModalPaper'
import React, { useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { useTranslation } from 'react-i18next'
import { InputField } from '../InputField'

/** @notExported  */
interface PickerProps {
  /** The value of the picker.  */
  value
  /** The function to call when the value changes.  */
  onChange: (newValue: string) => void
  /** The label of the picker.   */
  label?: string
  /** Whether the picker is required.   */
  required?: boolean
  /** Whether the picker should have an inset label.   */
  insetLabel?: boolean
}

/**
 * Picker component.
 *
 * @returns The picker component.
 * @notExported
 */
const Picker: React.FC<PickerProps> = ({ value, onChange, label = 'Color', required = false, insetLabel }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const infoOpen = Boolean(anchorEl)

  return (
    <>
      {!insetLabel && <CaleoInputLabel label={label} required={required} />}
      <Grid container direction="row" alignItems="center">
        <Tooltip title={infoOpen ? `${t('close')}` : `${t('open')}`}>
          <Grid
            item
            onClick={(event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget)}
            sx={{
              height: '28px',
              width: '28px',
              marginRight: '5px',
              borderRadius: '8px',
              border: '3px solid',
              boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)',
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.5,
              },
              backgroundColor: value,
            }}
          ></Grid>
        </Tooltip>
        <Grid item>
          <InputField
            size="small"
            margin="dense"
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e.target.value)
            }}
            label={insetLabel ? label : undefined}
            required={required}
            insetLabel={insetLabel}
          />
        </Grid>
      </Grid>
      <Popover
        open={infoOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ModalPaper>
          <HexColorPicker color={value} onChange={newValue => onChange(newValue)} />
        </ModalPaper>
      </Popover>
    </>
  )
}

export default Picker
