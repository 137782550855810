import React from 'react'
import { Card, CardHeader, CircularProgress, Dialog, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

/**
 * Loading indicator component.
 *
 * @returns Loading indicator component.
 * @notExported
 */
const LoadingIndicator: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Dialog open>
      <Card>
        <CardHeader title={t('loading')} />
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      </Card>
    </Dialog>
  )
}

export default LoadingIndicator
