import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  CircularProgress,
  Grid,
  tableCellClasses,
  useTheme,
} from '@mui/material'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  RowSelectionState,
  SortingState,
  VisibilityState,
  useReactTable,
  Updater,
  OnChangeFn,
  PaginationState,
} from '@tanstack/react-table'
import TableToolbar from './TableToolbar'
import TablePaginationActions from './TablePaginationActions'
import { useIsComponentMounted } from 'hooks/util'
import _ from 'lodash'

/**
 * Custom react-table component.
 *
 * @param columns - Table columns object.
 * @param data - Table data.
 * @param columnVisibility - Column visibility state.
 * @param setColumnVisibility - Function to set column visibility state.
 * @param search - Whether to enable search.
 * @param selectable - Whether to enable selectable rows.
 * @param multiSelect - Whether to enable multi-select.
 * @param setSelected - Function to set selected items.
 * @param selectedItems - Selected items.
 * @param toolbarControls - Toolbar controls.
 * @param expanded - Whether to enable expandable rows.
 * @param rowHover - Whether to enable row hover.
 * @param rowClickAction - Function to execute on row click.
 * @param initialPageSize - Initial page size.
 * @param setRowCount - Function to set row count.
 * @param rowCount - Row count.
 * @param selectedPage - Currently selected page.
 * @param setSelectedPage - Function to set currently selected page.
 * @param elevation - Table elevation.
 * @param loading - Whether to show loading indicator.
 * @param noTopMargin - Whether to show top margin.
 * @param globalFilter - Global filter value.
 * @param setGlobalFilter - Function to set global filter value.
 * @param dbSorting - Database sorting state.
 * @param setDbSorting - Function to set database sorting state.
 * @param totalCount - Total count.
 * @param pageIndex - Current page index.
 * @param pageSize - Page size.
 * @param setPagination - Function to set pagination state.
 * @param databasePagination - Whether to use database pagination.
 * @param newStyle - Whether to use new style.
 * @returns Custom react-table component.
 * @notExported
 */
const CustomTable = ({
  columns,
  data,
  columnVisibility,
  setColumnVisibility,
  search = false,
  selectable = false,
  multiSelect = false,
  setSelected = undefined,
  selectedItems = undefined,
  toolbarControls = null,
  expanded,
  rowHover,
  rowClickAction = undefined,
  initialPageSize = 10,
  setRowCount,
  rowCount,
  selectedPage,
  setSelectedPage = undefined,
  elevation = 3,
  loading,
  noTopMargin = false,
  globalFilter,
  setGlobalFilter,
  dbSorting,
  setDbSorting,
  totalCount,
  pageIndex,
  pageSize,
  setPagination,
  databasePagination,
  newStyle,
}: {
  columns: ColumnDef<any, any>[]
  data: any[]
  columnVisibility: VisibilityState
  setColumnVisibility: (updaterOrValue: Updater<VisibilityState>) => void
  search?: boolean
  selectable?: boolean
  multiSelect?: boolean
  setSelected?: (selected: any[]) => void
  selectedItems?: any[]
  toolbarControls?: React.ReactNode
  expanded?: boolean
  rowHover?: boolean
  rowClickAction?: (row: Row<any>) => void
  initialPageSize?: 5 | 10 | 25
  setRowCount?: (count: number) => void
  rowCount?: number
  selectedPage?: number
  setSelectedPage?: (index: number) => void
  elevation?: number
  loading?: boolean
  noTopMargin?: boolean
  globalFilter?: string
  setGlobalFilter?: (value: string) => void
  dbSorting?: SortingState
  setDbSorting?: (value: SortingState) => void
  totalCount?: number
  pageIndex: number
  pageSize: number
  setPagination: OnChangeFn<PaginationState>
  databasePagination?: boolean
  newStyle?: boolean
}) => {
  const isComponentMounted = useIsComponentMounted()
  const theme = useTheme()
  const { t } = useTranslation()
  const [sorting, setSorting] = useState<SortingState>([])
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const [{ pageIndex: defaultPageIndex, pageSize: defaultPageSize }, setDefaultPagination] = useState<PaginationState>({
    pageIndex: selectedPage ?? 0,
    pageSize: rowCount ?? 25,
  })

  const pagination = useMemo(
    () => ({
      pageIndex: databasePagination ? pageIndex : defaultPageIndex,
      pageSize: databasePagination ? pageSize : defaultPageSize,
    }),
    [pageIndex, pageSize, defaultPageIndex, defaultPageSize]
  )

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: typeof selectedPage === 'number' ? selectedPage : 0,
        pageSize: initialPageSize,
      },
    },
    state: {
      sorting: dbSorting ? dbSorting : sorting,
      globalFilter,
      rowSelection,
      columnVisibility,
      pagination: pagination,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setDbSorting ? (setDbSorting as OnChangeFn<SortingState>) : setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    enableSorting: true,
    enableRowSelection: selectable,
    enableMultiRowSelection: multiSelect,
    manualPagination: databasePagination ? true : false,
    onPaginationChange: databasePagination ? setPagination : setDefaultPagination,
  })

  const handleChangePage = (e, newPage: number) => {
    if (isComponentMounted.current) {
      table.setPageIndex(newPage)
      if (isComponentMounted.current && typeof selectedPage === 'number' && setSelectedPage) {
        setSelectedPage(newPage)
      }
    }
  }

  const handleChangeRowsPerPage = e => {
    if (isComponentMounted.current) {
      const count = Number(e.target.value)
      table.setPageSize(count)
      if (setRowCount) {
        setRowCount(count)
      }
    }
  }

  useEffect(() => {
    if (rowCount && isComponentMounted.current) {
      if (rowCount === 1) {
        table.setPageSize(table.getRowModel().rows.length)
      } else {
        table.setPageSize(rowCount)
      }
    }
  }, [rowCount])

  useEffect(() => {
    if (isComponentMounted.current && table.getPageCount() && typeof selectedPage === 'number' && setSelectedPage) {
      table.setPageIndex(selectedPage)
    }
  }, [table.getPageCount()])

  useEffect(() => {
    if (selectable && setSelected && isComponentMounted.current) {
      if (Object.keys(rowSelection).length) {
        const rows = table
          .getCoreRowModel()
          .rows.map(row => {
            if (row.id in rowSelection) return row
          })
          .filter(item => item)
        setSelected(rows.map(row => row?.original))
      } else {
        setSelected([])
      }
    }
  }, [rowSelection, setSelected])

  useEffect(() => {
    if (isComponentMounted.current && selectable && multiSelect && setSelected && selectedItems) {
      if (selectedItems.length < 1 && Object.keys(rowSelection).length) {
        table.toggleAllRowsSelected(false)
      } else {
        for (const row of table.getCoreRowModel().rows) {
          if (selectedItems.find(item => item.skill.id === row.original.skill.id)) {
            row.toggleSelected(true)
          } else {
            row.toggleSelected(false)
          }
        }
      }
    }
  }, [selectedItems])

  useEffect(() => {
    if (isComponentMounted.current && search && globalFilter) {
      handleChangePage(undefined, 0)
    }
  }, [globalFilter])

  useEffect(() => {
    if (isComponentMounted.current && !dbSorting) {
      handleChangePage(undefined, 0)
    }
  }, [data])

  return (
    <Paper
      sx={{
        width: '100%',
        marginTop: 3,
        borderRadius: '5px',
        background: theme.palette.background.default,
        overflowX: 'auto',
      }}
      elevation={elevation}
      style={noTopMargin ? { marginTop: 0 } : {}}
    >
      {(search || toolbarControls) && (
        <TableToolbar
          search={search}
          preGlobalFilteredRowsCount={table.getPrePaginationRowModel().rows.length}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
          toolbarControls={toolbarControls}
          table={table}
          newStyle={newStyle}
        />
      )}
      <Table
        size="small"
        sx={
          newStyle
            ? {
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                },
              }
            : {}
        }
      >
        <TableHead sx={newStyle ? { borderBottom: '1px solid #e0e0e0' } : {}}>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <TableCell
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    maxWidth: header.getSize(),
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        style: header.column.getCanSort()
                          ? { cursor: 'pointer', fontWeight: 'bold' }
                          : { fontWeight: 'bold' },
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <TableSortLabel active={true} direction="asc" />,
                        desc: <TableSortLabel active={true} direction="desc" />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={1}>
                    <CircularProgress style={{ margin: '20px 0' }} />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ) : (
            <>
              {table.getRowModel().rows.map(row => (
                <TableRow
                  sx={{
                    '&:nth-of-type(odd)': {
                      backgroundColor: 'action.hover',
                    },
                    hover: {
                      cursor: rowHover ? 'pointer' : '',
                    },
                  }}
                  key={row.id}
                  hover={rowHover}
                  onClick={
                    rowClickAction && isComponentMounted.current && expanded ? () => rowClickAction(row) : undefined
                  }
                >
                  {row.getVisibleCells().map(cell => (
                    <TableCell
                      key={cell.id}
                      style={{
                        maxWidth: cell.column.getSize(),
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={
                expanded
                  ? [5, 10, 25, 50, { value: table.getRowModel().rows.length, label: t('showAll') }]
                  : [5, 10, 25, 50]
              }
              count={totalCount ? totalCount : table.getPrePaginationRowModel().rows.length}
              rowsPerPage={table.getState().pagination.pageSize}
              page={table.getState().pagination.pageIndex}
              SelectProps={{
                inputProps: { 'aria-label': t('custom-table.labelRowsSelect') },
                native: true,
                sx: { order: 8 },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t('custom-table.labelRowsSelect')}
              labelDisplayedRows={({ from, to, count }) =>
                t('custom-table.labelDisplayedRows', { from: from, to: to, count: count })
              }
              ActionsComponent={TablePaginationActions}
              sx={{
                [`& .MuiTablePagination-spacer`]: {
                  order: 9,
                  display: 'none',
                },
                [`& .MuiTablePagination-displayedRows`]: {
                  order: 4,
                },
                [`& .MuiTablePagination-selectLabel`]: {
                  ml: 1,
                  order: 7,
                },
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  )
}

export default CustomTable
