import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { Checkbox, FormControlLabel } from '@mui/material'
import React, { useState } from 'react'

/** @notExported  */
interface RegularCheckboxProps {
  /** The checked state of the checkbox. */
  checked: boolean
  /** The function to call when the checkbox is changed. */
  onChange: (item) => void
  /** The label to display next to the checkbox. */
  label?: string
}

/**
 * Regular checkbox component.
 *
 * @returns The regular checkbox component.
 * @notExported
 */
const RegularCheckbox: React.FC<RegularCheckboxProps> = ({ checked, onChange, label }) => {
  const [hidden, setHidden] = useState<boolean>(checked)

  return (
    <FormControlLabel
      onClick={event => event.stopPropagation()}
      onFocus={event => event.stopPropagation()}
      style={{ marginLeft: 5 }}
      label={label ?? ''}
      control={
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlank />}
          checkedIcon={<CheckBox />}
          inputProps={{
            'aria-label': 'checkbox',
          }}
          checked={hidden}
          onChange={event => {
            onChange(event.target.checked)
            setHidden(!hidden)
          }}
        />
      }
    />
  )
}

export default RegularCheckbox
