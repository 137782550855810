import React, { ComponentPropsWithRef } from 'react'
import { Grid, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CountryIso2, defaultCountries, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'

interface OwnProps {
  id?: string
  value: string
  error?: string | boolean
  helperText?: string | null
  label?: React.ReactNode
  required?: boolean
  insetLabel?: boolean
  onChange: (value: string) => void
}

const PhoneNumberInput: React.FC<OwnProps & Omit<ComponentPropsWithRef<typeof TextField>, keyof OwnProps>> = ({
  id,
  value,
  error,
  helperText,
  label,
  required,
  insetLabel,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation()

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: 'fi',
    value: value ?? '+358',
    countries: defaultCountries,
    onChange: ({ phone }) => {
      onChange(phone)
    },
  })

  return (
    <Grid sx={{ display: 'flex', alignItems: 'baseline', flexDirection: 'column' }}>
      {!insetLabel && <CaleoInputLabel htmlFor={id} required={required} label={label} />}
      <TextField
        id={id}
        margin="dense"
        FormHelperTextProps={{ style: { textAlign: 'right' } }}
        error={!!error}
        label={
          insetLabel ? (
            <>
              {label}
              <span style={{ color: '#BE3C3C', fontSize: '0.85em', marginLeft: '5px' }}>
                {required && `${t('required')}`}
              </span>
            </>
          ) : undefined
        }
        variant="outlined"
        helperText={error ? error : helperText}
        required={required}
        type="tel"
        size="small"
        InputLabelProps={insetLabel ? { shrink: true } : {}}
        value={inputValue}
        onChange={handlePhoneValueChange}
        inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: '2px', marginLeft: '-8px' }}>
              <Select
                MenuProps={{
                  style: {
                    height: '300px',
                    width: '360px',
                    top: '10px',
                    left: '-34px',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                sx={{
                  width: 'max-content',
                  // Remove default outline (display only on focus)
                  fieldset: {
                    display: 'none',
                  },
                  '&.Mui-focused:has(div[aria-expanded="false"])': {
                    fieldset: {
                      display: 'block',
                      border: 'none',
                    },
                  },
                  // Update default spacing
                  '.MuiSelect-select': {
                    padding: '8px',
                    paddingRight: '24px !important',
                  },
                  svg: {
                    right: 0,
                  },
                }}
                value={country.iso2}
                onChange={e => setCountry(e.target.value as CountryIso2)}
                renderValue={value => (
                  <FlagImage iso2={value} style={{ display: 'flex', alignSelf: 'center', minWidth: '1.3em' }} />
                )}
              >
                {defaultCountries.map(c => {
                  const country = parseCountry(c)
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <FlagImage iso2={country.iso2} style={{ marginRight: '8px', maxHeight: '1.3em' }} />
                      <Typography marginRight="8px">{country.name}</Typography>
                      <Typography color="gray">+{country.dialCode}</Typography>
                    </MenuItem>
                  )
                })}
              </Select>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </Grid>
  )
}

export default PhoneNumberInput
