import { ITeam, ITeamData, ITeamMember } from 'types/teamInterfaces'
import { IOrganization, IPerson } from 'types/userInterfaces'
import { SubAPI } from './API'
import { IIndustry, IRole, ISkill } from 'types/cvsInterfaces'

/**
 * Team API
 * @notExported
 */
class TeamAPI extends SubAPI {
  /**
   * Get user teams.
   *
   * @returns Array of teams.
   */
  public getUserTeams(controller?: AbortController): Promise<ITeam[]> {
    return this.api.get(`teams/user`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get organization teams.
   *
   * @param organizationId - Organization ID.
   * @returns Array of teams.
   */
  public getOrganizationTeams(organizationId: number, controller?: AbortController): Promise<ITeam[]> {
    return this.api.get(`teams/organization/${organizationId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get sales accessible teams.
   *
   * @returns Array of teams.
   */
  public getSalesTeams(controller?: AbortController): Promise<ITeam[]> {
    return this.api.get(`teams/sales`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get allowed teams for user.
   *
   * @returns Array of teams.
   */
  public getSalesOrganizationsTeams(controller?: AbortController): Promise<ITeam[]> {
    return this.api.get(`teams/salesOrganization`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get teams where user is manager.
   *
   * @returns Array of teams.
   */
  public getTeamsWhereManager(controller?: AbortController): Promise<ITeam[]> {
    return this.api.get(`teams/manager/`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get team members for managered teams
   *
   * @returns Array of team members.
   */
  public getLeaderMembers(controller?: AbortController): Promise<ITeamMember[]> {
    return this.api.get('teams/leader/members', controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get team by id.
   *
   * @param id - Team ID.
   * @returns Team data.
   */
  public getTeamById(id: number, controller?: AbortController): Promise<ITeam> {
    return this.api.get(`teams/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get all teams.
   *
   * @returns Array of teams.
   */
  public getAllTeams(controller?: AbortController): Promise<ITeam[]> {
    return this.api.get(`teams/all/`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get team members by team id.
   *
   * @param id - Team ID.
   * @returns Array of team members.
   */
  public getTeamMembers(id: number, controller?: AbortController): Promise<IPerson[]> {
    return this.api.get(`teams/${id}/members`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Delete team by id.
   *
   * @param id - Team ID.
   * @returns Promise of team deletion.
   */
  public delete(id: number, controller?: AbortController): Promise<void> {
    return this.api.delete(`teams/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Create team.
   *
   * @param data - Team data.
   * @returns Created team.
   */
  public create(data: unknown, controller?: AbortController): Promise<ITeam> {
    return this.api.post(`teams`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Update team.
   *
   * @param id - Team ID.
   * @param data - Team data.
   * @returns Updated team.
   */
  public save(id: number, data: unknown, controller?: AbortController): Promise<ITeam> {
    return this.api.put(`teams/${id}`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get allowed team organizations.
   *
   * @param organizationId - Organization ID.
   * @returns Array of organizations.
   */
  public allowedTeamOrganizations(organizationId: number, controller?: AbortController): Promise<IOrganization[]> {
    return this.api.get(
      `teams/allowedTeamOrganizations/${organizationId}`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Filter teams by ids, skills, roles, industries and certificates.
   *
   * @param ids - Team IDs.
   * @param skills - Skills.
   * @param roles - Roles.
   * @param industries - Industries.
   * @param certificates - Certificates.
   * @returns Array of teams.
   */
  public filterTeams(
    ids: number[],
    skills: ISkill[],
    roles: IRole[],
    industries: IIndustry[],
    certificates: string[],
    controller?: AbortController
  ): Promise<ITeam[]> {
    return this.api.post(
      `teams/filter`,
      { ids, skills, roles, industries, certificates },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get team data by team id.
   *
   * @param id - Team ID.
   * @returns Team data.
   */
  public getTeamData(id: number, controller?: AbortController): Promise<ITeamData> {
    return this.api.get(`teams/teamdata/${id}`, controller ? { signal: controller.signal } : undefined)
  }
}

export const teamAPI = new TeamAPI()
