import React from 'react'
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import AttachmentIcon from '@mui/icons-material/Attachment'
import { Grid, useTheme } from '@mui/material'

/** @notExported */
interface FileDropzoneProps {
  /** Function to be called when a file is dropped. */
  onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
  /** File type(s) to accept. */
  accept?: Accept
  /** Whether to allow multiple files to be dropped. */
  multiple?: boolean | undefined
  /** Old file name. */
  oldFile?: string
  /** Drag hint text. */
  dragHint: string
  /** Drop hint text. */
  dropHint: string
  /** Dropzone height. */
  height?: number
  /** Dropzone width. */
  width?: string
  /** Maximum number of files that can be dropped. */
  maxFiles?: number | undefined
  /** Function to validate dropped files. */
  validator?: (file: File) => null | { code: string; message: string }
  /** Background color */
  backgroundColor?: string
}

/**
 * File dropzone component.
 *
 * @returns The file dropzone component.
 */
export const FileDropzone: React.FC<FileDropzoneProps> = ({
  onDrop,
  accept,
  oldFile,
  multiple = false,
  dragHint,
  dropHint,
  height = 218,
  width = '100%',
  maxFiles,
  validator,
  backgroundColor,
}) => {
  const theme = useTheme()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
    validator,
    maxFiles: maxFiles ? maxFiles : 0,
  })

  return (
    <Grid
      container
      sx={{
        cursor: 'pointer',
        fontFamily: 'Helvetica',
        height: '218px',
        borderRadius: '5px 5px 0 0',
        border: '1px solid #D8D8D8',
      }}
      style={{ height, width, backgroundColor: backgroundColor ? backgroundColor : theme.palette.neutral.light }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        {oldFile ? (
          <Grid item>
            <span>{oldFile}</span>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <AttachmentIcon style={{ fontSize: 110 }} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              {isDragActive ? (
                <p style={{ fontSize: '14px', fontWeight: 'bold', color: theme.palette.text.primary }}>{dropHint}</p>
              ) : (
                <p style={{ fontSize: '14px', fontWeight: 'bold', color: theme.palette.text.primary }}>{dragHint}</p>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}
