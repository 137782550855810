import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import CloseIcon from '@mui/icons-material/Close'
import { cvProjectsAPI, cvEmployersAPI } from 'api/CvAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { orderBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IProject, IEmployer, IPersonSkillOrIndustryOrRole, ISkill, ISkillOrIndustryOrRole } from 'types/cvsInterfaces'
import { IError } from 'types/error'
import { getSkillExperienceLabel, toPositiveInteger } from 'utils/utils'
import DeleteButton from 'Components/reusable/IconButtons/DeleteButton'
import { useNotificationPopup } from 'Components/reusable/Notification'
import DeleteConnectedSkillConfirm from './DeleteConnectedSkillConfirm'
import SkillsItem from './SkillsItem'
import { TableInputField } from 'Components/reusable/InputFields/TableInputField'
import InterestLevelPicker from 'Components/reusable/InputFields/InterestLevelPicker'
import LevelPicker from 'Components/reusable/InputFields/LevelPicker'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import CaleoIconButton from 'Components/reusable/IconButtons/CaleoIconButton'
import DoneIcon from '@mui/icons-material/Done'
import SkillPicker from 'Components/reusable/InputFields/SkillPicker'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  tableCellClasses,
  TablePagination,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { getSkillsInitialData } from 'Components/reusable/DataContext/InitialData'
import TableSortLabel from '@mui/material/TableSortLabel'
import { Translateable, TranslateableObject, chooseDBTranslation } from 'utils/translations'
import AddToArrayButton from 'Components/reusable/Buttons/AddToArrayButton'
import { ExperienceInputField } from 'Components/reusable/InputFields/ExperienceInputField'
import { Schema, ValidationError } from 'yup'
import SkillLevelChip from 'Components/reusable/CaleoCustomComponents/SkillLevelChip'
import { useIsComponentMounted } from 'hooks/util'
import {
  ColumnDef,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  Row,
} from '@tanstack/react-table'
import TablePaginationActions from 'Components/reusable/Tables/CustomTable/TablePaginationActions'
import colors from 'constants/colors'

interface IComponentProps {
  onOpen: (item: IPersonSkillOrIndustryOrRole) => void
  editable: boolean
  setSkills: (newSkills: IPersonSkillOrIndustryOrRole[]) => void
  setProjects: (projects: IProject[]) => void
  setEmployers: (employers: IEmployer[]) => void
  type: string
  handleClose: (newItem: IPersonSkillOrIndustryOrRole) => void
  items: IPersonSkillOrIndustryOrRole[]
  api
  cvId: number
  schema: Schema<unknown>
  updateProgress: () => void
}

type AnyObject = Record<never, unknown>

type T = AnyObject

type EditableFields<T, K = T extends TranslateableObject<infer S> ? S : AnyObject> = Omit<
  T & K,
  keyof Translateable | 'id' | 'createdAt' | 'updatedAt'
>

type ErrorFields<T> = {
  [L in keyof T]?: string
}

/**
 * Skills table.
 *
 * @param onOpen - Function to open row for editing.
 * @param editable - Whether skills are editable.
 * @param setSkills - Function to set skills.
 * @param setProjects - Function to set projects.
 * @param setEmployers - Function to set employers.
 * @param type - Type of skills.
 * @param handleClose - Function to close row.
 * @param api - API instance.
 * @param items - Skills.
 * @param cvId - CV ID.
 * @param schema - Validation schema.
 * @returns Table component for skills.
 * @notExported
 */
const SkillsTable: React.FC<IComponentProps> = ({
  onOpen,
  editable,
  setSkills,
  setProjects,
  setEmployers,
  type,
  handleClose,
  api,
  items,
  cvId,
  schema,
  updateProgress,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const { t, i18n } = useTranslation()
  const { setSuccessNotificationPopup } = useNotificationPopup()
  const [deleteConnectedSkillConfirm, setDeleteConnectedSkillConfirm] = useState<boolean>(false)
  const [editableSkill, setEditableSkill] = useState<IPersonSkillOrIndustryOrRole>()
  const [editedId, setEditedId] = useState<number>()
  const [backendError, setBackendError] = useState<IError>()
  const [updateConnected, setUpdateConnected] = useState<boolean>(false)
  const [addNew, setAddNew] = useState<boolean>(false)
  const [errors, setErrors] = useState<ErrorFields<EditableFields<T>>>({})

  useEffect(() => {
    const controller = new AbortController()

    if (editableSkill && updateConnected === true) {
      ;(async () => {
        try {
          const projects = await cvProjectsAPI.getList(editableSkill.cvId, controller)
          const employers = await cvEmployersAPI.getList(editableSkill.cvId, controller)
          if (isComponentMounted.current) {
            setProjects(projects)
            setEmployers(employers)
            setUpdateConnected(false)
          }
        } catch (error) {
          setBackendError(error as IError)
        }
      })()
    }

    return () => {
      controller.abort()
    }
  }, [updateConnected])

  useEffect(() => {
    setEditableSkill(undefined)
    setEditedId(undefined)
  }, [i18n.language])

  const getDisabledOptions = () => {
    if (items) {
      return items.filter(item => item.Skill).map(personSkill => personSkill.Skill)
    }
    return []
  }

  const parseValidationErrors = err => {
    if (!(err instanceof ValidationError)) {
      throw err
    }

    const newErrors: typeof errors = {}

    for (const inner of err.inner) {
      const path = inner.path
      newErrors[path] = inner.message
    }

    setErrors(newErrors)
  }

  const onSubmit = async () => {
    if (items && isComponentMounted.current) {
      try {
        await schema.validate(editableSkill, { abortEarly: false })
        setErrors({})
      } catch (error) {
        parseValidationErrors(error)
        return error
      }

      if (Object.keys(errors).length < 1 && isComponentMounted.current) {
        try {
          let result: IPersonSkillOrIndustryOrRole
          if (editableSkill && editableSkill.id) {
            result = await api.save(editableSkill)
          } else {
            result = await api.create(editableSkill?.cvId, editableSkill)
          }

          if (isComponentMounted.current) {
            const newSkills: IPersonSkillOrIndustryOrRole[] = editableSkill?.id
              ? items.map(skill => {
                  if (skill.id === result.id || skill.skillId === result.skillId) {
                    return result
                  }
                  return skill
                })
              : items

            if (!editableSkill?.id) {
              newSkills.push(result)
            }

            setEditableSkill(undefined)
            setEditedId(undefined)
            setSuccessNotificationPopup()
            setErrors({})
            handleClose(result)
            setAddNew(false)
          }
        } catch (err) {
          setBackendError(err as IError)
        }
      }
    }
  }

  const setNewSkill = (newSkill: ISkillOrIndustryOrRole) => {
    if (editableSkill) {
      setEditableSkill({ ...editableSkill, Skill: newSkill as ISkill, skillId: newSkill.id })
    }
  }

  const setNewItemValue = (newValue, field: string) => {
    if (field === 'lastUsed' || field === 'experienceMonths') {
      if (editableSkill) setEditableSkill({ ...editableSkill, [field]: toPositiveInteger(newValue) })
    } else {
      if (editableSkill) setEditableSkill({ ...editableSkill, [field]: newValue })
    }
  }

  const deleteItem = async () => {
    for (const item of items) {
      if (item.id === editableSkill?.id) {
        const currentItem = item as IPersonSkillOrIndustryOrRole
        try {
          await api.delete(currentItem.id)
          if (isComponentMounted.current) {
            setSkills(items.filter(item => item.id !== currentItem.id))
            setUpdateConnected(true)
            setEditableSkill(undefined)
            setEditedId(undefined)
            updateProgress()
            setSuccessNotificationPopup()
          }
        } catch (err) {
          setBackendError(err as IError)
        }
      }
    }
    setDeleteConnectedSkillConfirm(false)
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const tableItems = useMemo(() => {
    return orderBy(
      items,
      ['level', item => (item.Skill ? chooseDBTranslation(i18n, item.Skill).name : null)],
      ['desc', 'asc']
    ).filter((obj, index, self) => index === self.findIndex(t => t.skillId === obj.skillId))
  }, [items])

  const [sorting, setSorting] = useState<SortingState>([])

  const unspecified = () => <CardContentText type="error">{t(`profile.${type}.unspecified`)}</CardContentText>

  const columns = React.useMemo<ColumnDef<IPersonSkillOrIndustryOrRole>[]>(
    () => [
      {
        id: 'name',
        header: t(`profile.${type}.card.nameColumn`),
        accessorFn: row => chooseDBTranslation(i18n, row.Skill).name,
        cell: ({ row }) => {
          return (
            <SkillsItem
              skill={row.original}
              editable={editable}
              onClick={() => {
                onOpen(row.original)
                setEditableSkill(undefined)
                setEditedId(undefined)
              }}
              showTooltip={true}
              key={row.original.id}
              showLastUsed={true}
              type={type}
            />
          )
        },
      },
      {
        id: 'level',
        header: t(`profile.${type}.level`),
        accessorFn: row => row.level,
        cell: ({ row }) => {
          return <SkillLevelChip level={row.original.level ?? 0} type="skill" />
        },
      },
      {
        id: 'interestLevel',
        header: t(`profile.${type}.interest`),
        accessorFn: row => row.interestLevel,
        cell: ({ row }) => {
          const thumbs: React.ReactNode[] = Array.from(Array(row.original.interestLevel), (x, i) => (
            <ThumbUpIcon key={i} fontSize="small" style={{ padding: 1 }} color="secondary" />
          ))

          return row.original.interestLevel ? thumbs : unspecified()
        },
      },
      {
        id: 'experienceMonths',
        header: t(`profile.${type}.experience`),
        accessorFn: row => row.experienceMonths,
        cell: ({ row }) => {
          return row.original.experienceMonths ? (
            getSkillExperienceLabel(row.original.experienceMonths)
          ) : (
            <CardContentText type="error">{t(`profile.${type}.unspecified`)}</CardContentText>
          )
        },
      },
      {
        id: 'lastUsed',
        header: t(`profile.${type}.lastUsed`),
        accessorFn: row => row.lastUsed,
        cell: ({ row }) => {
          if (editable && editableSkill && row.original.id === editableSkill.id && !addNew) {
            return (
              <TableInputField
                value={editableSkill?.lastUsed ?? 2022}
                onChange={newValue => setNewItemValue(parseInt(newValue), 'lastUsed')}
                type="number"
                helperText={t(`profile.${type}.year`)}
                disabled={!editableSkill?.skillId}
                error={errors['lastUsed']}
              />
            )
          }
          return row.original.lastUsed
        },
      },
      {
        id: 'controls',
        header: '',
        cell: () => {
          return ''
        },
      },
    ],
    [i18n.language, editable, editedId, editableSkill?.level, editableSkill?.interestLevel, editableSkill?.skillId]
  )

  const [{ pageIndex: defaultPageIndex, pageSize: defaultPageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })

  const pagination = useMemo(
    () => ({
      pageIndex: defaultPageIndex,
      pageSize: defaultPageSize,
    }),
    [defaultPageIndex, defaultPageSize]
  )

  const table = useReactTable({
    data: tableItems,
    columns: columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    state: {
      sorting: sorting,
      pagination,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableSorting: true,
    autoResetPageIndex: false,
  })

  return (
    <Grid>
      {tableItems && (
        <>
          {editable && !editableSkill && (
            <AddToArrayButton
              icon={<AddIcon color="primary" fontSize="large" />}
              tooltip={t('add')}
              maxWidth={true}
              clickAction={() => {
                setEditableSkill(getSkillsInitialData(cvId) as unknown as IPersonSkillOrIndustryOrRole)
                setAddNew(true)
              }}
            />
          )}
          <Table
            size="small"
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
              },
            }}
          >
            <TableHead sx={{ borderBottom: '1px solid #e0e0e0' }}>
              {table.getHeaderGroups().map(headerGroup => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <TableCell
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        maxWidth: header.getSize(),
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            style: header.column.getCanSort()
                              ? { cursor: 'pointer', fontWeight: 'bold' }
                              : { fontWeight: 'bold' },
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <TableSortLabel active={true} direction="asc" />,
                            desc: <TableSortLabel active={true} direction="desc" />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {editable && editableSkill && !editableSkill.id && addNew && (
                // Addition row
                <TableRow
                  sx={{ borderTop: `1px solid ${colors.borderColor}`, borderBottom: `1px solid ${colors.borderColor}` }}
                >
                  <TableCell sx={{ minWidth: '250px' }}>
                    <SkillPicker
                      type={type}
                      onChange={chosenSkill => {
                        if (chosenSkill) {
                          setNewSkill(chosenSkill)
                        }
                      }}
                      value={editableSkill.Skill}
                      placeholderLabel={t(`profile.${type}.modal.searchSkill`)}
                      usedSkills={getDisabledOptions()}
                      disableClearable
                    />
                  </TableCell>
                  <TableCell>
                    <LevelPicker
                      kind="skill"
                      value={editableSkill?.level ?? null}
                      onChange={newValue => {
                        setNewItemValue(newValue, 'level')
                      }}
                      smallIcons={true}
                      disabled={!editableSkill?.skillId}
                    />
                  </TableCell>
                  <TableCell>
                    <InterestLevelPicker
                      smallIcons={true}
                      value={editableSkill?.interestLevel ?? null}
                      onChange={newValue => setNewItemValue(newValue, 'interestLevel')}
                      disabled={!editableSkill?.skillId}
                    />
                  </TableCell>
                  <TableCell>
                    <ExperienceInputField
                      value={editableSkill?.experienceMonths ?? 0}
                      onChange={(newValue: number) => setNewItemValue(newValue, 'experienceMonths')}
                      disabled={!editableSkill?.skillId}
                      error={errors['experienceMonths']}
                    />
                  </TableCell>
                  <TableCell>
                    <TableInputField
                      value={editableSkill?.lastUsed ?? 2022}
                      onChange={newValue => setNewItemValue(parseInt(newValue), 'lastUsed')}
                      type="number"
                      helperText={t(`profile.${type}.year`)}
                      disabled={!editableSkill?.skillId}
                      error={errors['lastUsed']}
                    />
                  </TableCell>
                  <TableCell>
                    <CaleoIconButton
                      size="medium"
                      clickAction={onSubmit}
                      icon={<DoneIcon />}
                      tooltip={t('save')}
                      valid={!!editableSkill?.skillId}
                    />
                    <CaleoIconButton
                      size="medium"
                      clickAction={() => {
                        setEditableSkill(undefined)
                        setEditedId(undefined)
                        setAddNew(false)
                      }}
                      icon={<CloseIcon />}
                      tooltip={t('close')}
                    />
                  </TableCell>
                </TableRow>
              )}
              {table
                .getRowModel()
                .rows.reduce((accumulator: Row<IPersonSkillOrIndustryOrRole>[], row) => {
                  const exists = accumulator.find(item => item.original.id === row.original.id)
                  if (!exists) {
                    accumulator = accumulator.concat(row)
                  }
                  return accumulator
                }, [])
                .map(row => {
                  if (editableSkill && row.original.id === editableSkill.id && !addNew) {
                    // Edit row
                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          borderTop: `1px solid ${colors.borderColor}`,
                          borderBottom: `1px solid ${colors.borderColor}`,
                        }}
                      >
                        <TableCell>
                          <SkillsItem
                            skill={editableSkill}
                            editable={editable}
                            onClick={() => {
                              onOpen(editableSkill)
                              setEditableSkill(undefined)
                              setEditedId(undefined)
                            }}
                            showTooltip={true}
                            key={editableSkill.id}
                            showLastUsed={true}
                            type={type}
                          />
                        </TableCell>
                        <TableCell>
                          <LevelPicker
                            kind="skill"
                            value={editableSkill?.level ?? null}
                            onChange={newValue => {
                              setNewItemValue(newValue, 'level')
                            }}
                            smallIcons={true}
                            disabled={!editableSkill?.skillId}
                          />
                        </TableCell>
                        <TableCell>
                          <InterestLevelPicker
                            smallIcons={true}
                            value={editableSkill?.interestLevel ?? null}
                            onChange={newValue => setNewItemValue(newValue, 'interestLevel')}
                            disabled={!editableSkill?.skillId}
                          />
                        </TableCell>
                        <TableCell>
                          <ExperienceInputField
                            value={editableSkill?.experienceMonths ?? 0}
                            onChange={(newValue: number) => setNewItemValue(newValue, 'experienceMonths')}
                            disabled={!editableSkill?.skillId}
                            error={errors['experienceMonths']}
                          />
                        </TableCell>
                        <TableCell>
                          <TableInputField
                            value={editableSkill?.lastUsed ?? 2022}
                            onChange={newValue => setNewItemValue(parseInt(newValue), 'lastUsed')}
                            type="number"
                            helperText={t(`profile.${type}.year`)}
                            disabled={!editableSkill?.skillId}
                            error={errors['lastUsed']}
                          />
                        </TableCell>
                        <TableCell>
                          <CaleoIconButton
                            size="medium"
                            clickAction={onSubmit}
                            icon={<DoneIcon />}
                            tooltip={t('save')}
                            valid={!!editableSkill?.skillId}
                          />
                          {!!editableSkill?.id && (
                            <DeleteButton
                              clickAction={() => {
                                setDeleteConnectedSkillConfirm(true)
                              }}
                              size="medium"
                            />
                          )}
                          <CaleoIconButton
                            size="medium"
                            clickAction={() => {
                              setEditableSkill(undefined)
                              setEditedId(undefined)
                            }}
                            icon={<CloseIcon />}
                            tooltip={t('close')}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  }

                  if (row.original.id && row.original.skillId) {
                    // No action row
                    return (
                      <TableRow
                        sx={{
                          '&:nth-of-type(odd)': {
                            backgroundColor: 'action.hover',
                          },
                        }}
                        key={row.id}
                        onClick={
                          editable
                            ? () => {
                                setEditableSkill(row.original)
                                setEditedId(row.original.id)
                              }
                            : undefined
                        }
                      >
                        {row.getVisibleCells().map(cell => (
                          <TableCell key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  }
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  count={table.getPrePaginationRowModel().rows.length}
                  rowsPerPage={table.getState().pagination.pageSize}
                  page={table.getState().pagination.pageIndex}
                  SelectProps={{
                    inputProps: { 'aria-label': t('custom-table.labelRowsSelect') },
                    native: true,
                    sx: { order: 8 },
                  }}
                  onPageChange={(_, page) => {
                    table.setPageIndex(page)
                  }}
                  onRowsPerPageChange={e => {
                    const size = e.target.value ? Number(e.target.value) : 10
                    table.setPageSize(size)
                  }}
                  labelRowsPerPage={t('custom-table.labelRowsSelect')}
                  labelDisplayedRows={({ from, to, count }) =>
                    t('custom-table.labelDisplayedRows', { from: from, to: to, count: count })
                  }
                  ActionsComponent={TablePaginationActions}
                  sx={{
                    [`& .MuiTablePagination-spacer`]: {
                      display: 'none',
                      order: 9,
                    },
                    [`& .MuiTablePagination-displayedRows`]: {
                      order: 4,
                    },
                    [`& .MuiTablePagination-selectLabel`]: {
                      ml: 1,
                      order: 7,
                    },
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </>
      )}
      {editableSkill && deleteConnectedSkillConfirm && (
        <DeleteConnectedSkillConfirm
          open={deleteConnectedSkillConfirm}
          confirm={deleteItem}
          onClose={() => setDeleteConnectedSkillConfirm(false)}
          item={editableSkill}
          type={type}
        />
      )}
    </Grid>
  )
}

export default SkillsTable
