import { Box, Grid, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputField } from './InputField'

/**
 * Experience input field.
 *
 * @param value - The current value in months.
 * @param onChange - The change handler.
 * @param disabled - Whether the field is disabled.
 * @param error - The error message.
 * @returns The experience input field.
 * @notExported
 */
export const ExperienceInputField = ({
  value,
  onChange,
  disabled,
  error,
}: {
  value: number
  onChange: (newData: number) => void
  disabled?: boolean
  error?: string
}) => {
  const [currentYearValue, setCurrentYearValue] = useState<number>(Math.floor(value / 12))
  const [currentMonthValue, setCurrentMonthValue] = useState<number>(value % 12)
  const { t } = useTranslation()
  const theme = useTheme()

  const changeValue = (newValue: number, type: string) => {
    let monthlyTotal = 0
    if (type === 'month') {
      monthlyTotal = newValue + currentYearValue * 12
    } else {
      monthlyTotal = newValue * 12 + currentMonthValue
    }
    onChange(monthlyTotal)
    setCurrentYearValue(Math.floor(monthlyTotal / 12))
    setCurrentMonthValue(monthlyTotal % 12)
  }

  return (
    <Grid container spacing={1} alignItems="center" justifyContent="flex-start">
      <Grid item xs={6}>
        <InputField
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = parseInt(e.target.value)
            changeValue(newValue ? newValue : 0, 'year')
          }}
          type="number"
          disabled={disabled}
          value={currentYearValue}
          name="experienceYears"
          inputProps={{ min: 0, max: 500 }}
          sx={{ minWidth: '60px' }}
          helperText={t('profile.skill.modal.experience.years')}
        />
      </Grid>
      <Grid item xs={6}>
        <InputField
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = parseInt(e.target.value)
            changeValue(newValue ? newValue : 0, 'month')
          }}
          type="number"
          disabled={disabled}
          value={currentMonthValue}
          name="experienceMonths"
          inputProps={{ min: 0, max: 11 }}
          sx={{ minWidth: '60px' }}
          helperText={t('profile.skill.modal.experience.months')}
        />
      </Grid>
      {!!error && (
        <Grid item xs={12}>
          <Box style={{ color: theme.palette.error.main, fontSize: '0.75rem' }}>{error}</Box>
        </Grid>
      )}
    </Grid>
  )
}
