import { Badge, IconButton, Tooltip } from '@mui/material'
import React, { MouseEventHandler } from 'react'

/** @notExported */
interface BadgeIconButtonProps {
  /** Action to perform when button is clicked. */
  clickAction?: MouseEventHandler<unknown>
  /** Tooltip for button. */
  tooltip?: string | React.ReactFragment
  /** Whether or not the button is valid. */
  valid?: boolean
  /** Size of the button. */
  size?: 'medium' | 'small' | undefined | 'xsmall'
  /** Icon to display. */
  icon
  /** Badge content. */
  badgeContent: number | string
}

/**
 * Badge icon button component.
 *
 * @returns Badge icon button component.
 * @notExported
 * @category Buttons
 */
const BadgeIconButton: React.FC<BadgeIconButtonProps> = ({
  clickAction,
  tooltip,
  valid = true,
  size = 'small',
  icon,
  badgeContent,
}) => {
  const getButton = () => {
    return (
      <IconButton
        sx={{ float: 'right' }}
        onClick={clickAction}
        disabled={!valid}
        size={size === 'xsmall' ? 'medium' : size}
      >
        <Badge badgeContent={badgeContent} color="primary">
          {icon}
        </Badge>
      </IconButton>
    )
  }
  return <>{tooltip ? <Tooltip title={tooltip}>{getButton()}</Tooltip> : getButton()}</>
}

export default BadgeIconButton
