import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { layoutAPI } from 'api/LayoutAPI'
import DeleteButton from 'Components/reusable/Buttons/DeleteButton'
import AddButton from 'Components/reusable/IconButtons/AddButton'
import { InputField } from 'Components/reusable/InputFields/InputField'
import { useUser } from 'hooks'
import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ICv, ICvTranslation } from 'types/cvsInterfaces'
import { ILayout } from 'types/layoutInterfaces'
import { chooseDBTranslation } from 'utils/translations'

/**
 * Renders the print controls component.
 *
 * @param {object} setOpen - function to set the value of "open" state
 * @param {function} change - function to change the layout
 * @param {array} layouts - array of available layouts
 * @param {object} selected - selected layout
 * @param {function} refresh - function to refresh the component
 * @param {function} setOwnCompanyBranding - function to set the value of "ownCompanyBranding" state
 * @param {boolean} ownCompanyBranding - boolean value indicating whether to use own company branding
 * @param {string} organizationId - ID of the organization
 * @param {function} setCv - function to set the value of "cv" state
 * @param {array} cvs - array of available CVs
 * @param {number} selectedId - ID of the selected CV
 * @return {JSX.Element} the print controls component
 * @notExported
 */
const PrintControls = ({
  setOpen,
  change,
  layouts,
  selected,
  refresh,
  setOwnCompanyBranding,
  ownCompanyBranding,
  organizationId,
  setCv,
  cvs,
  selectedId,
}) => {
  const { t, i18n } = useTranslation()
  const { user } = useUser()
  const [selectedCv, setSelectedCv] = useState<string | undefined>(undefined)

  if (user === null || !layouts) {
    return null
  }

  const deleteLayout = async (id: number) => {
    await layoutAPI.deleteLayout(id)
    refresh(new Date())
  }

  interface CVOption {
    label: string
    data: ICv | undefined
  }
  let CVOptions: CVOption[] = []

  if (cvs) {
    for (const cv of cvs) {
      const translation = chooseDBTranslation(i18n, cv)
      const label = translation.name || cv.translations.find((tr: ICvTranslation) => tr.name)?.name || 'CV_' + cv.id

      CVOptions.push({
        label,
        data: cv,
      })
    }

    CVOptions = _.orderBy(CVOptions, ['data.id'], ['asc'])
  }

  if (CVOptions.length < 1) {
    return null
  }

  return (
    <>
      <Box my={2}>
        <Box display="flex" flexDirection="row" flexWrap="nowrap" pb={1} style={{ paddingBottom: '0px' }}>
          <Autocomplete
            autoSelect
            noOptionsText={t('controls.noOptionsText')}
            disableClearable
            options={CVOptions}
            getOptionLabel={option => option.label}
            value={CVOptions.find(option => option?.data?.id === selectedId)}
            inputValue={selectedCv}
            isOptionEqualToValue={(option, value) => {
              if (value && option.data === value.data) {
                return true
              }
              return false
            }}
            onChange={(e, value) => {
              if (value) {
                setCv(value?.data?.id)
              }
            }}
            style={{ marginRight: 18, width: 300, paddingBottom: 0 }}
            renderInput={params => (
              <InputField
                {...params}
                name="layout"
                size="small"
                label={t('print.controls.selectCV')}
                onChange={event => {
                  setSelectedCv(event.target.value)
                }}
              />
            )}
          />
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="nowrap" pb={1} style={{ paddingBottom: '0px' }}>
          <Autocomplete
            getOptionLabel={option => option.name}
            autoSelect
            options={layouts}
            onChange={(e, value: ILayout) => {
              change(value)
              refresh(value)
            }}
            value={selected ? selected : layouts[0]}
            renderInput={params => (
              <InputField {...params} name="layout" size="small" label={t('print.controls.layout')} />
            )}
            disableClearable={true}
            style={{ marginRight: 18, width: 300, paddingBottom: 0 }}
          />
          <div style={{ marginTop: '24px' }}>
            <AddButton clickAction={() => setOpen(true)} tooltip={t('print.controls.addLayout')} />
          </div>
        </Box>
        {user.organizationId !== organizationId && organizationId && (
          <FormControlLabel
            control={
              <Checkbox
                checked={ownCompanyBranding}
                onChange={e => setOwnCompanyBranding(e.target.checked)}
                name="checkbox"
                color="primary"
              />
            }
            label={<Typography>{t('print.layout.useOwnCompanyBranding')}</Typography>}
          />
        )}
        {selected && selected.id !== 0 && (
          <DeleteButton clickAction={() => deleteLayout(selected.id)} customLabel={t('print.controls.deleteLayout')} />
        )}
      </Box>
    </>
  )
}

export default PrintControls
