import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'

/** @notExported  */
interface CloseButtonProps {
  /** Action to perform when button is clicked. */
  clickAction: () => void
  /** Tooltip for button. */
  tooltip?: string
  /** Size of the button. */
  size?: 'medium' | 'small' | undefined
}

/**
 * Close button component.
 *
 * @returns Close button component.
 * @notExported
 * @category Buttons
 */
const CloseButton: React.FC<CloseButtonProps> = ({ clickAction, tooltip, size }) => {
  const { t } = useTranslation()

  const getButton = () => {
    return (
      <IconButton sx={{ float: 'right' }} onClick={() => clickAction()} size={size}>
        <CloseIcon />
      </IconButton>
    )
  }
  return <Tooltip title={tooltip ?? (t('close') as string)}>{getButton()}</Tooltip>
}

export default CloseButton
