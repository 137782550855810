import { Chip, CircularProgress, Grid, Link, Tooltip, Typography } from '@mui/material'
import { cvAPI } from 'api/CvAPI'
import { skillAPI } from 'api/SkillAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import CaleoPrimaryButton from 'Components/reusable/Buttons/CaleoPrimaryButton'
import SkillPicker from 'Components/reusable/InputFields/SkillPicker'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISkillOrIndustryOrRole, ISkillSuggestions } from 'types/cvsInterfaces'
import { IError } from 'types/error'
import { CvId } from 'types/ids'
import { chooseDBTranslation } from 'utils/translations'
import DefaultValues, { IWizardDefaultValues } from './DefaultValues'
import { useIsComponentMounted } from 'hooks/util'
import colors from 'constants/colors'
import BorderedSection from 'Components/reusable/CaleoCustomComponents/BorderedSection'

/** @notExported */
interface IProfileFillingWizardProps {
  /** The ID of the CV */
  id: CvId
  /** A function to open/close the component. */
  open: (value: boolean) => void
  /** A function to save and close the component. */
  onSaveClose: (id: CvId) => void
  /** A boolean value indicating whether the component is a dialog. */
  isDialog: boolean
  /** An array of old skills. */
  oldSkills: number[]
}

/**
 * ProfileFillingWizard component.
 *
 * @param id - The ID of the CV.
 * @param open - A function to open/close the component.
 * @param original - A boolean value indicating whether the component is original.
 * @param onSaveClose - A function to save and close the component.
 * @param isDialog - A boolean value indicating whether the component is a dialog.
 * @param oldSkills - An array of old skills.
 * @returns The ProfileFillingWizard component.
 * @notExported
 */
const ProfileFillingWizard: React.FC<IProfileFillingWizardProps> = ({ id, open, onSaveClose, isDialog, oldSkills }) => {
  const isComponentMounted = useIsComponentMounted()
  const { t, i18n } = useTranslation()

  const [allKindSkillSuggestions, setAllKindSkillSuggestions] = useState<ISkillSuggestions>()
  const [primaryRole, setPrimaryRole] = useState<ISkillOrIndustryOrRole>()
  const [wizardDefaultValues, setWizardDefaultValues] = useState<IWizardDefaultValues>({})
  const [selectedSkills, setSelectedSkills] = useState<ISkillOrIndustryOrRole[]>([])
  const [backendError, setBackendError] = useState<IError>()
  const [loading, setLoading] = useState<boolean>(false)
  const [skillView, setSkillView] = useState<number>(10)
  const [industryView, setIndustryView] = useState<number>(10)
  const [roleView, setRoleView] = useState<number>(10)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  useEffect(() => {
    const controller = new AbortController()

    if (primaryRole) {
      setLoading(true)
      ;(async () => {
        try {
          const suggestions = await skillAPI.getSkillSuggestions(primaryRole.id, controller)
          if (!isComponentMounted.current) return
          setAllKindSkillSuggestions(suggestions)
        } catch (error) {
          setBackendError(error as IError)
        }
      })()
      setSkillView(10)
      setIndustryView(10)
      setRoleView(10)
      setLoading(false)
    }

    return () => {
      controller.abort()
    }
  }, [primaryRole])

  const defaultValidation = useMemo(() => {
    if (
      wizardDefaultValues.experienceMonths &&
      wizardDefaultValues.experienceMonths > 0 &&
      wizardDefaultValues.interest &&
      wizardDefaultValues.interest > 0 &&
      wizardDefaultValues.level &&
      wizardDefaultValues.level > 0
    ) {
      return true
    }
    return false
  }, [wizardDefaultValues])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const saveItemsToDB = async () => {
    try {
      setButtonLoading(true)
      await cvAPI.postAllSelectionsToCv({ skills: selectedSkills, defaultValues: wizardDefaultValues }, id)
      if (!isComponentMounted.current) return
      onSaveClose(id)
      setButtonLoading(false)
    } catch (error) {
      setBackendError(error as IError)
    }
  }

  const loadMore = type => {
    if (type === 'skill') {
      setSkillView(skillView + 10)
    } else if (type === 'industry') {
      setIndustryView(industryView + 10)
    } else {
      setRoleView(roleView + 10)
    }
  }

  const addOrRemoveFromSelectedSkills = skill => {
    if (selectedSkills?.find(selectedSkill => selectedSkill.id === skill.id)) {
      const filteredSkills = selectedSkills.filter(selectedSkill => selectedSkill.id !== skill.id)
      setSelectedSkills(filteredSkills)
    } else {
      setSelectedSkills(oldArray => [...oldArray, skill])
    }
  }

  const getChips = (array: ISkillOrIndustryOrRole[]) => {
    return array.map(skill => (
      <Tooltip key={skill.id} title={chooseDBTranslation(i18n, skill)?.name}>
        <Chip
          color="primary"
          variant={selectedSkills.find(selectedSkill => selectedSkill.id === skill.id) ? 'filled' : 'outlined'}
          style={{ marginRight: 3, marginBottom: 3, fontSize: '0.9em', maxWidth: 250, borderRadius: 5 }}
          clickable={true}
          disabled={!!oldSkills.find(oldSkill => oldSkill === skill.id)}
          onClick={() => addOrRemoveFromSelectedSkills(skill)}
          label={chooseDBTranslation(i18n, skill)?.name ?? ''}
          size="small"
        />
      </Tooltip>
    ))
  }

  const getWizardBlock = (type: 'skill' | 'industry' | 'role', items: ISkillOrIndustryOrRole[], viewCount: number) => {
    const visibleItems = [...selectedSkills.filter(item => item.kind === type), ...items].filter(
      (value, index, self) => index === self.findIndex(t => t.id === value.id)
    )

    return (
      <Grid container direction="column" alignItems="center">
        <BorderedSection
          title={t(`profile.fillingWizard.${type}.recommendations`)}
          fontSize="0.9em"
          borderBefore="3%"
          color="#E0E0E0"
          fontWeight="500"
          sx={{ minWidth: '100%' }}
        >
          <Grid>
            <SkillPicker
              value={null}
              onChange={newValue => {
                if (newValue) {
                  addOrRemoveFromSelectedSkills(newValue)
                }
              }}
              type={type}
              placeholderText={t(`profile.fillingWizard.select${type}`)}
              label={t(`profile.fillingWizard.${type}.search`)}
              noPlaceholders={true}
              insetLabel
              usedSkillIds={oldSkills}
            />
          </Grid>
          <Grid item mt={1}>
            <Grid item>{getChips(visibleItems.slice(0, viewCount))}</Grid>
          </Grid>
          {visibleItems.length > viewCount && (
            <Grid item mt={1}>
              <CaleoPrimaryButton
                valid={true}
                label={t('profile.fillingWizard.loadMore')}
                clickAction={() => loadMore(type)}
                size="small"
              />
            </Grid>
          )}
        </BorderedSection>
      </Grid>
    )
  }

  return (
    <Grid
      p={2}
      sx={{
        maxWidth: 1000,
        backgroundColor: colors.searchBackground,
        margin: '0 auto',
        backgroundImage: 'none',
        marginTop: 3,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom fontWeight="bold">
            {t('profile.fillingWizard.title')}
          </Typography>
          <Typography gutterBottom>{t('profile.fillingWizard.instruction')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={primaryRole ? 4 : 12}>
                <SkillPicker
                  value={primaryRole ?? null}
                  onChange={newValue => {
                    if (newValue) setPrimaryRole(newValue)
                  }}
                  type="role"
                  placeholderText={t(`profile.fillingWizard.selectrole`)}
                  label={t(`profile.fillingWizard.role`)}
                  noPlaceholders={true}
                  insetLabel
                  required
                />
              </Grid>
              {primaryRole && (
                <Grid item xs={8}>
                  <DefaultValues data={wizardDefaultValues} setData={setWizardDefaultValues} />
                </Grid>
              )}
            </Grid>
            {primaryRole && !loading && allKindSkillSuggestions && (
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom fontWeight="bold">
                    {t('profile.fillingWizard.selectSkills')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {t('profile.fillingWizard.helperText')}
                </Grid>
                <Grid item xs={4}>
                  {getWizardBlock('skill', allKindSkillSuggestions.skills, skillView)}
                </Grid>
                <Grid item xs={4}>
                  {getWizardBlock('role', allKindSkillSuggestions.roles, roleView)}
                </Grid>
                <Grid item xs={4}>
                  {getWizardBlock('industry', allKindSkillSuggestions.industries, industryView)}
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container justifyContent={primaryRole ? 'center' : 'flex-end'} spacing={1}>
                {primaryRole && (
                  <Grid item xs={12} container justifyContent="center">
                    <CaleoPrimaryButton
                      valid={selectedSkills.length > 0 && primaryRole && defaultValidation}
                      label={t(`profile.fillingWizard.${isDialog ? 'update' : 'save'}`, {
                        count: selectedSkills.length,
                      })}
                      clickAction={() => saveItemsToDB()}
                      loading={buttonLoading}
                    />
                  </Grid>
                )}
                <Grid item>
                  <Link sx={{ cursor: 'pointer', fontSize: '0.8em' }} onClick={() => open(false)}>
                    {t('profile.fillingWizard.skip')}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            {loading && (
              <Grid container justifyContent="center">
                <CircularProgress size={60} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProfileFillingWizard
