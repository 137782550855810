import React from 'react'
import { IconButton, Tooltip, useTheme } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteSweep'
import { useTranslation } from 'react-i18next'

/** @notExported  */
interface DeleteButtonProps {
  /** Action to perform when button is clicked. */
  clickAction: (event?: React.MouseEvent<HTMLButtonElement>) => void
  /** Tooltip for button. */
  tooltip?: string
  /** Size of the button. */
  size?: 'medium' | 'small' | undefined
}

/**
 * Delete button component.
 *
 * @returns Delete button component.
 * @notExported
 * @category Buttons
 */
const DeleteButton: React.FC<DeleteButtonProps> = ({ clickAction, tooltip, size = 'small' }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const getButton = () => {
    return (
      <IconButton
        sx={{ '&:hover': { backgroundColor: theme.palette.error.light, color: theme.palette.error.dark } }}
        onClick={event => clickAction(event)}
        size={size}
      >
        <DeleteIcon />
      </IconButton>
    )
  }
  return <Tooltip title={tooltip ?? (t('delete') as string)}>{getButton()}</Tooltip>
}

export default DeleteButton
