import { Backdrop, Grid } from '@mui/material'
import CaleoPrimaryButton from 'Components/reusable/Buttons/CaleoPrimaryButton'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { IError } from 'types/error'
import caleoError from 'assets/images/Caleo-error.png'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported  */
interface IErrorOverlayProps {
  /** Error object. */
  error: IError
  /** Error state setter. */
  setOpen: Dispatch<SetStateAction<IError | undefined>>
  /** Disable continue button.  */
  disableContinue?: boolean
}

/**
 * Error overlay component.
 *
 * @returns Error overlay component.
 * @notExported
 */
const ErrorOverlay: React.FC<IErrorOverlayProps> = ({ error, setOpen, disableContinue }) => {
  const isComponentMounted = useIsComponentMounted()
  const { t } = useTranslation()

  if (error && (error.name === 'CanceledError' || error.name === 'AbortError') && isComponentMounted.current) {
    setOpen(undefined)
  }

  return (
    <>
      <Backdrop
        sx={{ zIndex: theme => theme.zIndex.drawer + 1, backgroundColor: theme => theme.palette.background.default }}
        open={!!error}
      >
        <Grid sx={{ textAlign: 'center' }}>
          <Grid
            sx={{
              color: theme => theme.palette.primary.main,
              fontSize: '2.5rem',
              paddingTop: '2vw',
            }}
          >
            {t('error.header')}
          </Grid>
          <Grid
            sx={{
              color: theme => theme.palette.primary.main,
              fontSize: '1.25rem',
            }}
          >
            {t(error.key, { id: error.id })}
          </Grid>
          <Grid sx={{ textAlign: 'center' }}>
            <img src={caleoError} alt="error" style={{ maxWidth: '45vw' }} />
          </Grid>
          <Grid
            sx={{
              fontSize: '0.875rem',
              marginBottom: '10px',
            }}
          >
            {error.message}
          </Grid>
          {!disableContinue && isComponentMounted.current && (
            <CaleoPrimaryButton label={t('error.ok')} clickAction={() => setOpen(undefined)} valid={true} />
          )}
        </Grid>
      </Backdrop>
    </>
  )
}

export default ErrorOverlay
