import { DialogContent, Grid } from '@mui/material'
import DataContext from 'Components/reusable/DataContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ISkillOrIndustryOrRole, IPersonSkillOrIndustryOrRole } from 'types/cvsInterfaces'
import AddToArrayButton from 'Components/reusable/Buttons/AddToArrayButton'
import newWithEditModal from 'Components/reusable/HOC/newWithEditModal'
import SkillsItem from './SkillsItem'

/** @notExported  */
interface ISkillModalProps {
  /** Skill being edited. */
  item: IPersonSkillOrIndustryOrRole | null
  /** Skill name. */
  name?: string
  /** Already added skills. */
  usedSkills?: IPersonSkillOrIndustryOrRole[]
  /** Multiple skills being edited. */
  multipleItems: IPersonSkillOrIndustryOrRole[]
  /** Function to add multiple skills. */
  addMultiple: () => void
  /** Function to remove skills from array. */
  removeFromArray: (items: IPersonSkillOrIndustryOrRole[]) => void
  /** Skill type. */
  type: string
}

/**
 * Skill modal.
 *
 * @returns Skill modal component.
 * @notExported
 */
const SkillModal: React.FC<ISkillModalProps> = ({
  item,
  name,
  usedSkills,
  multipleItems,
  addMultiple,
  removeFromArray,
  type,
}) => {
  const { t } = useTranslation()

  const handleSkillDelete = id => {
    const newSkillsData = multipleItems.filter(item => item.skillId !== id)
    removeFromArray(newSkillsData)
  }

  const getDisabledOptions = () => {
    const disabledOptions: ISkillOrIndustryOrRole[] = []
    usedSkills?.filter(skill => skill.id !== item?.id).map(item => disabledOptions.push(item.Skill))
    return disabledOptions
  }

  return (
    <Grid container>
      <DialogContent>
        <Grid>
          <DataContext.SkillField<IPersonSkillOrIndustryOrRole>
            field="skillId"
            dataField="Skill"
            required
            fullWidth
            usedSkills={getDisabledOptions()}
            name={name}
            type={type}
            insetLabel
          />
          <DataContext.ExperienceTimeField<IPersonSkillOrIndustryOrRole> field="experienceMonths" required fullWidth />
          <DataContext.NumberField<IPersonSkillOrIndustryOrRole> field="lastUsed" required fullWidth insetLabel />
          <DataContext.SkillLevelField<IPersonSkillOrIndustryOrRole> field="level" required fullWidth insetLabel />
          <DataContext.InterestLevelField<IPersonSkillOrIndustryOrRole> field="interestLevel" fullWidth insetLabel />
          {!item ? (
            <DataContext.Validation>
              {({ valid }) => (
                <AddToArrayButton
                  valid={valid}
                  clickAction={() => addMultiple()}
                  label={t(`profile.${type}.modal.addSkill`)}
                />
              )}
            </DataContext.Validation>
          ) : null}
        </Grid>
      </DialogContent>
      {multipleItems.length > 0 ? (
        <Grid sx={{ minWidth: '150px', paddingTop: '20px', paddingRight: '10px' }}>
          {multipleItems.map((skill, i) =>
            !item ? (
              <SkillsItem
                key={skill.id ?? `new-${i}`}
                editable
                newChip={true}
                showTooltip={true}
                showExperience={true}
                showLastUsed={true}
                skill={skill as IPersonSkillOrIndustryOrRole}
                onDeleteClick={() => handleSkillDelete(skill.skillId)}
                type={type}
              />
            ) : null
          )}
        </Grid>
      ) : null}
    </Grid>
  )
}

export default newWithEditModal(SkillModal)
