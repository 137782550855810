import { Box, DialogContent } from '@mui/material'
import SkillLevelChip from 'Components/reusable/CaleoCustomComponents/SkillLevelChip'
import React from 'react'
import { skillLevels } from 'utils/utils'
import newWithSimpleModal from 'Components/reusable/HOC/newWithSimpleModal'

/** @notExported */
interface ISkillDetailsModalProps {
  /** Type of skill. */
  type: 'skill' | 'industry' | 'role'
}

/**
 * Modal to display skill details.
 *
 * @returns Modal component for skill details.
 * @notExported
 */
const SkillDetailsModal: React.FC<ISkillDetailsModalProps> = ({ type }) => {
  return (
    <DialogContent>
      <Box display="flex" flexDirection="column">
        {skillLevels(type).map((skill, index) => (
          <Box key={'skillLevel' + index}>
            <Box display="flex" flexDirection="row">
              <SkillLevelChip level={skill?.level ?? 0} type="skill" />
            </Box>
            <Box sx={{ marginBottom: 1 }}>{skill.description}</Box>
          </Box>
        ))}
      </Box>
    </DialogContent>
  )
}

export default newWithSimpleModal(SkillDetailsModal)
