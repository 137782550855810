import { Grid } from '@mui/material'
import { personAPI } from 'api/PersonAPI'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPerson } from 'types/userInterfaces'
import SalesContact from './SalesContact'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported */
interface ISalesContact {
  /** Person data */
  personData: IPerson
}

/**
 * Contact sales card component.
 *
 * @returns Contact sales card component.
 * @notExported
 */
const ContactSalesCard: React.FC<ISalesContact> = ({ personData }) => {
  const isComponentMounted = useIsComponentMounted()
  const [salesContacts, setSalesContacts] = useState<IPerson[]>()
  const { t } = useTranslation()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (personData?.organizationId) {
        const results = await personAPI.getSalesContacts(personData?.organizationId, controller)
        if (!isComponentMounted.current) return
        setSalesContacts(results)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <Grid sx={{ maxWidth: 1400, m: '0 auto' }}>
      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={3}>
        <Grid item>
          <CardContentText type="cardSubHeader">{t('contactSales')}</CardContentText>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={4} justifyContent="center">
            {salesContacts?.map((salesContact, i) => (
              <SalesContact salesContact={salesContact} key={i} />
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <CardContentText type={'priceInfo'}>{`${t('officeAddress')}: ${personData.Site?.streetAddress}, ${
            personData.Site?.city
          }, ${personData.Site?.countryCode}`}</CardContentText>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(ContactSalesCard)
