import React from 'react'
import Rating, { RatingProps } from '@mui/material/Rating'
import { Star, StarBorder } from '@mui/icons-material'
import { Grid, InputBaseComponentProps } from '@mui/material'
import { ILevel } from 'types/cvsInterfaces'
import { InputField } from '../InputField'

type WrappedRatingProps = InputBaseComponentProps & RatingProps

const WrappedRating = React.forwardRef<HTMLInputElement, WrappedRatingProps>((props, ref) => {
  const { className, value, ...rest } = props
  return (
    <Grid className={className}>
      <Rating value={Number(value)} ref={ref} {...rest} />
    </Grid>
  )
})

const NewLevePicker = <T extends ILevel>({
  value,
  onChange,
  disabled = false,
  label = 'Level',
  required,
  precision,
  max,
  icon,
  emptyIcon,
}: {
  value: T | null
  onChange: (newValue: string) => void
  disabled?: boolean
  label?: string
  required?: boolean
  precision?: number
  max?: number
  icon?: JSX.Element
  emptyIcon?: JSX.Element
}) => {
  return (
    <InputField
      value={value}
      label={label}
      onChange={e => {
        onChange(e.target.value)
      }}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputComponent: WrappedRating,
        inputProps: {
          max: max ? max : 5,
          icon: icon ? icon : <Star fontSize="inherit" />,
          emptyIcon: emptyIcon ? emptyIcon : <StarBorder fontSize="inherit" />,
          precision: precision ? precision : 1,
        },
      }}
      disabled={disabled}
      required={required}
      insetLabel
    />
  )
}

export default NewLevePicker
