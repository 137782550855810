import Autocomplete from '@mui/material/Autocomplete'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import React from 'react'
import { ILanguageSkill } from 'types/cvsInterfaces'
import { fuseFiltering } from 'utils/utils'
import { InputField } from '../InputField'

/** @notExported */
interface ILanguagePickerProps {
  /** The current value. */
  value: string | null
  /** Action to do when the value changes. */
  onChange: (newValue: ILanguageSkill | null) => void
  /** The label text. */
  label?: string
  /** Whether the field is required. */
  required?: boolean
  /** Whether the field is disabled. */
  disabled: boolean
  /** The available options. */
  languageOptions
  /** Action to do when key is pressed. */
  onKeyDown?: (e) => void
  /** The icon to display. */
  icon?: JSX.Element
  /** Whether the field clearing is disabled. */
  disableClearable?: boolean
  /** Whether to show inset label. */
  insetLabel?: boolean
}

/**
 * Language picker component.
 *
 * @returns The language picker component.
 * @notExported
 */
const LanguagePicker: React.FC<ILanguagePickerProps> = ({
  value,
  onChange,
  label = 'Language',
  required = false,
  disabled,
  languageOptions,
  onKeyDown,
  icon,
  disableClearable = true,
  insetLabel,
}) => {
  languageOptions.forEach((l, index) => {
    if (l.languageCode === 'fi' || l.languageCode === 'en') {
      languageOptions.splice(index, 1)
      languageOptions.unshift(l)
    }
  })

  const getSelectedValue = () => {
    if (value) {
      const selectedValueItem = languageOptions.find(item => item.languageCode === value)
      return selectedValueItem
    } else {
      return null
    }
  }

  return (
    <>
      {!insetLabel && <CaleoInputLabel icon={icon} label={label} required={required} />}
      <Autocomplete
        filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['label'])}
        disabled={disabled}
        value={getSelectedValue()}
        options={languageOptions}
        onKeyDown={e => onKeyDown && onKeyDown(e)}
        fullWidth
        selectOnFocus
        disableClearable={disableClearable}
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(selectedValue, option) => option === selectedValue}
        style={{ marginTop: '0px' }}
        onChange={(_event, newValue) => {
          if (newValue !== null) {
            onChange(newValue.languageCode as unknown as ILanguageSkill)
          }
        }}
        renderInput={params => (
          <InputField
            {...params}
            name="languageCode"
            margin="dense"
            size="small"
            fullWidth
            InputLabelProps={{ style: { textTransform: 'capitalize' }, shrink: insetLabel ? true : false }}
            variant="outlined"
            label={insetLabel ? label : undefined}
            insetLabel={insetLabel}
          />
        )}
      />
    </>
  )
}

export default LanguagePicker
