import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPersonRole } from 'types/cvsInterfaces'
import { CvId } from 'types/ids'
import * as Yup from 'yup'
import DataContext, { NewOrExisting } from 'Components/reusable/DataContext'
import LoadingIndicator from 'Components/reusable/LoadingIndicator'

/**
 * YUP schema for role skills.
 * @notExported
 */
export const skillSchema = t =>
  Yup.object({
    skillId: Yup.number().required(t('fieldRequired')),
  })

/** @notExported */
interface IRoleCreateProps {
  /** CV ID. */
  cvId: CvId
  /** Function to add role. */
  onAdd: ({ newItem }: { newItem?: NewOrExisting<IPersonRole> }) => void
  /** Already added roles. */
  usedRoles?
  /** Label for role input field.  */
  label?: string
  /** Whether to inset label. */
  insetLabel?: boolean
}

/**
 * Controls for adding role to project.
 *
 * @returns Role create component.
 * @notExported
 */
const RoleCreate: React.FC<IRoleCreateProps> = ({ cvId, onAdd, usedRoles, label, insetLabel }) => {
  const [data, setData] = useState<NewOrExisting<IPersonRole> | null>(null)
  const { t } = useTranslation()

  useEffect(() => {
    setData({
      cvId,
      skillId: null as unknown,
    } as NewOrExisting<IPersonRole>)
  }, [onAdd])

  if (!data) {
    return <LoadingIndicator />
  }

  const add = async () => {
    if (data && data.Skill) onAdd({ newItem: data })
  }

  return (
    <DataContext
      data={data}
      onSubmit={add}
      localeBase="assignment.role.requirement.role"
      schema={skillSchema(t)}
      onChange={newItem => {
        if (newItem && newItem.Skill) onAdd({ newItem })
      }}
    >
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <DataContext.SkillField<IPersonRole>
            field="skillId"
            usedSkills={usedRoles}
            dataField="Skill"
            fullWidth
            type="role"
            placeholder={t('profile.project.modal.addrole.placeholder')}
            submitOnEnter={true}
            label={t(`${label ?? 'profile.employer.modal.role'}`)}
            insetLabel={insetLabel}
          />
        </Grid>
      </Grid>
    </DataContext>
  )
}

export default RoleCreate
