import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import React, { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

interface OwnProps {
  type?: string
  value: number | undefined
  onChange: (event) => void
  smallerScale?: boolean
}

/**
 * Number slider field component.
 *
 * @param type - Type of the slider.
 * @param value - Value of the slider.
 * @param onChange - Function to be called when the value of the slider changes.
 * @param smallerScale - Whether the slider should be smaller.
 * @returns The number slider field component.
 */
export const NumberSliderField: React.FC<OwnProps & Omit<ComponentProps<typeof TextField>, keyof OwnProps>> = ({
  type,
  value,
  onChange,
  smallerScale,
}) => {
  const { t } = useTranslation()

  let marks
  if (type === 'year') {
    marks = [
      {
        value: 0,
        label: `0 ${t('years')}`,
      },
      {
        value: 10,
        label: `10 ${t('years')}`,
      },
    ]
  } else {
    marks = [
      {
        value: 0,
        label: `0`,
      },
      {
        value: 5,
        label: `5`,
      },
    ]
  }

  return (
    <Box sx={{ width: smallerScale ? 200 : 300 }}>
      <Slider
        aria-label="slider"
        defaultValue={0}
        step={1}
        marks={marks}
        value={value}
        min={0}
        max={type === 'year' ? 10 : 5}
        onChange={onChange}
        valueLabelDisplay="auto"
      />
    </Box>
  )
}
