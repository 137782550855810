import { Chip, Grid, styled } from '@mui/material'
import React from 'react'
import { ILanguageSkill } from 'types/cvsInterfaces'
import { languageLevels } from 'utils/utils'

const ChipSkillsListItem = styled(Chip)(() => ({
  borderRadius: '4px',
  height: '27px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '4px',
  float: 'left',
}))

const SpanSkillsListText = styled('span')(({ theme }) => ({
  fontSize: '0.813rem',
  fontFamily: 'Helvetica',
  letterSpacing: '0px',
  color: theme.palette.background.default,
  padding: '8px 0px',
}))

const SpanSkillsListBoldText = styled('span')(({ theme }) => ({
  fontSize: '0.813rem',
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
  letterSpacing: '0px',
  marginLeft: '3px',
  color: theme.palette.background.default,
  padding: '8px 0px',
}))

/** @notExported */
interface INewLanguageItemProps {
  /** Language skill. */
  skill: null | ILanguageSkill
  /** Function for deleting language skill. */
  onDeleteClick?: () => void
  /** List of languages. */
  languages
}

/**
 * New language item component.
 *
 * @returns New language item component.
 * @notExported
 */
const NewLanguageItem: React.FC<INewLanguageItemProps> = ({ skill, onDeleteClick, languages }) => {
  const levels = languageLevels()

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
      <ChipSkillsListItem
        onDelete={onDeleteClick}
        label={
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <SpanSkillsListText>
              {languages.find(item => item.languageCode === skill?.languageCode)?.label}
            </SpanSkillsListText>
            <SpanSkillsListBoldText>
              {levels.find(levelValue => levelValue.level === skill?.level)?.label}
            </SpanSkillsListBoldText>
          </Grid>
        }
        color="warning"
      />
    </Grid>
  )
}

export default NewLanguageItem
