import React from 'react'
import { useTranslation } from 'react-i18next'
import { IReference } from 'types/cvsInterfaces'
import { useDBTranslation } from 'utils/translations'
import withProfileCardItem from 'Components/reusable/HOC/withProfileCardItem'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import ExtendedCardContent from 'Components/reusable/CaleoCustomComponents/ExtendedCardContent'
import { Grid } from '@mui/material'

/** @notExported */
interface IReferenceItemCardProps {
  /** The reference object. */
  item: IReference
}

/**
 * The ReferenceItemCard component.
 *
 * @param item - The reference object.
 * @returns The ReferenceItemCard component.
 * @notExported
 */
const ReferenceItemCard: React.FC<IReferenceItemCardProps> = ({ item }) => {
  const { t } = useTranslation()

  const referenceTranslation = useDBTranslation(item.translations)

  const getContent = () => {
    return (
      <>
        <CardContentText type={'header'}>
          {item.firstName} {item.lastName}
        </CardContentText>
        <Grid>
          <CardContentText fontWeight={'bold'}>
            {t('profile.reference.modal.company')}
            {': '}{' '}
          </CardContentText>
          <CardContentText>{item.company}</CardContentText>
        </Grid>
      </>
    )
  }

  return <ExtendedCardContent description={referenceTranslation.letter}>{getContent()}</ExtendedCardContent>
}

export default withProfileCardItem(ReferenceItemCard)
