import en from './en'
import fi from './fi'

/**
 * English and Finnish translations for application text
 * @notExported
 */
const translations = Object.freeze({
  en: { translations: en },
  // sv: { translations: sv },
  fi: { translations: fi },
})

export default translations
