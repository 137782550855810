import React, { useEffect, useState } from 'react'
import newWithSimpleModal from 'Components/reusable/HOC/newWithSimpleModal'
import { DialogContent } from '@mui/material'
import { IError } from 'types/error'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { IUpdateHistory } from 'types/cvsInterfaces'
import { cvAPI } from 'api/CvAPI'
import CustomTable from 'Components/reusable/Tables/CustomTable'
import { useTranslation } from 'react-i18next'
import { convertToDisplayDate } from 'utils/utils'
import { ColumnDef, PaginationState, VisibilityState } from '@tanstack/react-table'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported */
interface ICvUpdateHistoryModalProps {
  /** Cv id */
  cvId: number
}

/**
 * Cv update history modal.
 *
 * @returns Cv update history modal.
 * @notExported
 */
const CvUpdateHistoryModal: React.FC<ICvUpdateHistoryModalProps> = ({ cvId }) => {
  const isComponentMounted = useIsComponentMounted()
  const { t, i18n } = useTranslation()

  const [data, setData] = useState<IUpdateHistory[]>()
  const [backendError, setBackendError] = useState<IError>()
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  })

  useEffect(() => {
    const controller = new AbortController()

    if (cvId) {
      ;(async () => {
        try {
          const histories = await cvAPI.getUpdateHistory(cvId, controller)

          if (!isComponentMounted.current) return
          setData(histories)
        } catch (err) {
          setBackendError(err as IError)
        }
      })()
    }

    return () => {
      controller.abort()
    }
  }, [cvId])

  const columns = React.useMemo<ColumnDef<IUpdateHistory>[]>(
    () => [
      {
        id: 'date',
        header: t('cvHistory.date'),
        accessorFn: row => {
          return convertToDisplayDate(row.createdAt)
        },
      },
      {
        id: 'editor',
        header: t('cvHistory.editor'),
        accessorFn: row => {
          if (row.Account) {
            return `${row.Account.Person?.firstName} ${row.Account.Person?.lastName}`
          }
        },
      },
      {
        id: 'section',
        header: t('cvHistory.section'),
        accessorFn: row => {
          return t(`cvHistory.${row.section}`)
        },
      },
    ],
    [i18n.language]
  )

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  if (!data) {
    return null
  }

  return (
    <DialogContent>
      <CustomTable
        columns={columns}
        data={data}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        setPagination={setPagination}
        pageIndex={pageIndex}
        pageSize={pageSize}
        elevation={0}
        newStyle
      />
    </DialogContent>
  )
}

export default newWithSimpleModal(CvUpdateHistoryModal)
