import { Grid, Tabs, Tab, FormControlLabel, Checkbox, Typography, Select, MenuItem, useTheme } from '@mui/material'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import AllocationScopePicker from 'Components/reusable/InputFields/AllocationScopePicker'
import RegularCheckbox from 'Components/reusable/InputFields/RegularCheckbox'
import { useUser } from 'hooks'
import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IAllocationManagementTableData,
  IAllocation,
  IAllocationScopeItem,
  IAllocationFilters,
} from 'types/allocationInterfaces'
import { IError } from 'types/error'
import PeopleAllocationTable from './PeopleAllocationTable'
import { personAPI } from 'api/PersonAPI'
import { IPerson } from 'types/userInterfaces'
import OverviewSummary from './OverviewSummary/OverviewSummary'
import { useLocalStorage } from 'hooks/data'
import { useLoading } from 'Components/reusable/LoadingProvider'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported  */
interface IAllocationOverviewProps {
  /** Function to set the edited item  */
  setEditedItem: (value: IAllocation | null | undefined) => void
  /** Function to delete an item */
  deleteItem: (value: IAllocation) => Promise<void>
  /** Function to get the options for the scope picker*/
  getOptions: () => IAllocationScopeItem[]
  /** Sales access permissions */
  salesAccess: boolean
  /** Update from editing  */
  updateFromEditing: Date | undefined
}

/**
 * Allocation overview component.
 *
 * @returns Allocation overview component.
 * @notExported
 */
const AllocationOverview: React.FC<IAllocationOverviewProps> = ({
  setEditedItem,
  deleteItem,
  getOptions,
  salesAccess,
  updateFromEditing,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const theme = useTheme()
  const { user } = useUser()
  const { t } = useTranslation()

  const [backendError, setBackendError] = useState<IError>()
  const [showOutdated, setShowOutdated] = useState<boolean>(false)
  const [showActive, setShowActive] = useState<boolean>(true)
  const [selectedScope, setSelectedScope] = useState<{ value: number; label: string; type: string }>()
  const [managementTableData, setManagementTableData] = useState<IAllocationManagementTableData[]>([])
  const [overviewUpdate, setOverviewUpdate] = useState<boolean>(false)
  const { startLoading, endLoading } = useLoading()
  const [tab, setTab] = useState<number>(0)
  const [personIds, setPersonIds] = useState<number[]>([])
  const [timeScope, setTimeScope] = useState<number>(1)
  const [filters, setFilters] = useLocalStorage('filters', {
    other: true,
    assignment: true,
    absence: true,
    free: true,
    partial: true,
    full: true,
    verified: true,
    tentative: true,
  })

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        if (overviewUpdate || updateFromEditing) {
          startLoading('allocationOverview')
          const personIds: number[] = []
          let peopleWithAllocations: IPerson[] = []

          if (selectedScope?.type === 'own-company' && user) {
            peopleWithAllocations = await personAPI.getCompanyMembersAllocations(user.organizationId, controller)
          }

          if (selectedScope?.type === 'team' && selectedScope?.value) {
            peopleWithAllocations = await personAPI.getTeamMembersAllocations(selectedScope.value, controller)
          } else if (selectedScope?.type === 'company' && selectedScope?.value) {
            peopleWithAllocations = await personAPI.getCompanyMembersAllocations(selectedScope.value, controller)
          }

          if (!isComponentMounted.current) return

          if (peopleWithAllocations.length) {
            const arrayOfAllocations: IAllocationManagementTableData[] = []

            for (const person of peopleWithAllocations) {
              if (person?.Allocations?.length) {
                for (const alloc of person.Allocations) {
                  if (alloc.startDate) alloc.startDate = new Date(alloc.startDate)
                  if (alloc.endDate) alloc.endDate = new Date(alloc.endDate)

                  arrayOfAllocations.push({
                    firstName: person.firstName,
                    lastName: person.lastName,
                    id: person.id,
                    Allocation: alloc,
                  })
                }
              } else {
                arrayOfAllocations.push({ firstName: person.firstName, lastName: person.lastName, id: person.id })
              }

              personIds.push(person.id)
            }
            setPersonIds(personIds)
            setManagementTableData(arrayOfAllocations)
          } else {
            setManagementTableData([])
          }
        }
      } catch (error) {
        setBackendError(error as IError)
      } finally {
        if (isComponentMounted.current) {
          setOverviewUpdate(false)
          endLoading('allocationOverview')
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [overviewUpdate, updateFromEditing])

  const tableData: IAllocationManagementTableData[] = useMemo(() => {
    if (!showOutdated && showActive) {
      return managementTableData?.filter(a => !a?.Allocation?.endDate || a?.Allocation?.endDate >= new Date())
    } else if (showOutdated && showActive) {
      return managementTableData
    } else if (!showActive && showOutdated) {
      return managementTableData?.filter(a => !a?.Allocation?.endDate || a?.Allocation?.endDate <= new Date())
    }
    return []
  }, [managementTableData, showOutdated, showActive])

  const changeTab = (_event: unknown, newValue: number) => {
    setTab(newValue)
  }

  const changeFilter = (filter: string) => {
    const newFilters: IAllocationFilters = { ...filters }

    newFilters[filter] = !newFilters[filter]
    setFilters(newFilters)
  }

  const filterControls = () => {
    return (
      <Grid item xs={12}>
        <Grid item xs="auto" container alignItems="center" spacing={1}>
          <Grid item xs={12} lg={3} sx={{ borderRight: '1px solid #000', marginRight: 2 }} container>
            <Grid item xs={12}>
              <Typography fontWeight="bold">{t('allocation.state')}</Typography>
            </Grid>
            <Grid item xs="auto">
              <FormControlLabel
                label={t(`allocation.tentative`)}
                control={<Checkbox checked={filters['tentative']} onChange={() => changeFilter('tentative')} />}
              />
            </Grid>
            <Grid item xs="auto">
              <FormControlLabel
                label={t(`allocation.verified`)}
                control={<Checkbox checked={filters['verified']} onChange={() => changeFilter('verified')} />}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ borderRight: '1px solid #000', marginRight: 2 }} container>
            <Grid item xs={12}>
              <Typography fontWeight="bold">{t('allocation.type')}</Typography>
            </Grid>
            <Grid item xs="auto">
              <FormControlLabel
                label={t(`allocation.otherWork`)}
                control={<Checkbox checked={filters['other']} onChange={() => changeFilter('other')} />}
              />
            </Grid>
            <Grid item xs="auto">
              <FormControlLabel
                label={t(`allocation.assignment`)}
                control={<Checkbox checked={filters['assignment']} onChange={() => changeFilter('assignment')} />}
              />
            </Grid>
            <Grid item xs="auto">
              <FormControlLabel
                label={t(`allocation.absence`)}
                control={<Checkbox checked={filters['absence']} onChange={() => changeFilter('absence')} />}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3} sx={{ borderRight: '1px solid #000', marginRight: 2 }} container>
            <Grid item xs={12}>
              <Typography fontWeight="bold">{t('allocation.percentage')}</Typography>
            </Grid>
            <Grid item xs="auto">
              <FormControlLabel
                label={t(`allocation.free`)}
                control={<Checkbox checked={filters['free']} onChange={() => changeFilter('free')} />}
              />
            </Grid>
            <Grid item xs="auto">
              <FormControlLabel
                label={t(`allocation.partial`)}
                control={<Checkbox checked={filters['partial']} onChange={() => changeFilter('partial')} />}
              />
            </Grid>
            <Grid item xs="auto">
              <FormControlLabel
                label={t(`allocation.full`)}
                control={<Checkbox checked={filters['full']} onChange={() => changeFilter('full')} />}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={1} container>
            <Grid item xs={12}>
              <Typography fontWeight="bold">{t('allocation.overview.timeScope')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Select
                onChange={event => {
                  if (event) setTimeScope(event.target.value as number)
                }}
                value={timeScope}
                size="small"
                fullWidth
              >
                <MenuItem value={1}>{t('oneMonth')}</MenuItem>
                <MenuItem value={2}>{t('twoMonths')}</MenuItem>
                <MenuItem value={3}>{t('threeMonths')}</MenuItem>
                <MenuItem value={4}>{t('fourMonths')}</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const legend = () => {
    return (
      <Grid item xs={12} md={6} lg={4}>
        <Grid container alignItems="end">
          <Grid item xs={3} container wrap="nowrap" alignItems="center">
            <Grid
              item
              sx={{
                width: '15px',
                height: '15px',
                borderRadius: '30%',
                display: 'inline-block',
                opacity: 0.5,
                backgroundColor: theme.palette.background.default,
                border: `1px solid ${theme.palette.primary.main}`,
              }}
            />
            <Grid item sx={{ marginRight: '25px', marginLeft: '5px' }}>
              0%
            </Grid>
          </Grid>
          <Grid item xs={3} container wrap="nowrap" alignItems="center">
            <Grid
              item
              sx={{
                width: '15px',
                height: '15px',
                borderRadius: '30%',
                display: 'inline-block',
                backgroundColor: theme.palette.warning.main,
              }}
            />
            <Grid item sx={{ marginRight: '25px', marginLeft: '5px' }}>
              1-99%
            </Grid>
          </Grid>
          <Grid item xs={3} container wrap="nowrap" alignItems="center">
            <Grid
              item
              sx={{
                width: '15px',
                height: '15px',
                borderRadius: '30%',
                display: 'inline-block',
                backgroundColor: theme.palette.error.main,
              }}
            />
            <Grid item sx={{ marginRight: '25px', marginLeft: '5px' }}>
              100%
            </Grid>
          </Grid>
          <Grid item xs={3} container wrap="nowrap" alignItems="center">
            <Grid
              item
              sx={{
                width: '15px',
                height: '15px',
                borderRadius: '30%',
                opacity: 0.8,
                display: 'inline-block',
                backgroundColor: theme.palette.info.main,
              }}
            />
            <Grid item sx={{ marginRight: '25px', marginLeft: '5px' }}>
              100+%
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold" mt={2}>
            {t('allocation.filter')}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <AllocationScopePicker
            value={selectedScope}
            onChange={scope => {
              setSelectedScope(scope)
              setOverviewUpdate(true)
            }}
            items={getOptions() || []}
            label={t('allocation.scope')}
            insetLabel
          />
        </Grid>
        {tab === 1 && (
          <>
            <Grid item>
              <RegularCheckbox
                checked={showActive}
                onChange={() => setShowActive(!showActive)}
                label={t(`allocation.showActive`)}
              />
            </Grid>
            <Grid item>
              <RegularCheckbox
                checked={showOutdated}
                onChange={() => setShowOutdated(!showOutdated)}
                label={t(`allocation.showOutdated`)}
              />
            </Grid>
          </>
        )}
        {tab === 0 && filterControls()}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold" mt={2}>
          {t('allocation.results')}
        </Typography>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={12} md={6} lg={8}>
          <Tabs value={tab} onChange={changeTab} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
            <Tab label={t('allocation.overview.summary')} />
            <Tab label={t('allocation.overview.edit')} />
          </Tabs>
        </Grid>
        {tab === 0 && legend()}
      </Grid>
      {tab === 0 && (
        <OverviewSummary
          filters={filters}
          timeScope={timeScope}
          scope={personIds}
          deleteItem={deleteItem}
          setEditedItem={setEditedItem}
          updateFromEditing={updateFromEditing}
        />
      )}
      {tab === 1 && (
        <>
          {user?.Person?.id && (
            <PeopleAllocationTable
              items={tableData}
              openItem={(item: IAllocation) => {
                setEditedItem(item)
              }}
              deleteItem={deleteItem}
              personId={user.Person.id}
              salesAccess={salesAccess}
            />
          )}
        </>
      )}
    </>
  )
}

export default AllocationOverview
