import { Alert, Grid, Snackbar } from '@mui/material'
import React, { useContext } from 'react'
import { ChatContext } from 'Components/General/ChatContext'

const ChatNotification: React.FC = () => {
  const { showNotification, closeNotification, notificationData } = useContext(ChatContext)

  if (!notificationData) {
    return null
  }

  let duration: number | undefined = 3000
  if (notificationData.duration === 'short') {
    duration = 2000
  } else if (notificationData.duration === 'long') {
    duration = 5000
  } else if (notificationData.duration === 'indefinite') {
    duration = undefined
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={showNotification}
      autoHideDuration={duration}
      onClose={closeNotification}
    >
      <Alert elevation={6} variant="filled" severity={notificationData.type ?? 'info'} onClose={closeNotification}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {notificationData.message}
          </Grid>
          <Grid item xs={12} justifySelf="flex-end">
            {notificationData.action}
          </Grid>
        </Grid>
      </Alert>
    </Snackbar>
  )
}

export default ChatNotification
