import { Grid } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IProject } from 'types/cvsInterfaces'
import { useDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'
import withProfileCardItem from 'Components/reusable/HOC/withProfileCardItem'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import ExtendedCardContent from 'Components/reusable/CaleoCustomComponents/ExtendedCardContent'
import SkillsItem from '../SkillsCard/SkillsItem'

/** @notExported */
interface IProjectItemCardProps {
  /** Project to display. */
  project: IProject
  /** If true, project card is anonymized. */
  anonymous: boolean
}

/**
 * CV project card.
 *
 * @returns Project card component.
 * @notExported
 */
const ProjectItemCard: React.FC<IProjectItemCardProps> = ({ project, anonymous }) => {
  const { t } = useTranslation()
  const translation = useDBTranslation(project)
  const description = !anonymous ? translation.description : ''

  const getSkills = () => {
    return (
      <div style={{ paddingLeft: '16px' }}>
        {translation.primaryRole.length > 0 && (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
            spacing={1}
          >
            <Grid item>
              <CardContentText fontWeight={'bold'}>
                {t('profile.project.modal.primaryRole')}
                {':'}
              </CardContentText>
            </Grid>
            <Grid item>
              <CardContentText> {translation.primaryRole}</CardContentText>
            </Grid>
          </Grid>
        )}
        {project.ProjectRoles && project.ProjectRoles.length > 0 && (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <CardContentText fontWeight={'bold'}>
              {t('profile.project.modal.roles')}
              {':  '}
            </CardContentText>
            {project.ProjectRoles?.map(role => (
              <SkillsItem key={role.id} skill={role} editable type="role" />
            ))}
          </Grid>
        )}
        {project.PersonSkills && project.PersonSkills.length > 0 && (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <CardContentText fontWeight={'bold'}>
              {t('profile.project.modal.skills')}
              {':  '}
            </CardContentText>
            {project.PersonSkills?.map(skill => (
              <SkillsItem key={skill.id} skill={skill} editable type="skill" />
            ))}
          </Grid>
        )}
        {project.Industries && project.Industries.length > 0 && (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <CardContentText fontWeight={'bold'}>
              {t('profile.project.modal.industries')}
              {':  '}
            </CardContentText>
            {project.Industries?.map(industry => (
              <SkillsItem key={industry.id} skill={industry} editable type="industry" />
            ))}
          </Grid>
        )}
      </div>
    )
  }

  return (
    <ExtendedCardContent description={description} skills={getSkills()}>
      <CardContentText type={'header'}>{!anonymous ? translation.name : ''}</CardContentText>
      <CardContentText type={'date'}>
        {convertToDisplayDate(project.startDate, project.dateResolution)} -{' '}
        {project.endDate ? convertToDisplayDate(project.endDate, project.dateResolution) : '...'}
      </CardContentText>
      {!anonymous && (
        <>
          <div>
            <CardContentText fontWeight={'bold'}>
              {t('profile.project.modal.customerName')}
              {':  '}
            </CardContentText>
            <CardContentText>{translation?.customerName}</CardContentText>
          </div>
          <div>
            <CardContentText fontWeight={'bold'}>
              {t('profile.project.modal.employerName')}
              {':  '}
            </CardContentText>
            <CardContentText>{translation?.employerName}</CardContentText>
          </div>
        </>
      )}
    </ExtendedCardContent>
  )
}

export default withProfileCardItem(ProjectItemCard)
