import { LogLevel } from '@azure/msal-browser'

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * @notExported
 */
export const msalConfig = {
  auth: {
    clientId: 'd5882781-bc26-413b-a014-2585e8b7faad', // This is the ONLY mandatory field that you need to supply. Could use different ID for each environment
    authority: 'https://login.microsoftonline.com/common', // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: '/healt', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
          case LogLevel.Verbose:
            console.info(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
        }
      },
    },

    windowHashTimeout: 9000, // Applies just to popup calls - In milliseconds
    iframeHashTimeout: 9000, // Applies just to silent calls - In milliseconds
    loadFrameTimeout: 9000, // Applies to both silent and popup calls - In milliseconds
  },
}

/**
 * Scopes for Azure AD login
 * @notExported
 */
export const scopes = ['6a60052b-9a03-4e25-84c2-ec3e2413dd5b/Caleo.API']
