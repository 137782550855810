import { SubAPI } from './API'
import {
  IReference,
  IProject,
  IEducation,
  ICourse,
  ICertificate,
  IPersonSkill,
  IEmployer,
  ILanguageSkill,
  ICv,
  IPersonIndustry,
  IPersonRole,
  ICvTranslation,
  IUpdateHistory,
} from 'types/cvsInterfaces'
import { About } from 'Components/General/ProfileCard'
import { CvId } from 'types/ids'
import { INetwork } from 'types/networkInterfaces'
import { updateDate } from '../utils/utils'

type CvSubObject = {
  id: number
}

interface CvSubAPIOptions<T> {
  dateFields?: (keyof T)[]
}

/**
 * CV sub API.
 * @notExported
 */
class CvSubAPI<T extends CvSubObject> extends SubAPI {
  urlPart: string
  options?: CvSubAPIOptions<T>

  constructor(urlPart: string, options?: CvSubAPIOptions<T>) {
    super()

    this.urlPart = urlPart
    this.options = options
  }

  /**
   * Creates a new object in the CV.
   *
   * @param cvId - CV ID.
   * @param data - Data to create the object with.
   * @returns Created object.
   */
  public async create(cvId: number, data?: unknown): Promise<T> {
    return this.convertFromAPI(await this.api.post(`cvs/${cvId}/${this.urlPart}`, data))
  }

  /**
   * Updates an object in the CV.
   *
   * @param item - Object to update.
   * @param data - Data to update the object with.
   * @returns Updated object.
   */
  public async save(item: T, data?: unknown): Promise<T> {
    return this.convertFromAPI(await this.put(item.id, item, data))
  }

  /**
   * Updates an object in the CV.
   *
   * @param id - Object ID.
   * @param item - Object to update.
   * @param data - Data to update the object with.
   * @returns Updated object.
   */
  public async put(id: number, item: T, data?: unknown): Promise<T> {
    return this.convertFromAPI(await this.api.put(`cvs/${this.urlPart}/${id}`, data ?? item))
  }

  /**
   * Gets an object from the CV.
   *
   * @param id - Object ID.
   * @returns Object.
   */
  public async get(id: number): Promise<T> {
    return this.convertFromAPI(await this.api.get(`cvs/${this.urlPart}/${id}`))
  }

  /**
   * Gets a list of objects from the CV.
   *
   * @param cvId - CV ID.
   * @returns List of objects.
   */
  public async getList(cvId: number, controller?: AbortController): Promise<T[]> {
    const data = (await this.api.get(
      `/cvs/${cvId}/${this.urlPart}`,
      controller ? { signal: controller.signal } : undefined
    )) as T[]
    return data && data.length ? data.map(item => this.convertFromAPI(item)) : []
  }

  /**
   * Gets anonymous list of objects from the CV.
   *
   * @param anonymousCvId - Anonymous CV ID.
   * @returns List of objects.
   */
  public async getAnonymousList(anonymousCvId: string): Promise<T[]> {
    const data = (await this.api.get(`/cvs/anonymous/${anonymousCvId}/${this.urlPart}`)) as T[]
    return data && data.length ? data.map(item => this.convertFromAPI(item)) : []
  }

  /**
   * Deletes an object from the CV.
   *
   * @param id - Object ID.
   * @returns Promise of deletion.
   */
  public delete(id: number): Promise<void> {
    return this.api.delete(`/cvs/${this.urlPart}/${id}`)
  }

  /**
   * Mass update CV objects.
   *
   * @param cvId - CV ID.
   * @param data - Data to update the objects with.
   * @returns Updated objects.
   */
  public massUpdate(cvId: number, data?: unknown, controller?: AbortController): Promise<T[]> {
    return this.api.put(
      `/cvs/${cvId}/${this.urlPart}/massupdate`,
      data,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Converts API result date strings to date objects.
   *
   * @param rawData - API result.
   * @returns CV object.
   */
  convertFromAPI(rawData: unknown): T {
    if (typeof rawData !== 'object' || rawData === null) {
      throw new Error('API result was unexpectedly not an object!')
    }

    const data = rawData as Record<keyof T, string | Date | null>

    for (const field of this.options?.dateFields ?? []) {
      const currentValue = data[field] as string | Date | null
      if (currentValue instanceof Date) {
        continue
      }

      if (currentValue === null) {
        continue
      }

      data[field] = new Date(currentValue)
    }

    return data as unknown as T
  }
}

/**
 * CV API.
 * @notExported
 */
class CvAPI extends SubAPI {
  /**
   * Gets CV data.
   *
   * @param id - CV ID.
   * @returns CV data.
   */
  public async getCvData(id: number, controller?: AbortController): Promise<ICv | undefined> {
    const data: ICv = await this.api.get(`cvs/${id}`, controller ? { signal: controller.signal } : undefined)
    if (data) {
      data.Education.forEach(updateDate)
      data.Courses.forEach(updateDate)
      data.Certificates.forEach(item => {
        if (item.issueDate && !(item.issueDate instanceof Date)) item.issueDate = new Date(item.issueDate)
        if (item.endDate && !(item.endDate instanceof Date)) item.endDate = new Date(item.endDate)
      })
      data.Projects.forEach(updateDate)
      data.Employers.forEach(updateDate)
      return data
    }
    return data
  }

  /**
   * Gets CV about data.
   *
   * @param id - CV ID.
   * @returns CV about data.
   */
  public getCvAbout(id: number, controller?: AbortController): Promise<About> {
    return this.api.get(`cvs/${id}/about`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Gets user about data.
   *
   * @param {AbortController} controller - Optional AbortController for the request.
   * @return {Promise<About>} Promise that resolves to the user about data.
   */
  public getUserAbout(controller?: AbortController): Promise<About> {
    return this.api.get(`cvs/user/about`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Gets CV about data by public ID.
   *
   * @param publicId - CV public ID.
   * @returns CV about data.
   */
  public getCvPublicIdAbout(publicId: string, controller?: AbortController): Promise<About> {
    return this.api.get(`cvs/publicId/${publicId}/about`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Gets anonymous CV about data by public ID.
   *
   * @param publicId - CV public ID.
   * @returns CV about data.
   */
  public getAnonymousAbout(publicId: string, controller?: AbortController): Promise<About> {
    return this.api.get(`cvs/publicId/${publicId}/anonymous`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Updates CV about data.
   *
   * @param id - CV ID.
   * @param data - CV about data.
   * @returns Updated CV about data.
   */
  public setCvAbout(id: number, data: unknown, controller?: AbortController): Promise<string> {
    return this.api.put(`cvs/${id}/about`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Updates CV state.
   *
   * @param id - CV ID.
   * @param data - CV state.
   * @returns Promise of update.
   */
  public putCvState(id: number, data: unknown): Promise<void> {
    return this.api.put(`cvs/${id}/state`, data)
  }

  /**
   * Gets CV updated at.
   *
   * @param id - CV ID.
   * @returns Updated at.
   */
  public getUpdatedAt(id: number, controller?: AbortController): Promise<string> {
    return this.api.get(`/cvs/updated/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Mass create person skills.
   *
   * @param data - Skills data.
   * @param cvId - CV ID.
   * @returns Array of created person skills.
   */
  public postAllSelectionsToCv(data: unknown, cvId: CvId): Promise<IPersonSkill[]> {
    return this.api.post(`cvs/${cvId}/personSkills/masscreate`, data)
  }

  /**
   * Update CV description.
   *
   * @param cvId - CV ID.
   * @param data - CV description.
   * @returns Promise of update.
   */
  public putDescription(cvId: number, data?: ICvTranslation): Promise<void> {
    return this.api.put(`/cvs/${cvId}/description`, data)
  }

  /**
   * Update CV primary role.
   *
   * @param cvId - CV ID.
   * @param data - CV primary role.
   * @returns Promise of update.
   */
  public putRole(cvId: number, data?: ICvTranslation): Promise<void> {
    return this.api.put(`/cvs/${cvId}/primaryRole`, data)
  }

  /**
   * Update CV pricing.
   *
   * @param cvId - CV ID.
   * @param data - CV pricing.
   * @returns Promise of update.
   */
  public putPrices(cvId: number, data?: { minPrice: number; maxPrice: number }): Promise<void> {
    return this.api.put(`/cvs/${cvId}/pricing`, data)
  }

  /**
   * Update CV remote work availability.
   *
   * @param cvId - CV ID.
   * @param data - CV remote work availability.
   * @returns Promise of update.
   */
  public putRemoteWorkAvailability(cvId: number, data: unknown, controller?: AbortController): Promise<void> {
    return this.api.put(`/cvs/${cvId}/remoteWork`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get update history for CV.
   *
   * @param cvId - CV ID.
   * @returns Array of update histories.
   */
  public getUpdateHistory(cvId: number, controller?: AbortController): Promise<IUpdateHistory[]> {
    return this.api.get(`/cvs/update-history/${cvId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get public skills for CV.
   *
   * @param cvId - CV ID.
   * @returns Array of public skills.
   */
  public getPublicSkills(cvId: number, controller?: AbortController): Promise<IPersonSkill[]> {
    return this.api.get(`/salespool/personSkills/${cvId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get network price visible for CV.
   *
   * @param cvId - CV ID.
   * @returns Networks CV prices are visible.
   */
  public getNetworkPriceVisible(cvId: number, controller?: AbortController): Promise<INetwork[]> {
    return this.api.get(`/cvs/networkprice/${cvId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Convert CV employer to project.
   *
   * @param employerId - CV employer ID.
   * @returns Converted CV project.
   */
  public convertToProject(employerId: number, controller?: AbortController): Promise<IProject> {
    return this.api.put(`/cvs/convertproject/${employerId}`, {}, controller ? { signal: controller.signal } : undefined)
  }
}

export const cvAPI = new CvAPI()

export const cvEducationsAPI = new CvSubAPI<IEducation>('educations', {
  dateFields: ['startDate', 'endDate'],
})
export const cvCoursesAPI = new CvSubAPI<ICourse>('courses', {
  dateFields: ['startDate', 'endDate'],
})
export const cvCertificatesAPI = new CvSubAPI<ICertificate>('certificates', {
  dateFields: ['issueDate', 'endDate'],
})
export const cvPersonSkillsAPI = new CvSubAPI<IPersonSkill>('personSkills')
export const cvIndustriesAPI = new CvSubAPI<IPersonIndustry>('industries')
export const cvRolesAPI = new CvSubAPI<IPersonRole>('roles')
export const cvProjectsAPI = new CvSubAPI<IProject>('projects', {
  dateFields: ['startDate', 'endDate'],
})
export const cvEmployersAPI = new CvSubAPI<IEmployer>('employers', {
  dateFields: ['startDate', 'endDate'],
})
export const cvLanguageSkillsAPI = new CvSubAPI<ILanguageSkill>('languageSkills')
export const cvReferencesAPI = new CvSubAPI<IReference>('references')
