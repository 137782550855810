import React from 'react'
import { Tooltip, TooltipProps } from '@mui/material'

/**
 * Custom tooltip component.
 *
 * @param children - Tooltip content.
 * @param props - Tooltip props.
 * @returns Custom tooltip element.
 * @notExported
 */
export const CustomTooltip: React.FC<TooltipProps> = ({ children, ...props }) => {
  return (
    <Tooltip
      {...props}
      sx={{
        backgroundColor: 'background.default',
        color: 'text.primary',
        maxWidth: 350,
        border: `1px solid ${'neutral.light'}`,
      }}
    >
      {children}
    </Tooltip>
  )
}
