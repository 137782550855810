import { SubAPI } from './API'
import { ITermsAndPolicy, ITermsAndPolicyAcceptance } from 'types/termsAndPolicyInterfaces'

/**
 * Terms and Policy API
 * @notExported
 */
class TermsAndPolicyAPI extends SubAPI {
  /**
   * Get global terms and policies.
   *
   * @returns Array of terms and policies.
   */
  public getGlobalTermsAndPolicies(controller?: AbortController): Promise<ITermsAndPolicy[]> {
    return this.api.get('terms-policies/global', controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get organization terms and policies.
   *
   * @param organizationId - Organization ID.
   * @returns Array of terms and policies.
   */
  public getOrganizationTermsAndPolicies(
    organizationId: number,
    controller?: AbortController
  ): Promise<ITermsAndPolicy[]> {
    return this.api.get(
      `terms-policies/organization/${organizationId}`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get users terms and policy acceptances.
   *
   * @param userId - User ID.
   * @returns Array of terms and policy acceptances.
   */
  public getUsersTermsAndPolicies(userId: number, controller?: AbortController): Promise<ITermsAndPolicyAcceptance[]> {
    return this.api.get(`terms-policies/user/${userId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Update user terms and policy acceptances.
   *
   * @param userId - User ID.
   * @param data - Acceptance data.
   * @returns Promise of acceptance update.
   */
  public updateAcceptances(userId: number, data: unknown, controller?: AbortController): Promise<void> {
    return this.api.post(
      `terms-policies/user/${userId}/acceptances`,
      data,
      controller ? { signal: controller.signal } : undefined
    )
  }
}

export const termsAndPolicyApi = new TermsAndPolicyAPI()
